import { Control } from 'react-hook-form';
import { LwFormNumberInput } from 'redesign';
import { HelperTexts } from 'shared/components/shift-forms/components/forms/shared/types';
import { ProjectFormTypeAll } from '../../types/project-form.types';

export const HourlyRateElement = ({
  control,
  disabled,
  existingRate,
  helperTexts,
  required,
}: {
  control: Control<ProjectFormTypeAll>;
  disabled: boolean;
  existingRate: number | undefined;
  helperTexts: HelperTexts;
  required: boolean;
}) => {
  const requiredMessage = "Voer tarief zzp'er in";

  return (
    <LwFormNumberInput
      name="hourlyRate"
      label={"Tarief zzp'er"}
      control={control}
      rules={{ required: required ? requiredMessage : false }}
      step={0.01}
      min={0}
      placeholder={`${(existingRate || 0) / 100}`}
      helperText={helperTexts.hourlyRate}
      data-testid="hourly-rate"
      disabled={disabled}
    />
  );
};
