import { LevelTableCellProps } from 'redesign';
import { DateTime } from 'luxon';
import { InfiniteQueryObserverSuccessResult } from 'react-query';
import {
  GetTableShiftPlanningListItemsResponse,
  TableShiftPlanningListItem,
} from 'ogp/services/shift-service.types';

export const INITIAL_PAGE = 0;

export type TableShiftPlanningColumnDefinition = TableShiftPlanningShift & {
  id: string;
  action: string;
};

export type TableShiftPlanningCellProp<T extends keyof TableShiftPlanningColumnDefinition> =
  LevelTableCellProps<
    TableShiftPlanningColumnDefinition,
    TableShiftPlanningColumnDefinition[T],
    TableShiftPlanningOptions
  >;

type TableShiftPlanningShift = Omit<TableShiftPlanningListItem, 'dateTime'> & {
  dateTime: {
    fromDateTime: DateTime;
    toDateTime: DateTime;
  };
};

export type TableShiftPlanningOptions = {
  onShowDetail: (shiftId: string) => void;
};

export type TableShiftPlanningProps = {
  shiftsQuery: InfiniteQueryObserverSuccessResult<GetTableShiftPlanningListItemsResponse, unknown>;
  onSelectionChange: (ids: string[]) => void;
  selectedShiftIds: string[];
};
