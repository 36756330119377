import { QUERY_KEYS } from 'api/query-keys';
import { flexPoolService } from 'ogp/services';
import { useQuery, UseQueryOptions } from 'react-query';
import { FlexWorkerFlexPools } from '@types';

export const useOgpGetFlexWorkerFlexPools = (
  flexWorkerId: string,
  shiftId: string,
  options: UseQueryOptions<FlexWorkerFlexPools[], Error> = {}
) => {
  const queryFn = async () => await flexPoolService.getFlexWorkerFlexPools(flexWorkerId, shiftId);

  return useQuery<FlexWorkerFlexPools[], Error>(
    QUERY_KEYS.flexWorkerWithFlexPoolsForShift(flexWorkerId, shiftId),
    queryFn,
    {
      enabled: !!flexWorkerId.length && !!shiftId.length,
      ...options,
    }
  );
};
