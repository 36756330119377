import { Box, styled, Tooltip, Typography } from '@mui/material';
import { formatCurrencyCents } from 'shared/utils/formatting';
import { IconDollar } from 'assets/img';

type HourlyRatesProps = {
  freelanceWage: number | undefined;
  tempworkWage: number | undefined;
  showTempWorkRate: boolean;
};

export const HourlyRates = ({
  freelanceWage,
  tempworkWage,
  showTempWorkRate,
}: HourlyRatesProps) => {
  const showFreelance = freelanceWage !== undefined;
  const showTempWork = showTempWorkRate && tempworkWage !== undefined;

  if (!showFreelance && !showTempWork) {
    return <StyledIconDollar width={24} height={24} />;
  }

  return (
    <>
      <StyledIconDollar width={24} height={24} />
      <Box display="flex" flexDirection="column">
        {showFreelance ? (
          <Tooltip title="Tarief zzp'er">
            <Typography
              noWrap
              variant="subtitle2"
              data-testid="hourly-rate"
            >{`${formatCurrencyCents(freelanceWage)} / uur`}</Typography>
          </Tooltip>
        ) : null}
        {showTempWork ? (
          <Tooltip title="Bruto uurloon">
            <Typography
              noWrap
              variant="subtitle2"
              data-testid="gross-hourly-rate"
            >{`${formatCurrencyCents(tempworkWage)} / uur`}</Typography>
          </Tooltip>
        ) : null}
      </Box>
    </>
  );
};

const StyledIconDollar = styled(IconDollar)(({ theme }) => ({
  marginRight: theme.spacing(3),
  flexShrink: 0,
}));
