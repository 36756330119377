import { Box, Typography } from '@mui/material';
import { useBulkActionResultContext } from 'shared/providers/bulk-action-provider';
export const SuccessfulBulkShiftActionsSection = () => {
  const { failure, fulfilled } = useBulkActionResultContext();

  const totalMutations = failure.length + fulfilled.length;
  const totalOfSuccesfulMutations = fulfilled.length;

  return (
    <Box>
      <Typography variant="h6">
        {totalOfSuccesfulMutations} van de {totalMutations} geselecteerde diensten zijn succesvol
        gewijzigd
      </Typography>
    </Box>
  );
};
