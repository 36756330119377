import { QUERY_KEYS } from 'api/query-keys';
import { sharedShiftService } from 'api/services';
import { GetShiftCreateData } from 'api/services/types';
import { useQuery, UseQueryResult } from 'react-query';
import { useAlert } from 'shared/components/alerts';
import { Site } from 'shared/hooks';

export function useGetWorkCreateData(
  site: Site,
  companyId?: string,
  enabled: boolean = true
): UseQueryResult<GetShiftCreateData> {
  const { alertError } = useAlert();

  return useQuery(
    QUERY_KEYS.workCreateData(companyId),
    async () => await sharedShiftService.getShiftCreateData(site, companyId),
    { enabled, onError: (e) => alertError(e) }
  );
}
