import { QUERY_KEYS } from 'api/query-keys';
import { shiftClaimService } from 'ogp/services';
import { useMutation, useQueryClient } from 'react-query';

export function useSettleCheckouts() {
  const queryClient = useQueryClient();

  return useMutation(async (ids: string[]) => await shiftClaimService.settleCheckouts(ids), {
    onSuccess: () => {
      // Wait for the refetch to be done so we have an updated screen after approving.
      queryClient.invalidateQueries(QUERY_KEYS.checkouts());
    },
  });
}
