import { QUERY_KEYS } from 'api/query-keys';
import { shiftApplicationService } from 'ogp/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

type ShiftApplicationMutationParams = {
  shiftApplicationId: string;
  shiftId: string;
  flexWorkerId: string;
};
type ShiftApplicationMutationOptions = UseMutationOptions<
  unknown,
  unknown,
  ShiftApplicationMutationParams
>;

export const useAcceptShiftApplication = (options?: ShiftApplicationMutationOptions) => {
  const mutationFn = async (params: ShiftApplicationMutationParams) =>
    await shiftApplicationService.acceptShiftApplication(
      params.shiftApplicationId,
      params.shiftId,
      params.flexWorkerId
    );

  const queryClient = useQueryClient();
  const { onSuccess, ...rest } = options ?? {};
  const mutationOptions: ShiftApplicationMutationOptions = {
    onSuccess: async (data, variables, context) => {
      queryClient.invalidateQueries(QUERY_KEYS.shifts());
      onSuccess?.(data, variables, context);
    },
    ...rest,
  };

  return useMutation(mutationFn, mutationOptions);
};
