import { useExternalParams } from 'shared/hooks/use-external-params';
import { useMemo } from 'react';
import { ShiftPlanningFilters } from './use-shift-planning.types';
import { stringifyFilters } from '../../../../../../../shared/utils/stringify-filters';
import { FilledStatus } from '../../../../../../../shared/components/filters/inputs/filled-status/filter-filled-status.types';

const initialValues = {
  fromDate: {
    initialValue: undefined,
    type: 'date',
  },
  company: {
    initialValue: undefined,
    type: 'string',
  },
  branchOffice: {
    initialValue: undefined,
    type: 'string',
  },
  department: {
    initialValue: undefined,
    type: 'string',
  },
  shiftDetailId: {
    initialValue: undefined,
    type: 'string',
  },
  filledStatus: {
    initialValue: undefined,
    type: 'string',
  },
  partner: {
    initialValue: undefined,
    type: 'string',
  },
  jobType: {
    initialValue: undefined,
    type: 'array',
  },
  isFreelance: {
    initialValue: undefined,
    type: 'boolean',
  },
  isTempWork: {
    initialValue: undefined,
    type: 'boolean',
  },
  areFreelanceApplications: {
    initialValue: undefined,
    type: 'boolean',
  },
  areTempworkApplications: {
    initialValue: undefined,
    type: 'boolean',
  },
  areNoApplications: {
    initialValue: undefined,
    type: 'boolean',
  },
} as const;

export const useShiftPlanningPage = () => {
  const [params, setParams] = useExternalParams(initialValues);

  const filters: ShiftPlanningFilters = useMemo(() => {
    return {
      fromDate: params.fromDate || undefined,
      company: params.company || undefined,
      branchOffice: params.branchOffice || undefined,
      department: params.department || undefined,
      filledStatus: (params.filledStatus as FilledStatus) || undefined,
      partner: params.partner || undefined,
      jobType: ((params.jobType || undefined) as string[]) ?? undefined,
      isFreelance: params.isFreelance ?? undefined,
      isTempWork: params.isTempWork ?? undefined,
      areFreelanceApplications: params.areFreelanceApplications ?? undefined,
      areTempworkApplications: params.areTempworkApplications ?? undefined,
      areNoApplications: params.areNoApplications ?? undefined,
    };
  }, [params]);

  const selectedFilterCount = getActiveFiltersCount(filters);

  const strigifiedFilters = useMemo(() => stringifyFilters(filters), [filters]);

  return {
    filters,
    setFilters: setParams,
    selectedFilterCount,
    strigifiedFilters: strigifiedFilters,
  };
};

const getActiveFiltersCount = (filters: ShiftPlanningFilters) => {
  const {
    isFreelance,
    isTempWork,
    areFreelanceApplications,
    areTempworkApplications,
    areNoApplications,
    ...filtersWithoutDuplicatedCounts
  } = filters;

  const filterLengthWithoutEmployment = Object.values(filtersWithoutDuplicatedCounts).filter(
    (value) => {
      return value !== null && value !== undefined && value !== '';
    }
  ).length;

  const hasEmploymentFilter = isFreelance || isTempWork;
  if (hasEmploymentFilter) {
    return filterLengthWithoutEmployment + 1;
  }

  const hasApplicationFilter =
    areFreelanceApplications || areTempworkApplications || areNoApplications;
  if (hasApplicationFilter) {
    return filterLengthWithoutEmployment + 1;
  }

  return filterLengthWithoutEmployment;
};
