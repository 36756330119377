import { Typography } from '@mui/material';
import { useBulkActionResultContext } from 'shared/providers/bulk-action-provider';
import {
  FailedBulkShiftActionsSectionProps,
  FailedBulkShiftActionsTable,
} from '../components/failed-bulk-shift-actions-table/failed-bulk-shift-actions-table';

export const FailedBulkShiftActionsSection = () => {
  const { failure } = useBulkActionResultContext();

  if (failure.length === 0) {
    return null;
  }

  return (
    <>
      <Typography variant="h2" gutterBottom>
        De volgende diensten konden niet gewijzigd worden:
      </Typography>
      <FailedBulkShiftActionsTable items={failure as FailedBulkShiftActionsSectionProps['items']} />
    </>
  );
};
