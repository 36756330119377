import { Grid, Typography } from '@mui/material';
import { InputSelectOption } from '@types';
import { useFormContext } from 'react-hook-form';
import { getHelperTexts } from 'shared/components/shift-forms/components/forms/shared/get-helper-texts/get-helper-texts';
import { FormLocation, LwFormTime, StyledErrorText } from 'redesign';
import { BreakMinutesInput } from 'shared/components/shift-forms/components/forms/shared/inputs/break-minutes-input';
import { MaxClaimantsElement } from 'shared/components/shift-forms/components/forms/shared/inputs/max-claimants-input';
import { BulkShiftEditForm } from './bulk-shift-edit-form';

type BulkShiftEditFormInputsProps = {
  breakOptions: InputSelectOption[];
};

export const BulkShiftEditFormInputs = ({ breakOptions }: BulkShiftEditFormInputsProps) => {
  const { control, formState } = useFormContext<BulkShiftEditForm>();

  const helperTexts = getHelperTexts(undefined);

  const error = formState.errors.root;

  return (
    <Grid container padding={4} spacing={4}>
      <Grid padding={4} item xs={12}>
        <Typography variant="h2">Shifts aanpassen</Typography>
      </Grid>
      <Grid item xs={6}>
        <LwFormTime name="startTime" label="Starttijd" control={control} />
      </Grid>
      <Grid item xs={6}>
        <LwFormTime name="endTime" label="Eindtijd" control={control} />
      </Grid>
      <Grid item xs={6}>
        <BreakMinutesInput
          options={breakOptions}
          helperText={helperTexts.breakMinutes}
          disabled={false}
        />
      </Grid>
      <Grid item xs={6}>
        <MaxClaimantsElement disabled={false} />
      </Grid>
      <Grid item xs={12}>
        <FormLocation disabled={false} />
      </Grid>
      {error && (
        <Grid item xs={12}>
          <StyledErrorText className="error-state">{error.message}</StyledErrorText>
        </Grid>
      )}
    </Grid>
  );
};
