import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToolboxFormCreate } from 'shared/components/common/toolbox-form-create';
import { ProjectForm } from 'shared/components/project-forms/components/project-form';
import { Header, Page } from 'redesign';
import { useAlert } from 'shared/components/alerts';
import { HQProjectFormTypeCreate } from 'shared/components/project-forms/types/project-form.types';
import { DetailsForWorkTemplate } from 'shared/components/work-forms/details/work-form-details.types';
import { DateTime } from 'luxon';
import { invariant } from 'shared/utils/utils';
import { useCompanyRelativePath } from '../../../../../../../hooks';
import { paths } from '../../../../../../../paths';
import { routes } from '../../../../../../../Routes';
import { useDuplicateProject } from '../../../hooks/use-duplicate-project';
import { OgpProjectDuplicatePayload } from '../../../../../../../services/project-service.types';

type ProjectDuplicateProps = {
  ancestorProjectId: string;
  defaultValues: Partial<HQProjectFormTypeCreate>;
  successMessage: string;
  pageTitle: string;
  workDetails: DetailsForWorkTemplate;
};

export const ProjectDuplicateFormPage = ({
  ancestorProjectId,
  defaultValues,
  successMessage,
  pageTitle,
  workDetails,
}: ProjectDuplicateProps) => {
  const { alertSuccess, alertError } = useAlert();
  const formMethods = useForm<HQProjectFormTypeCreate>({
    defaultValues,
  });

  const navigate = useNavigate();
  const generatePath = useCompanyRelativePath();
  const mutation = useDuplicateProject();

  const handleSubmit = () => {
    formMethods.handleSubmit((data: HQProjectFormTypeCreate) => {
      invariant(data.location, 'Location is required');

      const payload: OgpProjectDuplicatePayload = {
        departmentId: data.departmentId,
        ancestorProjectId,
        startDate: DateTime.fromFormat(data.startDate, 'yyyy-MM-dd').toISO(),
        endDate: DateTime.fromFormat(data.endDate, 'yyyy-MM-dd').toISO(),
        weekHourVolume: +data.weeklyHours,
        freelanceProperties: data.isFreelance
          ? {
              hourlyRateCents: Math.round(+data.hourlyRate * 100),
            }
          : undefined,
        location: data.location,
        isTempWork: data.isTempwork,
      };
      mutation.mutate(payload, {
        onSuccess: (id) => {
          alertSuccess(successMessage);
          navigate(generatePath(routes.Projects, {}, { id }));
        },
        onError: (e) => alertError(e),
      });
    })();
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText={pageTitle} />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={() => navigate(generatePath(paths.Projects), { replace: true })}
          />
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <ProjectForm
              mode="duplicate"
              isProjectWithActivePlacement={false}
              workDetails={workDetails}
            />
          </Grid>
        </Grid>
      </Page>
    </FormProvider>
  );
};
