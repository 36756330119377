import React, { useCallback } from 'react';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router';
import { routes } from 'ogp/Routes';
import { useCompanyRelativePath } from 'ogp/hooks';
import { usePlanningParamsContext } from '../providers/planning-params-provider';

type UpdatePlanningPathArguments = {
  date?: DateTime | null;
  shiftDetailId?: string | null;
};

const DAY_COUNT = 7;

/**
 * Helper to create paths for the planning screen.
 *
 * Undefined values leave the current query param in the URL intact.
 * Null values remove them.
 * Other values are set.
 */
export function useUpdatePlanningPath() {
  const location = useLocation();
  const generatePath = useCompanyRelativePath();

  return useCallback(
    ({ date, shiftDetailId }: UpdatePlanningPathArguments) => {
      const path = generatePath(routes.ShiftPlanning);
      const params = new URLSearchParams(location.search); // Using location.search to keep other params intact.

      if (date === null) {
        params.set('date', DateTime.now().toISODate());
      } else if (date) {
        params.set('date', date.toISODate());
      }
      if (shiftDetailId) {
        params.set('shiftId', shiftDetailId);
      }
      params.sort();

      return `${path}/calendar?${params}`;
    },
    [generatePath, location.search]
  );
}

export const usePlanningDateRange = () => {
  const {
    filters: { date },
  } = usePlanningParamsContext();

  const startDate = React.useMemo(() => {
    const validDate = date && date.isValid ? date : DateTime.now();
    return validDate.startOf('week');
  }, [date]);
  const endDate = React.useMemo(() => startDate.plus({ days: DAY_COUNT - 1 }), [startDate]);

  return { startDate, endDate };
};
