import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { CalendarJobType } from './calendar-job-type';

export type GridDepartmentProps = {
  department: {
    id: string;
    name: string;
    jobTypes: {
      id: string;
      name: string;
      shifts: {
        applicants: number;
        claimants: number;
        jobName: string;
        flexPoolOnly: boolean;
        dateLabel: string;
        dateTime: string;
        endTime: string;
        id: string;
        maxClaimants: number;
        startTime: string;
        hasPartners: boolean;
        hasCanceledClaim: boolean;
      }[];
    }[];
  };
};

export const CalendarDepartment = ({ department }: GridDepartmentProps) => {
  const classes = getClasses();
  return (
    <section className={classes.section}>
      <Typography className={classes.name} title={department.name}>
        {department.name}
      </Typography>
      {department.jobTypes.length ? (
        department.jobTypes.map((jobType) => <CalendarJobType key={jobType.id} jobType={jobType} />)
      ) : (
        <CalendarJobType jobType={undefined} />
      )}
    </section>
  );
};

const getClasses = makeStyles((theme: Theme) => ({
  section: {
    minHeight: theme.spacing(8),
  },
  name: {
    fontWeight: 700,
    width: '14vw',
    position: 'relative',
    transform: 'translateY(-100%)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
  },
}));
