import { QUERY_KEYS } from 'api/query-keys';
import { shiftService } from 'hq/services';
import { HQUpdateShiftPayload } from 'hq/services/shift-service.types';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

type ShiftMutationOptions = UseMutationOptions<unknown, unknown, HQUpdateShiftPayload>;

export const useHqUpdateShift = (options?: ShiftMutationOptions) => {
  const mutationFn = async (shift: HQUpdateShiftPayload) => await shiftService.updateShift(shift);
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options ?? {};

  return useMutation(mutationFn, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(QUERY_KEYS.shifts());
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
};
