import { projectService as ogpProjectService } from 'ogp/services';
import { projectService as hqProjectService } from 'hq/services';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'api/query-keys';
import { SiteDetectionResult, useSiteDetection } from '../../../../../../hooks';

export const useGetProjectDetail = (projectId: string) => {
  const site = useSiteDetection();
  const queryFn = getQueryFunction(site, projectId);

  return useQuery(QUERY_KEYS.projectDetail(projectId), queryFn);
};

const getQueryFunction = (siteDetectionResult: SiteDetectionResult, projectId: string) => {
  if (siteDetectionResult.isHQ) {
    return async () => await hqProjectService.getProjectDetail(projectId);
  }
  if (siteDetectionResult.isOGP) {
    return async () => await ogpProjectService.getProjectDetail(projectId);
  }

  throw new Error(`useGetProjectDetail: site "${siteDetectionResult.site}" is neither HQ nor OGP.`);
};
