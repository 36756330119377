import { useCallback, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { useOgpGetShift } from 'shared/components/shift-forms/hooks/ogp/use-ogp-get-shift';
import { useOgpMultiplyShift } from 'shared/components/shift-forms/hooks/ogp/use-ogp-multiply-shift';
import { shiftAlerts as alerts, useAlert } from 'shared/components/alerts';
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { pluralizeWithValue } from 'shared/utils/pluralize';
import { invariant } from 'shared/utils/utils';
import { RenderUnsettledUI } from 'shared/components';
import { useCompanyRelativePath } from 'ogp/hooks';
import { PrimaryButton, SecondaryButton, useDialog } from 'ogp/providers/dialog-provider';
import { FormProvider, useForm } from 'react-hook-form';
import { Header, Page } from 'redesign';
import { useUpdatePlanningPath } from 'ogp/components/views/planning/planning/hooks/use-get-path-helpers';
import { paths } from 'ogp/paths';
import { ShiftDetailResponse } from 'shared/services/shift-service-types';
import { useGetWorkDetails } from 'shared/components/work-forms/details/hooks/use-get-work-details';
import { DetailsForWorkTemplate } from 'shared/components/work-forms/details/work-form-details.types';
import { ShiftMultiplyCalendar } from '../../components/shift-multiply-calendar/shift-multiply-calendar';
import { ToolboxFormCreate } from '../../../common/toolbox-form-create';
import { OgpShiftForm } from '../ogp-shift-form';
import { OGPShiftFormData } from '../../types/shift-forms.types';
import { getDefaultValuesForShiftForm } from '../../utils/get-default-values-for-shift-form';

type ShiftMultiplyProps = {
  shift: ShiftDetailResponse;
  shiftId: string;
  workDetails: DetailsForWorkTemplate;
};

export const OgpShiftMultiplyDataProvider = () => {
  const { id: shiftId } = useParams<'id'>();
  invariant(shiftId, 'Shift ID was supposed to be passed via route param');
  const shiftQuery = useOgpGetShift(shiftId);
  const workDetailsQuery = useGetWorkDetails(shiftId);

  if (shiftQuery.status !== 'success') {
    return <RenderUnsettledUI data={shiftQuery} />;
  }

  if (workDetailsQuery.status !== 'success') {
    return <RenderUnsettledUI data={workDetailsQuery} />;
  }

  return (
    <ShiftMultiply shift={shiftQuery.data} shiftId={shiftId} workDetails={workDetailsQuery.data} />
  );
};

const ShiftMultiply = ({ shift, shiftId, workDetails }: ShiftMultiplyProps) => {
  const classes = getClasses();
  const generatePath = useCompanyRelativePath();
  const updatePlanningPath = useUpdatePlanningPath();
  const navigate = useNavigate();
  const { alertSuccess, alertError } = useAlert();
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const [value, setValue] = useState<DateTime[]>();
  const location = useLocation();
  const isUserComingFromCalendar = location.state?.isUserComingFromCalendar || false;

  const dates = useMemo(() => value ?? [], [value]);
  const dateCount = useMemo(() => dates.length, [dates.length]);
  const shiftStartDate = DateTime.fromFormat(shift.startDate, 'yyyy-LL-dd');
  const shiftDateLabel = shiftStartDate.toFormat('dd-LL-yyyy');

  const mutation = useOgpMultiplyShift({
    onSuccess: () => {
      const shiftDetailPath = updatePlanningPath({
        date: dates[0],
      });
      closeDialog();
      alertSuccess(alerts.success.multiplied);
      navigate(shiftDetailPath);
    },
    onError: (e) => alertError(e),
    onSettled: () => {
      enableDialog();
    },
  });

  const handleSubmit = useCallback(() => {
    openDialog(
      {
        title: 'Shift dupliceren',
        content: `Weet je zeker dat je ${pluralizeWithValue(
          dateCount,
          'nieuwe shift',
          'nieuwe shifts'
        )} wilt toevoegen met deze gegevens?`,
        primaryButton: (props) => <PrimaryButton {...props}>Opslaan</PrimaryButton>,
        secondaryButton: (props) => <SecondaryButton {...props}>Annuleren</SecondaryButton>,
      },
      () => {
        disableDialog();
        mutation.mutate({
          id: shiftId,
          startDates: dates.map((date) => date.toFormat('yyyy-LL-dd')),
        });
      }
    );
  }, [dateCount, dates, disableDialog, mutation, openDialog, shift.job.id, shiftId]);

  const onCancel = useCallback(
    () =>
      isUserComingFromCalendar
        ? navigate(-1)
        : navigate(
            generatePath(paths.ShiftPlanning, {
              search: createSearchParams({ shiftDetailId: shiftId }).toString(),
            })
          ),
    [navigate, generatePath, shiftId]
  );

  const formMethods = useForm<OGPShiftFormData>({
    defaultValues: { ...getDefaultValuesForShiftForm(shift), jobId: undefined },
  });

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText={`Shift ${shiftDateLabel} in bulk aanmaken`} />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={onCancel}
            disabledPrimaryButton={dateCount < 1}
          />
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <OgpShiftForm mode={'read'} workDetails={workDetails} />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.calendarExplainer}>
              Selecteer de dagen waarop je de shift nog meer wilt laten plaatsvinden.
            </Typography>
            <ShiftMultiplyCalendar shiftDate={shiftStartDate} onChange={setValue} />
          </Grid>
        </Grid>
      </Page>
    </FormProvider>
  );
};

const getClasses = makeStyles((theme) => ({
  calendarExplainer: {
    marginBottom: theme.spacing(1),
  },
}));
