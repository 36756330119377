import { partnerPlannerService } from 'partner/shared/services';
import { useInfiniteQuery } from 'react-query';
import { FirstParamOfFn } from '@types';
import { QUERY_KEYS } from 'api/query-keys';

const useGetShiftPlanningShifts = (
  params: FirstParamOfFn<typeof partnerPlannerService.getShiftPlanningShifts>
) => {
  return useInfiniteQuery({
    queryKey: QUERY_KEYS.shiftPlanningWithFilters(params),
    queryFn: async ({ pageParam = 0 }) =>
      await partnerPlannerService.getShiftPlanningShifts({
        pageParam,
        fromDate: params.fromDate,

        pageSizeParam: params.pageSizeParam,
        company: params.company,
        branchOffice: params.branchOffice,
        department: params.department,
        jobType: params.jobType,
        filledStatus: params.filledStatus,
        workerId: params.workerId,
        isFreelance: params.isFreelance,
        isTempWork: params.isTempWork,
      }),
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};
export { useGetShiftPlanningShifts };
