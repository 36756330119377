import { QUERY_KEYS } from 'api/query-keys';
import { partnerPlacementService } from 'partner/shared/services';
import { useMutation, useQueryClient } from 'react-query';

type CancelShiftPlacementsParams = {
  shiftClaimId: string;
};

export const useCancelShiftPlacement = () => {
  const mutationFn = async ({ shiftClaimId }: CancelShiftPlacementsParams) =>
    await partnerPlacementService.cancelShiftPlacement(shiftClaimId);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.shifts());
    },
  });
};
