import { Box } from '@mui/material';
import { GetPendingBankAccountsResponse } from 'hq/services/bank-account-service.types';
import { Table } from 'redesign';
import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { bankAccountAlerts, useAlert } from 'shared/components/alerts';
import { QUERY_KEYS } from 'api/query-keys';
import { getBankAccountTableData } from './utils/get-bank-account-table-data';
import { getBankAccountColumnDefinition } from './utils/get-bank-account-column-definition';
import { BankAccountTableData, BankAccountTableOptions } from './bank-account-table.types';
import { useApprovePendingBankAccount } from './hooks/use-approve-pending-bank-account';
import { useRejectPendingBankAccount } from './hooks/use-reject-pending-bank-account';

export const BankAccountList = ({
  data,
  allowEmploymentId,
}: {
  data: GetPendingBankAccountsResponse;
  allowEmploymentId: boolean;
}) => {
  const { alertError, alertSuccess } = useAlert();
  const queryClient = useQueryClient();
  const approveMutation = useApprovePendingBankAccount();
  const rejectMutation = useRejectPendingBankAccount();
  const columnDefinition = useMemo(
    () => getBankAccountColumnDefinition(allowEmploymentId),
    [allowEmploymentId]
  );
  const tableData = useMemo(() => getBankAccountTableData(data), [data]);

  const approve = useCallback((id: string) => {
    approveMutation.mutate(id, {
      onSuccess: () => {
        alertSuccess(bankAccountAlerts.success.approved);
        queryClient.invalidateQueries(QUERY_KEYS.workers());
        queryClient.invalidateQueries(QUERY_KEYS.inbox());
      },
      onError: (e) => {
        alertError(e);
      },
    });
  }, []);

  const reject = useCallback((id: string) => {
    rejectMutation.mutate(id, {
      onSuccess: () => {
        alertSuccess(bankAccountAlerts.success.reject);
        queryClient.invalidateQueries(QUERY_KEYS.workers());
        queryClient.invalidateQueries(QUERY_KEYS.inbox());
      },
      onError: (e) => {
        alertError(e);
      },
    });
  }, []);

  return (
    <Box display={'flex'}>
      <Table<BankAccountTableData, BankAccountTableOptions>
        columns={columnDefinition}
        data={tableData}
        tableStyle={{ tableLayout: 'fixed' }}
        approve={approve}
        reject={reject}
      />
    </Box>
  );
};
