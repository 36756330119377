import { Box } from '@mui/material';
import { FilterButton } from 'ogp/components/views/administration/shared';
import { useMemo, useState } from 'react';
import { SearchBar, Toolbox } from 'redesign';
import { FlexWorkersForProjectFilterOptions } from 'shared/components/flex-workers-for-work-table/flex-workers-for-work.types';
import { useDebounce } from 'shared/hooks/use-debounce';
import { getAllowedEmploymentTypes } from 'shared/components/planner/common/utils';
import { CreatePlacementFlexWorkerFilters } from './create-placement-flex-worker-filters';
import { CreatePlacementTableProvider } from './create-placement-table-provider';
import { FlexWorkerTableOptions } from '../../../../flex-workers-for-work-table/flex-workers-for-work-table.types';

type Props = {
  projectDetails: {
    projectId: string;
    isFreelance: boolean;
    isTempWork: boolean;
  };
  getRowActions: FlexWorkerTableOptions['getRowActions'];
};

export const PlacementCreate = ({ ...props }: Props) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const debouncedSearchInput = useDebounce(searchInput, 300);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const allowedEmploymentTypes = getAllowedEmploymentTypes(
    props.projectDetails.isFreelance,
    props.projectDetails.isTempWork
  );

  const [filterValues, setFilterValues] = useState<FlexWorkersForProjectFilterOptions>(
    allowedEmploymentTypes.length >= 2
      ? {
          isFreelancer: props.projectDetails.isFreelance,
          isTempWorker: props.projectDetails.isTempWork,
        }
      : {}
  );

  const handleOpeneningFilters = (val: boolean) => {
    setIsOpen(val);
  };

  const numberOfAppliedFilters = useMemo(
    () =>
      Object.values(filterValues).filter(
        (filterValue) => filterValue !== undefined && filterValue !== '0' && filterValue
      ).length,
    [filterValues]
  );

  return (
    <>
      <Box display="flex" marginTop={4} marginBottom={8}>
        <SearchBar onChange={setSearchInput} placeholder="Flex werker naam" />
        <Toolbox.PushRight>
          <CreatePlacementFlexWorkerFilters
            handleOpeneningFilters={handleOpeneningFilters}
            isOpen={isOpen}
            setFilterValues={setFilterValues}
            filterValues={filterValues}
            allowedEmploymentTypes={allowedEmploymentTypes}
          />
          <FilterButton
            onClick={() => handleOpeneningFilters(true)}
            nrOfActiveFilters={numberOfAppliedFilters}
          />
        </Toolbox.PushRight>
      </Box>
      <CreatePlacementTableProvider
        filters={{ fullNameSearch: debouncedSearchInput || undefined, ...filterValues }}
        {...props}
      />
    </>
  );
};
