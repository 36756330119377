import { QUERY_KEYS } from 'api/query-keys';
import { partnerWorkerService } from 'partner/shared/services';
import { useQuery } from 'react-query';

export const useGetPartnerWorker = (workerId: string) => {
  return useQuery({
    queryKey: QUERY_KEYS.partnerWorker(workerId),
    queryFn: async () => await partnerWorkerService.getPartnerWorker(workerId),
  });
};
