import { Grid } from '@mui/material';
import { LocationAutoSuggestItem, LocationData } from '@types';
import { useGeocode } from 'ogp/hooks/queryHooks/locations/use-geocode';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDebounce } from 'shared/hooks/use-debounce';
import { LwFormAutocomplete } from '../autocomplete';

export type FormLocationType = {
  location: LocationData | LocationAutoSuggestItem;
};

/**
 * Hopefully one day this will actually be nice to work with
 * This shared input deals with selecting location by using `@type FormLocationType`
 * Unfortunately, this is one of the oldest things on the FE. The `LocatioNData` and `LocationAutoSuggestItem` are
 * extremely woven together.
 */
export const FormLocation = ({
  disabled = false,
  required,
}: {
  disabled?: boolean;
  required?: boolean;
}) => {
  const context = useFormContext<FormLocationType>();
  const location = context.watch('location');
  const [locationInputValue, setLocationInputValue] = useState(location?.address ?? '');
  const debouncedInput = useDebounce(locationInputValue, 300);
  const geoQuery = useGeocode(debouncedInput, debouncedInput !== location?.address);
  const options = useMemo(() => {
    return geoQuery.data ?? [];
  }, [geoQuery.data]);

  return (
    <Grid item xs={12}>
      <LwFormAutocomplete
        name="location"
        label="Locatie"
        control={context.control}
        rules={required ? { required: 'Voer een locatie in' } : undefined}
        data-testid="location-select"
        disableClearable={true}
        disabled={disabled}
        getOptionKey={(option) => option.hereId ?? ''}
        getOptionLabel={(option) => option.address}
        onChange={(_event, value) => {
          if (value) {
            context.setValue('location', value);
          }
        }}
        isOptionEqualToValue={(option, value) => {
          return option.hereId === value.hereId;
        }}
        inputValue={locationInputValue}
        onInputChange={(_event, value) => {
          setLocationInputValue(value);
        }}
        options={options}
        isLoading={geoQuery.isLoading}
      />
    </Grid>
  );
};
