import { IconIsolationMode } from 'assets/img';
import { HqShiftPlanningCellProps } from 'shared/components/planner/shifts/table/shift-planning-table.types';
import type { Column } from 'react-table';
import {
  BoxAction,
  BoxIcon,
  Cell,
  DateTimeCell,
  Header,
  JobNameCell,
  PartnersCell,
} from 'shared/components/planner/common/utils';
import {
  HqShiftPlanningApplicationColumnDefinition,
  HqShiftPlanningCellProp,
} from '../shift-planning-table.types';
import { CandidatePlacementsCellWithTooltip } from '../../../common/placements-tooltip/candidate-placements-cell-with-tooltip';
import { ApplicationsCellWithTooltip } from '../../../common/applications-tooltip/applications-cell-with-tooltip';

const getShiftPlanningApplicationColumnDefinition: () => Column<HqShiftPlanningApplicationColumnDefinition>[] =
  () => {
    const jobName = {
      Header: () => <Header>Functie</Header>,
      accessor: 'jobName',
      Cell: JobNameCell,
      width: '200px',
    } as Column<HqShiftPlanningApplicationColumnDefinition>;
    const companyName = {
      Header: () => <Header>Bedrijf</Header>,
      accessor: 'companyName',
      Cell: Cell,
      width: '200px',
    } as Column<HqShiftPlanningApplicationColumnDefinition>;
    const departmentName = {
      Header: () => <Header>Afdeling</Header>,
      accessor: 'departmentName',
      Cell: Cell,
      width: '350px',
    } as Column<HqShiftPlanningApplicationColumnDefinition>;
    const dateTime = {
      Header: () => <Header>Datum & tijd</Header>,
      accessor: 'dateTime',
      Cell: DateTimeCell,
      width: '230px',
    } as Column<HqShiftPlanningApplicationColumnDefinition>;
    const candidatePlacements = {
      Header: () => <Header>Plaatsingen</Header>,
      accessor: 'candidatePlacements',
      Cell: PartnerPlacementsTooltipCell,
      width: '110px',
    } as Column<HqShiftPlanningApplicationColumnDefinition>;
    const applications = {
      Header: () => <Header>Aanmeldingen</Header>,
      accessor: 'applications',
      Cell: ApplicationsCell,
      width: '150px',
    } as Column<HqShiftPlanningApplicationColumnDefinition>;
    const partners = {
      Header: () => <Header>Partners</Header>,
      accessor: 'partners',
      Cell: PartnersCell,
      width: '85px',
    } as Column<HqShiftPlanningApplicationColumnDefinition>;
    const action = {
      Header: () => <Header>Actie</Header>,
      accessor: 'action',
      Cell: ActionCell,
      width: '65px',
    } as Column<HqShiftPlanningApplicationColumnDefinition>;

    return [
      jobName,
      companyName,
      departmentName,
      dateTime,
      candidatePlacements,
      applications,
      partners,
      action,
    ];
  };

const ActionCell = (props: HqShiftPlanningCellProp<'action'>) => {
  return (
    <BoxAction>
      <BoxIcon>
        <IconIsolationMode onClick={() => props.onShowDetail(props.value)} />
      </BoxIcon>
    </BoxAction>
  );
};

const PartnerPlacementsTooltipCell = (props: any) => {
  return <CandidatePlacementsCellWithTooltip {...props} />;
};

const ApplicationsCell = (props: HqShiftPlanningCellProps<'applications'>) => {
  return <ApplicationsCellWithTooltip {...props} />;
};

export { getShiftPlanningApplicationColumnDefinition };
