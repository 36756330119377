import { useMemo } from 'react';
import { Table } from 'redesign';
import { FailedBulkShiftActionsColumnDefinition } from './failed-bulk-shift-actions-table.types';
import { getFailedBulkShiftActionsTableData } from './get-failed-bulk-shift-actions-table-data';
import { getFailedBulkShiftActionsColumnDefinition } from './get-failed-bulk-shift-actions-table-column-definition';

export type FailedBulkShiftActionsSectionProps = {
  items: {
    id: string;
    error: string;
    shiftName: string;
    dateStartEndTime: string;
  }[];
};

export const FailedBulkShiftActionsTable = ({ items }: FailedBulkShiftActionsSectionProps) => {
  const columns = useMemo(() => getFailedBulkShiftActionsColumnDefinition(), []);
  const tableData = useMemo(() => getFailedBulkShiftActionsTableData(items), [items]);

  return (
    <Table<FailedBulkShiftActionsColumnDefinition>
      stickyHeader={true}
      columns={columns}
      data={tableData}
      tableStyle={{ tableLayout: 'fixed' }}
      data-testid="failed-bulk-shift-actions-table"
    />
  );
};
