import React, { useCallback } from 'react';
import { useAlert } from 'shared/components/alerts';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { Spinner, useToggle } from 'shared/hooks';
import { invariant } from 'shared/utils/utils';
import { LwButton } from 'redesign';
import { QUERY_KEYS } from 'api/query-keys';
import { useOgpSetNoShow } from './query-hooks/ogp/use-ogp-set-no-show';
import { useHqSetNoShow } from './query-hooks/hq/use-hq-set-no-show';
import { NoShowFormDataProvider } from './form/no-show-form';
import { NoShowPayload, NoShowPayloadHandlerFn } from './use-no-show-dialog.types';

type ShiftPayload = {
  shiftClaimId: string;
  shiftId?: string;
};

type MutationType = typeof useOgpSetNoShow | typeof useHqSetNoShow;

const useNoShowDialog = (mutationQuery: MutationType) => {
  const { on: isDialogOpen, setOn: openDialog, setOff: closeDialog } = useToggle(false);
  const [shiftPayload, setShiftPayload] = React.useState<ShiftPayload>();
  const [isFlexWorker, setIsFlexWorker] = React.useState<boolean>(true);
  const [noShowReasonPayload, setNoShowReasonPayload] = React.useState<NoShowPayload>();
  const { alertSuccess, alertError } = useAlert();
  const invalidateQuery = useInvalidateQuery();
  const noShowMutation = mutationQuery();

  const handleChange: NoShowPayloadHandlerFn = React.useCallback((payload) => {
    setNoShowReasonPayload(payload);
  }, []);

  const handleSubmit = useCallback(() => {
    invariant(shiftPayload);
    invariant(noShowReasonPayload);

    noShowMutation.mutate(
      {
        shiftClaimId: shiftPayload.shiftClaimId,
        noShowWithConsequences: noShowReasonPayload.noShowWithConsequences,
        noShowReasonId: noShowReasonPayload.noShowReason.id,
      },
      {
        onSuccess: () => {
          alertSuccess('Deze werker is nu als no-show gemarkeerd');
          invariant(shiftPayload);
          invalidateQuery(QUERY_KEYS.shifts());
          invalidateQuery(QUERY_KEYS.checkouts());
          invalidateQuery(QUERY_KEYS.workers());
        },
        onError: (e) => {
          alertError(e);
        },
        onSettled: () => {
          closeDialog();
        },
      }
    );
  }, [
    alertError,
    alertSuccess,
    closeDialog,
    invalidateQuery,
    noShowMutation,
    noShowReasonPayload,
    shiftPayload,
  ]);

  const open = useCallback(
    (shiftClaimId: string, shiftId?: string) => {
      setShiftPayload({ shiftId, shiftClaimId });
      openDialog();
    },
    [openDialog]
  );

  const Element = (
    <Dialog maxWidth="xs" onClose={closeDialog} open={isDialogOpen}>
      <DialogTitle>No show</DialogTitle>
      <DialogContent>
        <NoShowFormDataProvider
          onSubmit={handleSubmit}
          onChange={handleChange}
          isFlexWorker={isFlexWorker}
        />
      </DialogContent>
      <Box
        component={DialogActions}
        display="flex"
        style={{ gap: '32px', justifyContent: 'center' }}
      >
        <LwButton color="secondary" autoFocus onClick={closeDialog}>
          Annuleer
        </LwButton>
        <LwButton color="primary" type="submit" form="no-show-reason-form">
          Bevestig
          {noShowMutation.status === 'loading' ? <Spinner /> : null}
        </LwButton>
      </Box>
    </Dialog>
  );
  return { Element, open, close: closeDialog, setIsFlexWorker };
};

export { useNoShowDialog };
