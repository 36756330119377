import { QUERY_KEYS } from 'api/query-keys';
import { partnerUserService } from 'hq/services';
import { useQuery } from 'react-query';
import { CACHE_TIME_LONG, STALE_TIME_LONG } from 'shared/react-query/query-defaults';

const useGetPartnerUsers = (partnerId: string) => {
  const queryFn = async () => await partnerUserService.getUsers(partnerId);

  return useQuery(QUERY_KEYS.partnerUsers(partnerId), {
    queryFn,
    cacheTime: CACHE_TIME_LONG,
    staleTime: STALE_TIME_LONG,
  });
};

export { useGetPartnerUsers };
