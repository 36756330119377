import { QUERY_KEYS } from 'api/query-keys';
import { IconNoShow } from 'assets/img';
import { DateTime } from 'luxon';
import { useDialog } from 'ogp/providers/dialog-provider';
import { ContextMenuItem } from 'redesign';
import { shiftAlerts, useAlert } from 'shared/components/alerts';
import {
  ShiftClaimant,
  ShiftDetailData,
} from 'shared/components/shift-detail-modal/components/tabs/claimants/claimant-table.types';
import { useHqUndoNoShow } from 'shared/components/shift-detail-modal/query-hooks/hq/use-hq-undo-no-show';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';

export const HQNoShowAction = ({
  candidate,
  shiftData,
  setIsFlexWorker,
  openNoShow,
}: {
  candidate: ShiftClaimant;
  shiftData: ShiftDetailData;
  setIsFlexWorker: React.Dispatch<React.SetStateAction<boolean>>;
  openNoShow: (shiftClaimId: string, shiftId?: string) => void;
}) => {
  const { alertSuccess, alertError } = useAlert();
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const invalidateQuery = useInvalidateQuery();
  const undoNoShowMutation = useHqUndoNoShow();
  const handleUndoNoShow = (params: {
    shiftClaimId: string;
    firstName: string;
    shiftId: string;
  }) => {
    openDialog(
      {
        title: `Weet je zeker dat je ${params.firstName} niet als no show wilt rapporteren?`,
        content: `Als er nog geen ingediende uren zijn krijgt ${params.firstName} de mogelijkheid om de checkout te starten in de app. Zo niet, dan is de checkout te zien in de checkouts-inbox.`,
      },
      () => {
        disableDialog();
        undoNoShowMutation.mutate(
          { shiftClaimId: params.shiftClaimId, shiftId: params.shiftId },
          {
            onSuccess: () => {
              invalidateQuery(QUERY_KEYS.shifts());
              closeDialog();
              alertSuccess(shiftAlerts.success.undoneNoShow);
            },
            onError: (e) => alertError(e),
            onSettled: () => {
              enableDialog();
            },
          }
        );
      }
    );
  };

  const startDateTime = DateTime.fromISO(`${shiftData.startDate}T${shiftData.startTime}`);
  const allowNoShow = DateTime.now() >= startDateTime.plus({ minutes: 15 });
  const noShowNotAllowedMessage =
    candidate.state === 'checkoutSettled'
      ? 'No-shows melden kan niet meer, deze claim is al gesettled.'
      : 'No-shows melden kan pas 15 minuten na aanvang van de shift.';

  const onContextMenuClick = () => {
    if (candidate.state === 'noShow') {
      handleUndoNoShow({
        shiftClaimId: candidate.claimId,
        firstName: candidate.firstName,
        shiftId: shiftData.id,
      });
      return;
    }
    if (allowNoShow) {
      setIsFlexWorker(candidate.isFlexWorker);
      openNoShow(candidate.claimId, shiftData.id);
      return;
    }
    alertError(new Error(noShowNotAllowedMessage));
  };

  return (
    <ContextMenuItem onClick={onContextMenuClick} key={`no-show-${candidate.claimId}`}>
      <IconNoShow />
      {candidate.state === 'noShow'
        ? `Undo no show - ${candidate.noShowReason?.shortDescription}`
        : 'No show'}
    </ContextMenuItem>
  );
};
