import { Authenticator, Button, useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { configureAmplify } from 'shared/components/auth/utils';
import { pools } from 'shared/components/auth/config';
import { useSiteDetection } from 'shared/hooks/use-site-detection';
import { SignInFooter } from './ogp-sign-in-footer';
import { LOGO_PATH } from '../../../../config';

const MyAuthenticator = () => {
  const { site } = useSiteDetection();
  const cmps = { ...getComponents(site) };

  const formFields: Exclude<React.ComponentProps<typeof Authenticator>['formFields'], undefined> = {
    signIn: {
      username: {
        labelHidden: false,
        placeholder: '',
        isRequired: true,
        type: 'email',
      },
      password: {
        labelHidden: false,
        isRequired: true,
        placeholder: '',
      },
    },
    resetPassword: {
      username: {
        labelHidden: false,
        isRequired: true,
        placeholder: '',
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        labelHidden: false,
        isRequired: true,
        placeholder: '',
      },
      password: {
        labelHidden: false,
        isRequired: true,
        placeholder: '',
      },
      confirm_password: {
        labelHidden: false,
        isRequired: true,
        placeholder: '',
      },
    },
  };
  return (
    <Authenticator
      services={{
        handleSignIn: async (args) => {
          // when user is actively signing in (sign in button is pressed), we are sure we need to authenticate against the matching cognito pool
          configureAmplify(pools[site]);
          return await Auth.signIn(args);
        },
      }}
      components={cmps}
      formFields={formFields}
      hideSignUp
    />
  );
};

const getComponents = (
  site: ReturnType<typeof useSiteDetection>['site']
): React.ComponentProps<typeof Authenticator>['components'] => {
  return {
    Header() {
      const styles = getStyles();

      return <img className={styles.logo} src={LOGO_PATH} alt="Logo" />;
    },
    ResetPassword: {
      Header() {
        return <strong style={{ display: 'block' }}>Wachtwoord opnieuw instellen</strong>;
      },
    },
    SignIn: {
      Header() {
        return <strong style={{ display: 'block', marginBottom: '1rem' }}>Inloggen</strong>;
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();
        const styles = getStyles();
        const Footer = site !== 'hq' ? <SignInFooter /> : <></>;

        return (
          <>
            <Button
              className={styles.forgotPasswordLink}
              fontWeight="normal"
              onClick={toResetPassword}
              variation="link"
            >
              Wachtwoord vergeten?
            </Button>
            {Footer}
          </>
        );
      },
    },
  };
};

const getStyles = makeStyles((theme: Theme) => ({
  logo: {
    width: '100%',
    margin: '0 0 75px',
    display: 'block',
  },
  forgotPasswordLink: {
    top: theme.spacing(80),
    height: 'fit-content',
  },
}));

export { MyAuthenticator };
