import { useExternalParams } from 'shared/hooks';
import { DateTime } from 'luxon';
import { PlacementsFilters } from './use-placements-page.types';

const initialValues = {
  fromDate: {
    initialValue: DateTime.now().startOf('day'),
    type: 'date',
  },
  company: {
    initialValue: undefined,
    type: 'string',
  },
  branchOffice: {
    initialValue: undefined,
    type: 'string',
  },
  department: {
    initialValue: undefined,
    type: 'string',
  },
  jobType: {
    initialValue: undefined,
    type: 'array',
  },
  worker: {
    initialValue: undefined,
    type: 'string',
  },
  isFreelance: {
    initialValue: undefined,
    type: 'boolean',
  },
  isTempWork: {
    initialValue: undefined,
    type: 'boolean',
  },
  areFreelanceApplications: {
    initialValue: undefined,
    type: 'boolean',
  },
  areTempworkApplications: {
    initialValue: undefined,
    type: 'boolean',
  },
  areNoApplications: {
    initialValue: undefined,
    type: 'boolean',
  },
  state: {
    initialValue: undefined,
    type: 'array',
  },
} as const;

export const usePlacementsPage = () => {
  const [params, setParams] = useExternalParams(initialValues);

  const filters: PlacementsFilters = {
    fromDate: params.fromDate ?? undefined,
    company: params.company ?? undefined,
    branchOffice: params.branchOffice ?? undefined,
    department: params.department ?? undefined,
    jobType: (params.jobType as string[]) ?? undefined,
    worker: params.worker ?? undefined,
    isFreelance: params.isFreelance ?? undefined,
    isTempWork: params.isTempWork ?? undefined,
    areFreelanceApplications: params.areFreelanceApplications ?? undefined,
    areTempworkApplications: params.areTempworkApplications ?? undefined,
    areNoApplications: params.areNoApplications ?? undefined,
    states: (params.state as string[]) ?? undefined,
  };
  const selectedFilterCount = Object.values(filters).filter((value) => !!value).length;

  return {
    filters,
    setFilters: setParams,
    selectedFilterCount,
  };
};
