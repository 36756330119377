import { useCompanyRelativePath } from 'ogp/hooks';
import { AcknowledgeButton, useDialog } from 'ogp/providers/dialog-provider';
import { useNavigate } from 'react-router';
import { departmentAlerts, useAlert } from 'shared/components/alerts';
import { invariant } from 'shared/utils/utils';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { QUERY_KEYS } from 'api/query-keys';
import { useArchiveDepartment } from './use-archive-department';
import { routes } from '../../../../../../Routes';

const useArchiveDepartmentDialog = (departmentId: string) => {
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const { alertSuccess, alertError } = useAlert();

  const navigate = useNavigate();
  const generatePath = useCompanyRelativePath();
  const invalidateQuery = useInvalidateQuery();

  const archiveJob = useArchiveDepartment({
    onSuccess: () => {
      alertSuccess(departmentAlerts.success.deleted);
      navigate(generatePath(routes.Departments));
      closeDialog();
      invalidateQuery(QUERY_KEYS.departments());
    },
    onError: (e) => alertError(e),
    onSettled: () => {
      enableDialog();
    },
  });

  const handleArchiveDepartment = () => {
    invariant(departmentId, 'Department id is not defined');
    disableDialog();
    archiveJob.mutate(departmentId);
  };

  const handleForbiddenArchival = () => {
    openDialog(
      {
        title: 'Oeps, dit kan niet!',
        content:
          'Het is niet mogelijk om deze opdrachtgever te verwijderen aangezien er nog actieve plaatsingen zijn.',
        secondaryButton: (props) => <AcknowledgeButton {...props} />,
        primaryButton: null,
      },
      () => {}
    );
  };

  const handleArchival = () => {
    openDialog(
      { title: 'Weet je zeker dat je deze afdeling wilt verwijderen?' },
      handleArchiveDepartment
    );
  };

  return { handleArchival, handleForbiddenArchival };
};

export { useArchiveDepartmentDialog };
