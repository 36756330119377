import { QUERY_KEYS } from 'api/query-keys';
import { companyService } from 'hq/services';
import { useQuery } from 'react-query';

const useGetCompanyPartners = (id: string) => {
  const queryFn = async () => await companyService.getPartnerRelations(id);

  return useQuery(QUERY_KEYS.companyPartnersByCompany(id), {
    queryFn,
  });
};

export { useGetCompanyPartners };
