import { useLocation } from 'react-router-dom';
import { Site } from './use-site-detection.types';

export const useSiteDetection = () => {
  const { pathname } = useLocation();
  const site = pathname.split('/')[1] as Site;

  return {
    site,
    isOGP: site === 'ogp',
    isHQ: site === 'hq',
    isPartner: site === 'partner',
  } as const;
};
