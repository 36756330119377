import { Box, Typography } from '@mui/material';
import { LwCheckbox } from 'redesign';
import { useRolesMatrix } from 'ogp/hooks/queryHooks/users/use-roles-matrix';
import { useFormContext } from 'react-hook-form';
import { TakeDefined } from '@types';
import { translateRoleToDutch } from 'shared/utils/roles-utils';
import { useGetUserAsForm } from 'ogp/hooks/queryHooks/users/use-get-user';
import { UserFormProps } from './user-form.types';

export const UserFormRoles = ({
  matrix,
  state,
}: {
  matrix: TakeDefined<ReturnType<typeof useRolesMatrix>['data']>;
  state: UserFormProps['state'];
}) => {
  const { setValue, watch } =
    useFormContext<TakeDefined<ReturnType<typeof useGetUserAsForm>['data']>>();

  if (matrix.length === 0) {
    return null;
  }

  const roles = matrix[0].departments[0].matrix;

  return (
    <Box width="100%">
      <Box display="flex" flexDirection="row" paddingBottom={1}>
        <Box flex="3">
          <Typography variant="subtitle1">Vestiging rollen</Typography>
        </Box>
        {roles.map((role) => {
          return (
            <Box key={role.name} display="flex" flex="1" justifyContent="center">
              <Typography variant="subtitle1">{translateRoleToDutch(role.name)}</Typography>
            </Box>
          );
        })}
      </Box>
      {matrix.map((branchOffice) => (
        <Box display="flex" flexDirection="row" key={branchOffice.id} marginTop={4}>
          <Box flex="3">
            <Typography variant="subtitle1">{branchOffice.name}</Typography>
            {branchOffice.departments.map((department) => (
              <Box display="flex" flexDirection="row" key={department.id} mt={4} ml={2}>
                <Box flex="3" alignSelf="center">
                  <Typography variant="subtitle2">{department.name}</Typography>
                </Box>

                {roles.map((role) => {
                  const identifier =
                    `roles.departmentRoles.${department.id}` as `roles.departmentRoles.${string}`;
                  const currentValue = watch(identifier);
                  return (
                    <Box key={role.name} flex="1" justifyContent="center" display="flex">
                      <LwCheckbox
                        name={identifier}
                        title={role.name}
                        checked={currentValue === role.role}
                        onChange={(e) => {
                          setValue(identifier, e.target.checked ? role.role : null);
                        }}
                        disabled={state === 'read' || !role.enabled}
                      />
                    </Box>
                  );
                })}
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};
