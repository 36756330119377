import { QUERY_KEYS } from 'api/query-keys';
import _ from 'lodash';
import { partnerCheckoutsService } from 'partner/shared/services';
import { useQuery } from 'react-query';

export const usePartnerCheckoutInboxFilters = () => {
  const queryFn = async () => await partnerCheckoutsService.getCheckoutInboxFilters();

  return useQuery(QUERY_KEYS.checkoutInboxFilters(), {
    queryFn,
    select: (data) => {
      return {
        ...data,
        companies: _.sortBy(data.companies, (company) => company.name.toLowerCase()),
        jobs: _.sortBy(data.jobs, (job) => job.name.toLowerCase()),
      };
    },
  });
};
