import { Box, Divider, Grid, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useEffect, useMemo, useRef } from 'react';
import { FormCheckbox, FormLocation } from 'redesign';
import { FlexPoolsSection } from 'ogp/components/views/planning/jobs/forms/shared/flex-pools/flex-pools-section';
import {
  FreelanceShiftFormData,
  TempWorkShiftFormData,
} from 'shared/components/shift-forms/types/shift-forms.types';
import { WorkFormDetails } from 'shared/components/work-forms/details/work-form-details';
import { FlexPoolOnlyIndicator } from 'shared/components/common/flexpool-only-indicator';
import { useGetDepartmentDetail } from 'api/hooks/department/use-get-department-detail';
import { useSiteDetection } from 'shared/hooks';
import { useGetWorkTemplate } from 'api';
import { ShiftFormProps } from '../shared/types';
import { JobFormElement } from '../shared/inputs/job-inputs';
import { ShiftDateTimeInputs } from '../shared/inputs/date-time-inputs';
import { BreakMinutesInput } from '../shared/inputs/break-minutes-input';
import { MaxClaimantsElement } from '../shared/inputs/max-claimants-input';
import { HourlyRateInput } from '../shared/inputs/hourly-rate-input';
import { getHelperTexts } from '../shared/get-helper-texts/get-helper-texts';
import { MutableUntilHoursInput } from '../shared/inputs/mutable-until-input';
import { DepartmentFormElement } from '../shared/inputs/department-input';

export const ShiftForm = ({
  isDisabled,
  mode,
  shiftCreateData,
  jobList,
  employmentTypeOfCompany,
  allowDateBeforeToday,
  workDetails,
}: ShiftFormProps) => {
  const { site } = useSiteDetection();
  const { setValue, watch, control, getValues } = useFormContext<
    FreelanceShiftFormData | TempWorkShiftFormData
  >();
  const selectedDepartmentId = watch('departmentId');
  const isFreelanceCheckboxChecked = watch('isFreelance');
  const selectedWorkTemplateId = watch('jobId');
  const previousDepartmentId = useRef(getValues('departmentId'));
  const skipDepartmentEffect = useRef(false);

  const { data: departmentData } = useGetDepartmentDetail(
    site,
    selectedDepartmentId,
    !!selectedDepartmentId
  );
  const { data: workTemplateData } = useGetWorkTemplate(
    site,
    selectedWorkTemplateId,
    !!selectedWorkTemplateId
  );
  const helperTexts = useMemo(
    () =>
      getHelperTexts(
        workTemplateData
          ? {
              breakMinutes: workTemplateData.breakMinutes,
              hourlyRateCents: workTemplateData.freelanceJob?.hourlyRateCents,
            }
          : undefined
      ),
    [workTemplateData]
  );

  const workTemplateOptions = useMemo(
    () =>
      jobList.map((job) => ({
        value: job.id,
        label: (
          <FlexPoolOnlyIndicator flexPoolOnly={job.flexPoolOnly}>{job.name}</FlexPoolOnlyIndicator>
        ),
      })),
    [jobList]
  );

  const contractFieldsDisabled =
    isDisabled || (!allowDateBeforeToday && mode === 'disableContractFields');

  const areCheckboxesDisabled = useMemo(() => {
    const jobEmploymentType = {
      isFreelance: !!workTemplateData?.freelanceJob,
      isTempwork: !!workTemplateData?.tempWorkJob,
    };
    const companyAllowsBoth =
      employmentTypeOfCompany && Object.values(employmentTypeOfCompany).every(Boolean);

    const jobAllowsBoth = jobEmploymentType && Object.values(jobEmploymentType).every(Boolean);
    const isEnabled = !isDisabled && selectedWorkTemplateId && companyAllowsBoth && jobAllowsBoth;

    return !isEnabled;
  }, [employmentTypeOfCompany, workTemplateData, selectedWorkTemplateId, isDisabled]);

  //  setting the values from job only if company allows the employment type (edge case)
  useEffect(() => {
    if (workTemplateData && employmentTypeOfCompany) {
      const { offersFreelanceWork, offersTempWork } = employmentTypeOfCompany;

      setValue('isFreelance', !!workTemplateData?.freelanceJob && offersFreelanceWork);
      setValue('isTempwork', !!workTemplateData?.tempWorkJob && offersTempWork);
    }
  }, [workTemplateData, employmentTypeOfCompany]);

  useEffect(() => {
    if (!workTemplateData) {
      return;
    }
    if (mode !== 'create') {
      return;
    }

    skipDepartmentEffect.current = !!workTemplateData.departmentId;
    setValue('departmentId', workTemplateData.departmentId ?? '');
    setValue('breakMinutes', workTemplateData.breakMinutes);
    setValue('location', workTemplateData.location);
    if (!!workTemplateData.freelanceJob && employmentTypeOfCompany?.offersFreelanceWork) {
      setValue('hourlyRate', workTemplateData.freelanceJob.hourlyRateCents / 100);
      setValue('mutableUntilHours', workTemplateData.freelanceJob.mutableUntilMinutes / 60);
    } else {
      setValue('hourlyRate', 0);
    }
  }, [workTemplateData, mode, employmentTypeOfCompany]);

  useEffect(() => {
    if (skipDepartmentEffect.current) {
      skipDepartmentEffect.current = false;
      return;
    }
    const shouldSetDepartmentLocation =
      departmentData?.department.location &&
      previousDepartmentId.current !== departmentData.department.id;

    if (mode !== 'create' && shouldSetDepartmentLocation) {
      setValue('location', departmentData.department.location);
      previousDepartmentId.current = departmentData.department.id;

      return;
    }
    if (departmentData?.department.location && shouldSetDepartmentLocation) {
      setValue('location', departmentData.department.location);
      previousDepartmentId.current = departmentData.department.id;
    }
  }, [departmentData]);

  useEffect(() => {
    if (!workTemplateData) {
      return;
    }
    if (mode !== 'create') {
      return;
    }

    const validFlexPools = workTemplateData.flexPools.filter((flexPool) =>
      departmentData?.department.flexPools?.find((fp) => fp.id === flexPool.id)
    );
    setValue('flexPools', validFlexPools);
  }, [departmentData, workTemplateData]);

  return (
    <Box display="flex" flexDirection="row">
      <Box display="flex" flexDirection="row" height="100%" flex="1">
        <form name="shift-form">
          <Grid container item spacing={4} xs={12}>
            {mode === 'create' ? (
              <Grid item xs={6}>
                <JobFormElement options={workTemplateOptions} disabled={isDisabled} />
              </Grid>
            ) : null}
            <Grid item xs={6}>
              <DepartmentFormElement
                options={shiftCreateData?.branchOfficesWithDepartments ?? []}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLocation disabled={isDisabled} required={true} />
            </Grid>
            <Grid item xs={12}>
              <ShiftDateTimeInputs
                disabled={contractFieldsDisabled}
                allowDateBeforeToday={allowDateBeforeToday}
                mode="create"
              />
            </Grid>
            <Grid item xs={6}>
              <MaxClaimantsElement disabled={isDisabled} required={true} />
            </Grid>
            <Grid item xs={6}>
              <BreakMinutesInput
                options={shiftCreateData?.breakOptions ?? []}
                disabled={contractFieldsDisabled}
                helperText={helperTexts.breakMinutes}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FlexPoolsSection
                state={mode}
                flexPoolOptions={departmentData?.department.flexPools ?? []}
              />
            </Grid>
            <Grid item xs={12}>
              <FormCheckbox
                name="isTempwork"
                label="Uitzendkracht"
                control={control}
                disabled={areCheckboxesDisabled}
              />
            </Grid>
            {/* FREELANCE FIELDS */}
            <Grid item xs={12}>
              <FormCheckbox
                name="isFreelance"
                label="Zelfstandige"
                control={control}
                disabled={areCheckboxesDisabled}
              />
            </Grid>
            <Grid item xs={6}>
              <HourlyRateInput
                disabled={!isFreelanceCheckboxChecked || contractFieldsDisabled}
                helperText={helperTexts.hourlyRate ?? ''}
                placeholder={`${(workTemplateData?.freelanceJob?.hourlyRateCents || 0) / 100}`}
                required={isFreelanceCheckboxChecked}
              />
            </Grid>
            <Grid item xs={6}>
              <MutableUntilHoursInput
                disabled={!isFreelanceCheckboxChecked || contractFieldsDisabled}
                required={isFreelanceCheckboxChecked}
              ></MutableUntilHoursInput>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box>
        <StyledDivider orientation="vertical" />
      </Box>
      <Box flex="1" display="flex">
        <WorkFormDetails workDetails={workDetails} />
      </Box>
    </Box>
  );
};

const StyledDivider = styled(Divider)(({ theme }) => ({
  height: '100%',
  marginLeft: theme.spacing(7),
  marginRight: theme.spacing(7),
  width: 1,
}));
