import {
  IconBranchOffices,
  IconCheckouts,
  IconCompanies,
  IconDocument1,
  IconFlexPools,
  IconInbox,
  IconJobs,
  IconPlanning,
  IconProject,
  IconUser,
  IconUsers,
} from 'assets/img';
import { paths } from 'ogp/paths';
import { LogoutIcon, LogoutLabel } from './sidebar-logout';
import { getCheckoutsArchiveDefaultFilters } from '../administration/shared';

const items = {
  planning: [
    {
      ...paths.ShiftPlanning,
      label: 'Planning',
      icon: <IconPlanning />,
    },
    {
      ...paths.Projects,
      label: 'Projectplanning',
      icon: <IconProject />,
    },
    {
      ...paths.Jobs,
      label: 'Functies',
      icon: <IconJobs />,
    },
    {
      ...paths.FlexPools,
      label: 'Flexpools',
      icon: <IconFlexPools />,
    },
  ],
  administration: [
    {
      ...paths.CheckoutsInbox,
      label: 'Checkouts',
      icon: <IconInbox />,
    },
    {
      ...paths.CheckoutsArchive,
      path: `${paths.CheckoutsArchive.path}${getCheckoutsArchiveDefaultFilters()}`,
      label: 'Archief',
      icon: <IconCheckouts />,
    },
  ],
  management: [
    {
      ...paths.Company,
      label: 'Bedrijf',
      icon: <IconCompanies />,
    },
    {
      ...paths.BranchOffices,
      label: 'Vestigingen',
      icon: <IconBranchOffices />,
    },
    {
      ...paths.Departments,
      label: 'Afdelingen',
      icon: <IconBranchOffices />,
    },
    {
      ...paths.Users,
      label: 'Gebruikers',
      icon: <IconUsers />,
    },
    {
      ...paths.Legal,
      label: 'Voorwaarden',
      icon: <IconDocument1 />,
    },
  ],
  profile: [
    {
      label: 'Profiel',
      icon: <IconUser />,
    },
    {
      label: <LogoutLabel />,
      icon: <LogoutIcon />,
    },
  ],
};

export { items };
