import { QUERY_KEYS } from 'api/query-keys';
import { sharedWorkTemplateService } from 'api/services';
import { useQuery } from 'react-query';
import { useAlert } from 'shared/components/alerts';
import { Site } from 'shared/hooks';

export const useGetWorkTemplate = (site: Site, jobId: string, enabled: boolean = true) => {
  const { alertError } = useAlert();

  return useQuery(
    QUERY_KEYS.workTemplate(jobId),
    async () => await sharedWorkTemplateService.getWorkTemplate(site, jobId),
    { enabled, onError: (e) => alertError(e) }
  );
};
