import React from 'react';
import { Auth } from 'aws-amplify';
import { Drawer, styled } from '@mui/material';
import { usePopoverHandlers } from 'shared/components/user-popover';
import { AuthenticatedElement } from 'ogp/components/common/authenticated-element';
import { useCompanyRelativePath } from 'ogp/hooks';
import { Link } from 'react-router-dom';
import { SiteInfo } from 'shared/components';
import { useToggle } from 'shared/hooks';
import { UserPopoverOgp } from 'shared/components/user-popover/user-popover';
import { useQueryClient } from 'react-query';
import { SidebarSection } from './sidebar-section';
import { useSidebarProvider } from './sidebar-provider';
import { useUserRoles } from './use-user-roles';
import { SidebarToggle } from './sidebar-toggle';
import { items } from './sidebar-items';
import { CompanyName } from './sidebar-company-name';
import { SiteInfoContainer } from './site-info';
import { LOGO_PATH, SMALL_LOGO_PATH } from '../../../../config';

const Sidebar = () => {
  const { isOpen } = useSidebarProvider();
  const [userLabel, setUserLabel] = React.useState('');
  const { id: popoverId, anchorEl } = usePopoverHandlers();
  const userRoles = useUserRoles();
  const generatePath = useCompanyRelativePath();
  const { on: userInfoOpen, toggle: toggleUserInfo } = useToggle();
  const queryClient = useQueryClient();

  const logout = async () => {
    await Auth.signOut();
    queryClient.removeQueries();
  };

  // TODO this might be moved to popover itself?
  React.useEffect(() => {
    let isMounted = true;
    Auth.currentUserInfo().then((res) => {
      isMounted && res && setUserLabel(res.username);
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const getSectionPermissions = (section: Exclude<keyof typeof items, 'profile'>) => ({
    roles: items[section].map(({ roles }) => roles).flat(),
    policyGroup: items[section][0].policyGroup, // TODO do we have a better way to hide sections? https://levelworks.atlassian.net/browse/LEV-775
  });

  return (
    <>
      <Styled.Sidebar variant="permanent" folded={!isOpen}>
        <Styled.Logo folded={!isOpen} to={generatePath(items.planning[0])}>
          <img src={isOpen ? LOGO_PATH : SMALL_LOGO_PATH} alt="Level.works" />
        </Styled.Logo>

        <CompanyName />

        <AuthenticatedElement {...getSectionPermissions('planning')}>
          <SidebarSection>
            <SidebarSection.Title>Planning</SidebarSection.Title>
            <SidebarSection.List>
              {items.planning.map((item) => (
                <AuthenticatedElement
                  key={item.label}
                  roles={item.roles}
                  policyGroup={item.policyGroup}
                >
                  <SidebarSection.Link {...item} />
                </AuthenticatedElement>
              ))}
            </SidebarSection.List>
          </SidebarSection>
        </AuthenticatedElement>

        <AuthenticatedElement {...getSectionPermissions('administration')}>
          <SidebarSection>
            <SidebarSection.Title>Administratie</SidebarSection.Title>
            <SidebarSection.List>
              {items.administration.map((item) => (
                <AuthenticatedElement
                  key={item.label}
                  roles={item.roles}
                  policyGroup={item.policyGroup}
                >
                  <SidebarSection.Link {...item} />
                </AuthenticatedElement>
              ))}
            </SidebarSection.List>
          </SidebarSection>
        </AuthenticatedElement>

        <AuthenticatedElement {...getSectionPermissions('management')}>
          <SidebarSection>
            <SidebarSection.Title>Management</SidebarSection.Title>
            <SidebarSection.List>
              {items.management.map((item) => (
                <AuthenticatedElement
                  key={item.label}
                  roles={item.roles}
                  policyGroup={item.policyGroup}
                >
                  <SidebarSection.Link {...item} />
                </AuthenticatedElement>
              ))}
            </SidebarSection.List>
          </SidebarSection>
        </AuthenticatedElement>

        <SidebarSection style={{ marginTop: 'auto' }}>
          <SidebarSection.Title>Profiel</SidebarSection.Title>
          <SidebarSection.List>
            <SidebarSection.Button {...items.profile[0]} onClick={toggleUserInfo} />
            <SidebarSection.Button {...items.profile[1]} onClick={logout} />
          </SidebarSection.List>
        </SidebarSection>
        <SiteInfoContainer>
          <SiteInfo />
        </SiteInfoContainer>
      </Styled.Sidebar>
      <SidebarToggle />
      <Drawer anchor="right" open={userInfoOpen} onClose={toggleUserInfo}>
        <UserPopoverOgp
          userLabel={userLabel}
          userRoles={userRoles}
          id={popoverId}
          open={!!anchorEl}
          anchorEl={anchorEl}
        />
      </Drawer>
    </>
  );
};

const Styled = {
  Sidebar: styled(Drawer, { shouldForwardProp: (prop) => prop !== 'folded' })<{
    folded: boolean;
  }>(({ theme, folded }) => ({
    '&.MuiDrawer-root': {
      width: folded ? '120px' : '248px',
    },
    '&.MuiDrawer-root > *': {
      fontFamily: 'Urbanist',
      fontWeight: 500,
      fontVariationSettings: '"wght" 500',
      gap: theme.spacing(5),
      paddingLeft: theme.spacing(folded ? 3 : 6),
      paddingRight: theme.spacing(folded ? 1 : 4),
      width: folded ? '120px' : '248px',
      '::-webkit-scrollbar': {
        width: '22px',
      },
      '::-webkit-scrollbar-track': {
        background: 'rgba(0, 0, 0, 0.1)',
        border: '8px solid white',
        borderRadius: '12px',
      },

      '::-webkit-scrollbar-thumb': {
        background: 'rgba(0, 0, 0, 0.2)',
        backgroundClip: 'content-box',
        border: '8px solid transparent',
        borderRadius: '12px',
        '&:hover': {
          background: 'rgba(0, 0, 0, 0.4)',
          backgroundClip: 'content-box',
          border: '8px solid transparent',
          borderRadius: '12px',
        },
      },
    },

    '& .MuiPaper-root': {
      borderRight: 0,
      overflowX: 'hidden',
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: folded
          ? theme.transitions.duration.enteringScreen
          : theme.transitions.duration.leavingScreen,
      }),
    },

    ...(folded && {
      '&.MuiDrawer-root section p:first-of-type': {
        paddingLeft: 0,
      },

      '& .MuiBox-root': {
        justifyContent: 'center',
      },
    }),
  })),
  Logo: styled(Link, { shouldForwardProp: (prop) => prop !== 'folded' })<{ folded: boolean }>(
    ({ theme, folded }) => ({
      height: '24px',
      marginBottom: theme.spacing(7),
      marginTop: theme.spacing(8),
      textAlign: 'center',
      width: '100%',

      '& img': {
        maxHeight: '40px',
        maxWidth: folded ? '60%' : '100%',
      },
    })
  ),
};

export { Sidebar };
