import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToolboxFormCreate } from 'shared/components/common/toolbox-form-create';
import { ProjectForm } from 'shared/components/project-forms/components/project-form';
import { Header, Page } from 'redesign';
import { useAlert } from 'shared/components/alerts';
import { HQProjectFormTypeCreate } from 'shared/components/project-forms/types/project-form.types';
import { useGetJobDetails } from 'shared/components/work-forms/details/hooks/use-get-job-details';
import { invariant } from 'shared/utils/utils';
import { useEffect } from 'react';
import { useCompanyRelativePath } from '../../../../../../../hooks';
import { paths } from '../../../../../../../paths';
import { routes } from '../../../../../../../Routes';
import { useCreateProject } from '../../../hooks/use-create-project';
import { OgpProjectCreatePayload } from '../../../../../../../services/project-service.types';

type ProjectCreateProps = {
  successMessage: string;
  pageTitle: string;
};

export const ProjectCreateFormPage = ({ successMessage, pageTitle }: ProjectCreateProps) => {
  const { alertSuccess, alertError } = useAlert();
  const formMethods = useForm<HQProjectFormTypeCreate>({ defaultValues: { location: null } });

  const jobId = formMethods.watch('jobId');
  const companyId = formMethods.watch('companyId');
  const jobDetailsQuery = useGetJobDetails(jobId);

  const navigate = useNavigate();
  const generatePath = useCompanyRelativePath();
  const mutation = useCreateProject();

  useEffect(() => {
    if (companyId) {
      formMethods.reset({ companyId }, { keepDefaultValues: true });
    }
  }, [companyId]);

  const handleSubmit = () => {
    formMethods.handleSubmit((data: HQProjectFormTypeCreate) => {
      invariant(data.location, 'Location is required');

      const payload: OgpProjectCreatePayload = {
        departmentId: data.departmentId,
        jobId: data.jobId,
        startDate: data.startDate,
        endDate: data.endDate,
        weekHourVolume: +data.weeklyHours,
        freelanceProperties: data.isFreelance
          ? {
              hourlyRateCents: Math.round(+data.hourlyRate * 100),
            }
          : undefined,
        isTempWork: data.isTempwork,
        location: data.location,
      };
      mutation.mutate(payload, {
        onSuccess: ({ id }) => {
          alertSuccess(successMessage);
          navigate(generatePath(routes.Projects, {}, { id }));
        },
        onError: (e) => alertError(e),
      });
    })();
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText={pageTitle} />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={() => navigate(generatePath(paths.Projects), { replace: true })}
          />
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <ProjectForm
              mode="create"
              isProjectWithActivePlacement={false}
              workDetails={jobDetailsQuery.data}
            />
          </Grid>
        </Grid>
      </Page>
    </FormProvider>
  );
};
