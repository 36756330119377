import { DateTime } from 'luxon';

const getTodaysDateFormatted = (): string => {
  return DateTime.now().toUTC().startOf('day').toISODate();
};

const getPlannerShiftDefaultFilter = (): string => {
  return `?fromDate=${getTodaysDateFormatted()}`;
};

const getPlannerProjectDefaultFilter = (): string => {
  return `?toDate=${getTodaysDateFormatted()}`;
};
const getPlannerPlacementDefaultFilter = (): string => {
  return `?fromDate=${getTodaysDateFormatted()}`;
};

export {
  getPlannerShiftDefaultFilter,
  getPlannerProjectDefaultFilter,
  getPlannerPlacementDefaultFilter,
};
