import { CACHE_TIME_LONG, STALE_TIME_LONG } from 'shared/react-query/query-defaults';
import { useQuery } from 'react-query';
import { accreditationsService } from 'hq/services';
import { QUERY_KEYS } from 'api/query-keys';

export const useGetCertificates = () => {
  const queryFn = async () => await accreditationsService.getCertificates();

  return useQuery(QUERY_KEYS.accreditationsCertificates(), queryFn, {
    cacheTime: CACHE_TIME_LONG,
    staleTime: STALE_TIME_LONG,
  });
};
