import { useFormContext } from 'react-hook-form';
import { LwFormSelect } from 'redesign';
import { Props, VatFormContext } from './vat.types';

export const VatSelect = (props: Props) => {
  const { control } = useFormContext<VatFormContext>();
  const formName = 'vat';
  const label = 'BTW-tarief';

  return (
    <LwFormSelect
      name={formName}
      control={control}
      label={label}
      disabled={props.disabled}
      options={props.options}
    />
  );
};
