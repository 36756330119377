import { Typography } from '@mui/material';
import { useBulkActionResultContext } from 'shared/providers/bulk-action-provider';
import { FailedBulkCancelPlacementsTable } from './failed-bulk-cancel-table/failed-bulk-cancel-placements-table';

export const FailedBulkCancelActionsSection = () => {
  const { failure } = useBulkActionResultContext();

  if (failure.length === 0) {
    return null;
  }

  return (
    <>
      <Typography variant="h2" gutterBottom>
        De volgende plaatsingen konden niet worden gewijzigd:
      </Typography>
      <FailedBulkCancelPlacementsTable items={failure} />
    </>
  );
};
