import { statisticsService } from 'ogp/services';
import { PageStatistics } from 'ogp/services/statistics-service';
import { useQuery } from 'react-query';
import { CACHE_TIME_LONG } from 'shared/react-query/query-defaults';
import { FirstParamOfFn } from '@types';
import { QUERY_KEYS } from 'api/query-keys';

export const useGetStatistics = (
  options: FirstParamOfFn<typeof statisticsService.getStatistics>
) => {
  const queryFn = async () => await statisticsService.getStatistics(options);

  return useQuery<Promise<PageStatistics[]>, Error, PageStatistics[]>(
    QUERY_KEYS.statistics(),
    queryFn,
    {
      cacheTime: CACHE_TIME_LONG,
    }
  );
};
