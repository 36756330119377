import { HqApiService } from 'hq/services/hqApiService';
import { OgpApiService } from 'ogp/services/ogp-api-service';
import { Site } from 'shared/hooks';
import {
  GetHQWorkTemplateDetailDto,
  GetOGPWorkTemplateDetailDto,
  GetWorkTemplatesDto,
} from './work-template-service.dto';
import {
  WorkTemplateDetail,
  workTemplateSchema,
  WorkTemplatesResponse,
  workTemplatesSchema,
} from './work-template-service.schema';

export class WorkTemplateService {
  constructor(
    private readonly ogpApiService: OgpApiService,
    private readonly hqApiService: HqApiService
  ) {}

  public async getWorkTemplate(site: Site, jobId: string): Promise<WorkTemplateDetail> {
    const response = await this.getWorkTemplateImpl(site, jobId);
    return workTemplateSchema.parse(response);
  }

  private async getWorkTemplateImpl(
    site: Site,
    jobId: string
  ): Promise<GetHQWorkTemplateDetailDto | GetOGPWorkTemplateDetailDto> {
    const path = `jobs/${jobId}`;
    if (site === 'hq') {
      return await this.hqApiService.get<GetHQWorkTemplateDetailDto>({
        path,
      });
    }

    if (site === 'ogp') {
      const res = await this.ogpApiService.get<GetOGPWorkTemplateDetailDto>({
        path,
      });
      return res;
    }

    throw new Error('Not Implemented');
  }

  public async getWorkTemplates(site: Site, companyId?: string): Promise<WorkTemplatesResponse> {
    const response = await this.getWorkTemplatesImpl(site, companyId);
    return workTemplatesSchema.parse(response);
  }

  private async getWorkTemplatesImpl(
    site: Site,
    companyId: string | undefined
  ): Promise<GetWorkTemplatesDto> {
    const path = `jobs`;
    if (site === 'hq') {
      return await this.hqApiService.get<GetWorkTemplatesDto>({
        path,
        params: { companyId },
      });
    }

    if (site === 'ogp') {
      return await this.ogpApiService.get<GetWorkTemplatesDto>({
        path,
      });
    }

    throw new Error('Not Implemented');
  }
}
