import { HqApiService } from 'hq/services/hqApiService';
import { OgpApiService } from 'ogp/services/ogp-api-service';
import { EnvironmentService } from 'shared/services';
import { PartnerApiService } from 'partner/shared/services/partner-api-service';
import { ShiftService } from './shift/shift-service';
import { DepartmentService } from './department/department-service';
import { WorkTemplateService } from './work-template/work-template-service';
import { CompanyService } from './company/company-service';
import { PartnerWorkerService } from './shifts/shift.service';

const ogpEndpoint = EnvironmentService.getVariable('BACKEND_OGP_URI');
const hqEndpoint = EnvironmentService.getVariable('BACKEND_HQ_URI');
const partnerEndpoint = EnvironmentService.getVariable('BACKEND_PARTNER_URI');

const ogpApiService = new OgpApiService({ endpoint: ogpEndpoint });
const hqApiService = new HqApiService({ endpoint: hqEndpoint });
const partnerApiService = new PartnerApiService({ endpoint: partnerEndpoint });

export const partnerWorkerService = new PartnerWorkerService(
  partnerApiService,
  ogpApiService,
  hqApiService
);
export const sharedShiftService = new ShiftService(ogpApiService, hqApiService, partnerApiService);
export const sharedDepartmentService = new DepartmentService(ogpApiService, hqApiService);
export const sharedWorkTemplateService = new WorkTemplateService(ogpApiService, hqApiService);
export const sharedCompanyService = new CompanyService(ogpApiService, hqApiService);
