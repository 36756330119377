import { useFormContext } from 'react-hook-form';
import { LwFormTextArea } from 'redesign';
import { getRequiredMessage } from './shared';
import { LongDescriptionFormContext, Props } from './long-description.types';

export const LongDescriptionInput = (props: Props) => {
  const { control } = useFormContext<LongDescriptionFormContext>();
  const formName = 'longDescription';
  const label = 'Lange omschrijving';
  const requiredMessage = getRequiredMessage(label);

  return (
    <LwFormTextArea
      name={formName}
      control={control}
      label={label}
      disabled={props.disabled}
      rules={props.required ? { required: requiredMessage } : undefined}
      rows={10}
    />
  );
};
