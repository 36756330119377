import { HqApiService } from 'hq/services/hqApiService';
import { OgpApiService } from 'ogp/services/ogp-api-service';
import { Site } from 'shared/hooks';
import { PartnersFilterData } from 'shared/components/filters/inputs/partners/filter-partners.types';
import { companyEmploymentTypesSchema, companyPartnersSchema } from './company-service.schema';
import { CompanyEmploymentTypesResponseDto, CompanyPartnersDto } from './company-service.dto';

export class CompanyService {
  constructor(
    private readonly ogpApiService: OgpApiService,
    private readonly hqApiService: HqApiService
  ) {}

  public getCompanyEmploymentTypes = async (site: Site, companyId?: string) => {
    const companyEmploymentTypes = await this.getCompanyEmploymentTypesImpl(site, companyId);

    return companyEmploymentTypesSchema.parse(companyEmploymentTypes);
  };

  public getCompanyPartners = async (site: Site): Promise<PartnersFilterData[]> => {
    const companyPartners = await this.getCompanyPartnersImpl(site);

    return companyPartnersSchema.parse(companyPartners);
  };

  private async getCompanyEmploymentTypesImpl(
    site: Site,
    companyId?: string
  ): Promise<CompanyEmploymentTypesResponseDto> {
    if (site === 'hq') {
      return await this.hqApiService.get<CompanyEmploymentTypesResponseDto>({
        path: `companies/${companyId}/employment-types/`,
      });
    }

    if (site === 'ogp') {
      return await this.ogpApiService.get<CompanyEmploymentTypesResponseDto>({
        path: `companies/employment-types/`,
      });
    }

    throw new Error('Not implemented');
  }

  private async getCompanyPartnersImpl(site: Site): Promise<CompanyPartnersDto[]> {
    if (site === 'ogp') {
      return await this.ogpApiService.get<CompanyPartnersDto[]>({
        path: `companies/partners`,
      });
    }

    throw new Error('Not implemented');
  }
}
