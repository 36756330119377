import { QUERY_KEYS } from 'api/query-keys';
import { shiftPlanningService } from 'hq/services';
import { useQuery } from 'react-query';

const useGetWorkPlanningBusinessUnitsData = () => {
  return useQuery({
    queryKey: QUERY_KEYS.businessUnits(),
    queryFn: async () => await shiftPlanningService.getShiftPlanningBusinessUnitsData(),
  });
};

const useGetWorkPlanningJobTypesData = () => {
  return useQuery({
    queryKey: QUERY_KEYS.jobTypes(),
    queryFn: async () => await shiftPlanningService.getShiftPlanningJobTypesData(),
  });
};

export { useGetWorkPlanningJobTypesData, useGetWorkPlanningBusinessUnitsData };
