import { Header, LwTab, ModalPanelTabLinkType, Page } from 'redesign';
import { Tabs } from '@mui/material';
import { styled } from '@mui/styles';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PropsWithChildren, useMemo } from 'react';
import { RouteDefinition, routes } from '../../../../Routes';

export const PartnerPage = ({ children }: PropsWithChildren<unknown>) => {
  const location = useLocation();
  const { allowPartnerRelations } = useFeatureFlagContext();
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const { id } = useParams() as { id: string };
  const selectedTabId = useMemo(() => {
    const path = location.pathname;
    return path.split('/').pop();
  }, [location.pathname]);
  const tabs: ModalPanelTabLinkType[] = getTabs(
    allowPartnerRelations,
    generatePath,
    id,
    selectedTabId
  );

  return (
    <Page
      header={<Header titleText="Partner Detail" />}
      toolbox={
        <StyledTabs aria-label="partner-detail-tabs" value={selectedTabId}>
          {tabs.map((tab) => (
            <LwTab
              key={tab.id}
              value={tab.id}
              label={tab.tabLabel}
              onClick={() => {
                navigate(tab.to);
              }}
            />
          ))}
        </StyledTabs>
      }
    >
      {children}
    </Page>
  );
};

const getTabs = (
  allowPartnerRelations: boolean,
  generatePath: (
    route: RouteDefinition,
    params?:
      | {
          [key: string]: string | undefined;
        }
      | undefined
  ) => string,
  id: string,
  selectedTabId: string | undefined
) => {
  const tabs = [
    {
      to: generatePath(routes.PartnerDetail, { id }),
      id: 'info',
      tabLabel: 'Partner info',
    },
    ...(allowPartnerRelations
      ? [
          {
            to: generatePath(routes.PartnerRelation, { id }),
            id: 'relations',
            tabLabel: 'Partner relaties',
          },
        ]
      : []),
    {
      to: generatePath(routes.PartnerUsers, { id }),
      id: 'users',
      tabLabel: 'Partner gebruikers',
    },
  ];
  return tabs.map((tab) => ({
    ...tab,
    selected: tab.id === selectedTabId,
  }));
};

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& div.MuiTabs-scroller': {
    '& .MuiTabs-flexContainer': {
      gap: theme.spacing(8),
      overflow: 'visible',
    },
  },
}));
