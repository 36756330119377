import { RelationType } from 'ogp/hooks/queryHooks/flexWorkers/useGetFlexWorkerProfile';
import { ApplicantColumnDefinition } from 'shared/components/shift-detail-modal/components/tabs/applicants/components/helpers/applicant-table.types';
import { WorkApplicationTableItem } from '../../../../../services/work-application-service.types';

export const getApplicantTableData = (
  applicationDetails: WorkApplicationTableItem[]
): ApplicantColumnDefinition<WorkApplicationTableItem>[] => {
  return applicationDetails.map((applicationDetail) => ({
    flexWorkerImage: {
      firstName: applicationDetail.firstName ?? '',
      lastName: applicationDetail.lastName ?? '',
      imageUrl: applicationDetail.imageUrl,
      relation: { id: applicationDetail.id, relation: RelationType.SHIFTAPPLICATION },
      id: applicationDetail.workerId,
      hasCandidateLevel: applicationDetail.hasCandidateLevel,
      employmentType: applicationDetail.employmentType,
    },
    hourlyRate: {
      hourlyRate: applicationDetail.freelanceProperties
        ? applicationDetail.freelanceProperties.hourlyRateCents / 100
        : 0,
      defaultHourlyRate: applicationDetail.freelanceProperties
        ? applicationDetail.freelanceProperties.defaultHourlyRateCents / 100
        : 0,
      workerEmploymentType: applicationDetail.employmentType,
      hasDivergentRate: applicationDetail.hasDivergentRate,
    },
    flexWorkerFullName: applicationDetail.fullName,
    rating: applicationDetail.ratingPercentage,
    attendanceRate: applicationDetail.attendanceRate,
    workedShifts: applicationDetail.workedTotal ?? 0,
    hasRequiredCertificates: applicationDetail.hasRequiredCertificates,
    appliedShifts: applicationDetail.applicationIds ? applicationDetail.applicationIds.length : 0,
    phone: applicationDetail.phone ?? '',
    actions: applicationDetail,
    experienceInMonths: applicationDetail.experienceInMonths,
    distanceInKm: applicationDetail.distanceInKm,
  }));
};
