import { useFormContext } from 'react-hook-form';
import { LwFormTextArea } from 'redesign';
import { getRequiredMessage } from './shared';
import {
  DescriptionLegalRequirementsFormContext,
  Props,
} from './description-legal-requirements.types';

export const DescriptionLegalRequirementsInput = (props: Props) => {
  const { control } = useFormContext<DescriptionLegalRequirementsFormContext>();
  const formName = 'descriptionLegalRequirements';
  const label = 'Afspraken en vereisten';
  const requiredMessage = getRequiredMessage(label);

  return (
    <LwFormTextArea
      name={formName}
      control={control}
      label={label}
      disabled={props.disabled}
      rules={props.required ? { required: requiredMessage } : undefined}
      rows={5}
    />
  );
};
