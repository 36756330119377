import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { LwFormDate } from 'redesign';
import { compareDateStrings } from 'shared/utils/validate-date';
import { useDebounce } from 'shared/hooks/use-debounce';
import { Grid } from '@mui/material';
import { TableDateForm } from './table-date-control';
import { usePlanningParamsContext } from '../../../providers/planning-params-provider';

export const DateInputs = () => {
  const { watch, trigger } = useFormContext<TableDateForm>();
  const { setFilters } = usePlanningParamsContext();

  const fromDate = watch('fromDate');
  const endDate = watch('toDate');
  const debouncedFromDate = useDebounce(fromDate, 500);
  const debouncedToDate = useDebounce(endDate, 500);

  useEffect(() => {
    const validateAndSetFilters = async () => {
      if (!fromDate) {
        return;
      }
      const isValid = await trigger('toDate');
      if (!isValid) {
        return;
      }
      setFilters({
        fromDate: DateTime.fromISO(debouncedFromDate),
        toDate: debouncedToDate ? DateTime.fromISO(debouncedToDate) : undefined,
      });
    };

    validateAndSetFilters();
  }, [debouncedFromDate, debouncedToDate]);

  return (
    <Grid spacing={4} container>
      <Grid item xs={6}>
        <StartDateElement />
      </Grid>
      <Grid item xs={6}>
        <EndDateElement minDate={fromDate} />
      </Grid>
    </Grid>
  );
};

const StartDateElement = () => {
  const { control } = useFormContext<TableDateForm>();

  return (
    <LwFormDate
      name="fromDate"
      label="Startdatum"
      control={control}
      rules={{
        required: 'Voer een startdatum in',
      }}
      data-testid="start-date"
    />
  );
};

const EndDateElement = ({ minDate }: { minDate: string }) => {
  const { control } = useFormContext<TableDateForm>();
  return (
    <LwFormDate
      name="toDate"
      label="Einddatum"
      control={control}
      rules={{
        validate: (value) => {
          if (value && !compareDateStrings(minDate, value?.toString() || '')) {
            return 'Einddatum moet na de startdatum liggen';
          }
        },
      }}
      minDate={minDate}
      data-testid="end-date"
    />
  );
};
