import { FailedBulkShiftActionsColumnDefinition } from './failed-bulk-shift-actions-table.types';

type RawFailedBulkShiftActionsData = {
  id: string;
  error: string;
  shiftName: string;
  dateStartEndTime: string;
}[];

export const getFailedBulkShiftActionsTableData = (
  data: RawFailedBulkShiftActionsData
): FailedBulkShiftActionsColumnDefinition[] => {
  return data.map((item) => ({
    shiftId: item.id,
    error: item.error,
    shiftName: item.shiftName,
    dateStartEndTime: item.dateStartEndTime,
    action: item.id,
  }));
};
