import { Box, IconButton, Popper, styled, Typography } from '@mui/material';
import { IconPartner } from 'assets/img';
import { useCallback, useState } from 'react';
import { useGetShiftPartners } from 'shared/components/shift-detail-modal/query-hooks/hq/use-get-shift-partners';

export const CalendarShiftPartnersPopover = ({ shiftId }: { shiftId: string }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { data } = useGetShiftPartners(shiftId, !!anchorEl);

  const handleMouseEnter = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setAnchorEl(null);
    },
    [setAnchorEl]
  );

  return (
    <Box pr={1}>
      <CandidateIconButton onMouseLeave={handleClose} onMouseEnter={handleMouseEnter}>
        <IconPartner />
      </CandidateIconButton>
      <Popper
        onMouseLeave={handleClose}
        id={shiftId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <StyledBox>
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            Beschikbaar voor:
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1} paddingTop={3}>
            {data
              ? data.map(({ name }) => (
                  <Box key={name}>
                    <Typography variant="body2">{name}</Typography>
                  </Box>
                ))
              : null}
          </Box>
        </StyledBox>
      </Popper>
    </Box>
  );
};
const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  width: theme.spacing(40),
  minWidth: theme.spacing(40),
  border: '2px solid #00000019',
  borderRadius: theme.spacing(2),
  padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
}));

// Usage with additional `sx` styling
<StyledBox sx={{ color: 'red' }}>{/* Content here */}</StyledBox>;

const CandidateIconButton = styled(IconButton)(({ theme }) => ({
  width: '30px',
  height: '35px',
  padding: theme.spacing(0.5),
  marginLeft: theme.spacing(1),
}));
