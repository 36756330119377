import { QUERY_KEYS } from 'api/query-keys';
import _ from 'lodash';
import { shiftClaimService } from 'ogp/services';
import { useQuery } from 'react-query';

const useGetCheckoutsArchiveFilters = () => {
  const queryFn = async () => await shiftClaimService.getCheckoutsArchiveFilters();

  return useQuery(QUERY_KEYS.checkoutArchiveFilters(), {
    queryFn,
    select: (data) => ({
      ...data,
      branchOffices: _.sortBy(data.branchOffices, (branchOffice) =>
        branchOffice.name.toLowerCase()
      ),
    }),
  });
};

export { useGetCheckoutsArchiveFilters };
