import { ProviderFactory } from 'shared/providers/provider-factory';
import React, { Children, useCallback } from 'react';
import { WizardContext } from './wizard-provider.types';

const [Provider, useWizardProvider] = ProviderFactory<WizardContext>(
  'WizardContext can only be accessed within WizardProvider'
);

const WizardProvider = ({
  children,
  onClose,
}: React.PropsWithChildren<{ onClose?: () => void }>) => {
  const steps = Children.toArray(children);
  const [currentStep, setCurrentStep] = React.useState(0);

  const nextStep = useCallback(() => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else if (onClose) {
      onClose();
    }
  }, [currentStep, onClose, steps.length]);

  return (
    <Provider value={{ nextStep, currentStep, close: onClose }}>{steps[currentStep]}</Provider>
  );
};

const useWizardContext = () => {
  const wizardContext = useWizardProvider();

  return wizardContext;
};

export { WizardProvider, useWizardContext };
