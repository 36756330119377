import { QUERY_KEYS } from 'api/query-keys';
import { partnerInboxService } from 'partner/shared/services';
import { useInfiniteQuery } from 'react-query';

export const useGetPartnerInboxMessages = () => {
  return useInfiniteQuery({
    queryKey: QUERY_KEYS.inboxMessages(),
    queryFn: async ({ pageParam = 0 }) =>
      await partnerInboxService.getPartnerInboxMessages({
        pageParam,
      }),
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};
