import { Box, Divider } from '@mui/material';
import { LwTabIndicator, ModalPanelBody, ModalPanelTabType } from 'redesign';
import { RenderUnsettledUI, SmallError, SmallLoader } from 'shared/components';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { CancelledTableProvider } from './cancelled/cancelled-table-provider';
import { PlacementTable } from './placements/placement-table';
import { ProjectDetailHead } from './project-detail-head';
import { ProjectDetailTab } from './types';
import { ProjectApplicantTableProvider } from './applications/applicant-table-provider';
import { PlacementCreate } from './create-placement/create-placement';
import { useGetCreatePlacementActions } from './create-placement/use-get-create-placement-actions';
import { useGetProjectDetail } from './applications/hooks/use-get-project-detail';
import { useGetCompanyBasicInfo } from './applications/hooks/use-get-company-basic-info';
import { useHqAdmin } from '../../../../hooks';

type ProjectDetailProps = {
  close: () => void;
  id: string;
  handleEdit: () => void;
  handleDuplicate: () => void;
  handleArchive: () => void;
};

export const ProjectDetail = ({
  close,
  id: workId,
  handleEdit,
  handleDuplicate,
  handleArchive,
}: ProjectDetailProps) => {
  const projectQuery = useGetProjectDetail(workId);
  const companyQuery = useGetCompanyBasicInfo(workId);
  const flexWorkerTableActions = useGetCreatePlacementActions();
  const isHqAdmin = useHqAdmin();
  const { allowProjectApplications, allowGrossHourlyRateVisibleInOgp } = useFeatureFlagContext();
  const showTempWorkRate = isHqAdmin || allowGrossHourlyRateVisibleInOgp;

  if (projectQuery.status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={projectQuery}
      />
    );
  }

  if (companyQuery.status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={companyQuery}
      />
    );
  }

  const projectDetails = {
    projectId: workId,
    isFreelance: !!projectQuery.data.freelanceProperties,
    isTempWork: !!projectQuery.data.tempWorkProperties,
  };

  const options: ModalPanelTabType[] = [
    {
      id: ProjectDetailTab.TEAM,
      tabLabel: 'Jouw team',
      element: <PlacementTable project={projectQuery.data} />,
    },
    ...(allowProjectApplications
      ? [
          {
            id: ProjectDetailTab.APPLICATIONS,
            tabLabel: (
              <>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  Aanmeldingen
                  {projectQuery.data.applicantsCount ? (
                    <LwTabIndicator>{projectQuery.data.applicantsCount}</LwTabIndicator>
                  ) : null}
                </Box>
              </>
            ),

            element: <ProjectApplicantTableProvider id={projectQuery.data.id} />,
          },
        ]
      : []),
    {
      id: ProjectDetailTab.CANCELS,
      tabLabel: 'Annuleringen',
      element: (
        <CancelledTableProvider
          projectId={workId}
          hourlyRateCents={projectQuery.data.freelanceProperties?.hourlyRateCents ?? 0}
        />
      ),
    },
    ...(isHqAdmin
      ? [
          {
            id: ProjectDetailTab.ADD,
            tabLabel: 'Flexwerker toevoegen',
            element: (
              <PlacementCreate
                projectDetails={projectDetails}
                getRowActions={flexWorkerTableActions}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <Box p={8} height="100%" display="flex" flexDirection="column">
      <ProjectDetailHead
        close={close}
        companyName={companyQuery.data.name}
        data={projectQuery.data}
        handleEdit={handleEdit}
        handleDuplicate={handleDuplicate}
        handleArchive={handleArchive}
        showTempWorkRate={showTempWorkRate}
      />
      <Box mt={8} mb={8}>
        <Divider />
      </Box>
      <ModalPanelBody tabs={options} />
    </Box>
  );
};
