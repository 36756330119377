import { QUERY_KEYS } from 'api/query-keys';
import { partnerCheckoutsService } from 'partner/shared/services';
import { useQuery } from 'react-query';
import { CACHE_TIME_LONG, STALE_TIME_LONG } from 'shared/react-query/query-defaults';

export const useGetCounterOfferQuery = (shiftClaimId: string) => {
  const queryFn = async () => await partnerCheckoutsService.getCounterOffer(shiftClaimId);

  return useQuery(QUERY_KEYS.counterOffer(shiftClaimId), {
    queryFn,
    cacheTime: CACHE_TIME_LONG,
    staleTime: STALE_TIME_LONG,
  });
};
