import { Box, styled } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ActionSelect, LwButton } from 'redesign';
import { FilterButton } from 'ogp/components/views/administration/shared';
import { useState } from 'react';
import { useToggle } from 'shared/hooks';
import { TableDateControl } from './components/table-date-control/table-date-control';
import { CalendarDateControl } from './components/calendar-date-control/calendar-date-control';
import { PlanningViewSwitcher } from './components/planning-view-switcher';
import { useGetActions } from './hooks/use-get-actions';
import { PlanningFilterDataProvider } from './components/filters/planning-filter-data-provider';
import { ExportModal } from './components/export-drawer';
import { usePlanningParamsContext } from '../providers/planning-params-provider';

export const PlanningToolBox = () => {
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const { on: filtersOpen, toggle: toggleFilters } = useToggle();
  const location = useLocation();
  const activeRoute = location.pathname.split('/').pop();
  const actions = useGetActions(activeRoute);
  const { selectedFiltersCount } = usePlanningParamsContext();

  return (
    <>
      <Header height={100}>
        <ActionSelect actions={actions} />
        {activeRoute === 'table' ? <TableDateControl /> : <CalendarDateControl />}
        <ButtonSection>
          <PlanningViewSwitcher />
          <LwButton color="secondary" onClick={() => setExportModalOpen(true)}>
            Export
          </LwButton>
          <FilterButton onClick={toggleFilters} nrOfActiveFilters={selectedFiltersCount} />
        </ButtonSection>
      </Header>
      {filtersOpen ? <PlanningFilterDataProvider toggleFilters={toggleFilters} /> : null}
      {exportModalOpen ? (
        <ExportModal
          setExportModalOpen={() => setExportModalOpen(false)}
          exportModalOpen={exportModalOpen}
        />
      ) : null}
    </>
  );
};

const Header = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  marginTop: theme.spacing(8),
  gap: theme.spacing(8),
  width: '100%',
  justifyContent: 'space-between',
}));

const ButtonSection = styled(Box)(({ theme }) => ({
  gap: theme.spacing(8),
  display: 'flex',
  flexShrink: 0,
}));

const findActiveRoute = (location: Location) => {
  const pathArray = location.pathname.split('/');
  pathArray.find;
};
