import { flexWorkerService as flexWorkerServiceHQ } from 'hq/services';
import { flexWorkerService as flexWorkerServiceOGP } from 'ogp/services';
import { useInfiniteQuery } from 'react-query';
import { FlexWorkersForShiftFilters } from 'shared/components/flex-workers-for-work-table/flex-workers-for-work.types';
import { QUERY_KEYS } from 'api/query-keys';
import { useSiteDetection } from '../../../../hooks/use-site-detection';

const useGetFlexWorkersForShiftPlanning = (
  shiftId: string,
  pageSize: number,
  filters?: FlexWorkersForShiftFilters
) => {
  const { isHQ } = useSiteDetection();
  const queryKey = QUERY_KEYS.workersForShift(shiftId, filters);
  const queryFn = isHQ
    ? async ({ pageParam = 0 }) =>
        await flexWorkerServiceHQ.getAllFlexWorkers({ shiftId, page: pageParam, pageSize, filters })
    : async ({ pageParam = 0 }) =>
        await flexWorkerServiceOGP.getAllFlexWorkersForShift({
          shiftId,
          page: pageParam,
          pageSize,
          filters,
        });

  return useInfiniteQuery({
    queryKey: queryKey,
    queryFn: queryFn,
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};

export { useGetFlexWorkersForShiftPlanning };
