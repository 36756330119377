import { HqApiService } from 'hq/services/hqApiService';
import { OgpApiService } from 'ogp/services/ogp-api-service';
import { Site } from 'shared/hooks';
import { DepartmentDetailResponse, departmentSchema } from './department-service.schema';
import { GetDepartmentResponseDto } from './department-service.dto';

export class DepartmentService {
  constructor(
    private readonly ogpApiService: OgpApiService,
    private readonly hqApiService: HqApiService
  ) {}

  public async getDepartmentDetail(
    site: Site,
    departmentId: string
  ): Promise<DepartmentDetailResponse> {
    const response = await this.getDepartmentDetailImpl(site, departmentId);

    return departmentSchema.parse(response);
  }

  private async getDepartmentDetailImpl(
    site: Site,
    departmentId: string
  ): Promise<GetDepartmentResponseDto> {
    if (site === 'hq') {
      return await this.hqApiService.get<GetDepartmentResponseDto>({
        path: `departments/${departmentId}/detail`,
      });
    }

    if (site === 'ogp') {
      return await this.ogpApiService.get<GetDepartmentResponseDto>({
        path: `departments/${departmentId}`,
      });
    }

    throw new Error('Not implemented');
  }
}
