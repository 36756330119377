import { LevelTableCellProps } from 'redesign';
import { DateTime } from 'luxon';
import { PartnerPlanningShiftResponse } from 'partner/shared/services/partner-planner-service.types';

export const INITIAL_PAGE = 0;

export type PartnerShiftPlanningColumnDefinition = PartnerPlanningShift & {
  action: string;
};

export type PartnerShiftPlanningTableOptions = {
  openShiftDetail: (shiftId: string) => void;
};
export type PartnerShiftPlanningCellProp<T extends keyof PartnerShiftPlanningColumnDefinition> =
  LevelTableCellProps<
    PartnerShiftPlanningColumnDefinition,
    PartnerShiftPlanningColumnDefinition[T],
    PartnerShiftPlanningTableOptions
  >;

type PartnerPlanningShift = Omit<PartnerPlanningShiftResponse, 'dateTime'> & {
  dateTime: {
    fromDateTime: DateTime;
    toDateTime: DateTime;
  };
};
