import { Box, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FlexPoolOnlyIndicator } from 'shared/components/common/flexpool-only-indicator';
import { getHelperTexts } from 'shared/components/shift-forms/components/forms/shared/get-helper-texts/get-helper-texts';
import { getMinDate } from 'shared/utils/get-min-date';
import { CompanyEmploymentTypes } from '@types';
import { FormMode } from 'shared/components/shift-forms/components/forms/shared/types';
import { useEffect, useMemo, useRef } from 'react';
import { FormCheckbox, FormLocation } from 'redesign';
import { useGetWorkTemplate } from 'api';
import { useGetDepartmentDetail } from 'api/hooks/department/use-get-department-detail';
import { useSiteDetection } from 'shared/hooks';
import { JobFormElement } from 'shared/components/shift-forms/components/forms/shared/inputs/job-inputs';
import { DepartmentFormElement } from 'shared/components/shift-forms/components/forms/shared/inputs/department-input';
import { EndDateElement } from './elements/end-date-element';
import { HourlyRateElement } from './elements/hourly-rate-element';
import { StartDateElement } from './elements/start-date-element';
import { WeeklyHoursElement } from './elements/weekly-hours-element';
import { ProjectFormTypeAll } from '../types/project-form.types';

export type ProjectFormInputsProps = {
  jobsData: { id: string; name: string; flexPoolOnly: boolean }[];
  branchOfficesWithDepartments: {
    value: string;
    label: string;
    options: {
      value: string;
      label: string;
    }[];
  }[];
  mode: FormMode;
  isDisabled: boolean;
  isProjectWithActivePlacement: boolean;
  companyEmploymentType: CompanyEmploymentTypes | undefined;
};

export const ProjectFormInputs = ({
  isProjectWithActivePlacement,
  branchOfficesWithDepartments,
  jobsData,
  mode,
  companyEmploymentType,
}: ProjectFormInputsProps) => {
  const { site } = useSiteDetection();
  const { control, watch, setValue, getValues } = useFormContext<ProjectFormTypeAll>();
  const selectedWorkTemplateId = watch('jobId');
  const isFreelanceCheckboxChecked = watch('isFreelance');
  const selectedDepartmentId = watch('departmentId');
  const previousDepartmentId = useRef(getValues('departmentId'));

  const { data: workTemplateData, isLoading: isWorkTemplateLoading } = useGetWorkTemplate(
    site,
    selectedWorkTemplateId,
    !!selectedWorkTemplateId
  );

  const { data: department } = useGetDepartmentDetail(
    site,
    selectedDepartmentId,
    !!selectedDepartmentId
  );
  const skipDepartmentEffect = useRef(false);

  const { minDate } = getMinDate({ allowDateBeforeToday: true });
  const helperTexts = useMemo(() => {
    return getHelperTexts(
      workTemplateData
        ? {
            breakMinutes: workTemplateData.breakMinutes,
            hourlyRateCents: workTemplateData.freelanceJob?.hourlyRateCents,
          }
        : undefined
    );
  }, [workTemplateData]);

  const workTemplateOptions = useMemo(() => {
    if (!jobsData) {
      return [];
    }
    return jobsData.map((job) => ({
      value: job.id,
      label: (
        <FlexPoolOnlyIndicator flexPoolOnly={job.flexPoolOnly}>{job.name}</FlexPoolOnlyIndicator>
      ),
    }));
  }, [jobsData]);

  const areCheckboxesDisabled = useMemo(() => {
    if (mode === 'update') {
      return true;
    }

    const companyAllowsBoth =
      companyEmploymentType && Object.values(companyEmploymentType).every(Boolean);

    if (!workTemplateData) {
      return !companyAllowsBoth;
    }

    const jobEmploymentType = {
      isFreelance: !!workTemplateData.freelanceJob,
      isTempwork: !!workTemplateData.tempWorkJob,
    };

    const jobAllowsBoth = jobEmploymentType && Object.values(jobEmploymentType).every(Boolean);
    const isEnabled = selectedWorkTemplateId && companyAllowsBoth && jobAllowsBoth;

    return !isEnabled;
  }, [companyEmploymentType, workTemplateData, selectedWorkTemplateId]);

  useEffect(() => {
    if (!workTemplateData) {
      return;
    }
    skipDepartmentEffect.current = !!workTemplateData.departmentId;

    setValue('location', workTemplateData.location);
    setValue('departmentId', workTemplateData.departmentId ?? '');
    setValue('isFreelance', !!workTemplateData.freelanceJob);
    setValue('isTempwork', !!workTemplateData.tempWorkJob);
  }, [workTemplateData]);

  useEffect(() => {
    if (workTemplateData && companyEmploymentType) {
      const { offersFreelanceWork, offersTempWork } = companyEmploymentType;

      setValue('isFreelance', !!workTemplateData?.freelanceJob && offersFreelanceWork);
      setValue('isTempwork', !!workTemplateData?.tempWorkJob && offersTempWork);
    }
  }, [workTemplateData, companyEmploymentType]);

  useEffect(() => {
    if (skipDepartmentEffect.current) {
      skipDepartmentEffect.current = false;
      return;
    }
    const shouldSetDepartmentLocation =
      department &&
      department.department.location &&
      previousDepartmentId.current !== department.department.id;

    if (mode !== 'create' && shouldSetDepartmentLocation) {
      setValue('location', department.department.location);
      previousDepartmentId.current = department.department.id;
      return;
    }
    if (department?.department.location && shouldSetDepartmentLocation) {
      setValue('location', department.department.location);
      previousDepartmentId.current = department.department.id;
    }
  }, [department]);

  return (
    <Box display="flex" flexDirection="row" height="100%">
      <form aria-label="Projects form">
        <Grid container item spacing={4} xs={12}>
          {mode === 'create' ? (
            <Grid item xs={6}>
              <JobFormElement options={workTemplateOptions} />
            </Grid>
          ) : null}
          <Grid item xs={6}>
            <DepartmentFormElement options={branchOfficesWithDepartments} />
          </Grid>
          <Grid item xs={12}>
            <FormLocation required={true} />
          </Grid>
          <Grid item xs={6}>
            <StartDateElement
              key="startDate"
              control={control}
              disabled={isProjectWithActivePlacement}
              minDate={minDate}
            />
          </Grid>
          <Grid item xs={6}>
            <EndDateElement
              key="endDate"
              control={control}
              disabled={isProjectWithActivePlacement}
              minDate={minDate}
            />
          </Grid>
          <Grid item xs={6}>
            <WeeklyHoursElement
              key="weeklyHours"
              control={control}
              disabled={isWorkTemplateLoading}
              existingHours={undefined}
            />
          </Grid>

          <Grid item xs={12}>
            <FormCheckbox
              name="isTempwork"
              label="Uitzendkracht"
              control={control}
              disabled={areCheckboxesDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <FormCheckbox
              name="isFreelance"
              label="Zelfstandige"
              control={control}
              disabled={areCheckboxesDisabled}
            />
          </Grid>

          <Grid item xs={6}>
            <HourlyRateElement
              key="hourlyRate"
              control={control}
              disabled={
                !isFreelanceCheckboxChecked || isWorkTemplateLoading || isProjectWithActivePlacement
              }
              required={isFreelanceCheckboxChecked}
              existingRate={workTemplateData?.freelanceJob?.hourlyRateCents}
              helperTexts={helperTexts}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
