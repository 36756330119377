import { Box, styled, Typography } from '@mui/material';
import { LwButton, useWizardContext } from 'redesign';
import { SuccessfulBulkCancelSection } from './bulk-cancel-successful-result';
import { FailedBulkCancelActionsSection } from './bulk-cancel-failed-result';

export const BulkCancelResult = () => {
  const { close } = useWizardContext();

  return (
    <Container>
      <Box padding={2}>
        <Typography variant="h1" gutterBottom>
          Resultaten
        </Typography>
      </Box>
      <Box padding={2}>
        <SuccessfulBulkCancelSection />
      </Box>
      <Box padding={2}>
        <FailedBulkCancelActionsSection />
      </Box>
      <Actions>
        <LwButton color="secondary" onClick={close}>
          Sluiten
        </LwButton>
      </Actions>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4),
}));

const Actions = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[200]}`,
  paddingTop: theme.spacing(5),
  display: 'flex',
  justifyContent: 'space-between',
  position: 'absolute',
  bottom: 20,
  width: '100%',
  flexBasis: '100%',
  backgroundColor: theme.palette.background.paper,
}));
