import { Box, styled, Typography } from '@mui/material';
import { CandidateInfo } from 'shared/services/planning.types';
import { formatFullName } from 'shared/utils/formatting';

export const FormatApplicantNameComponent = ({
  applications,
  fontColor = 'black',
}: {
  applications: {
    freelancersList: CandidateInfo[];
    tempworkersList: CandidateInfo[];
  };
  fontColor?: string;
}) => {
  return (
    <NameContainer>
      {applications.freelancersList.length > 0 ? (
        <Box>
          <Typography variant="subtitle2" color={fontColor}>
            Freelancers:
          </Typography>
          {applications.freelancersList.map((candidate) => (
            <Typography
              key={`${candidate.lastName}-${candidate.firstName}`}
              variant="body2"
              color={fontColor}
            >
              {formatFullName(candidate)}
            </Typography>
          ))}
        </Box>
      ) : null}

      {applications.tempworkersList.length > 0 ? (
        <Box>
          <Typography variant="subtitle2" color={fontColor}>
            Uitzendkrachten:
          </Typography>
          {applications.tempworkersList.map((candidate) => (
            <Typography
              key={`${candidate.lastName}-${candidate.firstName}`}
              variant="body2"
              color={fontColor}
            >
              {formatFullName(candidate)}
            </Typography>
          ))}
        </Box>
      ) : null}
    </NameContainer>
  );
};

const NameContainer = styled(Box)(({ theme }) => {
  const columnGap = theme.spacing(3);
  return {
    display: 'flex',
    flexWrap: 'wrap',
    gap: `${theme.spacing(1)} ${columnGap}`,
    '& > div': {
      minWidth: `calc(50% - ${columnGap} / 2)`,
    },
  };
});
