import { Box, Grid, styled, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { CalendarDayColumn } from './calendar-day-column';
import { useVisibleDaysProvider } from '../../providers/visible-days-provider';

type CalendarJobTypeProps = {
  jobType?: {
    id: string;
    name: string;
    shifts: {
      applicants: number;
      claimants: number;
      jobName: string;
      flexPoolOnly: boolean;
      dateLabel: string;
      dateTime: string;
      endTime: string;
      id: string;
      maxClaimants: number;
      startTime: string;
      hasPartners: boolean;
      hasCanceledClaim: boolean;
    }[];
  };
};

export const CalendarJobType = ({ jobType }: CalendarJobTypeProps) => {
  const { visibleDays } = useVisibleDaysProvider();
  return (
    <JobWrapper>
      <JobName title={jobType ? jobType.name : ''}>{jobType ? jobType.name : null}</JobName>
      <JobShiftGrid container flexWrap={'nowrap'}>
        {visibleDays.map((date) => {
          const columnData = jobType
            ? jobType.shifts.filter(
                (shift) =>
                  DateTime.fromISO(shift.dateTime).toFormat('dd-LL') ===
                  DateTime.fromISO(date).toFormat('dd-LL')
              )
            : [];

          return <CalendarDayColumn key={date} data={columnData} dayCount={visibleDays.length} />;
        })}
      </JobShiftGrid>
    </JobWrapper>
  );
};

const JobWrapper = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[200]}`,
  display: 'flex',
}));

const JobShiftGrid = styled(Grid)({
  '& > .MuiGrid-item:nth-child(2n - 1)': {
    background: 'white',
  },
});

const JobName = styled(Typography)(({ theme }) => ({
  width: '14vw',
  padding: theme.spacing(2, 2, 2, 0),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flex: 'none',
  whiteSpace: 'nowrap',
}));
