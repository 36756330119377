import { LwFormMultiSelect } from 'redesign';
import { useFormContext } from 'react-hook-form';
import { JobCertificatesFormContext, Props } from './job.certificates.types';

export const JobCertificatesSelect = (props: Props) => {
  const { control } = useFormContext<JobCertificatesFormContext>();
  const formName = 'jobCertificates';
  const label = 'Aanvullende certificaten';

  return (
    <LwFormMultiSelect
      name={formName}
      control={control}
      options={props.options}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => {
        return option.name;
      }}
      label={label}
    />
  );
};
