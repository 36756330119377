import { IconAdd } from 'assets/img';
import { hqRoutes } from 'hq/Routes';
import { FilterButton } from 'ogp/components/views/administration/shared';
import { LwButton, Toolbox } from 'redesign';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { stringifyFilters } from 'shared/utils/stringify-filters';
import { ShiftPlanningFilters } from './table/hooks/use-shift-planning.types';

type ShiftPlanningToolboxProps = {
  appliedFilters: ShiftPlanningFilters;
  selectedShiftIds: string[];
  selectedFilterCount: number;
  setIsBulkForwardToPartnerOpen: (open: boolean) => void;
  toggleFilters: () => void;
  setIsBarSideBarOpen: (open: boolean) => void;
  setIsBulkArchiveBarOpen: (open: boolean) => void;
};

export const ShiftPlanningToolbox = ({
  appliedFilters,
  selectedShiftIds,
  selectedFilterCount,
  setIsBulkForwardToPartnerOpen,
  setIsBarSideBarOpen,
  setIsBulkArchiveBarOpen,
  toggleFilters,
}: ShiftPlanningToolboxProps) => {
  const generatePath = useHqRelativePath();
  return (
    <Toolbox underline={false}>
      <LwButton
        to={generatePath(
          hqRoutes.PlannerShiftCreateShift,
          undefined,
          stringifyFilters(appliedFilters)
        )}
        color="primary"
        startIcon={<IconAdd />}
        iconColorMode="fill"
      >
        Voeg shift toe
      </LwButton>

      <LwButton
        disabled={selectedShiftIds.length === 0}
        color="secondary"
        onClick={() => setIsBulkForwardToPartnerOpen(true)}
        iconColorMode="fill"
      >
        Naar partners{selectedShiftIds.length > 0 ? ` (${selectedShiftIds.length})` : ''}
      </LwButton>

      <LwButton
        disabled={selectedShiftIds.length === 0}
        color="secondary"
        onClick={() => setIsBarSideBarOpen(true)}
        iconColorMode="fill"
      >
        Aanpassen{selectedShiftIds.length > 0 ? ` (${selectedShiftIds.length})` : ''}
      </LwButton>

      <LwButton
        disabled={selectedShiftIds.length === 0}
        color="secondary"
        onClick={() => setIsBulkArchiveBarOpen(true)}
        iconColorMode="fill"
      >
        Verwijderen{selectedShiftIds.length > 0 ? ` (${selectedShiftIds.length})` : ''}
      </LwButton>

      <Toolbox.PushRight>
        <FilterButton onClick={toggleFilters} nrOfActiveFilters={selectedFilterCount} />
      </Toolbox.PushRight>
    </Toolbox>
  );
};
