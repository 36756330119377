import { useFormContext } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { DetailsForWorkTemplate } from 'shared/components/work-forms/details/work-form-details.types';
import { shiftCreateDataResponse2ShiftCreateDataModel } from 'ogp/services/mappers/mappers';
import { useGetCompanyEmploymentTypes, useGetWorkCreateData, useGetWorkTemplates } from 'api';
import { HQShiftFormData } from './types/hq-shift-form.types';
import { ShiftForm } from '../components/forms/shift-form/shift-form';
import { FormMode, ShiftCreateDataModel } from '../components/forms/shared/types';

type ShiftFormProps = {
  mode: FormMode;
  workDetails: DetailsForWorkTemplate | undefined;
};

export const HqShiftForm = ({ mode, workDetails }: ShiftFormProps) => {
  const { watch, reset } = useFormContext<HQShiftFormData>();
  const companyId = watch('companyId');
  const shiftCreateDataQuery = useGetWorkCreateData('hq', companyId, companyId != null);
  const employmentTypeOfCompanyQuery = useGetCompanyEmploymentTypes(
    'hq',
    companyId,
    companyId != null
  );
  const workTemplates = useGetWorkTemplates('hq', companyId, companyId != null);

  const shiftCreateData: ShiftCreateDataModel = useMemo(() => {
    if (!shiftCreateDataQuery.data) {
      return {
        branchOfficesWithDepartments: [],
        breakOptions: [],
      };
    }

    return shiftCreateDataResponse2ShiftCreateDataModel(shiftCreateDataQuery.data);
  }, [shiftCreateDataQuery.data]);

  useEffect(() => {
    if (mode === 'create') {
      reset({ companyId });
    }
  }, [companyId, mode]);

  return (
    <ShiftForm
      mode={mode}
      isDisabled={shiftCreateDataQuery.status !== 'success' || mode === 'read'}
      shiftCreateData={shiftCreateData}
      employmentTypeOfCompany={employmentTypeOfCompanyQuery.data}
      jobList={workTemplates.data ?? []}
      allowDateBeforeToday={true}
      workDetails={workDetails}
    />
  );
};
