import { Grid } from '@mui/material';
import { useCompanyRelativePath } from 'ogp/hooks';
import { useAlert } from 'shared/components/alerts';
import { FormProvider, useForm } from 'react-hook-form';
import { RenderUnsettledUI } from 'shared/components';
import { Header, Page } from 'redesign';
import { paths } from 'ogp/paths';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'Routes';
import { OgpProjectGetResponse, ProjectsUpdatePayload } from 'ogp/services/project-service.types';
import { invariant } from 'shared/utils/utils';
import { ProjectForm } from 'shared/components/project-forms/components/project-form';
import { ToolboxFormCreate } from 'shared/components/common/toolbox-form-create';
import {
  ProjectFormTypeAll,
  ProjectFormTypeDuplicate,
} from 'shared/components/project-forms/types/project-form.types';
import { useGetWorkDetails } from 'shared/components/work-forms/details/hooks/use-get-work-details';
import { DetailsForWorkTemplate } from 'shared/components/work-forms/details/work-form-details.types';
import { useUpdateProject } from '../../hooks/use-update-project';
import { useGetProjectDetail } from '../../../../../../../shared/components/planner/project-detail-modal/detail/applications/hooks/use-get-project-detail';

export const ProjectUpdateDataProvider = () => {
  const { id: projectId } = useParams<'id'>();
  invariant(projectId, 'Project ID should be defined');
  const projectQuery = useGetProjectDetail(projectId);
  const workDetailsQuery = useGetWorkDetails(projectId);

  if (projectQuery.status !== 'success') {
    return <RenderUnsettledUI data={projectQuery} />;
  }

  if (workDetailsQuery.status !== 'success') {
    return <RenderUnsettledUI data={workDetailsQuery} />;
  }

  return <ProjectsUpdate projectData={projectQuery.data} workDetails={workDetailsQuery.data} />;
};

type ProjectUpdateProps = {
  projectData: OgpProjectGetResponse;
  workDetails: DetailsForWorkTemplate;
};

const ProjectsUpdate = ({ projectData, workDetails }: ProjectUpdateProps) => {
  const { alertSuccess, alertError } = useAlert();
  const formMethods = useForm<ProjectFormTypeDuplicate>({
    defaultValues: {
      departmentId: projectData.department.id,
      startDate: projectData.startDate,
      endDate: projectData.endDate,
      hourlyRate: (projectData.freelanceProperties?.hourlyRateCents ?? 0) / 100,
      weeklyHours: projectData.weekHourVolume,
      isTempwork: !!projectData.tempWorkProperties,
      isFreelance: !!projectData.freelanceProperties,
      location: projectData.location ?? null,
    },
  });
  const navigate = useNavigate();
  const generatePath = useCompanyRelativePath();
  const mutation = useUpdateProject();
  const isProjectWithActivePlacement = projectData.placements.some(
    (placement) => placement.state === 'active'
  );

  const handleSubmit = () => {
    formMethods.handleSubmit((data: ProjectFormTypeAll) => {
      invariant(data.location, 'Location is required');

      const payload: ProjectsUpdatePayload = {
        departmentId: data.departmentId,
        id: projectData.id,
        startDate: data.startDate,
        endDate: data.endDate,
        weekHourVolume: +data.weeklyHours,
        freelanceProperties: data.isFreelance
          ? {
              hourlyRateCents: Math.round(+data.hourlyRate * 100),
            }
          : undefined,
        isTempWork: data.isTempwork,
        location: data.location,
      };
      mutation.mutate(payload, {
        onSuccess: () => {
          alertSuccess('Project geüpdatet');
          navigate(generatePath(routes.Projects));
        },
        onError: (e) => alertError(e),
      });
    })();
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Update project" />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={() => navigate(generatePath(paths.Projects), { replace: true })}
          />
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <ProjectForm
              isProjectWithActivePlacement={isProjectWithActivePlacement}
              mode="update"
              workDetails={workDetails}
            />
          </Grid>
        </Grid>
      </Page>
    </FormProvider>
  );
};
