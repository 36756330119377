import { partnerInboxService } from 'partner/shared/services';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'api/query-keys';

export const useGetPartnerMessageDetail = (messageId: string) => {
  return useQuery({
    queryKey: QUERY_KEYS.inboxMessage(messageId),
    queryFn: async () => await partnerInboxService.getPartnerInboxMessage(messageId),
  });
};
