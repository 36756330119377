import { RenderUnsettledUI, SmallError, SmallLoader } from 'shared/components';
import { useGetApplicants } from './hooks/use-get-applicants';
import { ProjectApplicantTable } from './applicant-table';
import { useGetApplicantActions } from './hooks/use-get-applicant-actions';

type Props = {
  id: string;
};

export const ProjectApplicantTableProvider = ({ id }: Props) => {
  const query = useGetApplicants(id);
  const { getApplicantActions } = useGetApplicantActions();

  if (query.status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={query}
      />
    );
  }

  return <ProjectApplicantTable query={query} getActions={getApplicantActions} />;
};
