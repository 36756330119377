import { userService } from 'hq/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { HqUser } from '@types';
import { QUERY_KEYS } from 'api/query-keys';

type UserMutationOptions = UseMutationOptions<unknown, unknown, HqUser>;

export const useCreateUser = () => {
  const mutationFn = async (user: HqUser) => await userService.createUser(user);

  const queryClient = useQueryClient();
  const mutationOptions: UserMutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.users());
    },
  };

  return useMutation(mutationFn, mutationOptions);
};
