import { ProjectForm } from 'shared/components/project-forms/components/project-form';
import { DetailsForWorkTemplate } from 'shared/components/work-forms/details/work-form-details.types';

type Props = {
  isProjectWithActivePlacement: boolean;
  workDetails: DetailsForWorkTemplate;
};

export const ProjectEditFormProvider = ({ isProjectWithActivePlacement, workDetails }: Props) => {
  return (
    <ProjectForm
      mode="update"
      isProjectWithActivePlacement={isProjectWithActivePlacement}
      workDetails={workDetails}
    />
  );
};
