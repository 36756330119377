import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CalendarPlanningHeader } from './components/calendar-planning-header';
import { CalendarJobType } from './components/calendar-job-type';
import { CalendarDepartment } from './components/calendar-department';
import { VisibleDaysProvider } from '../providers/visible-days-provider';
import { usePlanningParamsContext } from '../../../providers/planning-params-provider';

type CalendarShiftProps = {
  data: {
    id: string;
    name: string;
    jobTypes: {
      id: string;
      name: string;
      shifts: {
        applicants: number;
        claimants: number;
        jobName: string;
        flexPoolOnly: boolean;
        dateLabel: string;
        dateTime: string;
        endTime: string;
        id: string;
        maxClaimants: number;
        startTime: string;
        hasPartners: boolean;
        hasCanceledClaim: boolean;
      }[];
    }[];
  }[];
};

export const CalendarShift = ({ data }: CalendarShiftProps) => {
  const classes = getClasses();
  const { filters } = usePlanningParamsContext();

  if (data.length === 0) {
    return (
      <Box className={classes.noResults}>
        <Typography variant="h2" className={classes.message}>
          Je hebt deze week geen shifts ingepland {filters.departmentId && 'bij deze vestiging'}
        </Typography>
        <Typography>Begin nu met het toevoegen van shifts zodat er gewerkt kan worden!</Typography>
      </Box>
    );
  }

  return (
    <VisibleDaysProvider>
      <CalendarPlanningHeader />
      <Box>
        <Box>
          <Typography className={classes.name}>Afdelingen</Typography>
          <CalendarJobType jobType={undefined} />
        </Box>
        {data.map((dpt) => (
          <CalendarDepartment key={dpt.id} department={dpt} />
        ))}
      </Box>
    </VisibleDaysProvider>
  );
};

const getClasses = makeStyles((theme: Theme) => ({
  name: {
    padding: theme.spacing(0, 2, 2, 0),
    position: 'relative',
    transform: 'translateY(-100%)',
  },
  noResults: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: '1 1 100%',
    '& *': {
      textAlign: 'center',
      maxWidth: theme.spacing(120),
    },
  },
  message: {
    fontWeight: 500,
    marginTop: theme.spacing(24),
    marginBottom: theme.spacing(8),
  },
}));
