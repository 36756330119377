import { useQuery } from 'react-query';
import { companyService } from 'hq/services';
import { CACHE_TIME_LONG } from 'shared/react-query/query-defaults';
import { QUERY_KEYS } from 'api/query-keys';
import { companiesResponse2CompaniesSelectModel } from 'hq/services/mappers/mappers';

type Response = Awaited<ReturnType<typeof companyService.getCompanies>>;

const useGetCompanies = <TData = Response>(
  filter?: string,
  includeArchived?: boolean,
  select?: (data: Response) => TData
) => {
  const queryFn = async () =>
    await companyService.getCompanies(includeArchived, filter ?? undefined);

  return useQuery(QUERY_KEYS.companiesList(filter), queryFn, {
    select,
    cacheTime: CACHE_TIME_LONG,
  });
};

const useGetCompaniesAsSelect = (filter?: string, includeArchived?: boolean) => {
  const result = useGetCompanies(filter, includeArchived, companiesResponse2CompaniesSelectModel);
  return result;
};

export { useGetCompanies, useGetCompaniesAsSelect };
