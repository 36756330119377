import { QUERY_KEYS } from 'api/query-keys';
import { sharedDepartmentService } from 'api/services';
import { useQuery } from 'react-query';
import { useAlert } from 'shared/components/alerts';
import { Site } from 'shared/hooks';

export const useGetDepartmentDetail = (site: Site, departmentId: string, enabled = true) => {
  const { alertError } = useAlert();

  return useQuery(
    QUERY_KEYS.departmentDetail(departmentId),
    async () => await sharedDepartmentService.getDepartmentDetail(site, departmentId),
    {
      enabled,
      onError: (e) => alertError(e),
    }
  );
};
