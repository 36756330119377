import { placementService } from 'hq/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import {
  BulkCancelPlacementPayload,
  BulkCancelPlacementsResponse,
} from 'hq/services/placement-service.types';
import { QUERY_KEYS } from 'api/query-keys';

type MutationOptions = UseMutationOptions<
  BulkCancelPlacementsResponse,
  unknown,
  BulkCancelPlacementPayload
>;

export const useBulkCancelPlacements = () => {
  const mutationFn = async (data: BulkCancelPlacementPayload) =>
    await placementService.bulkCancelPlacements(data);
  const queryClient = useQueryClient();
  const options: MutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.placements());
    },
  };

  return useMutation(mutationFn, options);
};
