import { useFormContext } from 'react-hook-form';
import { useWizardContext } from 'redesign';
import { BulkFormButtons } from 'shared/components/planner/common/bulk-form-buttons';
import { BulkCancelPlacementForm } from './bulk-cancel-placements.types';

export const BulkCancelFormButtons = ({ isSubmitting }: { isSubmitting: boolean }) => {
  const { reset, formState, watch } = useFormContext<BulkCancelPlacementForm>();
  const { close } = useWizardContext();

  return (
    <BulkFormButtons
      isSubmitting={isSubmitting}
      submitLabel="Indienen"
      onCancel={close}
      onReset={() => reset(formState.defaultValues)}
      isSubmitDisabled={!watch('cancellationType')}
    />
  );
};
