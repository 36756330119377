import { placementService as ogpPlacementService } from 'ogp/services';
import { placementService as hqPlacementService } from 'hq/services';
import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS } from 'api/query-keys';
import { SiteDetectionResult } from '../../../hooks';

type WorkApplicationMutationParams = {
  placementId: string;
  siteDetectionResult: SiteDetectionResult;
};

export const usePlacementCancel = () => {
  const mutationFn = (params: WorkApplicationMutationParams) => getMutationFunction(params);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.projects());
    },
  });
};

const getMutationFunction = (params: WorkApplicationMutationParams) => {
  const { siteDetectionResult, placementId } = params;
  if (siteDetectionResult.isHQ) {
    return hqPlacementService.cancelPlacement(placementId);
  }
  if (siteDetectionResult.isOGP) {
    return ogpPlacementService.cancelPlacement(placementId);
  }

  throw new Error(`usePlacementCancel: site "${siteDetectionResult.site}" is neither HQ nor OGP.`);
};
