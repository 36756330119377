import { useMemo } from 'react';
import { Table } from 'redesign';
import { useForm } from 'react-hook-form';
import { IconIsolationMode } from 'assets/img';
import { useDialog } from 'ogp/providers/dialog-provider';
import { useAlert } from 'shared/components/alerts';
import { BoxAction, BoxIcon } from 'shared/components/planner/common/utils';
import { getMinimumRateColumnDefinition } from './get-minimum-rate-table-column-definition';
import { MinimumRateColumnDefinition, MinimumRateTableOptions } from './minimum-rate-table.types';
import { getMinimumRateTableData } from './get-minimum-rate-table-data';
import { MinimumJobTypeRateEditor } from '../minimum-job-type-rate-editor';
import { useEditMinimumJobTypeRate } from '../../hooks/use-edit-minimum-job-type-rate';

type MinimumRateTableProps = {
  items: {
    id: string;
    name: string;
    minimumRateCents: number;
  }[];
};

export const MinimumRateTable = ({ items }: MinimumRateTableProps) => {
  const columns = useMemo(() => getMinimumRateColumnDefinition(), []);
  const tableData = useMemo(() => getMinimumRateTableData(items), [items]);

  return (
    <Table<MinimumRateColumnDefinition, MinimumRateTableOptions>
      stickyHeader={true}
      columns={columns}
      data={tableData}
      getEditAction={EditAction}
      tableStyle={{ tableLayout: 'fixed' }}
      data-testid="shift-planning-virtual-table"
    />
  );
};

const EditAction = (id: string, minimumHourlyRate: number) => {
  const { openDialog, closeDialog, disableDialog, enableDialog } = useDialog();
  const { control, reset, getValues, trigger } = useForm<{
    minimumHourlyRate: number;
  }>({
    defaultValues: {
      minimumHourlyRate,
    },
  });
  const mutation = useEditMinimumJobTypeRate();
  const { alertSuccess, alertError } = useAlert();

  const handleEdit = (jobTypeId: string) => {
    openDialog(
      {
        title: "Stel een minimum tarief zzp'er in",
        content: <MinimumJobTypeRateEditor control={control} />,
      },
      async () => {
        const minimumRateCents = getValues('minimumHourlyRate') * 100;

        if (!(await trigger('minimumHourlyRate'))) {
          return;
        }
        mutation.mutate(
          { jobTypeId, minimumRateCents },
          {
            onSuccess: () => {
              alertSuccess("Het minimum tarief zzp'er is bijgewerkt.");
              closeDialog();
            },
            onSettled: () => {
              enableDialog();
            },
            onError: (e) => {
              alertError(e);
            },
          }
        );
        disableDialog();
      }
    );
  };

  return (
    <BoxAction data-testid={id}>
      <BoxIcon>
        <IconIsolationMode onClick={() => handleEdit(id)} />
      </BoxIcon>
    </BoxAction>
  );
};
