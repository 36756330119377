import { z } from 'zod';

const departmentSchema = z.object({
  id: z.string(),
  name: z.string(),
});

const branchOfficeSchema = z.object({
  id: z.string(),
  name: z.string(),
  companyId: z.string(),
  departments: z.array(departmentSchema),
});

const breakOptionsSchema = z.object({
  id: z.string(),
  label: z.string(),
  minutes: z.number(),
});

export const getShiftCreateDataSchema = z.object({
  shiftCreateData: z.object({
    branchOfficeNames: z.array(branchOfficeSchema),
    breakOptions: z.array(breakOptionsSchema),
  }),
});

export const bulkShiftActionDataResponseDtoSchema = z.object({
  failure: z.array(
    z.object({
      id: z.string(),
      error: z.string(),
      shiftName: z.string(),
      dateStartEndTime: z.string(),
    })
  ),
  fulfilled: z.array(z.string()),
});

export const getBreakOptionsResponseDtoSchema = z.object({
  breakOptions: z.array(breakOptionsSchema),
});

export type BulkShiftActionData = z.infer<typeof bulkShiftActionDataResponseDtoSchema>;

export type GetShiftCreateData = z.infer<typeof getShiftCreateDataSchema>;
