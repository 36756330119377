import React from 'react';
import { Auth } from 'aws-amplify';
import { Box, Drawer, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { SiteInfo } from 'shared/components';
import { useToggle } from 'shared/hooks';
import { UserPopoverHq } from 'shared/components/user-popover/user-popover';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { SidebarSection } from './sidebar-section';
import { useSidebarProvider } from './sidebar-provider';
import { SidebarToggle } from './sidebar-toggle';
import { SiteInfoContainer } from './site-info';
import { useGetHqNotifications } from './hooks/use-get-hq-notifications';
import { useProfileInfo, useSidebarItems } from './sidebar-items';
import { LOGO_PATH, SMALL_LOGO_PATH } from '../../../config';
import { AuthenticatedElement } from '../common/authenticated-element';
import { useHqRelativePath } from '../../hooks/useHqRelativePath';
import { invariant } from '../../../shared/utils/utils';
import { useGetUserInfo } from '../../hooks/queryHooks/users/useGetUserInfo';

const Sidebar = () => {
  const { isOpen } = useSidebarProvider();
  const [userLabel, setUserLabel] = React.useState('');
  const { data } = useGetHqNotifications();
  const userQuery = useGetUserInfo();
  const generatePath = useHqRelativePath();
  const { on: userInfoOpen, toggle: toggleUserInfo } = useToggle();
  const queryClient = useQueryClient();
  const items = useSidebarItems();
  const profileInfo = useProfileInfo();

  const { t } = useTranslation('hq-screen-specific');

  const logout = async () => {
    await Auth.signOut();
    queryClient.removeQueries();
  };

  React.useEffect(() => {
    let isMounted = true;
    Auth.currentUserInfo().then((res) => {
      isMounted && res && setUserLabel(res.username);
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const getSectionPermissions = (section: (typeof items)[number]['key']) => {
    const group = items.find((i) => i.key === section);
    invariant(group);

    return {
      roles: group.children.map((child) => (child as any).roles).flat(),
      policyGroup: group.children[0].policyGroup,
    };
  };

  return (
    <>
      <Styled.Sidebar variant="permanent" folded={!isOpen}>
        <Styled.Logo folded={!isOpen} to={generatePath(items[0].children[0])}>
          <img src={isOpen ? LOGO_PATH : SMALL_LOGO_PATH} alt="Level.works" />
        </Styled.Logo>

        <Box px={2} textOverflow="ellipsis" textAlign="center">
          <Typography variant="body1">{t('sidebar.title')}</Typography>
        </Box>

        {items.map((sidebarItem) => {
          const permissions = getSectionPermissions(sidebarItem.key);

          return (
            // @ts-expect-error This fails on typing because it's shared between OGP, HQ and Partner. No clue how to fix this now
            <AuthenticatedElement {...permissions} key={sidebarItem.key}>
              <SidebarSection>
                <SidebarSection.Title>{sidebarItem.name}</SidebarSection.Title>
                <SidebarSection.List>
                  {sidebarItem.children.map((child) => {
                    const notificationCount = data?.[child.key] ?? 0;
                    return (
                      <AuthenticatedElement
                        key={child.key}
                        roles={child.roles}
                        policyGroup={child.policyGroup}
                      >
                        <SidebarSection.Link {...child} notificationCount={notificationCount} />
                      </AuthenticatedElement>
                    );
                  })}
                </SidebarSection.List>
              </SidebarSection>
            </AuthenticatedElement>
          );
        })}

        <SidebarSection style={{ marginTop: 'auto' }}>
          <SidebarSection.Title>{profileInfo.name}</SidebarSection.Title>
          <SidebarSection.List>
            <SidebarSection.Button {...profileInfo.children[0]} onClick={toggleUserInfo} />
            <SidebarSection.Button {...profileInfo.children[1]} onClick={logout} />
          </SidebarSection.List>
        </SidebarSection>
        <SiteInfoContainer>
          <SiteInfo />
        </SiteInfoContainer>
      </Styled.Sidebar>
      <SidebarToggle />
      <Drawer anchor="right" open={userInfoOpen} onClose={toggleUserInfo}>
        <UserPopoverHq
          userLabel={userLabel}
          userRoles={
            userQuery.data?.roles.hqRoles
              ? {
                  companyRoles: userQuery.data?.roles.hqRoles,
                  branchOfficesWithDepartmentRoles: [],
                }
              : undefined
          }
        ></UserPopoverHq>
      </Drawer>
    </>
  );
};

const Styled = {
  Sidebar: styled(Drawer, { shouldForwardProp: (prop) => prop !== 'folded' })<{
    folded: boolean;
  }>(({ theme, folded }) => ({
    '&.MuiDrawer-root': {
      width: folded ? '120px' : '248px',
    },
    '&.MuiDrawer-root > *': {
      fontFamily: 'Urbanist',
      fontWeight: 500,
      fontVariationSettings: '"wght" 500',
      gap: theme.spacing(5),
      paddingLeft: theme.spacing(folded ? 3 : 6),
      paddingRight: theme.spacing(folded ? 1 : 4),
      width: folded ? '120px' : '248px',
      '::-webkit-scrollbar': {
        width: '22px',
      },
      '::-webkit-scrollbar-track': {
        background: 'rgba(0, 0, 0, 0.1)',
        border: '8px solid white',
        borderRadius: '12px',
      },

      '::-webkit-scrollbar-thumb': {
        background: 'rgba(0, 0, 0, 0.2)',
        backgroundClip: 'content-box',
        border: '8px solid transparent',
        borderRadius: '12px',
        '&:hover': {
          background: 'rgba(0, 0, 0, 0.4)',
          backgroundClip: 'content-box',
          border: '8px solid transparent',
          borderRadius: '12px',
        },
      },
    },

    '& .MuiPaper-root': {
      borderRight: 0,
      overflowX: 'hidden',
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: folded
          ? theme.transitions.duration.enteringScreen
          : theme.transitions.duration.leavingScreen,
      }),
    },

    ...(folded && {
      '&.MuiDrawer-root section p:first-of-type': {
        paddingLeft: 0,
      },

      '& .MuiBox-root': {
        justifyContent: 'center',
      },
    }),
  })),
  Logo: styled(Link, { shouldForwardProp: (prop) => prop !== 'folded' })<{ folded: boolean }>(
    ({ theme, folded }) => ({
      height: '24px',
      marginBottom: theme.spacing(7),
      marginTop: theme.spacing(8),
      textAlign: 'center',
      width: '100%',

      '& img': {
        maxHeight: '40px',
        maxWidth: folded ? '60%' : '100%',
      },
    })
  ),
};

export { Sidebar };
