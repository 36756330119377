import { QUERY_KEYS } from 'api/query-keys';
import { branchOfficeService } from 'ogp/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { BranchOfficePayload } from '@types';
import { CreateBranchOfficeResponse } from '../../../services/branch-office-service.types';

type BranchOfficeMutationOptions = UseMutationOptions<
  CreateBranchOfficeResponse,
  unknown,
  BranchOfficePayload
>;

export const useCreateBranchOffice = (options?: BranchOfficeMutationOptions) => {
  const mutationFn = async (branchOffice: BranchOfficePayload) =>
    await branchOfficeService.createBranchOffice(branchOffice);

  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options ?? {};
  const mutationOptions: BranchOfficeMutationOptions = {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(QUERY_KEYS.branchOffices());
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  };

  return useMutation(mutationFn, mutationOptions);
};
