import { shiftService as hqShiftService } from 'hq/services';
import { shiftService as ogpShiftService } from 'ogp/services';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'api/query-keys';
import { STALE_TIME_LONG } from 'shared/react-query/query-defaults';
import { useSiteDetection } from '../../../../hooks';

const useGetShiftPartners = (shiftId: string, enabled: boolean) => {
  const { isHQ } = useSiteDetection();

  const queryFn = isHQ
    ? async () => await hqShiftService.getShiftPartners(shiftId)
    : async () => await ogpShiftService.getShiftPartners(shiftId);

  return useQuery(QUERY_KEYS.shiftPartner(shiftId), queryFn, {
    enabled,
    staleTime: STALE_TIME_LONG,
  });
};

export { useGetShiftPartners };
