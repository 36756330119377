import { jobService as jobServiceHQ } from 'hq/services';
import { jobService as jobServiceOGP } from 'ogp/services';
import { useQuery } from 'react-query';
import { useSiteDetection } from 'shared/hooks';
import { GetJobDetailsForWorkTemplateOGP } from 'ogp/services/job-service.types';
import { GetJobDetailsForWorkTemplateHQ } from 'hq/services/job-service.types';
import { QUERY_KEYS } from 'api/query-keys';

export const useGetJobDetails = (jobId: string) => {
  const { isHQ } = useSiteDetection();

  const queryFn = getQueryFn(isHQ);

  return useQuery<
    Promise<GetJobDetailsForWorkTemplateHQ | GetJobDetailsForWorkTemplateOGP>,
    Error,
    GetJobDetailsForWorkTemplateHQ | GetJobDetailsForWorkTemplateOGP
  >(QUERY_KEYS.jobDetail(jobId), () => queryFn(jobId), {
    enabled: !!jobId,
  });
};

const getQueryFn = (
  isHQ: boolean
): ((id: string) => Promise<GetJobDetailsForWorkTemplateHQ | GetJobDetailsForWorkTemplateOGP>) => {
  return isHQ
    ? async (id: string) => await jobServiceHQ.getJobDetailsForWorkTemplate(id)
    : async (id: string) => await jobServiceOGP.getJobDetailsForWorkTemplate(id);
};
