import { QUERY_KEYS } from 'api/query-keys';
import { partnerCheckoutsService } from 'partner/shared/services';
import { useMutation, useQueryClient } from 'react-query';

type ApproveCounterOfferParams = {
  shiftClaimId: string;
};

export const useApproveCounterOfferQuery = () => {
  const mutationFn = async ({ shiftClaimId }: ApproveCounterOfferParams) =>
    await partnerCheckoutsService.approveCounterOffer(shiftClaimId);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.counterOffers());
    },
  });
};
