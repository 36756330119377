export type JobRequirement = {
  id: string;
  name: string;
  type: SkillType;
};

export enum SkillType {
  SOFT_SKILL = 'SOFT_SKILL',
  APPAREL = 'APPAREL',
  LANGUAGE = 'LANGUAGE',
}
