import { QUERY_KEYS } from 'api/query-keys';
import { shiftService as hqShiftService } from 'hq/services';
import { shiftService as ogpShiftService } from 'ogp/services';
import { useQuery } from 'react-query';
import { useSiteDetection } from 'shared/hooks';

const useGetShiftEligiblePartners = (shiftId: string) => {
  const { isHQ } = useSiteDetection();
  const queryFn = isHQ
    ? async () => await hqShiftService.getShiftEligiblePartners(shiftId)
    : async () => await ogpShiftService.getShiftEligiblePartners(shiftId);

  return useQuery(QUERY_KEYS.shiftEligiblePartners(shiftId), queryFn);
};

export { useGetShiftEligiblePartners };
