import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { styled } from '@mui/material';
import { InputSelectOption, LocationAutoSuggestItem, LocationData } from '@types';
import { useAlert } from 'shared/components/alerts';
import { omitNullValues } from 'shared/utils/utils';
import { useWizardContext } from 'redesign';
import { useSiteDetection } from 'shared/hooks';
import { useBulkActionResultContext } from 'shared/providers/bulk-action-provider';
import { useBulkUpdateShift } from 'shared/components/bulk/bulk-shift-action/bulk-shift-edit-form/hooks/use-bulk-update-shift';
import { BulkShiftEditFormInputs } from './bulk-shift-edit-form-inputs';
import { BulkShiftActionFormButtons } from '../shared/bulk-shift-action-form-buttons';

export type BulkShiftEditForm = {
  startTime: string | null;
  endTime: string | null;
  breakMinutes: number | null;
  maxClaimants: number | null;
  location: LocationAutoSuggestItem | LocationData | null;
};

export type BulkShiftFormProps = {
  breakOptions: InputSelectOption[];
};

export const BulkShiftForm = ({ breakOptions }: BulkShiftFormProps) => {
  const formMethods = useForm<BulkShiftEditForm>({
    defaultValues: {
      startTime: null,
      endTime: null,
      breakMinutes: null,
      maxClaimants: null,
      location: null,
    },
  });
  const { alertError } = useAlert();
  const { nextStep } = useWizardContext();
  const { site } = useSiteDetection();
  const { selectedIds, setMutationResult } = useBulkActionResultContext();
  const { mutate, status } = useBulkUpdateShift(site);

  const onSubmit = useCallback(
    (data: BulkShiftEditForm) => {
      if (!atLeastOneFieldEdited(data)) {
        formMethods.setError('root', { message: 'Wijzig minstens één veld' });
        return;
      }

      formMethods.clearErrors('root');
      const sanitizedData = omitNullValues(data);
      mutate(
        {
          ...sanitizedData,
          shiftIds: selectedIds,
          maxClaimants: sanitizedData.maxClaimants ? Number(sanitizedData.maxClaimants) : undefined,
        },
        {
          onSuccess: (data) => {
            setMutationResult(data);
            nextStep();
          },
          onError: (error) => {
            alertError(error);
          },
        }
      );
    },
    [formMethods.setError, formMethods.clearErrors, mutate, selectedIds]
  );

  return (
    <FormProvider {...formMethods}>
      <StyledForm onSubmit={formMethods.handleSubmit(onSubmit)}>
        <BulkShiftEditFormInputs breakOptions={breakOptions} />
        <BulkShiftActionFormButtons
          isSubmitting={status === 'loading' || formMethods.formState.isSubmitting}
        />
      </StyledForm>
    </FormProvider>
  );
};

const StyledForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  width: '600px',
}));

const atLeastOneFieldEdited = (data: BulkShiftEditForm) => {
  return Object.values(data).some((value) => value !== undefined && value !== null && value !== '');
};
