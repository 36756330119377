import { z } from 'zod';

export const availabilityValues = ['unavailable', 'available', 'conditional'] as const;
export type AvailabilityValues = (typeof availabilityValues)[number];

const partnerWorkerSchema = z.object({
  id: z.string(),
  shiftClaimId: z.string().optional(),
  name: z.string(),
  hasRequiredCertificates: z.boolean(),
  email: z.string(),
  phone: z.string(),
  availability: z.enum(availabilityValues),
});

export const partnerWorkerInfiniteQueryResponseSchema = z.object({
  totalRows: z.number(),
  nextPage: z.number().optional(),
  items: z.array(partnerWorkerSchema),
});

export type PartnerWorkerInfiniteQueryResponse = z.infer<
  typeof partnerWorkerInfiniteQueryResponseSchema
>;

export type PartnerWorker = z.infer<typeof partnerWorkerSchema>;
