import { QUERY_KEYS } from 'api/query-keys';
import { partnerInboxService } from 'partner/shared/services';
import { useMutation, useQueryClient } from 'react-query';

type UnresolveMessageTaskParams = {
  messageId: string;
};

export const useUnresolveMessageTask = () => {
  const mutationFn = async ({ messageId }: UnresolveMessageTaskParams) =>
    await partnerInboxService.unresolveInboxMessageTask(messageId);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.inbox());
    },
  });
};
