import { useMutation } from 'react-query';
import { projectPlanningService } from 'hq/services';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { HQProjectPayloadCreate } from 'hq/services/project-planning-service.types';
import { QUERY_KEYS } from 'api/query-keys';

export const useCreateProject = () => {
  const mutationFn = async (project: HQProjectPayloadCreate) =>
    await projectPlanningService.createProject(project);
  const invalidateQuery = useInvalidateQuery();
  return useMutation(mutationFn, {
    onSuccess: () => {
      invalidateQuery(QUERY_KEYS.projects());
    },
  });
};
