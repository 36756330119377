import { DialogActions, styled } from '@mui/material';
import { IconApproved, IconCancel } from 'assets/img';
import { useFormContext } from 'react-hook-form';
import { LwButton } from 'redesign';
import { SelectableFor } from './dialog-form';
import { BulkForwardToShiftsFormInput } from '../bulk-forward-to-partner';

type Props = {
  closeDialog: () => void;
  selectableFor: SelectableFor;
};

export const BulkForwardToPartnerDialogActions = ({ closeDialog, selectableFor }: Props) => {
  const { watch } = useFormContext<BulkForwardToShiftsFormInput>();

  return (
    <CenteredDialogActions>
      <LwButton
        iconColorMode="stroke"
        startIcon={<IconCancel />}
        color="secondary"
        onClick={closeDialog}
      >
        Annuleer
      </LwButton>
      <LwButton
        iconColorMode="stroke"
        startIcon={<IconApproved />}
        color="primary"
        disabled={selectableFor === SelectableFor.None || !watch('partners')?.length}
        type="submit"
      >
        Toevoegen
      </LwButton>
    </CenteredDialogActions>
  );
};

const CenteredDialogActions = styled(DialogActions)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[200]}`,
  padding: theme.spacing(5),
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  position: 'absolute',
  bottom: 20,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
}));
