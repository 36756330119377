export enum ShiftClaimCancellationReason {
  SICKNESS = 'SICKNESS',
  PRIVATE_REASONS = 'PRIVATE_REASONS',
  CHANGE_IN_AVAILABILITY = 'CHANGE_IN_AVAILABILITY',
  OTHER = 'OTHER',
}

export const ShiftClaimCancellationReasonLabels: Record<
  keyof typeof ShiftClaimCancellationReason,
  string
> = {
  SICKNESS: 'Ziekte',
  PRIVATE_REASONS: 'Privé omstandigheden',
  CHANGE_IN_AVAILABILITY: 'Gewijzigde beschikbaarheid',
  OTHER: 'Overig',
};
