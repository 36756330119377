import { Box, Grid, Typography } from '@mui/material';
import { SkillType } from '@types';
import { DetailsForWorkTemplate } from './work-form-details.types';

export const WorkTemplateJobOrWorkDetails = ({
  workDetails,
}: {
  workDetails: DetailsForWorkTemplate | undefined;
}) => {
  if (!workDetails) {
    return null;
  }

  return (
    <Box>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color="secondary">
            Contactpersoon
          </Typography>
          <Typography variant="body1">{workDetails.contactName}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color="secondary">
            Telefoon contactpersoon
          </Typography>
          <Typography variant="body1">{workDetails.contactPhoneNumber}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2" color="secondary">
            Locatie
          </Typography>
          <Typography variant="body1">{workDetails.location?.address}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2" color="secondary">
            Korte omschrijving
          </Typography>
          <Typography variant="body1">{workDetails.descriptionShort}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2" color="secondary">
            Lange omschrijving
          </Typography>
          <Typography variant="body1">{workDetails.descriptionLong}</Typography>
        </Grid>

        {workDetails.companyGrossHourlyRate ? (
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="secondary">
              Bruto uurloon
            </Typography>
            <Typography variant="body1">
              {workDetails.companyGrossHourlyRate / 100 + ' eur'}
            </Typography>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <Typography variant="subtitle2" color="secondary">
            Afspraken en vereisten
          </Typography>
          <Typography variant="body1">{workDetails.descriptionLawRequirements}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" color="secondary">
              Functiegroep certificaten
            </Typography>
          </Box>

          <Box>
            {workDetails.jobTypeCertificates.length > 0 ? (
              workDetails.jobTypeCertificates.map(
                (cert: { certificate: { id: string; name: string } }) => {
                  return <Box key={cert.certificate.id}>{cert.certificate.name}</Box>;
                }
              )
            ) : (
              <Typography variant="body1" color="secondary">
                Geen certificaten
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" color="secondary">
              Aanvullende certificaten
            </Typography>
          </Box>

          <Box>
            {workDetails.certificates.length > 0 ? (
              workDetails.certificates.map(
                (cert: { certificate: { id: string; name: string } }) => {
                  return <Box key={cert.certificate.id}>{cert.certificate.name}</Box>;
                }
              )
            ) : (
              <Typography variant="body1" color="secondary">
                Geen certificaten
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" color="secondary">
              Werkvereisten
            </Typography>
          </Box>

          <Box>
            {workDetails.requirements.length > 0 ? (
              workDetails.requirements.map((req: { id: string; name: string; type: SkillType }) => {
                return <Box key={req.id}>{req.name}</Box>;
              })
            ) : (
              <Typography variant="body1" color="secondary">
                Geen werkvereisten
              </Typography>
            )}
          </Box>
        </Grid>

        {workDetails.flexPools && (
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle2" color="secondary">
                Flexpools
              </Typography>
            </Box>

            <Box>
              {workDetails.flexPools.length > 0 ? (
                workDetails.flexPools.map((flexPool) => {
                  return <Box key={flexPool.id}>{flexPool.name}</Box>;
                })
              ) : (
                <Typography variant="body1" color="secondary">
                  Geen flexpools
                </Typography>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
