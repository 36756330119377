import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'api/query-keys';
import { sharedCompanyService } from 'api/services';
import { useSiteDetection } from 'shared/hooks';
import { PartnersFilterData } from 'shared/components/filters/inputs/partners/filter-partners.types';

export const useGetOgpPartners = () => {
  const { site } = useSiteDetection();
  return useQuery<PartnersFilterData[], Error>({
    queryKey: QUERY_KEYS.partnersFilter(),
    queryFn: async () => await sharedCompanyService.getCompanyPartners(site),
  });
};
