import { QUERY_KEYS } from 'api/query-keys';
import { accreditationsService } from 'hq/services';
import { AccreditationType } from 'hq/services/accreditations-service.types';
import { DateTime } from 'luxon';
import { useMutation, useQueryClient } from 'react-query';

type ApproveAccreditationParams = {
  flexWorkerId: string;
  certificateId: string;
  accreditationType: AccreditationType;
  expirationDate?: DateTime;
};

export const useApproveAccreditation = () => {
  const mutationFn = async ({
    flexWorkerId,
    certificateId,
    expirationDate,
    accreditationType,
  }: ApproveAccreditationParams) =>
    await accreditationsService.acceptAccreditation(
      flexWorkerId,
      certificateId,
      accreditationType,
      expirationDate
    );
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.accreditations());
    },
  });
};
