import { useFormContext } from 'react-hook-form';
import { LwFormNumberInput } from 'redesign';
import { getRequiredMessage } from './shared';
import { HourlyRateFormContext, Props } from './hourly-rate.types';

export const HourlyRateInput = (props: Props) => {
  const { control } = useFormContext<HourlyRateFormContext>();
  const formName = 'hourlyRate';
  const label = "Tarief zzp'er";
  const placeholder = '10.50';
  const requiredMessage = getRequiredMessage(label);
  return (
    <LwFormNumberInput
      name={formName}
      control={control}
      label={label}
      rules={props.required ? { required: requiredMessage } : undefined}
      step={0.01}
      min={0}
      placeholder={placeholder}
      disabled={props.disabled}
    />
  );
};
