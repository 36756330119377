import type { PaletteOptions } from '@mui/material';
import { BLACK, DARK_BLUE, GREEN, LIGHT_BLUE, ORANGE, RED, WHITE } from '../base/colors';

const palette: PaletteOptions = {
  lwPrimary: {
    100: LIGHT_BLUE['100'],
    40: LIGHT_BLUE['40'],
    20: LIGHT_BLUE['20'],
    10: LIGHT_BLUE['10'],
    5: LIGHT_BLUE['5'],
  },
  lwSecondary: {
    100: DARK_BLUE['100'],
    80: DARK_BLUE['80'],
    60: DARK_BLUE['60'],
    40: DARK_BLUE['40'],
    30: DARK_BLUE['30'],
    20: DARK_BLUE['20'],
    10: DARK_BLUE['10'],
    5: DARK_BLUE['5'],
  },
  lwWarning: {
    100: ORANGE['100'],
    30: ORANGE['30'],
    20: ORANGE['20'],
  },
  lwDanger: {
    100: RED['100'],
    60: RED['60'],
    20: RED['20'],
    10: RED['10'],
  },
  lwSuccess: {
    100: GREEN['100'],
    20: GREEN['20'],
  },
  lwBlack: {
    100: BLACK['100'],
    60: BLACK['60'],
    40: BLACK['40'],
    30: BLACK['30'],
    20: BLACK['20'],
    10: BLACK['10'],
    2: BLACK['2'],
    6: BLACK['6'],
  },
  lwWhite: {
    100: WHITE['100'],
    80: WHITE['80'],
  },
  lwBlock: {
    actionable: 'rgba(255, 237, 158, 1)',
    default: WHITE['100'],
  },
  lwState: {
    disabled: 'linear-gradient(0deg, rgba(240, 240, 240, 0.5), rgba(240, 240, 240, 0.5)), #FFFFFF;',
    borderActive: '#E6EAFF;',
  },
  // DO NOT USE
  tertiary: {},
};

export { palette };
