import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Header, LwButton, Page, Toolbox, useFormButtons } from 'redesign';
import { CompanyEmploymentTypes } from '@types';
import { useAlert } from 'shared/components/alerts';
import { useJobCreateDefaults } from './hooks/use-job-create-defaults';
import { useCompanyRelativePath } from '../../../../../../hooks';
import { useCreateJob } from '../../../../../../hooks/queryHooks/jobs/use-create-job';
import { usePageProvider } from '../../../../../../layouts';
import { routes } from '../../../../../../Routes';
import { JobCreateDataModel } from '../../../../../../services/mappers/mappers';
import { JobForm } from '../../forms/shared/job-form';
import { decodeJobFormToJob } from '../utils';
import { JobFormContext } from '../../forms/shared/job-form.types';

type Props = {
  jobCreateData: JobCreateDataModel;
  defaultValues?: JobFormContext;
  successMessage: string;
  pageTitle: string;
  employmentType: CompanyEmploymentTypes;
};

export const JobCreatePage = ({
  jobCreateData,
  defaultValues,
  pageTitle,
  successMessage,
  employmentType,
}: Props) => {
  const formDefaultValues: JobFormContext = useJobCreateDefaults(defaultValues, employmentType);
  const formMethods = useForm<JobFormContext>({
    defaultValues: formDefaultValues,
  });

  const { alertSuccess, alertError } = useAlert();
  const generatePath = useCompanyRelativePath();
  const [{ filters }] = usePageProvider();
  const navigate = useNavigate();
  const createJob = useCreateJob();
  const { submitButtonProps, cancelButtonProps, returnTo } = useFormButtons(createJob.status);

  const handleSubmit = () => {
    formMethods.handleSubmit((data) => {
      const decodedData = decodeJobFormToJob(data);

      createJob.mutate(decodedData, {
        onSuccess: ({ id }) => {
          alertSuccess(successMessage);
          navigate(generatePath(routes.JobsDetail, { id }, filters));
        },
        onError: (e) => alertError(e),
      });
    })();
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText={pageTitle} />}
        toolbox={
          <Toolbox underline>
            <LwButton onClick={handleSubmit} iconColorMode="stroke" {...submitButtonProps}>
              Maak aan
            </LwButton>
            <LwButton
              onClick={returnTo(routes.Jobs, undefined, filters)}
              iconColorMode="fill"
              {...cancelButtonProps}
            >
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <JobForm formState="create" employmentType={employmentType} jobCreateData={jobCreateData} />
      </Page>
    </FormProvider>
  );
};
