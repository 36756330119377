import { RenderUnsettledUI } from 'shared/components';
import { useGetJobTypes } from 'hq/hooks/queryHooks/job-types/use-get-job-types';
import { useGetWorkerList } from 'hq/hooks/queryHooks/flexWorkers/use-get-flex-worker-list';
import { useState } from 'react';
import { useDebounce } from 'shared/hooks/use-debounce';
import { AutocompleteOption } from 'redesign';
import { PlacementsFilters } from './placements-filters';
import { PlacementsFiltersDataProviderProps } from './placements-filters.types';
import { useGetWorkPlanningBusinessUnitsData } from '../../common/hooks/use-get-work-planning-filters-data';

export const PlacementsFiltersDataProvider = ({
  toggleFilters,
  handleFiltersChange,
  appliedFilters,
  isOpen,
}: PlacementsFiltersDataProviderProps) => {
  const [flexWorkerSelectedOption, setFlexWorkerSelectedOption] =
    useState<AutocompleteOption | null>(null);

  const [flexWorkerSearchValue, setFlexWorkerSearchValue] = useState<string>('');

  const businessUnitsQuery = useGetWorkPlanningBusinessUnitsData();

  const jobTypeQuery = useGetJobTypes();
  const debouncedFlexWorkerSearchValue = useDebounce(flexWorkerSearchValue, 300);

  const workersQuery = useGetWorkerList(debouncedFlexWorkerSearchValue);

  if (businessUnitsQuery.status !== 'success') {
    return <RenderUnsettledUI data={businessUnitsQuery} />;
  }

  if (jobTypeQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobTypeQuery} />;
  }

  if (!isOpen) {
    return null;
  }

  return (
    <PlacementsFilters
      toggleFilters={toggleFilters}
      appliedFilters={appliedFilters}
      isOpen={isOpen}
      handleFiltersChange={handleFiltersChange}
      filterData={{
        companies: businessUnitsQuery.data.companies,
        jobTypes: jobTypeQuery.data,
        workers: workersQuery.data || [],
      }}
      flexWorkerOption={flexWorkerSelectedOption}
      setFlexWorkerOption={setFlexWorkerSelectedOption}
      flexWorkerSearch={flexWorkerSearchValue}
      setFlexWorkerSearch={setFlexWorkerSearchValue}
    />
  );
};
