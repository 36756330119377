import { useCallback, useState } from 'react';
import { ProviderFactory } from 'shared/providers/provider-factory';
import { PlanningContext } from './planning-actions-provider.types';

const [Provider, usePlanningProvider] = ProviderFactory<PlanningContext>(
  'PlanningContext can only be accessed within PlanningProvider'
);

type PlanningProviderProps = {
  children: React.ReactNode;
};

export const PlanningProvider = ({ children }: PlanningProviderProps) => {
  const [selectedShiftIds, setSelectedShiftIds] = useState<string[]>([]);
  const [bulkSidebarState, setBulkSidebarState] = useState<'edit' | 'archive' | null>(null);

  const handleSelectionChange = useCallback(
    (shiftIds: string[]) => {
      setSelectedShiftIds(shiftIds);
    },
    [setSelectedShiftIds]
  );

  return (
    <Provider
      value={{
        selectedShiftIds,
        handleSelectionChange: handleSelectionChange,
        bulkSidebarState,
        setBulkSidebarState,
      }}
    >
      {children}
    </Provider>
  );
};

export const usePlanningContext = () => {
  return usePlanningProvider();
};
