import { useCallback, useState } from 'react';
import { FlexWorkerRelation } from 'ogp/hooks/queryHooks/flexWorkers/useGetFlexWorkerProfile';
import { Popup } from '../components/popup-profile/popup';

export const usePopupProfile = () => {
  const [id, setId] = useState('');
  const [flexWorkerRelation, setFlexWorkerRelation] = useState<FlexWorkerRelation | undefined>();

  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  function openPopup(id: string, relation?: FlexWorkerRelation) {
    open();
    setId(id);
    setFlexWorkerRelation(relation);
  }

  const Element = (
    <Popup id={id} flexWorkerRelation={flexWorkerRelation} isOpen={isOpen} close={close} />
  );

  return { Element, open: openPopup, close };
};
