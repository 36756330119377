import { Box, Divider, styled } from '@mui/material';
import { WorkFormDetails } from 'shared/components/work-forms/details/work-form-details';
import { DetailsForWorkTemplate } from 'shared/components/work-forms/details/work-form-details.types';
import {
  FormMode,
  ShiftCreateDataModel,
} from 'shared/components/shift-forms/components/forms/shared/types';
import { useGetCompanyEmploymentTypes, useGetWorkCreateData, useGetWorkTemplates } from 'api';
import { useSiteDetection } from 'shared/hooks';
import { RenderUnsettledUI } from 'shared/components/render-unsettled-ui';
import { shiftCreateDataResponse2ShiftCreateDataModel } from 'ogp/services/mappers/mappers';
import { useFormContext } from 'react-hook-form';
import { ProjectFormInputs } from './project-form-inputs';

export type ProjectFormProps = {
  mode: FormMode;
  isProjectWithActivePlacement: boolean;
  workDetails: DetailsForWorkTemplate | undefined;
};

export const ProjectForm = ({
  mode,
  isProjectWithActivePlacement,
  workDetails,
}: ProjectFormProps) => {
  const { watch } = useFormContext();
  const { site } = useSiteDetection();
  const selectedCompanyId = watch('companyId');
  const shiftCreateDataQuery = useGetWorkCreateData(
    site,
    site === 'hq' ? selectedCompanyId : undefined,
    site === 'hq' ? !!selectedCompanyId : true
  );
  const employmentTypeOfCompanyQuery = useGetCompanyEmploymentTypes(
    site,
    site === 'hq' ? selectedCompanyId : undefined,
    site === 'hq' ? !!selectedCompanyId : true
  );
  const workTemplatesQuery = useGetWorkTemplates(
    site,
    site === 'hq' ? selectedCompanyId : undefined,
    site === 'hq' ? !!selectedCompanyId : true
  );

  if (employmentTypeOfCompanyQuery.status !== 'success') {
    return <RenderUnsettledUI data={employmentTypeOfCompanyQuery} />;
  }

  if (shiftCreateDataQuery.status !== 'success') {
    return <RenderUnsettledUI data={shiftCreateDataQuery} />;
  }

  if (workTemplatesQuery.status !== 'success') {
    return <RenderUnsettledUI data={workTemplatesQuery} />;
  }

  const shiftCreateData: ShiftCreateDataModel = shiftCreateDataResponse2ShiftCreateDataModel(
    shiftCreateDataQuery.data
  );

  return (
    <Box display="flex" flexDirection="row">
      <Box display="flex" flexDirection="row" height="100%" flex="1">
        <ProjectFormInputs
          branchOfficesWithDepartments={shiftCreateData.branchOfficesWithDepartments}
          jobsData={workTemplatesQuery.data}
          mode={mode}
          isProjectWithActivePlacement={isProjectWithActivePlacement}
          companyEmploymentType={employmentTypeOfCompanyQuery.data}
          isDisabled={false}
        />
      </Box>
      <Box>
        <StyledDivider />
      </Box>
      <Box flex="1" display="flex">
        <WorkFormDetails workDetails={workDetails} />
      </Box>
    </Box>
  );
};

const StyledDivider = styled(Divider)(({ theme }) => ({
  height: '100%',
  marginLeft: theme.spacing(7),
  width: 2,
}));
