import { IconCalendarView, IconGridView } from 'assets/img';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LwButton } from 'redesign';
import { stringifyFilters } from 'shared/utils/stringify-filters';
import { getQueryParameters } from 'shared/hooks/query-hooks/relative-path-utils';
import { usePlanningParamsContext } from '../../providers/planning-params-provider';

export const PlanningViewSwitcher = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { filters, savedParamsRef } = usePlanningParamsContext();
  const activeRoute = location.pathname.split('/').pop();

  const switchView = useCallback(() => {
    const oldViewParams = savedParamsRef.current;
    savedParamsRef.current = filters;

    const stringifiedParams = stringifyFilters(oldViewParams);

    navigate({
      pathname: activeRoute === 'calendar' ? 'table' : 'calendar',
      search: getQueryParameters(stringifiedParams),
    });
  }, [activeRoute, filters, navigate, savedParamsRef]);

  return (
    <LwButton data-testid="switch-view-icon" onClick={switchView} color="secondary">
      {switcher[activeRoute === 'calendar' ? 'calendar' : 'table']}
    </LwButton>
  );
};

const switcher = {
  table: <IconGridView style={{ flexShrink: 0 }} />,
  calendar: <IconCalendarView style={{ flexShrink: 0 }} />,
};
