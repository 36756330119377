import {
  ContextMenuButton,
  ContextMenuItem,
  Table,
  TableCell,
  TableHeader,
  UserAvatar,
} from 'redesign';
import { NotWelcomeListItem } from 'ogp/services/company-service.types';
import { formatDate } from 'shared/utils/formatting';
import { IconCancel } from 'assets/img';
import { useAlert } from 'shared/components/alerts';
import { useDialog } from 'ogp/providers/dialog-provider';
import { Tooltip, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Column } from 'react-table';
import { getFlexWorkerLabel } from 'shared/utils/get-flex-worker-label';
import { usePopupProfile } from 'ogp/components/views/planning/hooks';
import { useRemoveFlexWorkerFromNotWelcomeList } from './hooks/use-remove-flex-worker-from-not-welcome-list';
import {
  NotWelcomeListCellProps,
  NotWelcomeListColumnDefinition,
  NotWelcomeListTableOptions,
} from './not-welcome-list-table.types';

export const NotWelcomeListTable = ({
  notWelcomeList,
}: {
  notWelcomeList: NotWelcomeListItem[];
}) => {
  const columns = React.useMemo(() => getNotWelcomeListColumnDefinition(), []);
  const { open: openPopupProfile, Element: PopupProfileElement } = usePopupProfile();

  const data = useMemo(() => {
    return notWelcomeList.map((i) => {
      const flexWorkerName = `${i.firstName} ${i.infix} ${i.lastName}`;
      const res: NotWelcomeListColumnDefinition = {
        flexWorkerImage: {
          id: i.id,
          firstName: i.firstName,
          lastName: i.lastName,
          imageUrl: i.imageUrl ?? undefined,
          employmentType: i.employmentType,
          hasCandidateLevel: false,
          partnerName: i.partnerName,
        },
        flexWorkerName,
        reason: i.reason,
        dateAdded: i.dateAdded.toString(),
        departments: i.departments,
        actions: { id: i.id },
      };

      return res;
    });
  }, [notWelcomeList]);

  const onProfileClick = (workerId: string) => {
    openPopupProfile(workerId);
  };

  return (
    <>
      {PopupProfileElement}
      <Table<NotWelcomeListColumnDefinition, NotWelcomeListTableOptions>
        onProfileClick={onProfileClick}
        columns={columns}
        data={data}
        tableStyle={{ tableLayout: 'fixed' }}
      />
    </>
  );
};

const getNotWelcomeListColumnDefinition = () => {
  const columns: Column<NotWelcomeListColumnDefinition>[] = [
    {
      Header: '',
      accessor: 'flexWorkerImage',
      Cell: FlexWorkerImageCell,
      width: '130px',
    },
    {
      Header: () => <TableHeader>Naam</TableHeader>,
      accessor: 'flexWorkerName',
      Cell: (cell) => <TableCell>{cell.value}</TableCell>,
    },
    {
      accessor: 'dateAdded',
      Header: <TableHeader>Toegevoegd op</TableHeader>,
      Cell: (cell) => <TableCell>{formatDate(new Date(cell.value))}</TableCell>,
    },
    {
      accessor: 'reason',
      Header: () => <TableHeader>Reden</TableHeader>,
      Cell: (cell) => (
        <TableCell>
          <Typography noWrap={false} variant="body1">
            {cell.value}
          </Typography>
        </TableCell>
      ),
    },
    {
      accessor: 'departments',
      Header: () => <TableHeader>Afdelingen</TableHeader>,
      Cell: (cell) => <DepartmentsCell departments={cell.value} />,
    },
    {
      Header: () => <TableHeader></TableHeader>,
      accessor: 'actions',
      Cell: (cell) => <ActionsCell id={cell.value.id} />,
    },
  ];

  return columns;
};

const DepartmentsCell = ({ departments }: { departments: string[] }) => {
  let content;

  if (departments.length === 0) {
    content = 'Alle afdelingen';
  } else if (departments.length === 1) {
    content = departments[0];
  } else {
    content = (
      <Tooltip title={departments.join(', ')}>
        <span>{departments.length} afdelingen</span>
      </Tooltip>
    );
  }

  return (
    <TableCell>
      <Typography noWrap={false} variant="body1">
        {content}
      </Typography>
    </TableCell>
  );
};

const ActionsCell = ({ id }: { id: string }) => {
  const { alertSuccess, alertError } = useAlert();
  const { openDialog, closeDialog, disableDialog, enableDialog } = useDialog();
  const mutation = useRemoveFlexWorkerFromNotWelcomeList();

  const handleRemoval = () => {
    openDialog(
      {
        title: 'Verwijderen van lijst?',
        content:
          'Weet je zeker dat je de flexwerker van de niet-welkom-lijst wilt verwijderen? De flexwerker kan hierna weer aanmelden voor shifts en aan flexpools toegevoegd worden.',
      },
      () => {
        disableDialog();
        mutation.mutate(id, {
          onSuccess: () => {
            alertSuccess('Flexwerker van niet-welkom-lijst verwijderd.');
            closeDialog();
          },
          onError: (e) => {
            alertError(e);
            enableDialog();
          },
        });
      }
    );
  };

  return (
    <TableCell>
      <ContextMenuButton>
        <ContextMenuItem onClick={handleRemoval}>
          <IconCancel />
          Verwijderen
        </ContextMenuItem>
      </ContextMenuButton>
    </TableCell>
  );
};

const FlexWorkerImageCell = ({
  value,
  onProfileClick,
}: NotWelcomeListCellProps<'flexWorkerImage'>) => {
  const { id, firstName, lastName, imageUrl, employmentType, partnerName } = value;
  const avatarLabel = getFlexWorkerLabel(employmentType, partnerName);

  return (
    <UserAvatar
      onClick={() => onProfileClick(id)}
      firstName={firstName}
      lastName={lastName}
      imageUrl={imageUrl}
      avatarLabel={avatarLabel}
    />
  );
};
