import { QUERY_KEYS } from 'api/query-keys';
import { sharedWorkTemplateService } from 'api/services';
import { useQuery } from 'react-query';
import { useAlert } from 'shared/components/alerts';
import { Site } from 'shared/hooks';

export const useGetWorkTemplates = (site: Site, companyId?: string, enabled: boolean = true) => {
  const { alertError } = useAlert();

  return useQuery(
    QUERY_KEYS.workTemplatesForCompany(companyId),
    async () => await sharedWorkTemplateService.getWorkTemplates(site, companyId),
    { enabled, onError: (e) => alertError(e) }
  );
};
