import { useGetOGPUserInfo } from 'ogp/hooks';
import { hasAccessWithinPolicyGroup, PolicyGroups } from 'shared/utils/policy-controller';
import { CompanyRole } from '@types';
import { RenderUnsettledUI } from 'shared/components/render-unsettled-ui';
import { useGetCompanyEmploymentTypes, useGetWorkCreateData, useGetWorkTemplates } from 'api';
import { shiftCreateDataResponse2ShiftCreateDataModel } from 'ogp/services/mappers/mappers';
import { ShiftForm } from '../components/forms/shift-form/shift-form';
import { ShiftCreateDataModel, ShiftFormProps } from '../components/forms/shared/types';

export const OgpShiftForm = ({
  mode,
  workDetails,
}: Pick<ShiftFormProps, 'mode' | 'workDetails'>) => {
  const shiftCreateDataQuery = useGetWorkCreateData('ogp');
  const employmentTypeOfCompanyQuery = useGetCompanyEmploymentTypes('ogp');
  const workTemplatesQuery = useGetWorkTemplates('ogp');
  const { data: ogpUserInfo } = useGetOGPUserInfo();

  if (employmentTypeOfCompanyQuery.status !== 'success') {
    return <RenderUnsettledUI data={employmentTypeOfCompanyQuery} />;
  }

  if (shiftCreateDataQuery.status !== 'success') {
    return <RenderUnsettledUI data={shiftCreateDataQuery} />;
  }

  if (workTemplatesQuery.status !== 'success') {
    return <RenderUnsettledUI data={workTemplatesQuery} />;
  }

  const isHqAdmin =
    !!ogpUserInfo &&
    hasAccessWithinPolicyGroup(PolicyGroups.COMPANY, ogpUserInfo.userInfo, CompanyRole.HQADMIN);
  const allowDateInThePast = isHqAdmin || mode === 'create' || mode === 'duplicate';

  const shiftCreateData: ShiftCreateDataModel = shiftCreateDataResponse2ShiftCreateDataModel(
    shiftCreateDataQuery.data
  );

  return (
    <ShiftForm
      mode={mode}
      isDisabled={mode === 'read'}
      shiftCreateData={shiftCreateData}
      jobList={workTemplatesQuery.data}
      employmentTypeOfCompany={employmentTypeOfCompanyQuery.data}
      allowDateBeforeToday={allowDateInThePast}
      workDetails={workDetails}
    />
  );
};
