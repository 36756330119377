import { useQuery } from 'react-query';
import { CompanyEmploymentTypes } from '@types';
import { sharedCompanyService } from 'api/services';
import { QUERY_KEYS } from 'api/query-keys';
import { Site } from 'shared/hooks';
import { useAlert } from 'shared/components/alerts';

export const useGetCompanyEmploymentTypes = (
  site: Site,
  companyId?: string,
  enabled: boolean = true
) => {
  const { alertError } = useAlert();
  const queryFn = async () => await sharedCompanyService.getCompanyEmploymentTypes(site, companyId);

  return useQuery<Promise<CompanyEmploymentTypes>, Error, CompanyEmploymentTypes>(
    QUERY_KEYS.companyForEmploymentTypes(companyId),
    queryFn,
    { enabled, onError: (e) => alertError(e) }
  );
};
