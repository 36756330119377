import { QUERY_KEYS } from 'api/query-keys';
import { shiftService } from 'ogp/services';
import { OGPDuplicateShiftPayload } from 'ogp/services/shift-service.types';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

type ShiftMutationOptions = UseMutationOptions<string, Error, OGPDuplicateShiftPayload>;

export const useOgpDuplicateShift = () => {
  const mutationFn = async (shift: OGPDuplicateShiftPayload) =>
    await shiftService.duplicateShift(shift);
  const queryClient = useQueryClient();
  const options: ShiftMutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.shifts());
    },
  };

  return useMutation(mutationFn, options);
};
