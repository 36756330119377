import { DateTime } from 'luxon';
import { z } from 'zod';

const BUILD_TIMESTAMP = DateTime.now().toUTC().toISO();

const envSchema = z.object({
  VITE_BACKEND_OGP_URI: z.string().startsWith('http').endsWith('/ogp/'),
  VITE_COGNITO_OGP_USER_POOL_ID: z.string().startsWith('eu-west-1'),
  VITE_COGNITO_OGP_USER_POOL_CLIENT_ID: z.string(),
  VITE_BACKEND_HQ_URI: z.string().startsWith('http').endsWith('/hq/'),
  VITE_COGNITO_HQ_USER_POOL_ID: z.string().startsWith('eu-west-1'),
  VITE_COGNITO_HQ_USER_POOL_CLIENT_ID: z.string(),
  VITE_BACKEND_PARTNER_URI: z.string().startsWith('http').endsWith('/partner/'),
  VITE_COGNITO_PARTNER_USER_POOL_ID: z.string().startsWith('eu-west-1'),
  VITE_COGNITO_PARTNER_USER_POOL_CLIENT_ID: z.string(),
  VITE_ENV: z.enum(['production', 'accept', 'development', 'test']),
  VITE_COMMIT_HASH: z.string(),
  VITE_VERSION: z.string(),
  VITE_BUILD_TIMESTAMP: z.string().datetime().default(BUILD_TIMESTAMP),
  VITE_PLATFORM_CLIENT: z.enum(['LEVELWORKS', 'AETHON', 'HOLLANDWORX']),
  VITE_HUBSPOT_ACCOUNT_ID: z.string().optional(),
  VITE_LOCIZE_PROJECT_ID: z.string(),
  VITE_LOCIZE_COPIES_S3_URL: z.string().url().startsWith('https'),
});

const getEnv = (envLikeObject: Record<string, unknown>) => envSchema.parse(envLikeObject);

const getEnvIssues = (envLikeObject: Record<string, unknown>) => {
  const result = envSchema.safeParse(envLikeObject);

  if (!result.success) {
    return result.error.issues;
  }
};

type Env = ReturnType<typeof getEnv>;

export { getEnv, getEnvIssues };
export type { Env };
