import { z } from 'zod';
import { locationSchema, vatSchema } from '../zod-schemas/schema';

export const departmentSchema = z
  .object({
    department: z.object({
      id: z.string(),
      archived: z.boolean(),
      VAT: vatSchema,
      branchOfficeId: z.string(),
      location: locationSchema,
      locationId: z.string(),
      locationIndicator: z.string().nullable(),
      locationUrl: z.string().nullable(),
      name: z.string(),
      job: z.array(z.object({ id: z.string(), name: z.string(), flexPoolOnly: z.boolean() })),
      billingEntity: z
        .object({
          name: z.string(),
          id: z.string(),
          cocNumber: z.string(),
          location: locationSchema,
        })
        .nullable(),
      cocNumber: z.string().nullable(),
      companyNameForOVO: z.string().nullable(),
      costCenter: z.string().nullable(),
      legalPerson: z.string().nullable(),
      flexPoolDepartments: z.array(
        z.object({
          id: z.string(),
          departmentId: z.string(),
          flexPoolId: z.string(),
          flexPool: z.object({
            name: z.string(),
            id: z.string(),
          }),
        })
      ),
      flexPools: z.array(
        z.object({
          directClaimAllowed: z.boolean(),
          id: z.string(),
          name: z.string(),
        })
      ),
    }),
  })
  .strip();

export type DepartmentDetailResponse = z.infer<typeof departmentSchema>;
