import { CompanyEmploymentTypes } from '@types';
import { JobFormContext } from '../../../forms/shared/job-form.types';

export const useJobCreateDefaults = (
  defaultValues: JobFormContext | undefined,
  companyEmploymentType: CompanyEmploymentTypes
): JobFormContext => ({
  ...defaultValues,
  isFlexPoolOnly: defaultValues?.isFlexPoolOnly ?? false,
  isOrtApplicable: defaultValues?.isOrtApplicable ?? false,
  department: defaultValues?.department,
  workName: defaultValues?.workName,
  hourlyRate: defaultValues?.hourlyRate,
  mutableUntilHours: defaultValues?.mutableUntilHours,
  cla: defaultValues?.cla,
  breakMinutes: defaultValues?.breakMinutes,
  jobType: defaultValues?.jobType,
  jobCertificates: defaultValues?.jobCertificates ?? [],
  flexPool: defaultValues?.flexPool,
  flexPools: defaultValues?.flexPools ?? [],
  vat: defaultValues?.vat,
  jobRequirements: defaultValues?.jobRequirements,
  shortDescription: defaultValues?.shortDescription,
  longDescription: defaultValues?.longDescription,
  descriptionLegalRequirements: defaultValues?.descriptionLegalRequirements,
  location: defaultValues?.location,
  imageUrl: defaultValues?.imageUrl,
  contactName: defaultValues?.contactName,
  contactPhoneWithPrefix: defaultValues?.contactPhoneWithPrefix,
  costCenter: defaultValues?.costCenter,
  isFreelance: companyEmploymentType.offersFreelanceWork,
  isTempwork: companyEmploymentType.offersTempWork,
});
