import { DateTime } from 'luxon';
import { PlacementsFiltersFormType } from './placements-filters.types';

const stateMapper = (data: PlacementsFiltersFormType) => {
  data.state = [];
  if (data.accepted) {
    data.state.push('accepted');
  }
  if (data.checkoutPending) {
    data.state.push('checkoutPending');
  }
  if (data.checkoutSettled) {
    data.state.push('checkoutSettled');
  }
  if (data.cancelationOfferPending) {
    data.state.push('cancelationOfferPending');
  }
  if (data.cancelationOfferClaim) {
    data.state.push('cancelationOfferSettled');
  }
  if (data.cancelled) {
    data.state.push('canceled');
  }
  if (data.counterOffer) {
    data.state.push('counterOffer');
  }
  if (data.noShow) {
    data.state.push('noShow');
  }
  return;
};

export const placementToSubmitMapper = (data: PlacementsFiltersFormType) => {
  stateMapper(data);
  const dataToSubmit = {
    fromDate:
      data.fromDateCheckboxValue && data.fromDate
        ? DateTime.fromFormat(data.fromDate, 'yyyy-MM-dd')
        : undefined,
    company: data.businessUnitCheckboxValue ? data.company?.value : undefined,
    branchOffice: data.businessUnitCheckboxValue ? data.branchOffice?.value : undefined,
    jobType:
      data.jobTypeCheckboxValue && data.jobType?.length
        ? data.jobType.map((jobTypeSingle) => jobTypeSingle.id)
        : undefined,
    department: data.businessUnitCheckboxValue ? data.department?.value : undefined,
    worker: data.allowWorkersFilter ? data.worker?.value : undefined,
    isFreelance: data.employmentTypeCheckboxValue ? data.isFreelance : undefined,
    isTempWork: data.employmentTypeCheckboxValue ? data.isTempWork : undefined,
    state: data.isStateFilterEnabled && data.state?.length ? data.state : undefined,
  };
  return dataToSubmit;
};
