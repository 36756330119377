import { Box, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { CandidatePlacementsCell } from 'shared/components/planner/common/utils';
import { HqShiftPlanningCellProps } from 'shared/components/planner/shifts/table/shift-planning-table.types';
import { FormatCandidateNameComponent } from './candidate-name-format';
import { HqProjectPlanningCellProp } from '../../projects/table/project-planning-table.types';

export const CandidatePlacementsCellWithTooltip = (
  props:
    | HqShiftPlanningCellProps<'candidatePlacements'>
    | HqProjectPlanningCellProp<'candidatePlacements'>
): JSX.Element => {
  const numberOfClaimants = props.value.claimants.length;
  const hasClaimants = numberOfClaimants > 0;

  if (!hasClaimants) {
    return <CandidatePlacementsCell {...props} />;
  }

  return (
    <StyledTooltip
      data-testid="placements-planning-tooltip"
      title={
        <Container>
          {hasClaimants && (
            <Row>
              <FormatCandidateNameComponent candidates={props.value.claimants} />
            </Row>
          )}
        </Container>
      }
    >
      <CandidatesCell>
        <CandidatePlacementsCell {...props} />
      </CandidatesCell>
    </StyledTooltip>
  );
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.grey[300],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    color: 'white',
  },
}));

const Container = styled(Box)(({ theme }) => ({
  '& > :not(:last-child)': {
    paddingBottom: theme.spacing(3),
    '&:not(:has(div))': {
      paddingBottom: theme.spacing(5),
    },
  },
}));

const CandidatesCell = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'left',
}));

const Row = styled(Box)(({ theme }) => ({
  '& > :not(:last-child)': {
    paddingBottom: theme.spacing(2),
  },
}));
