import { Dialog } from 'ogp/providers/dialog-provider/components/buttons.types';
import { useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import { FormRadioGroup, RadioOption } from 'redesign';
import React from 'react';
import {
  ShiftClaimCancellationReason,
  ShiftClaimCancellationReasonLabels,
} from './cancelation-dialog.types';

export const getHqCancelationDialog = (
  setCancelationReason: React.Dispatch<React.SetStateAction<ShiftClaimCancellationReason>>
): Dialog => {
  return {
    title: 'Reden van annuleren',
    content: <HQCancelationDialog setCancelationReason={setCancelationReason} />,
  };
};

const HQCancelationDialog = ({
  setCancelationReason,
}: {
  setCancelationReason: React.Dispatch<React.SetStateAction<ShiftClaimCancellationReason>>;
}) => {
  const form = useForm<{ reason: ShiftClaimCancellationReason; applyPayout: boolean }>();
  const items: RadioOption[] = Object.keys(ShiftClaimCancellationReason).map(
    (cancelationReason) => {
      return {
        label:
          ShiftClaimCancellationReasonLabels[
            cancelationReason as keyof typeof ShiftClaimCancellationReason
          ],
        value: cancelationReason,
      };
    }
  );
  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <FormRadioGroup
          name="reason"
          control={form.control}
          items={items}
          onChange={(e) => {
            setCancelationReason(e as ShiftClaimCancellationReason);
          }}
        ></FormRadioGroup>
      </Box>
    </Box>
  );
};

export const getOgpCancelationDialog = (isFreelancerWithinCancelationPeriod: boolean): Dialog => {
  if (isFreelancerWithinCancelationPeriod) {
    return {
      title: 'Let op! Je annuleert de flexwerker binnen het annuleringstermijn',
      content:
        'Hierbij heeft de flexwerker recht op 50% van ingeplande uren volgens de Overeenkomst van Opdracht. Bij bevestiging ga je hiermee akkoord.',
    };
  }
  return {
    title: 'Weet je zeker dat je deze inschrijving wilt annuleren?',
  };
};
