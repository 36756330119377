import { hqNotificationService } from 'hq/services';
import { useQuery } from 'react-query';
import { GetHqNotificationsResponseDto } from 'hq/services/notification-service.types';
import { QUERY_KEYS } from 'api/query-keys';
import { NavigationKey } from '../sidebar-items.types';

export const useGetHqNotifications = () => {
  const queryFn = async () => await hqNotificationService.getNotifications();
  return useQuery({ queryKey: QUERY_KEYS.notifications(), queryFn, select });
};

const select = (data: GetHqNotificationsResponseDto): Record<NavigationKey, number> => {
  return {
    'bank-accounts': data.bankAccount,
    accreditations: data.accreditations,
    featureFlags: 0,
    finance: 0,
    operations: 0,
    partners: 0,
    payments: 0,
    platformSettings: 0,
    projects: 0,
    placements: 0,
    sales: 0,
    shifts: 0,
    users: 0,
  };
};
