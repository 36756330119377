import { useNavigate } from 'react-router-dom';
import { usePlanningContext } from '../../providers/planning-actions-provider';

export const useGetActions = (activeRoute?: string) => {
  const { setBulkSidebarState, selectedShiftIds } = usePlanningContext();
  const navigate = useNavigate();

  if (activeRoute === 'table') {
    return [
      {
        label: 'Voeg shift toe',
        onClick: () => navigate('create'),
      },
      {
        label: 'Voeg meerdere shifts toe',
        onClick: () => setBulkSidebarState('edit'),
        disabled: selectedShiftIds && selectedShiftIds.length === 0,
      },
      {
        label: 'Archiveer shifts',
        onClick: () => setBulkSidebarState('archive'),
        disabled: selectedShiftIds && selectedShiftIds.length === 0,
      },
    ];
  } else {
    return [
      {
        label: 'Voeg shift toe',
        onClick: () => navigate('create'),
      },
    ];
  }
};
