import { QUERY_KEYS } from 'api/query-keys';
import { companyService } from 'ogp/services';
import { NotWelcomeListItem } from 'ogp/services/company-service.types';
import { useQuery } from 'react-query';

export const useGetNotWelcomeList = () => {
  return useQuery<Promise<NotWelcomeListItem[]>, Error, NotWelcomeListItem[]>(
    QUERY_KEYS.notWelcomeList(),
    async () => await companyService.getNotWelcomeList()
  );
};
