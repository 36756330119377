import { ShiftClaimCancellationReason } from 'shared/components/shift-detail-modal/hooks/shared/cancelation-dialog/cancelation-dialog.types';

export enum CancellationType {
  HQ = 'HQ',
  OGP = 'OGP',
  OGP_NO_PAYOUT = 'OGPNOPAYOUT',
}

export type BulkCancelPlacementForm = {
  cancellationType: CancellationType;
  reason?: ShiftClaimCancellationReason;
};
