import { useFormContext } from 'react-hook-form';
import { Box, FilterOptionsState } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { AutocompleteOption, FormCheckbox, LwFormAutocomplete } from 'redesign';
import { FilterWorkersFormContext } from './filter-workers.types';
import { getFilterOptions } from '../../utils/utils';

type Props = {
  workers: {
    id: string;
    name: string;
  }[];
};

export const FilterWorkers = (props: Props) => {
  const { workers } = props;
  const { watch, control, setValue } = useFormContext<FilterWorkersFormContext>();
  const allowWorkerFilter = watch('allowWorkersFilter');
  const options: AutocompleteOption[] = useMemo(
    () =>
      workers.map((worker) => ({
        label: worker.name,
        value: worker.id,
      })),
    [workers]
  );

  const filterOptions = useCallback(
    (
      autocompleteOptions: AutocompleteOption[] | null,
      autocompleteState: FilterOptionsState<any>
    ) => {
      const res = getFilterOptions(autocompleteOptions, autocompleteState);
      return res;
    },
    []
  );

  return (
    <>
      <Box>
        <FormCheckbox
          name="allowWorkersFilter"
          control={control}
          label="Flexwerkers"
          isHeaderCheckbox={true}
        />
      </Box>
      {allowWorkerFilter ? (
        <Box paddingLeft="30px">
          <LwFormAutocomplete
            name="worker"
            label="Flexwerkers"
            options={options}
            filterOptions={filterOptions}
            getOptionLabel={(option) => option.label}
            getOptionKey={(option) => option.value}
            isOptionEqualToValue={(option, selected) => option.value === selected.value}
            onChange={(_, value) => setValue('worker', value)}
            control={control}
          />
        </Box>
      ) : null}
    </>
  );
};
