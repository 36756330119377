import { QUERY_KEYS } from 'api/query-keys';
import { partnerPlannerService } from 'partner/shared/services';
import { useQuery } from 'react-query';

export const usePartnerGetClaimants = (shiftId: string) => {
  return useQuery({
    queryKey: QUERY_KEYS.shiftPlanningShiftClaimants(shiftId),
    queryFn: async ({ pageParam = 0 }) => {
      return await partnerPlannerService.getShiftClaimants(shiftId, pageParam);
    },
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};
