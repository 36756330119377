import { Box } from '@mui/material';
import { FormCheckbox, FormRadioGroup } from 'redesign';
import { useFormContext } from 'react-hook-form';
import { FilterApplicantStatusFormContext } from './has-applicants-filter.types';

const items = [
  { value: true, label: 'Ja' },
  { value: false, label: 'Nee' },
];

export const OgpFilterApplicantStatus = () => {
  const { watch, control } = useFormContext<FilterApplicantStatusFormContext>();
  const allowApplicantsFilter = watch('allowApplicantsFilter');

  return (
    <Box width={200}>
      <FormCheckbox
        name="allowApplicantsFilter"
        label="Heeft aanmeldingen"
        control={control}
        isHeaderCheckbox={true}
      />
      {allowApplicantsFilter ? (
        <FormRadioGroup items={items} name="hasApplicants" control={control} />
      ) : null}
    </Box>
  );
};
