import { QUERY_KEYS } from 'api/query-keys';
import { shiftClaimService } from 'ogp/services';
import { useQuery } from 'react-query';
import { CACHE_TIME_MEDIUM } from 'shared/react-query/query-defaults';
import { FirstParamOfFn, PaginatedCollection } from '@types';
import { ClaimSettledExtended } from '../../shared/types/checkouts.types';

const useGetCheckoutsArchiveShiftClaims = (
  params: FirstParamOfFn<typeof shiftClaimService.getCheckoutsArchive>
) => {
  const queryFn = async () => await shiftClaimService.getCheckoutsArchive(params);
  return useQuery<PaginatedCollection<ClaimSettledExtended>, Error>(
    QUERY_KEYS.checkoutsSettled(params),
    queryFn,
    {
      cacheTime: CACHE_TIME_MEDIUM,
      keepPreviousData: true,
    }
  );
};

export { useGetCheckoutsArchiveShiftClaims };
