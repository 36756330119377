import { partnerCheckedOutService } from 'partner/shared/services';
import { useInfiniteQuery } from 'react-query';
import { QUERY_KEYS } from 'api/query-keys';
import { GetPartnerCheckedOutParams } from '../../../../shared/services/checked-outs-service';

const useGetPartnerCheckedOuts = (params: GetPartnerCheckedOutParams) => {
  return useInfiniteQuery(QUERY_KEYS.shiftClaimsWithFilters(params), {
    queryFn: async ({ pageParam = 0 }) =>
      await partnerCheckedOutService.getCheckedOuts({
        ...params,
        pageParam,
        pageSizeParam: params.pageSizeParam,
      }),
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};

export { useGetPartnerCheckedOuts };
