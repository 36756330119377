import type { Column } from 'react-table';
import { Cell, Header } from 'shared/components/planner/common/utils';
import { MinimumRateCellProp, MinimumRateColumnDefinition } from './minimum-rate-table.types';

export const getMinimumRateColumnDefinition: () => Column<MinimumRateColumnDefinition>[] = () => {
  const jobTypeName = {
    Header: () => <Header>Functiegroep</Header>,
    accessor: 'name',
    Cell: Cell,
  } as Column<MinimumRateColumnDefinition>;
  const minimumRate = {
    Header: () => <Header>Minimum tarief zzp'er</Header>,
    accessor: 'minimumRateToEuros',
    Cell: Cell,
    width: '200px',
  } as Column<MinimumRateColumnDefinition>;
  const action = {
    Header: () => <Header>Actie</Header>,
    accessor: 'action',
    Cell: ActionCell,
    width: '65px',
  } as Column<MinimumRateColumnDefinition>;

  return [jobTypeName, minimumRate, action];
};

const ActionCell = (cell: MinimumRateCellProp<'action'>) =>
  cell.getEditAction(cell.value.id, cell.value.minimumHourlyRate);
