import { flexPoolService } from 'ogp/services';
import { useQuery } from 'react-query';
import { FirstParamOfFn } from '@types';
import { QUERY_KEYS } from 'api/query-keys';

const useGetFlexPools = (params: FirstParamOfFn<typeof flexPoolService.getFlexPoolList>) => {
  const queryFn = async () => await flexPoolService.getFlexPoolList(params);

  return useQuery(QUERY_KEYS.flexPoolListWithParams(params), queryFn, {
    // No caching here because this data is highly volatile. Showing old data is very undesirable.
    // This is needed for pagination. We want to keep showing the previous data when fetching the new page.
    keepPreviousData: true,
  });
};

export { useGetFlexPools };
