import { useFormContext } from 'react-hook-form';
import { LwFormInput } from 'redesign';
import { getRequiredMessage } from './shared';
import { ImageUrlFormContext, Props } from './image-url.types';

export const ImageUrlInput = (props: Props) => {
  const { control } = useFormContext<ImageUrlFormContext>();
  const formName = 'imageUrl';
  const label = 'Afbeelding URL';
  const requiredMessage = getRequiredMessage(label);

  return (
    <LwFormInput
      name={formName}
      control={control}
      label={label}
      disabled={props.disabled}
      placeholder="https://"
      rules={props.required ? { required: requiredMessage } : undefined}
    />
  );
};
