import { Box, Divider } from '@mui/material';
import { LWModal, ModalPanelBody } from 'redesign';
import { useCallback } from 'react';
import { ShiftDetailHead } from 'shared/components/shift-detail-modal/components/shift-detail-head/shift-detail-head';
import { useOgpGetShiftDetailsTabs } from 'shared/components/shift-detail-modal/hooks/ogp/use-ogp-get-shift-detail-tabs';
import { useGetShiftDetailHeadActions } from 'shared/components/shift-detail-modal/hooks/use-get-shift-detail-head-actions';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { useHqAdmin } from 'shared/hooks';
import { ShiftDetailResponse } from 'shared/services/shift-service-types';
import { usePlanningParamsContext } from '../../providers/planning-params-provider';

type ShiftDetailsModalProps = {
  data: ShiftDetailResponse;
};

export const ShiftDetailsModal = ({ data }: ShiftDetailsModalProps) => {
  const shiftDetailTabs = useOgpGetShiftDetailsTabs(data);
  const isHq = useHqAdmin();
  const featureFlags = useFeatureFlagContext();
  const { setFilters } = usePlanningParamsContext();
  const showTempWorkRate = isHq || featureFlags.allowGrossHourlyRateVisibleInOgp;

  const closeShiftDetail = useCallback(() => {
    setFilters({ shiftId: undefined });
  }, [setFilters]);

  const { children: shiftDetailHeadActions, isArchiving } = useGetShiftDetailHeadActions({
    data,
    closeModal: closeShiftDetail,
  });

  return (
    <LWModal isOpen={true} onClose={closeShiftDetail} testId="shift-detail">
      <Box p={8} height="100%" display="flex" flexDirection="column">
        <ShiftDetailHead
          close={closeShiftDetail}
          data={data}
          actions={shiftDetailHeadActions}
          isArchiving={isArchiving}
          showTempWorkRate={showTempWorkRate}
        />
        <Box mt={8} mb={8}>
          <Divider />
        </Box>
        <ModalPanelBody tabs={shiftDetailTabs} />
      </Box>
    </LWModal>
  );
};
