import { QUERY_KEYS } from 'api/query-keys';
import { jobService } from 'ogp/services';
import { UpdateJobPayload } from 'ogp/services/job-service.types';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

type UpdateJobParams = UpdateJobPayload & { id: string };

type JobMutationOptions = UseMutationOptions<unknown, unknown, UpdateJobParams>;

export const useUpdateJob = () => {
  const mutationFn = async ({ id, ...payload }: UpdateJobParams) =>
    await jobService.updateJob(id, payload);

  const queryClient = useQueryClient();
  const mutationOptions: JobMutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.jobs());
      queryClient.invalidateQueries(QUERY_KEYS.workTemplates());
    },
  };

  return useMutation(mutationFn, mutationOptions);
};
