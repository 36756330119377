import { RenderUnsettledUI } from 'shared/components';
import { ShiftPlanningFiltersDataProviderProps } from './shift-planning-filters.types';
import { ShiftPlanningFilters } from './shift-planning-filters';
import {
  useGetFilterBusinessUnitsData,
  useGetFilterJobTypesData,
  useGetFilterWorkersData,
} from '../../../../hooks/use-get-filters-data';

export const ShiftPlanningFiltersDataProvider = ({
  onClose,
  handleFiltersChange,
  appliedFilters,
}: ShiftPlanningFiltersDataProviderProps) => {
  const businessUnitsQuery = useGetFilterBusinessUnitsData({
    jobTypeIds: appliedFilters.jobType,
  });

  const jobTypeQuery = useGetFilterJobTypesData({
    companyId: appliedFilters.company,
    branchOfficeId: appliedFilters.branchOffice,
    departmentId: appliedFilters.department,
  });

  const workersQuery = useGetFilterWorkersData();

  if (businessUnitsQuery.status !== 'success') {
    return <RenderUnsettledUI data={businessUnitsQuery} />;
  }

  if (jobTypeQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobTypeQuery} />;
  }

  if (workersQuery.status !== 'success') {
    return <RenderUnsettledUI data={workersQuery} />;
  }

  return (
    <ShiftPlanningFilters
      onClose={onClose}
      appliedFilters={appliedFilters}
      handleFiltersChange={handleFiltersChange}
      filterData={{
        companies: businessUnitsQuery.data.companies,
        jobTypes: jobTypeQuery.data.jobTypes,
        workers: workersQuery.data,
      }}
    />
  );
};
