import { useState } from 'react';
import { Drawer } from '@mui/material';
import { WizardProvider } from 'redesign';
import { BulkActionProvider } from 'shared/providers/bulk-action-provider';
import { BulkShiftActionData } from 'api';
import { BulkShiftEditFormProvider } from './bulk-shift-edit-form/bulk-shift-edit-form-provider';
import { BulkShiftActionResult } from './bulk-shift-action-result/bulk-shift-action-result';

type BulkEditSidebarProps = {
  selectedShiftIds: string[];
  isBulkSideBarOpen: boolean;
  setIsBarSideBarOpen: (isOpen: boolean) => void;
};

export const BulkEditSidebar = ({
  selectedShiftIds,
  isBulkSideBarOpen,
  setIsBarSideBarOpen,
}: BulkEditSidebarProps) => {
  const [mutationResult, setMutationResult] = useState<BulkShiftActionData>({
    fulfilled: [],
    failure: [],
  });

  return (
    <BulkActionProvider
      setMutationResult={setMutationResult}
      selectedIds={selectedShiftIds}
      failure={mutationResult.failure}
      fulfilled={mutationResult.fulfilled}
    >
      <Drawer
        anchor="right"
        open={isBulkSideBarOpen}
        onClose={() => setIsBarSideBarOpen(false)}
        PaperProps={{ style: { width: 600 } }}
      >
        <WizardProvider onClose={() => setIsBarSideBarOpen(false)}>
          <BulkShiftEditFormProvider />
          <BulkShiftActionResult />
        </WizardProvider>
      </Drawer>
    </BulkActionProvider>
  );
};
