import { useFormContext } from 'react-hook-form';
import { FormCheckbox } from 'redesign';
import { getRequiredMessage } from './shared';
import { IsFreelanceFormContext, Props } from './is-freelance.types';

export const IsFreelanceCheckbox = (props: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IsFreelanceFormContext>();
  const formName = 'isFreelance';
  const label = 'Freelance';
  const requiredMessage = getRequiredMessage(label);
  return (
    <FormCheckbox
      name={formName}
      control={control}
      label={label}
      disabled={props.disabled}
      rules={{
        ...(props.required ? { required: requiredMessage } : {}),
        ...(props.validate ? { validate: props.validate } : {}),
      }}
      onChange={props.onChange}
      checkboxError={errors.isFreelance?.message}
    />
  );
};
