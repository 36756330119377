export type MaxDistance = 5 | 10 | 30 | 50 | 75 | 100;

export type FlexWorkersForProjectFilters = FlexWorkersForProjectFilterOptions & {
  fullNameSearch?: string;
};

export type FlexWorkersForProjectFilterOptions = {
  flexPoolOnly?: boolean;
  maxDistance?: MaxDistance;
  workedForDepartment?: boolean;
  hasCertificate?: boolean;
  isFreelancer?: boolean | string;
  isTempWorker?: boolean | string;
};

export type FlexWorkersForProjectFilterOptionsStrings = {
  [K in keyof FlexWorkersForProjectFilterOptions]?: string;
};

export type FlexWorkersForShiftFilters = FlexWorkersForShiftFilterOptions & {
  fullNameSearch?: string;
};

export type FlexWorkersForShiftFilterOptions = {
  flexPoolOnly?: boolean;
  maxDistance?: MaxDistance;
  isAvailable?: boolean;
  workedForDepartment?: boolean;
  hasCertificate?: boolean;
  availabilityStatus?: string;
  isFreelancer?: boolean | string;
  isTempWorker?: boolean | string;
};

export type FlexWorkersForShiftFiltersStrings = {
  [K in keyof FlexWorkersForShiftFilterOptions]?: string;
};

export type FlexWorkersForProjectFiltersStrings = {
  [K in keyof FlexWorkersForProjectFilterOptions]?: string;
};

export enum AvailabilityStatus {
  CONDITIONAL = 'conditional',
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
}

export enum AvailabilityStatusCopy {
  CONDITIONAL = 'Voorwaardelijk',
  AVAILABLE = 'Beschikbaar',
  UNAVAILABLE = 'Niet beschikbaar',
}
