import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { BulkShiftActionData } from 'api';
import { QUERY_KEYS } from 'api/query-keys';
import { Site } from 'shared/hooks';
import { sharedShiftService } from 'api/services';
import { ShiftBulkArchivePayload } from '../../../../../services/shift-planning-service.types';

type MutationOptions = UseMutationOptions<BulkShiftActionData, unknown, ShiftBulkArchivePayload>;

export const useBulkArchiveShift = (site: Site) => {
  const mutationFn = async (data: ShiftBulkArchivePayload) =>
    await sharedShiftService.bulkArchiveShifts(site, data);
  const queryClient = useQueryClient();
  const options: MutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.shifts());
    },
  };

  return useMutation(mutationFn, options);
};
