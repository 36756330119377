import { useFormContext } from 'react-hook-form';
import { LwFormInput } from 'redesign';
import { getRequiredMessage } from './shared';
import { Props, WorkNameFormContext } from './work-name.types';

export const WorkNameInput = (props: Props) => {
  const { control } = useFormContext<WorkNameFormContext>();
  const formName = 'workName';
  const label = 'Functietitel';
  const requiredMessage = getRequiredMessage(label);
  return (
    <LwFormInput
      name={formName}
      control={control}
      label={label}
      disabled={props.disabled}
      rules={props.required ? { required: requiredMessage } : undefined}
    />
  );
};
