import { CreateJobPayload, JobDetailResponse } from 'ogp/services/job-service.types';
import { JobFormContext } from '../forms/shared/job-form.types';

export function encodeJobToJobForm(job: JobDetailResponse): JobFormContext {
  if (!job.freelanceJob && !job.tempWorkJob) {
    throw new Error('Unknown employment type');
  }

  return <JobFormContext>{
    workName: job.name,
    shortDescription: job.descriptionShort,
    descriptionLegalRequirements: job.descriptionLawRequirements,
    longDescription: job.descriptionLong,
    contactName: job.contactName,
    contactPhoneWithPrefix: job.contactPhoneNumber,
    location: job.location,
    department: job.departmentId,
    breakMinutes: job.breakMinutes.toString(),
    imageUrl: job.imageUrl ?? undefined,
    vat: job.VAT,
    isFlexPoolOnly: job.flexPoolOnly,
    costCenter: job.costCenter ?? undefined,
    jobType: job.jobTypeId,
    isOrtApplicable: job.ortApplicable,
    jobRequirements: job.jobRequirements,
    requiredCertificates: job.requiredCertificates,
    jobCertificates: job.jobCertificates,
    flexPools: job.flexPools,
    flexPool: undefined,
    hourlyRate: job.freelanceJob?.hourlyRateCents
      ? (job.freelanceJob.hourlyRateCents / 100).toString()
      : undefined,
    mutableUntilHours: job.freelanceJob?.mutableUntilMinutes
      ? (job.freelanceJob.mutableUntilMinutes / 60).toString()
      : undefined,
    cla: job.tempWorkJob?.cla,
    isFreelance: !!job.freelanceJob,
    isTempwork: !!job.tempWorkJob,
  };
}

export const decodeJobFormToJob = (job: JobFormContext): CreateJobPayload => {
  const {
    jobType,
    workName,
    shortDescription,
    descriptionLegalRequirements,
    contactName,
    contactPhoneWithPrefix,
    vat,
    breakMinutes,
    department,
    isOrtApplicable,
    ...rest
  } = job;
  const parsedBreakMinutes = Number(breakMinutes);
  if (
    jobType == null ||
    workName == null ||
    shortDescription == null ||
    descriptionLegalRequirements == null ||
    contactName == null ||
    contactPhoneWithPrefix == null ||
    parsedBreakMinutes == null ||
    isNaN(parsedBreakMinutes)
  ) {
    throw new Error('Missing required fields');
  }

  const result: CreateJobPayload = {
    ...rest,
    departmentId: department,
    name: workName,
    location: job.location,
    jobTypeId: jobType,
    descriptionShort: shortDescription,
    descriptionLawRequirements: descriptionLegalRequirements,
    contactName: contactName,
    contactPhoneNumber: contactPhoneWithPrefix,
    VAT: vat,
    flexPoolOnly: job.isFlexPoolOnly,
    ortApplicable: isOrtApplicable,
    descriptionLong: job.longDescription,
    breakMinutes: parsedBreakMinutes,
    jobCertificateIds: job.jobCertificates?.map((certificate) => certificate.id) ?? [],
    jobRequirements: [
      ...(job.jobRequirements?.skills ?? []),
      ...(job.jobRequirements?.language ?? []),
      ...(job.jobRequirements?.apparel ?? []),
    ],
  };

  if (job.isFreelance) {
    const { hourlyRate, mutableUntilHours } = job;
    const parsedHourlyRate = Number(hourlyRate);
    const parsedMutableUntilHours = Number(mutableUntilHours);
    if (
      parsedHourlyRate == null ||
      isNaN(parsedHourlyRate) ||
      parsedMutableUntilHours == null ||
      isNaN(parsedMutableUntilHours)
    ) {
      throw new Error('Missing required freelance fields');
    }
    result.freelanceJob = {
      hourlyRateCents: Math.round(parsedHourlyRate * 100),
      mutableUntilMinutes: Math.round(parsedMutableUntilHours * 60),
    };
  } else {
    result.freelanceJob = null;
  }

  if (job.isTempwork) {
    const { cla } = job;
    if (cla == null) {
      throw new Error('Missing required tempwork fields');
    }
    result.tempWorkJob = {
      cla,
    };
  } else {
    result.tempWorkJob = null;
  }

  return result;
};
