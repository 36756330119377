import { useInfiniteQuery } from 'react-query';
import { flexWorkerService as ogpFlexWorkerService } from 'ogp/services';
import { flexWorkerService as hqFlexWorkerService } from 'hq/services';
import { QUERY_KEYS } from 'api/query-keys';
import { FlexWorkersForProjectFilters } from 'shared/components/flex-workers-for-work-table/flex-workers-for-work.types';
import { SiteDetectionResult, useSiteDetection } from '../../../../../../hooks';

const useGetFlexWorkersForProject = (
  projectId: string,
  pageSize: number,
  filters?: FlexWorkersForProjectFilters
) => {
  const siteDetectionResult = useSiteDetection();
  const queryFn = ({ pageParam = 0 }) =>
    getQueryFunction(siteDetectionResult, projectId, pageSize, pageParam, filters);

  return useInfiniteQuery({
    queryKey: QUERY_KEYS.flexWorkersForProject(projectId, filters),
    queryFn: queryFn,
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};

const getQueryFunction = async (
  siteDetectionResult: SiteDetectionResult,
  projectId: string,
  pageSize: number,
  pageParam: number,
  filters?: FlexWorkersForProjectFilters
) => {
  if (siteDetectionResult.isHQ) {
    return await hqFlexWorkerService.getAllFlexWorkersForProject({
      projectId,
      page: pageParam,
      pageSize,
      filters,
    });
  }
  if (siteDetectionResult.isOGP) {
    return await ogpFlexWorkerService.getAllFlexWorkersForProject({
      projectId,
      page: pageParam,
      pageSize,
      filters,
    });
  }
  throw new Error(
    `useGetFlexWorkersForProject: site "${siteDetectionResult.site}" is neither HQ nor OGP.`
  );
};

export { useGetFlexWorkersForProject };
