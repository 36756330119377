import { z } from 'zod';
import { BreakOption } from '@types';
import { EmploymentType } from 'partner/shared/services/partner-worker-service.types';

const GetNotWelcomeListItem = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  imageUrl: z.string().optional(),
  infix: z.string(),
  reason: z.string(),
  dateAdded: z.string(),
  departments: z.array(z.string()),
  partnerName: z.string().optional(),
  employmentType: z.nativeEnum(EmploymentType).optional(),
});

export const GetNotWelcomeListResponse = z.array(GetNotWelcomeListItem);

export type NotWelcomeListItem = z.infer<typeof GetNotWelcomeListItem>;

export enum WorkerType {
  FLEXWORKER = 'FLEXWORKER',
  PARTNERWORKER = 'PARTNERWORKER',
}

const GetFlexWorkerForNotWelcomeList = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  infix: z.string().optional().nullable(),
  email: z.string(),
  workerType: z.nativeEnum(WorkerType),
});

export const GetFlexWorkerForNotWelcomeListResponse = z.array(GetFlexWorkerForNotWelcomeList);
export type FlexWorkerForNotWelcomeList = z.infer<typeof GetFlexWorkerForNotWelcomeList>;

export type GetBreakOptionsResponse = {
  breakOptions: BreakOption[];
};
