import { shiftService } from 'ogp/services';
import { ShiftDetailResponse } from 'shared/services/shift-service-types';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'api/query-keys';

export const useOgpGetShift = (shiftId?: string | null) => {
  const queryFn = async () => await shiftService.getShift(shiftId!);
  const queryKey = QUERY_KEYS.shift(shiftId!);

  return useQuery<Promise<ShiftDetailResponse>, Error, ShiftDetailResponse>({
    queryKey,
    queryFn,
    enabled: !!shiftId,
  });
};
