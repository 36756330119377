import { userService } from 'hq/services';
import { useQuery } from 'react-query';
import { CACHE_TIME_LONG, STALE_TIME_LONG } from 'shared/react-query/query-defaults';
import { HqUserInfo } from '@types';
import { QUERY_KEYS } from 'api/query-keys';

export const useGetUserInfo = () => {
  const queryFn = async () => await userService.getUserInfo();

  return useQuery<Promise<HqUserInfo>, Error, HqUserInfo>(QUERY_KEYS.hqUserInfo(), queryFn, {
    cacheTime: CACHE_TIME_LONG,
    staleTime: STALE_TIME_LONG,
  });
};
