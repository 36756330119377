import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { BulkShiftActionData } from 'api';
import { QUERY_KEYS } from 'api/query-keys';
import { sharedShiftService } from 'api/services';
import { ShiftBulkEditPayloadDto } from 'api/services/shift/shift-service.dto';
import { Site } from 'shared/hooks';

type MutationOptions = UseMutationOptions<BulkShiftActionData, unknown, ShiftBulkEditPayloadDto>;

export const useBulkUpdateShift = (site: Site) => {
  const mutationFn = async (data: ShiftBulkEditPayloadDto) =>
    await sharedShiftService.bulkUpdateShifts(site, data);
  const queryClient = useQueryClient();
  const options: MutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.shifts());
    },
  };

  return useMutation(mutationFn, options);
};
