import { QUERY_KEYS } from 'api/query-keys';
import { useQuery } from 'react-query';
import { SharedApiService } from 'shared/services';
import { environmentConfigurationResponse2FeatureFlagsModel } from 'shared/services/mappers/mappers';

const queryFn = async () => await SharedApiService.getEnvironmentConfiguration();

const useGetEnvironmentConfiguration = (enabled: boolean) =>
  useQuery(QUERY_KEYS.environmentConfiguration(), {
    queryFn,
    enabled,
  });

const useGetFeatureFlags = () => {
  return useQuery(QUERY_KEYS.featureFlags(), {
    queryFn,
    select: environmentConfigurationResponse2FeatureFlagsModel,
  });
};

export { useGetEnvironmentConfiguration, useGetFeatureFlags };
