import { QUERY_KEYS } from 'api/query-keys';
import { userService } from 'hq/services';
import { useQuery } from 'react-query';
import { CACHE_TIME_LONG } from 'shared/react-query/query-defaults';

export const useGetUsers = () => {
  const queryFn = async () => await userService.getUsers();

  return useQuery(QUERY_KEYS.users(), queryFn, {
    cacheTime: CACHE_TIME_LONG,
  });
};
