import { FilterOptionsState } from '@mui/material';
import _ from 'lodash';
import { LocationAutoSuggestItem } from '@types';

function invariant<T>(input: T | null | undefined, msg?: string): asserts input is T {
  if (input === undefined || input === null) {
    throw new Error(msg ?? `Expected val to be defined, but received ${input}`);
  }
}

const factory =
  <T>(base: T) =>
  (override: Partial<T> = {}) => {
    if (_.isArray(base)) {
      return base;
    }
    return _.cloneDeep({
      ...base,
      ...override,
    });
  };
/**
 * Function needed since the options that are sent to the `AutocompleteElement` include the locations from the BranchOffices within the company.
 * So we need to filter the options that are displayed in the dropdown with the ones that match the input value.
 * However, in order to have a behaviour as similar as possible with the JobForm, we need to bring some flexibility,
 * that's why the sanitizeString is needed.
 * @param autocompleteOptions Options that are within the `AutocompleteElement`. (These include the ones from the BranchOffices)
 * @param autocompleteState Object that includes the inputValue of the `AutocompleteElement`
 * @returns Options that will be displayed in the dropdown
 */
function filterOptions(
  autocompleteOptions: LocationAutoSuggestItem[],
  autocompleteState: FilterOptionsState<LocationAutoSuggestItem>
) {
  if (autocompleteState.inputValue.length) {
    const sanitizedInputValue = sanitizeString(autocompleteState.inputValue);
    return autocompleteOptions.filter((autocompleteOption) => {
      const sanitizedAutocompleteOption = sanitizeString(autocompleteOption.address);
      return sanitizedAutocompleteOption.includes(sanitizedInputValue);
    });
  } else {
    return [];
  }
}

/**
 * Function in charge of homogeneize an string.
 * It removes all the accents of the string. Málaga -> malaga
 * It removes all the blanks from the string. Den Haag -> denhaag
 * It removes all the dashes from the string. Weena-Zuid -> weenazuid
 * It converts to lowercase all the string.
 * @param str
 * @returns String without blanks, without accents, without dashes, and lower-cased
 */
function sanitizeString(str: string) {
  str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  str = str.replace(/(\s|-)/, '');
  return str.toLowerCase();
}

const omitNullValues = (data: object) => {
  return Object.fromEntries(Object.entries(data).filter(([_, value]) => value !== null));
};

export { factory, invariant, filterOptions, sanitizeString, omitNullValues };
