import { HQCreateShiftPayload, HQShiftPayloadBase } from 'hq/services/shift-service.types';
import { OgpShiftPayloadBase } from 'ogp/services/shift-service.types';
import { invariant } from 'shared/utils/utils';
import { FreelanceShiftFormData, TempWorkShiftFormData } from '../types/shift-forms.types';

export const transformData = (
  formData: FreelanceShiftFormData | TempWorkShiftFormData
): HQCreateShiftPayload | OgpShiftPayloadBase => {
  invariant(formData.location);
  invariant(formData.departmentId);

  const baseShiftPayload: HQShiftPayloadBase | OgpShiftPayloadBase = {
    departmentId: formData.departmentId,
    startDate: `${formData.startDate}T${formData.startTime}`,
    endDate: `${formData.endDate}T${formData.endTime}`,
    maxClaimants: +formData.maxClaimants,
    breakMinutes: formData.breakMinutes,
    flexPools: formData.flexPools.map((pool) => ({
      id: pool.id,
      directClaimAllowed: pool.directClaimAllowed,
    })),
    location: formData.location,
  };

  if (isFreelanceShiftFormData(formData)) {
    baseShiftPayload.freelanceProperties = {
      hourlyRateCents: Math.round(formData.hourlyRate * 100),
      mutableUntilMinutes: formData.mutableUntilHours * 60,
    };
  }

  baseShiftPayload.isTempWork = formData.isTempwork;
  return baseShiftPayload;
};

const isFreelanceShiftFormData = (
  formData: FreelanceShiftFormData | TempWorkShiftFormData
): formData is FreelanceShiftFormData => {
  return formData.isFreelance;
};
