import { useFormContext } from 'react-hook-form';
import { LwFormSelect } from 'redesign';
import { BreakMinutesFormContext, Props } from './break-minutes.types';

export const BreakMinutesSelect = (props: Props) => {
  const { control } = useFormContext<BreakMinutesFormContext>();
  const formName = 'breakMinutes';
  const label = 'Pauze';

  return (
    <LwFormSelect
      name={formName}
      control={control}
      label={label}
      disabled={props.disabled}
      defaultLabel="Selecteer de lengte van de pauze..."
      options={props.options}
      rules={{
        required: props.required
          ? 'Selecteer een pauze of geef aan dat er geen pauze is'
          : undefined,
      }}
    />
  );
};
