import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { FormCheckbox } from 'redesign';
import {
  FlexWorkerSelect,
  FlexWorkerSelectProps,
} from 'hq/components/views/flexWorkers/flex-worker-select';
import { FilterWorkersFormContext } from './filter-workers.types';

export const FilterWorkersSelect = (props: FlexWorkerSelectProps) => {
  const { workers } = props;
  const { watch, control } = useFormContext<FilterWorkersFormContext>();
  const allowWorkerFilter = watch('allowWorkersFilter');

  return (
    <>
      <Box>
        <FormCheckbox
          name="allowWorkersFilter"
          control={control}
          label="Flexwerkers"
          isHeaderCheckbox={true}
        />
      </Box>
      {allowWorkerFilter ? (
        <Box paddingLeft="30px">
          <FlexWorkerSelect
            workers={workers}
            flexWorkerOption={props.flexWorkerOption}
            flexWorkerSearch={props.flexWorkerSearch}
            setFlexWorkerSearch={props.setFlexWorkerSearch}
            setFlexWorkerOption={props.setFlexWorkerOption}
          />
        </Box>
      ) : null}
    </>
  );
};
