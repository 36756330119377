import { flexWorkerService } from 'hq/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { QUERY_KEYS } from 'api/query-keys';
import { FlexWorkerDataEdit } from '@types';

type FlexWorkerMutationOptions = UseMutationOptions<unknown, unknown, FlexWorkerDataEdit>;

export const useUpdateFlexWorker = (flexWorkerId: string) => {
  const mutationFn = async (data: FlexWorkerDataEdit) =>
    await flexWorkerService.updateFlexWorker(flexWorkerId, data);

  const queryClient = useQueryClient();
  const mutationOptions: FlexWorkerMutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.workers());
    },
  };

  return useMutation(mutationFn, mutationOptions);
};
