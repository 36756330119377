import { QUERY_KEYS } from 'api/query-keys';
import { adminSettingsService } from 'hq/services';
import { PlatformFallbackImageResponse } from 'hq/services/admin-settings-service.types';
import { useQuery } from 'react-query';

export const useGetPlatformFallbackImage = () => {
  const queryFn = async (): Promise<PlatformFallbackImageResponse> =>
    await adminSettingsService.getPlatformFallbackImage();

  return useQuery<Promise<PlatformFallbackImageResponse>, Error, PlatformFallbackImageResponse>(
    QUERY_KEYS.fallbackWorkImage(),
    queryFn
  );
};
