import { useFormContext } from 'react-hook-form';
import { LwFormTextArea } from 'redesign';
import { getRequiredMessage } from './shared';
import { Props, ShortDescriptionFormContext } from './short-description.types';

export const ShortDescriptionInput = (props: Props) => {
  const { control } = useFormContext<ShortDescriptionFormContext>();
  const formName = 'shortDescription';
  const label = 'Korte omschrijving';
  const requiredMessage = getRequiredMessage(label);

  return (
    <LwFormTextArea
      name={formName}
      control={control}
      label={label}
      disabled={props.disabled}
      rules={props.required ? { required: requiredMessage } : undefined}
      rows={3}
    />
  );
};
