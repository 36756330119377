import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { partnersAlerts, useAlert } from 'shared/components/alerts';
import { useConfirm } from 'shared/hooks';
import { useNavigate } from 'react-router-dom';
import { QUERY_KEYS } from 'api/query-keys';
import { useArchivePartner } from './use-archive-partner';
import { routes } from '../../../../../Routes';
import { useHqRelativePath } from '../../../../hooks/useHqRelativePath';

const useArchivePartnerDialog = () => {
  const [partnerId, setPartnerId] = useState<string>('');
  const generatePath = useHqRelativePath();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { alertSuccess, alertError } = useAlert();
  const archivePartner = useArchivePartner();

  function handleArchivePartner() {
    archivePartner.mutate(partnerId, {
      onSuccess: () => {
        dialog.close();
        queryClient.invalidateQueries(QUERY_KEYS.partners());
        alertSuccess(partnersAlerts.success.archived);
        navigate(generatePath(routes.Partners));
      },
      onError: (e) => alertError(e),
    });
  }

  const dialog = useConfirm({
    title: 'Weet je zeker dat je deze partner wilt archiveren?',
    positiveAction: { label: 'Ja', action: handleArchivePartner },
    negativeAction: { label: 'Nee', autoFocus: true },
  });

  function open(id: string) {
    setPartnerId(id);
    dialog.open();
  }

  return { ...dialog, open };
};

export { useArchivePartnerDialog };
