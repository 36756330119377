import { HqApiService } from 'hq/services/hqApiService';
import { OgpApiService } from 'ogp/services/ogp-api-service';
import { PartnerApiService } from 'partner/shared/services/partner-api-service';
import { Site } from 'shared/hooks';
import {
  PartnerWorkerInfiniteQueryResponse,
  partnerWorkerInfiniteQueryResponseSchema,
} from './shift.types';

export class PartnerWorkerService {
  constructor(
    private readonly partnerApiService: PartnerApiService,
    private readonly ogpApiService: OgpApiService,
    private readonly hqApiService: HqApiService
  ) {}

  public async getShiftWorkers(
    site: Site,
    shiftId: string,
    fullNameSearch: string | undefined,
    page: number,
    pageSize: number
  ): Promise<PartnerWorkerInfiniteQueryResponse> {
    const partnerWorkers = await this.getPartnerWorkers(
      site,
      shiftId,
      page,
      pageSize,
      fullNameSearch
    );
    return partnerWorkerInfiniteQueryResponseSchema.parse(partnerWorkers);
  }

  public async claimShift(site: Site, workerId: string, shiftId: string): Promise<void> {
    switch (site) {
      case 'partner':
        return this.partnerApiService.update({ path: `planning`, body: { workerId, shiftId } });
      case 'hq':
        return this.hqApiService.update({
          path: `shifts/claim`,
          body: {
            workerId,
            shiftId,
          },
        });
      case 'ogp':
        return this.ogpApiService.update({
          path: `shifts/claim`,
          body: {
            workerId,
            shiftId,
          },
        });
      default:
        throw new Error('Not implemented');
    }
  }

  private getPartnerWorkers(
    site: Site,
    shiftId: string,
    page: number,
    pageSize: number,
    fullNameSearch: string | undefined
  ): Promise<PartnerWorkerInfiniteQueryResponse> {
    switch (site) {
      case 'partner':
        return this.partnerApiService.get<PartnerWorkerInfiniteQueryResponse>({
          path: `planning/${shiftId}/workers`,
          params: {
            page,
            pageSize,
            fullNameSearch,
          },
        });
      case 'hq':
        return this.hqApiService.get<PartnerWorkerInfiniteQueryResponse>({
          path: `shifts/${shiftId}/partner-workers`,
          params: {
            page,
            pageSize,
            fullNameSearch,
          },
        });
      case 'ogp':
        return this.ogpApiService.get<PartnerWorkerInfiniteQueryResponse>({
          path: `shifts/${shiftId}/partner-workers`,
          params: {
            page,
            pageSize,
            fullNameSearch,
          },
        });

      default:
        throw new Error('Not implemented');
    }
  }
}
