import { QUERY_KEYS } from 'api/query-keys';
import { partnerInboxService } from 'partner/shared/services';
import { useMutation, useQueryClient } from 'react-query';

type MarkMessageAsReadParams = {
  messageId: string;
};

export const useMarkMessageAsRead = () => {
  const mutationFn = async ({ messageId }: MarkMessageAsReadParams) =>
    await partnerInboxService.markInboxMessageAsRead(messageId);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.inbox());
    },
  });
};
