import { QUERY_KEYS } from 'api/query-keys';
import { userService } from 'ogp/services';
import { OGPUserInfoResponse2OGPUserInfoModel } from 'ogp/services/mappers/mappers';
import { useQuery } from 'react-query';
import { useSiteDetection } from 'shared/hooks/use-site-detection';
import { CACHE_TIME_LONG, STALE_TIME_LONG } from 'shared/react-query/query-defaults';

type Response = Awaited<ReturnType<typeof userService.getOGPUserInfo>>;

const useGetOGPUserInfoRequest = <TData = Response>(select?: (data: Response) => TData) => {
  const { isOGP } = useSiteDetection();

  const queryFn = async () => await userService.getOGPUserInfo();

  return useQuery(QUERY_KEYS.ogpUserInfo(), queryFn, {
    cacheTime: CACHE_TIME_LONG,
    staleTime: STALE_TIME_LONG,
    enabled: isOGP,
    select,
  });
};

const useGetOGPUserInfo = () => {
  const result = useGetOGPUserInfoRequest(OGPUserInfoResponse2OGPUserInfoModel);
  return result;
};

export { useGetOGPUserInfo };
