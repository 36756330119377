import { Box } from '@mui/material';
import { LwTabIndicator } from 'redesign';
import { useHqAdmin } from 'shared/hooks';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { ApplicantTable } from 'shared/components/shift-detail-modal/components/tabs/applicants/applicant-table';
import { ShiftDetailResponse } from 'shared/services/shift-service-types';
import { DateTime } from 'luxon';
import { PlannerWorkerTableWithSearchbar } from 'partner/components/views/partner-shift-planning/partner-shift-planning-modal/tables/planner-worker-table';
import { EmploymentType } from '@types';
import { useOgpGetApplicantActions } from './actions/use-ogp-get-applicant-actions';
import { useOgpGetClaimantActions } from './actions/use-ogp-get-claimant-actions';
import { useOgpGetCancelledActions } from './actions/use-ogp-get-cancelled-actions';
import { useGetAddShiftClaimActions } from '../shared/use-get-add-shift-claim-actions';
import { CancelledTable } from '../../components/tabs/cancelled/cancelled-table';
import { ClaimantTable } from '../../components/tabs/claimants/claimant-table';
import { ShiftAddFlexWorker } from '../../components/tabs/add-flex-worker/shift-add-flex-worker';
import { ShiftDetailTab, ShiftDetailTabData } from '../../components/types';
import { ShiftAddPartnerDataProvider } from '../../components/tabs/shift-add-partner/shift-add-partner';
import { mapShiftDetails } from '../shared/map-shift-details';
import { ShiftDetailsApplicant } from '../../components/tabs/applicants/applicant-table.types';
import { useGetShiftPartners } from '../../query-hooks/hq/use-get-shift-partners';

export const useOgpGetShiftDetailsTabs = (shiftData: ShiftDetailResponse) => {
  const isHqAdmin = useHqAdmin();
  const { getCancelledActions } = useOgpGetCancelledActions();
  const { getClaimantActions, NoShowDialog, ChooseFlexWorkerFlexPools } =
    useOgpGetClaimantActions();
  const { getApplicantActions, BulkAcceptShiftApplicationsDialog } = useOgpGetApplicantActions();
  const flexWorkerTableActions = useGetAddShiftClaimActions({ isHq: false });
  const { allowPartnerRelations, allowPartners } = useFeatureFlagContext();
  const allowPartnerShiftClaimAssign = allowPartners && allowPartnerRelations;
  const getShiftEligiblePartnersQuery = useGetShiftPartners(shiftData.id, true);

  const claimTableDetails = {
    mutableUntilDate: shiftData.mutableUntilDate,
    isFreelance: shiftData.isFreelance,
    isTempwork: shiftData.isTempwork,
    id: shiftData.id,
    jobId: shiftData.job.id,
    startDate: shiftData.startDate,
    startTime: shiftData.startTime,
  };
  const applicantTableDetail: ShiftDetailsApplicant = {
    startDate: shiftData.isoStartDateTime,
    employmentType: (shiftData.isFreelance ? 'FREELANCER' : 'TEMPWORKER') as EmploymentType,
    defaultHourlyRate: (shiftData.isFreelance
      ? shiftData.freelanceProperties?.hourlyRateCents
      : shiftData.tempWorkProperties?.grossHourlyRateCents) as number,
  };
  const mappedWorkerData = mapShiftDetails(shiftData);

  if (!mappedWorkerData) {
    return [];
  }

  const addWorkerTableDetails = {
    shiftId: shiftData.id,
    isFreelance: shiftData.isFreelance,
    isTempWork: shiftData.isTempwork,
  };
  const isShiftInThePast = DateTime.fromISO(shiftData.isoStartDateTime) < DateTime.now();

  const tabs = [
    {
      id: ShiftDetailTab.TEAM,
      tabLabel: 'Jouw team',
      element: (
        <>
          {NoShowDialog}
          {ChooseFlexWorkerFlexPools}
          <ClaimantTable
            shiftDetails={claimTableDetails}
            shiftClaimants={mappedWorkerData.claimants}
            getActions={getClaimantActions}
          />
        </>
      ),
    },
    {
      id: ShiftDetailTab.APPLICATIONS,
      tabLabel: (
        <>
          {BulkAcceptShiftApplicationsDialog}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            Aanmeldingen
            {mappedWorkerData.isThereApplicants ? (
              <LwTabIndicator>{mappedWorkerData.pendingApplicants.length}</LwTabIndicator>
            ) : null}
          </Box>
        </>
      ),
      element: (
        <ApplicantTable
          shiftDetails={applicantTableDetail}
          applicants={mappedWorkerData.applicants}
          getActions={getApplicantActions}
        />
      ),
    },
    {
      id: ShiftDetailTab.CANCELS,
      tabLabel: 'Annuleringen',
      element: (
        <CancelledTable
          cancelledCandidates={mappedWorkerData.cancelled}
          getActions={getCancelledActions}
        />
      ),
    },
    ...(isHqAdmin
      ? [
          {
            id: ShiftDetailTab.ADD,
            tabLabel: 'Flexwerker toevoegen',
            element: (
              <ShiftAddFlexWorker
                shiftDetails={addWorkerTableDetails}
                getRowActions={flexWorkerTableActions}
                isActorOGPUser={false}
              />
            ),
          },
          allowPartnerShiftClaimAssign
            ? {
                id: ShiftDetailTab.PARTNERS,
                tabLabel: 'Shift doorverwijzen',
                element: <ShiftAddPartnerDataProvider shiftId={shiftData.id} />,
              }
            : null,
          getShiftEligiblePartnersQuery.status === 'success' &&
          getShiftEligiblePartnersQuery.data.length > 0
            ? {
                id: ShiftDetailTab.ADD_PARTNER,
                tabLabel: 'Kandidaat toevoegen',
                element: <PlannerWorkerTableWithSearchbar shiftId={shiftData.id} />,
              }
            : null,
        ]
      : [
          isShiftInThePast
            ? {
                id: ShiftDetailTab.POOL,
                tabLabel: 'Flexwerker toevoegen',
                element: (
                  <ShiftAddFlexWorker
                    shiftDetails={addWorkerTableDetails}
                    getRowActions={flexWorkerTableActions}
                    isActorOGPUser
                  />
                ),
              }
            : null,
        ]),
  ];
  return tabs.filter((tab) => tab !== null) as ShiftDetailTabData[];
};
