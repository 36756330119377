import { QUERY_KEYS } from 'api/query-keys';
import { accreditationsService } from 'hq/services';
import { mapAccreditations } from 'hq/services/mappers/mappers';
import { useQuery } from 'react-query';

const useGetFlexworkerAccreditations = (flexWorkerId: string) => {
  const queryFn = async () =>
    await accreditationsService.getAccreditationsForFlexWorker(flexWorkerId);

  return useQuery(QUERY_KEYS.accreditationsProfile(), {
    queryFn,
    select: mapAccreditations,
  });
};

export { useGetFlexworkerAccreditations };
