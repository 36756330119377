import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { hqRoutes } from 'hq/Routes';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Header, LwButton, Page, Toolbox, useFormButtons } from 'redesign';
import { Box } from '@mui/material';
import { useGetJobTypes } from 'hq/hooks/queryHooks/job-types/use-get-job-types';
import { InputSelectOption } from '@types';
import { useQueryClient } from 'react-query';
import { QUERY_KEYS } from 'api/query-keys';
import { invariant } from 'shared/utils/utils';
import { useAlert } from 'shared/components/alerts';
import { CreateCompanyGrossHourlyRatePayload } from 'hq/services/company-service.types';
import { DateTime } from 'luxon';
import { CreateCompanyGrossHourlyRateForm } from './form/create-company-gross-hourly-rate-form';
import { GrossHourlyRateFormCreateData } from './create-company-gross-hourly-rate.types';
import { useCreateCompanyGrossHourlyRate } from '../../hooks/use-create-company-gross-hourly-rate';

export const CompanyGrossHourlyRateCreate = () => {
  const { id } = useParams<{ id: string }>();
  invariant(id, 'Company ID needs to be in the path');
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const jobTypesQuery = useGetJobTypes();
  const jobTypeOptions: InputSelectOption[] = useMemo(
    () => jobTypesQuery.data?.map((jt) => ({ value: jt.id, label: jt.name })) ?? [],
    [jobTypesQuery.data]
  );
  const { alertSuccess, alertError } = useAlert();
  const queryClient = useQueryClient();
  const createGrossHourlyRate = useCreateCompanyGrossHourlyRate();
  const formMethods = useForm<GrossHourlyRateFormCreateData>();
  const { submitButtonProps, cancelButtonProps } = useFormButtons('success');

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();
      formMethods.handleSubmit((data) => {
        const payload: CreateCompanyGrossHourlyRatePayload = {
          companyId: id,
          jobTypeId: data.jobTypeId,
          grossHourlyRateCents: Math.round(+data.grossHourlyRate * 100),
          startDate: DateTime.fromISO(data.startDate).toISO(),
          endDate: data.endDate ? DateTime.fromISO(data.endDate).toISO() : undefined,
        };
        createGrossHourlyRate.mutate(payload, {
          onSuccess: () => {
            queryClient.invalidateQueries(QUERY_KEYS.companies());
            alertSuccess(
              'De relatie tussen het bedrijf en het functietype is met succes gecreëerd.'
            );
            navigate(generatePath(hqRoutes.CompanyDetail, { id }));
          },
          onError: (e) => alertError(e),
        });
      })();
    },
    [formMethods]
  );

  return (
    <form onSubmit={handleSubmit} aria-label="Gross Hourly Rate Form">
      <FormProvider {...formMethods}>
        <Page
          header={<Header titleText="Nieuw bruto uurloon aanmaken" />}
          toolbox={
            <Toolbox underline>
              <LwButton iconColorMode="stroke" {...submitButtonProps}>
                Aanmaken
              </LwButton>
              <LwButton
                onClick={() => navigate(generatePath(hqRoutes.CompanyDetail, { id }))}
                iconColorMode="fill"
                {...cancelButtonProps}
              >
                Annuleren
              </LwButton>
            </Toolbox>
          }
        >
          <Box width="100%">
            <CreateCompanyGrossHourlyRateForm
              jobTypeOptions={jobTypeOptions}
              areJobTypesLoaded={jobTypesQuery.status === 'success'}
            />
          </Box>
        </Page>
      </FormProvider>
    </form>
  );
};
