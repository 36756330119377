import { QUERY_KEYS } from 'api/query-keys';
import { partnerService } from 'hq/services';
import { useQuery } from 'react-query';
import { CACHE_TIME_LONG, STALE_TIME_LONG } from 'shared/react-query/query-defaults';

const useGetPartnersAsBlocks = () => {
  const queryFn = async () => await partnerService.getPartnerList();

  return useQuery(QUERY_KEYS.partners(), {
    queryFn,
    cacheTime: CACHE_TIME_LONG,
    staleTime: STALE_TIME_LONG,
  });
};

export { useGetPartnersAsBlocks };
