import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { FormCheckbox, LwFormDate } from 'redesign';
import { FilterFromDateFormContext } from './filter-from-date.types';

type Props = {
  minDate?: string;
};

export const FilterFromDate = (props: Props) => {
  const { watch, control } = useFormContext<FilterFromDateFormContext>();
  const { fromDateCheckboxValue } = watch();

  return (
    <Box>
      <FormCheckbox
        name="fromDateCheckboxValue"
        label="Vanaf datum"
        control={control}
        isHeaderCheckbox={true}
      />
      {fromDateCheckboxValue ? (
        <Box paddingLeft="20px">
          <LwFormDate
            name="fromDate"
            label="Vanaf datum"
            control={control}
            rules={{ required: true }}
            data-testid="start-date"
            minDate={props.minDate}
          />
        </Box>
      ) : null}
    </Box>
  );
};
