import { QUERY_KEYS } from 'api/query-keys';
import { adminSettingsService } from 'hq/services';
import { PlatformSettingsResponse } from 'hq/services/admin-settings-service.types';
import { useQuery } from 'react-query';

export const useGetPlatformSettings = () => {
  const queryFn = async () => await adminSettingsService.getPlatformSettings();

  return useQuery<Promise<PlatformSettingsResponse>, Error, PlatformSettingsResponse>(
    QUERY_KEYS.platformSettings(),
    queryFn
  );
};
