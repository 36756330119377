import { SkillType } from '@types';
import { useFormContext } from 'react-hook-form';
import { LwFormMultiSelect } from 'redesign';
import { Section } from 'shared/components/common/form-section';
import {
  JobRequirementFormName,
  JobRequirementsFormContext,
  Props,
} from './job-requirements.types';

export const JobRequirementsSelect = (props: Props) => {
  const { control } = useFormContext<JobRequirementsFormContext>();

  const renderJobRequirementSelect = (
    name: JobRequirementFormName,
    label: string,
    filterType: SkillType
  ) => (
    <LwFormMultiSelect
      name={name}
      label={label}
      control={control}
      getOptionKey={(option) => option.id}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => {
        return option?.id === value.id;
      }}
      options={props.options.filter((jr) => jr.type === filterType)}
      disabled={props.disabled}
    />
  );

  return (
    <Section sectionLabel="Werk vereisten">
      {renderJobRequirementSelect('jobRequirements.skills', 'Vaardigheden', SkillType.SOFT_SKILL)}
      {renderJobRequirementSelect(
        'jobRequirements.apparel',
        'Kledingvoorschriften',
        SkillType.APPAREL
      )}
      {renderJobRequirementSelect('jobRequirements.language', 'Vereiste talen', SkillType.LANGUAGE)}
    </Section>
  );
};
