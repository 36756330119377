import type { Column } from 'react-table';
import { Cell, Header } from 'shared/components/planner/common/utils';
import { FailedBulkCancelPlacementsColumnDefinition } from './failed-bulk-cancel-placements-table.types';

export const getFailedBulkCancelPlacementsColumnDefinition: () => Column<FailedBulkCancelPlacementsColumnDefinition>[] =
  () => {
    const error = {
      Header: () => <Header>Foutmelding</Header>,
      accessor: 'reason',
      Cell: Cell,
    } as Column<FailedBulkCancelPlacementsColumnDefinition>;

    const placementId = {
      Header: () => <Header>Plaatsingen Id</Header>,
      accessor: 'placementId',
      Cell: Cell,
      width: '160px',
    } as Column<FailedBulkCancelPlacementsColumnDefinition>;

    return [placementId, error];
  };
