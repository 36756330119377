import { QUERY_KEYS } from 'api/query-keys';
import { adminSettingsService } from 'hq/services';
import { UploadPlatformFallbackImagePayload } from 'hq/services/admin-settings-service.types';
import { useMutation, useQueryClient } from 'react-query';

export const useUploadPlatformFallbackImage = () => {
  const mutationFn = async (data: UploadPlatformFallbackImagePayload) =>
    await adminSettingsService.uploadPlatformFallbackImage(data);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.fallbackWorkImage());
    },
  });
};
