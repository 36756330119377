import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import IconCross from 'assets/img/icons/cross.svg?react';
import clsx from 'clsx';
import { ContextMenuButton, ContextMenuItem, LwIconButton } from 'redesign';
import {
  IconCalendar,
  IconClock,
  IconDuplicate,
  IconEdit,
  IconFlexPools,
  IconProfileUsers,
  IconTrash,
} from 'assets/img';
import { formatDateFromString, formatFloatNumber } from 'shared/utils/formatting';
import { AuthenticatedElement } from 'ogp/components/common/authenticated-element';
import { PolicyGroups } from 'shared/utils/policy-controller';
import { CompanyRole } from '@types';
import { ProjectGetResponse } from '../../../../services/project-service.types';
import { CertificatesDetail } from '../../common/certificates-detail';
import { FlexpoolsDetail } from '../../common/flexpools-detail';
import { HourlyRates } from '../../common/hourly-rates';

type ProjectDetailHeadProps = {
  data: ProjectGetResponse;
  companyName: string;
  close: () => void;
  handleEdit: () => void;
  handleDuplicate: () => void;
  handleArchive: () => void;
  showTempWorkRate: boolean;
};

export const ProjectDetailHead = ({
  data,
  companyName,
  close,
  handleEdit,
  handleDuplicate,
  handleArchive,
  showTempWorkRate,
}: ProjectDetailHeadProps) => {
  const classes = getClasses();

  const ContextMenu = () => {
    return (
      <Box>
        <ContextMenuButton size="medium" color="secondary">
          <ContextMenuItem onClick={handleEdit}>
            <IconEdit />
            Wijzig
          </ContextMenuItem>
          <ContextMenuItem onClick={handleDuplicate}>
            <IconDuplicate />
            Dupliceer
          </ContextMenuItem>
          <AuthenticatedElement policyGroup={PolicyGroups.COMPANY} roles={CompanyRole.HQADMIN}>
            <ContextMenuItem onClick={handleArchive}>
              <IconTrash />
              Verwijderen
            </ContextMenuItem>
          </AuthenticatedElement>
        </ContextMenuButton>
      </Box>
    );
  };

  return (
    <Box>
      <Box display="flex" flexDirection="row">
        <Box className={classes.companyInfo}>
          <Typography noWrap color="secondary" variant="body1" style={{ fontWeight: 'bold' }}>
            {companyName} / {data.location.address}
          </Typography>
          <Typography noWrap variant="h5" className={classes.jobName}>
            {data.name}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          ml="auto"
          alignItems="middle"
          style={{ gap: '32px' }}
        >
          <ContextMenu />
          <LwIconButton
            iconColorMode="stroke"
            onClick={close}
            color="secondary"
            data-testid="close-button"
          >
            <IconCross width={24} height={24} />
          </LwIconButton>
        </Box>
      </Box>
      <Box className={classes.properties} display="flex" flexDirection="row" marginTop={5}>
        <Box className={clsx(classes.companyInfo, classes.infoLabel)}>
          <Box className={classes.centerText}>
            <IconProfileUsers width={24} height={24} className={classes.icon} />
            <Typography noWrap variant="subtitle2">
              {data.placements.filter((placement) => placement.state === 'active').length} /{' '}
              {data.maxPlacements}
            </Typography>
          </Box>
          <Box className={classes.centerText}>
            <HourlyRates
              freelanceWage={data.freelanceProperties?.hourlyRateCents}
              tempworkWage={data.tempWorkProperties?.grossHourlyRateCents}
              showTempWorkRate={showTempWorkRate}
            />
          </Box>
        </Box>
        <Box className={clsx(classes.companyInfo, classes.infoLabel)}>
          <Box className={classes.centerText}>
            <IconCalendar width={24} height={24} className={classes.icon} />
            <Typography noWrap variant="subtitle2">
              {formatDateFromString(data.startDate)} - {formatDateFromString(data.endDate)}
            </Typography>
          </Box>
          <Box className={classes.centerText}>
            <IconClock width={24} height={24} className={classes.icon} />
            <Typography noWrap variant="subtitle2">
              {formatFloatNumber(data.weekHourVolume)} uren per week
            </Typography>
          </Box>
        </Box>
        <Box className={clsx(classes.companyInfo, classes.infoLabel)}>
          <Box className={classes.centerText}>
            <IconCalendar width={24} height={24} className={classes.icon} />
            <CertificatesDetail certificates={data.certificates} />
          </Box>
          <Box className={classes.centerText}>
            <IconFlexPools width={24} height={24} className={classes.icon} />
            <Typography noWrap variant="subtitle2">
              <FlexpoolsDetail flexPools={data.flexPools} />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const getClasses = makeStyles(({ spacing }: Theme) => ({
  properties: {
    gap: spacing(3),
    '& svg': {
      opacity: 0.6,
    },
  },
  companyInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  jobName: {
    marginTop: spacing(2),
  },
  infoLabel: {
    flex: 1,
    minWidth: 0,
  },
  centerText: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: spacing(3),
  },
  icon: {
    marginRight: spacing(3),
    flexShrink: 0,
  },
}));
