import { Box, styled } from '@mui/material';
import { FormCheckbox } from 'redesign';
import { useFormContext } from 'react-hook-form';
import { useMemo } from 'react';
export type FilterStateFormContext = {
  state: string[] | null;
  isStateFilterEnabled: boolean;
  accepted: boolean;
  cancelled: boolean;
  noShow: boolean;
  checkoutPending: boolean;
  counterOffer: boolean;
  checkoutSettled: boolean;
  cancelationOfferPending: boolean;
  cancelationOfferClaim: boolean;
};
import { stateLabels } from 'shared/utils/shift-claim-state';
export const FilterStates = () => {
  const { watch, control, setValue, clearErrors } = useFormContext<FilterStateFormContext>();
  const { isStateFilterEnabled } = watch();
  const stateFilterOptions = useMemo(() => options, []);
  return (
    <Box>
      <FormCheckbox
        name="isStateFilterEnabled"
        label="Status"
        control={control}
        isHeaderCheckbox={true}
      />
      {isStateFilterEnabled ? (
        <StyledBox>
          {stateFilterOptions.map((item) => (
            <FormCheckbox
              label={item.name}
              key={item.id}
              name={item.id}
              onChange={(val) => {
                if (val) {
                  clearErrors('state');
                }
              }}
              control={control}
            />
          ))}
        </StyledBox>
      ) : null}
    </Box>
  );
};

const StyledBox = styled(Box)(() => ({
  paddingLeft: '10px',
}));

const options = [
  {
    id: 'accepted' as const,
    name: stateLabels.accepted,
  },
  {
    id: 'cancelled' as const,
    name: stateLabels.cancelled,
  },
  {
    id: 'noShow' as const,
    name: stateLabels.noShow,
  },
  {
    id: 'checkoutPending' as const,
    name: stateLabels.checkoutPending,
  },
  {
    id: 'counterOffer' as const,
    name: stateLabels.counterOffer,
  },
  {
    id: 'checkoutSettled' as const,
    name: stateLabels.checkoutSettled,
  },
  {
    id: 'cancelationOfferPending' as const,
    name: stateLabels.cancelationOfferPending,
  },
  {
    id: 'cancelationOfferClaim' as const,
    name: stateLabels.cancelationOfferClaim,
  },
];
