import { QUERY_KEYS } from 'api/query-keys';
import { companyService } from 'ogp/services';
import { useMutation, useQueryClient } from 'react-query';

export const useRemoveFlexWorkerFromNotWelcomeList = () => {
  const mutationFn = async (flexWorkerId: string) =>
    await companyService.removeFlexWorkerFromNotWelcomeList(flexWorkerId);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.notWelcomeList());
    },
  });
};
