import { DateTime } from 'luxon';
import { useExternalParams } from 'shared/hooks';
import { useEffect, useMemo, useRef } from 'react';
import { ProviderFactory } from 'shared/providers/provider-factory';
import { PlanningParamsContext } from './planning-params-provider.types';

const initialValues = {
  branchOfficeId: {
    initialValue: undefined,
    type: 'string',
  },
  departmentId: {
    initialValue: undefined,
    type: 'string',
  },
  shiftId: {
    initialValue: undefined,
    type: 'string',
  },
  date: {
    initialValue: undefined,
    type: 'date',
  },
  fromDate: {
    initialValue: undefined,
    type: 'date',
  },
  toDate: {
    initialValue: undefined,
    type: 'date',
  },
  filledStatus: {
    initialValue: undefined,
    type: 'string',
  },
  partner: {
    initialValue: undefined,
    type: 'string',
  },
  hasApplicants: {
    initialValue: undefined,
    type: 'boolean',
  },
  jobType: {
    initialValue: undefined,
    type: 'string_array',
  },
  isFreelance: {
    initialValue: undefined,
    type: 'boolean',
  },
  isTempWork: {
    initialValue: undefined,
    type: 'boolean',
  },
} as const;

const [Provider, usePlanningParamsProvider] = ProviderFactory<PlanningParamsContext>(
  'PlanningParamsContext can only be accessed within PlanningProvider'
);

type PlanningParamsProviderProps = {
  children: React.ReactNode;
};

export const PlanningParamsProvider = ({ children }: PlanningParamsProviderProps) => {
  const [params, setParams] = useExternalParams(initialValues);
  const savedParamsRef = useRef(params);

  useEffect(() => {
    savedParamsRef.current = {
      ...savedParamsRef.current,
      departmentId: params.departmentId,
      shiftId: params.shiftId,
      isTempWork: params.isTempWork,
      isFreelance: params.isFreelance,
      branchOfficeId: params.branchOfficeId,
      jobType: params.jobType,
      hasApplicants: params.hasApplicants,
      partner: params.partner,
      filledStatus: params.filledStatus,
    };
  }, [
    params.departmentId,
    params.shiftId,
    params.isTempWork,
    params.isFreelance,
    params.branchOfficeId,
    params.jobType,
    params.hasApplicants,
    params.partner,
    params.filledStatus,
  ]);

  const filters = useMemo(() => {
    return {
      branchOfficeId: params.branchOfficeId,
      departmentId: params.departmentId,
      shiftId: params.shiftId,
      date: params.date,
      fromDate: params.fromDate,
      toDate: params.toDate,
      filledStatus: params.filledStatus || undefined,
      partner: params.partner || undefined,
      hasApplicants: params.hasApplicants ?? undefined,
      jobType: ((params.jobType || undefined) as string[]) ?? undefined,
      isFreelance: params.isFreelance ? params.isFreelance : undefined,
      isTempWork: params.isTempWork ? params.isTempWork : undefined,
    };
  }, [params]);
  const selectedFiltersCount = Object.values(filters).filter(
    (value) => value !== null && value !== undefined && value !== '' && !isDateTime(value)
  ).length;
  return (
    <Provider
      value={{
        filters,
        savedParamsRef,
        setFilters: setParams,
        selectedFiltersCount,
      }}
    >
      {children}
    </Provider>
  );
};

export const usePlanningParamsContext = () => {
  const planningContext = usePlanningParamsProvider();
  return planningContext;
};

const isDateTime = (filterValue: any) => {
  return filterValue instanceof DateTime;
};
