import { Box, FormControlLabel, Grid, Radio, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormRadioGroup, RadioOption } from 'redesign';
import {
  ShiftClaimCancellationReason,
  ShiftClaimCancellationReasonLabels,
} from 'shared/components/shift-detail-modal/hooks/shared/cancelation-dialog/cancelation-dialog.types';
import { BulkCancelPlacementForm, CancellationType } from './bulk-cancel-placements.types';

const ogpItems: RadioOption[] = [
  {
    label: 'Annuleer alle plaatsingen, zelfs met een 50%-regel toegepast',
    value: CancellationType.OGP,
  },
  {
    label: 'Annuleer alle plaatsingen waarbij de 50%-regel niet van toepassing is',
    value: CancellationType.OGP_NO_PAYOUT,
  },
];

const reasonItems: RadioOption[] = Object.entries(ShiftClaimCancellationReason).map(
  ([key, value]) => ({
    label: ShiftClaimCancellationReasonLabels[key as keyof typeof ShiftClaimCancellationReason],
    value,
  })
);

export const BulkCancelFormOptions = () => {
  const { setValue, watch, control, resetField } = useFormContext<BulkCancelPlacementForm>();
  const [showOGPOptions, setShowOGPOptions] = useState(false);
  const [showReasonOptions, setShowReasonOptions] = useState(false);

  const handleCancellationWithReason = useCallback(() => {
    setValue('cancellationType', CancellationType.HQ);
    setShowReasonOptions(true);
    setShowOGPOptions(false);
  }, [setValue, setShowReasonOptions, setShowOGPOptions]);

  const handleEnableOGPOptions = useCallback(() => {
    setShowOGPOptions(true);
    setShowReasonOptions(false);
    resetField('cancellationType');
    resetField('reason');
  }, [setShowOGPOptions, setShowReasonOptions, resetField]);

  return (
    <Grid container padding={10} spacing={4}>
      <Grid padding={4} item xs={12}>
        <Typography variant="h3">Annulering van plaatsingen</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          value={CancellationType.HQ}
          label={<Typography variant="h2">Annuleren met reden</Typography>}
          control={
            <Radio
              onChange={handleCancellationWithReason}
              checked={watch('cancellationType') === CancellationType.HQ}
            />
          }
        />
        <Box marginLeft={10}>
          <FormRadioGroup
            name="reason"
            disabled={!showReasonOptions}
            control={control}
            items={reasonItems}
          ></FormRadioGroup>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          value={CancellationType.OGP}
          label={<Typography variant="h2">Annuleren als opdrachtgever</Typography>}
          control={<Radio onChange={handleEnableOGPOptions} checked={showOGPOptions} />}
        />
        <Box marginLeft={10}>
          <FormRadioGroup
            name="cancellationType"
            disabled={!showOGPOptions}
            control={control}
            items={ogpItems}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
