import { QUERY_KEYS } from 'api/query-keys';
import { partnerWorkerService } from 'partner/shared/services';
import { useInfiniteQuery } from 'react-query';

export const useGetPlannerWorkers = (filter: string) => {
  return useInfiniteQuery({
    queryKey: QUERY_KEYS.partnerWorkersFiltered(filter),
    queryFn: async ({ pageParam = 0 }) =>
      await partnerWorkerService.getPartnerWorkersListing(pageParam, filter),

    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};
