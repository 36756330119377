import { ShiftDetailsProvider } from './shift-details-provider';
import { usePlanningParamsContext } from '../../providers/planning-params-provider';

export const ShiftDetails = () => {
  const { filters } = usePlanningParamsContext();

  if (!filters.shiftId) {
    return <></>;
  }

  return <ShiftDetailsProvider shiftId={filters.shiftId} />;
};
