import { QUERY_KEYS } from 'api/query-keys';
import { shiftClaimService } from 'ogp/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

type UndoNoShowMutationData = { shiftClaimId: string; shiftId?: string };
type UndoNoShowMutationOptions = UseMutationOptions<unknown, Error, UndoNoShowMutationData>;

export const useUndoNoShow = (options?: UndoNoShowMutationOptions) => {
  const mutationFn = async (shiftClaimData: UndoNoShowMutationData) =>
    await shiftClaimService.undoNoShow(shiftClaimData.shiftClaimId);
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options ?? {};

  return useMutation(mutationFn, {
    onSuccess: async (data, variables, context) => {
      queryClient.invalidateQueries(QUERY_KEYS.shifts());
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
};
