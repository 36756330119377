import { Grid } from '@mui/material';
import { Control } from 'react-hook-form';
import { LwFormInput } from 'redesign';

type MinimumJobTypeRateEditorProps = {
  control: Control<{
    minimumHourlyRate: number;
  }>;
};

export const MinimumJobTypeRateEditor = ({ control }: MinimumJobTypeRateEditorProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        Door hier een minimum tarief zzp'er in te stellen, zorg je ervoor dat elk werk van het
        geselecteerde functietype ten minste dit tarief biedt aan freelancers. Deze wijziging heeft
        geen invloed op reeds ingeplande diensten.
      </Grid>
      <Grid item xs={12}>
        <LwFormInput
          inputType="number"
          control={control}
          label="Minimum tarief zzp'er"
          name="minimumHourlyRate"
          rules={{
            required: 'Dit veld is verplicht',
            min: {
              value: 1,
              message: "Het minimum tarief zzp'er moet groter zijn dan 0",
            },
          }}
        />
      </Grid>
    </Grid>
  );
};
