import {
  GetProjectPlanningProjectsResponse,
  HqProjectCreateResponse,
  HQProjectPayloadCreate,
  HQProjectPayloadDuplicate,
  HQProjectPayloadEdit,
  ProjectPlanningParams,
} from './project-planning-service.types';
import { HqApiService } from './hqApiService';

class ProjectPlanningService {
  constructor(private readonly hqApiService: HqApiService) {}

  public async getProjectPlanningProjects({
    toDate,
    company,
    branchOffice,
    department,
    pageParam,
    pageSizeParam,
    jobType,
    filledStatus,
    isFreelance,
    isTempWork,
    areFreelanceApplications,
    areTempworkApplications,
    areNoApplications,
  }: ProjectPlanningParams) {
    const response = await this.hqApiService.get<GetProjectPlanningProjectsResponse>({
      params: {
        page: pageParam,
        pageSize: pageSizeParam,
        toDate: toDate?.toISO(),
        companyId: company,
        branchOfficeId: branchOffice,
        departmentId: department,
        jobType: jobType,
        filledStatus,
        isFreelance,
        isTempWork,
        areFreelanceApplications,
        areTempworkApplications,
        areNoApplications,
      },
      path: 'project-planning/projects',
    });

    return response;
  }

  public async createProject(body: HQProjectPayloadCreate) {
    const { id } = await this.hqApiService.create<HqProjectCreateResponse>({
      path: `project-planning/projects`,
      body,
    });
    return id;
  }

  public async duplicateProject(body: HQProjectPayloadDuplicate) {
    const id = await this.hqApiService.create<string>({
      path: `project-planning/projects/duplicate`,
      body,
    });
    return id;
  }

  public async editProject(projectId: string, body: HQProjectPayloadEdit) {
    await this.hqApiService.update({
      path: `project-planning/projects/${projectId}`,
      body,
    });
  }

  public async archiveProject(projectId: string) {
    await this.hqApiService.delete({
      path: `project-planning/projects/${projectId}`,
    });
  }
}

export { ProjectPlanningService };
