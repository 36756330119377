import { useAlert } from 'shared/components/alerts';
import { routes } from 'Routes';
import { useCompanyRelativePath } from 'ogp/hooks';
import { Header, LwButton, Page, Toolbox, useFormButtons } from 'redesign';
import { paths } from 'ogp/paths';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DepartmentFormData, DepartmentPayload } from 'ogp/services/department-service.types';
import { QUERY_KEYS } from 'api/query-keys';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { usePageProvider } from 'ogp/layouts';
import { useCallback } from 'react';
import { useCreateDepartment } from '../../hooks/use-create-department';
import { DepartmentFormDataProvider } from '../../components/department-form/department-form';

type Props = {
  defaultValues: Partial<DepartmentFormData>;
  successMessage: string;
  pageTitle: string;
};

export const DepartmentCreatePage = ({ defaultValues, successMessage, pageTitle }: Props) => {
  const { alertSuccess, alertError } = useAlert();
  const navigate = useNavigate();
  const [{ filters }] = usePageProvider();
  const generatePath = useCompanyRelativePath();
  const invalidateQuery = useInvalidateQuery();
  const formMethods = useForm<DepartmentFormData>({
    defaultValues,
  });
  const mutation = useCreateDepartment();
  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit((data) => {
      const { location } = data;
      if (!location) {
        throw 'Location is required';
      }
      const formatedData: DepartmentPayload = {
        branchOfficeId: data.branchOfficeId,
        name: data.name,
        legalPerson: data.legalPerson,
        companyNameForOVO: data.companyNameForOVO,
        location,
        locationIndicator: data.locationIndicator,
        VAT: data.VAT,
        costCenter: data.costCenter,
        billingEntityId: data.billingEntityId,
        cocNumber: data.cocNumber,
        departmentFlexPoolIds: data.departmentFlexPools?.map(({ id }) => id) || [],
      };
      mutation.mutate(formatedData, {
        onSuccess: ({ id }) => {
          alertSuccess(successMessage);
          navigate(generatePath(routes.DepartmentDetail, { id }));
          invalidateQuery(QUERY_KEYS.departments());
        },
        onError: (e) => alertError(e),
      });
    })();
  }, [
    alertError,
    alertSuccess,
    formMethods,
    generatePath,
    invalidateQuery,
    mutation,
    navigate,
    successMessage,
  ]);
  const { submitButtonProps, cancelButtonProps, returnTo } = useFormButtons(mutation.status);

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText={pageTitle} />}
        toolbox={
          <Toolbox underline>
            <LwButton iconColorMode="stroke" onClick={handleSubmit} {...submitButtonProps}>
              Maak aan
            </LwButton>
            <LwButton
              iconColorMode="fill"
              onClick={returnTo(paths.Departments, undefined, filters)}
              {...cancelButtonProps}
            >
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <DepartmentFormDataProvider state="create" />
      </Page>
    </FormProvider>
  );
};
