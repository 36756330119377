import { Box } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { Table, useOnScrollDown } from 'redesign';
import { RenderUnsettledUI } from 'shared/components';
import { InfiniteQueryObserverSuccessResult } from 'react-query';
import { useGetPlacements } from './utils/hooks/useGetPlacements';
import { PlacementsFilters } from './utils/hooks/use-placements-page.types';
import { usePlacementsPage } from './utils/hooks/use-placement-page';
import { getPlacementColumnDefinition } from './utils/placement-column-definition';
import { PlacementsColumnDefinition } from './placements.types';
import { PlacementsFiltersDataProvider } from './utils/placements-filters-data-provider';
import { getParsedTableData } from './utils/parse-table-data';
import {
  GetPlacementsResponse,
  PlacementPlanningListItem,
} from '../../../../services/placement-service.types';

type Props = {
  appliedFilters: PlacementsFilters;
  setFilters: ReturnType<typeof usePlacementsPage>['setFilters'];
  filtersOpen: boolean;
  toggleFilters: () => void;
  selectedPlacementIds: string[];
  onSelectionChange: (selectedIds: string[]) => void;
};

export const HQPlacementsDataProvider = (props: Props) => {
  const placementsQuery = useGetPlacements(props.appliedFilters);

  if (placementsQuery.status !== 'success') {
    return <RenderUnsettledUI data={placementsQuery} />;
  }
  return <PlacementsTable infiniteQueryResult={placementsQuery} {...props} />;
};

export const PlacementsTable = ({
  appliedFilters,
  setFilters,
  filtersOpen,
  toggleFilters,
  selectedPlacementIds,
  onSelectionChange,
  infiniteQueryResult,
}: Props & {
  infiniteQueryResult: InfiniteQueryObserverSuccessResult<GetPlacementsResponse, unknown>;
}) => {
  const flatData = useMemo(
    () => infiniteQueryResult.data.pages.flatMap((page) => page.items),
    [infiniteQueryResult.data]
  );
  const columns = useMemo(() => getPlacementColumnDefinition(), []);
  const tableData = useMemo(() => getParsedTableData(flatData), [infiniteQueryResult.data]);
  const selectedRows = getSelectedRows(flatData, selectedPlacementIds);
  const { fetchIfBottomScrolled } = useOnScrollDown({
    infiniteQueryResult: infiniteQueryResult,
    flatData: flatData,
  });

  const handleSelectionChange = useCallback(
    (rows: PlacementsColumnDefinition[]) => {
      const selectedIds = rows.map((row) => row.placementId);
      onSelectionChange(selectedIds);
    },
    [onSelectionChange]
  );

  return (
    <>
      <Box maxHeight={'100%'}>
        <Table<PlacementsColumnDefinition>
          stickyHeader={true}
          columns={columns}
          data={tableData}
          tableStyle={{ tableLayout: 'fixed' }}
          selectAllMode="VISIBLE"
          onSelectionChange={handleSelectionChange}
          selectable={true}
          selectedRows={selectedRows}
          onScroll={fetchIfBottomScrolled}
        />
      </Box>
      <PlacementsFiltersDataProvider
        appliedFilters={appliedFilters}
        handleFiltersChange={setFilters}
        isOpen={filtersOpen}
        toggleFilters={toggleFilters}
      />
    </>
  );
};

const getSelectedRows = (
  flatData: PlacementPlanningListItem[],
  selectedShiftIds: string[]
): Record<string, boolean> => {
  const result: Record<string, boolean> = {};
  flatData.forEach((row, index) => {
    result[index.toString()] = selectedShiftIds.includes(row.placementId);
  });
  return result;
};
