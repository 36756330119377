import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { LwFormAutocomplete } from 'redesign';
import { useDebounce } from 'shared/hooks/use-debounce';
import { Grid } from '@mui/material';
import { useGeocode } from 'ogp/hooks/queryHooks/locations/use-geocode';
import { LocationFormContext } from './location.types';

type Props = {
  required?: boolean;
  disabled: boolean;
  address?: string | null;
};

export const LocationSelect = (props: Props) => {
  const { watch, control, setValue } = useFormContext<LocationFormContext>();
  const watchedAddress = watch('location')?.address ?? '';
  const [locationInputValue, setLocationInputValue] = useState(watchedAddress);
  const debouncedInput = useDebounce(locationInputValue, 300);
  const geoQuery = useGeocode(debouncedInput, debouncedInput !== watchedAddress);
  const { data, isFetching } = geoQuery;

  return (
    <Grid item xs={12}>
      <LwFormAutocomplete
        name="location"
        label="Locatie"
        control={control}
        rules={{ required: props.required ? 'Voer een locatie in' : undefined }}
        disableClearable={true}
        disabled={props.disabled}
        getOptionKey={(option) => option.hereId ?? ''}
        getOptionLabel={(option) => option.address}
        onChange={(_event, value) => {
          if (value) {
            setValue('location', value);
          }
        }}
        isOptionEqualToValue={(option, value) => option.hereId === value.hereId}
        inputValue={locationInputValue}
        onInputChange={(_event, value) => {
          setLocationInputValue(value);
        }}
        options={data ?? []}
        isLoading={isFetching}
      />
    </Grid>
  );
};
