import { useQuery } from 'react-query';
import { JobTypeListItem } from '@types';
import { QUERY_KEYS } from 'api/query-keys';
import { jobTypeService } from '../../../services';

export const useGetJobTypes = (
  branchOfficeId?: string,
  companyId?: string,
  enabled: boolean = true
) => {
  const queryFn = async () => await jobTypeService.getJobTypes({ companyId, branchOfficeId });

  return useQuery<Promise<JobTypeListItem[]>, Error, JobTypeListItem[]>(
    QUERY_KEYS.jobTypesList(companyId, branchOfficeId),
    queryFn,
    { enabled }
  );
};
