import { LevelTableCellProps } from 'redesign';
import {
  GetShiftPlanningShiftsResponse,
  ShiftPlanningApplicationResponse,
  ShiftPlanningShiftResponse,
} from 'hq/services/shift-planning-service.types';
import { DateTime } from 'luxon';
import { InfiniteQueryObserverSuccessResult } from 'react-query';

export const INITIAL_PAGE = 0;

export type HqShiftPlanningColumnDefinition = ShiftPlanningShift & {
  id: string;
  action: string;
};

export type HqShiftPlanningApplicationColumnDefinition = ShiftPlanningApplicationShift & {
  id: string;
  action: string;
};

export type HqShiftPlanningApplicationCellProp<
  T extends keyof HqShiftPlanningApplicationColumnDefinition,
> = LevelTableCellProps<
  HqShiftPlanningColumnDefinition,
  HqShiftPlanningApplicationColumnDefinition[T],
  HqShiftPlanningTableOptions
>;

export type HqShiftPlanningCellProp<T extends keyof HqShiftPlanningColumnDefinition> =
  LevelTableCellProps<
    HqShiftPlanningColumnDefinition,
    HqShiftPlanningColumnDefinition[T],
    HqShiftPlanningTableOptions
  >;

type ShiftPlanningShift = Omit<ShiftPlanningShiftResponse, 'dateTime'> & {
  dateTime: {
    fromDateTime: DateTime;
    toDateTime: DateTime;
  };
};

type ShiftPlanningApplicationShift = Omit<ShiftPlanningApplicationResponse, 'dateTime'> & {
  dateTime: {
    fromDateTime: DateTime;
    toDateTime: DateTime;
  };
};

export type HqShiftPlanningTableOptions = {
  onShowDetail: (shiftId: string) => void;
};

export type ShiftPlanningTableProps = {
  shiftsQuery: InfiniteQueryObserverSuccessResult<GetShiftPlanningShiftsResponse, unknown>;
  onSelectionChange: (ids: string[]) => void;
  selectedShiftIds: string[];
};
