import { useFormContext } from 'react-hook-form';
import { LwFormSelect } from 'redesign';
import { ListSubheader, MenuItem } from '@mui/material';
import { getRequiredMessage } from './shared';
import { JobTypeFormContext, Props } from './job-type.types';

export const JobTypeSelect = (props: Props) => {
  const { control } = useFormContext<JobTypeFormContext>();
  const formName = 'jobType';
  const label = 'Functiegroep';
  const requiredMessage = getRequiredMessage(label);

  return (
    <LwFormSelect
      control={control}
      name={formName}
      rules={{ required: props.required ? requiredMessage : undefined }}
      label={label}
      options={props.jobGroups.map((group) => ({
        label: group.name,
        value: group.id,
        options: group.jobType.map((jobType) => ({ label: jobType.name, value: jobType.id })),
      }))}
      disabled={props.disabled}
      renderOption={(jg) => {
        return [
          <ListSubheader key={jg.value} value={jg.value}>
            <em>{jg.label}</em>
          </ListSubheader>,
          jg.options.map((jt) => (
            <MenuItem key={jt.value} value={jt.value}>
              {jt.label}
            </MenuItem>
          )),
        ];
      }}
    />
  );
};
