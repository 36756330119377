import { useFormContext } from 'react-hook-form';
import { FormSwitch } from 'redesign';
import { getRequiredMessage } from './shared';
import { IsFlexpoolOnlyFormContext, Props } from './is-flexpool-only.types';

export const FlexpoolOnlySwitch = ({ disabled, required }: Props) => {
  const { control } = useFormContext<IsFlexpoolOnlyFormContext>();
  const formName = 'isFlexPoolOnly';
  const label = 'Enkel binnen de flexpool';
  const requiredMessage = getRequiredMessage(label);
  return (
    <FormSwitch
      name={formName}
      control={control}
      label={label}
      disabled={disabled}
      rules={required ? { required: requiredMessage } : undefined}
    />
  );
};
