import { Drawer } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FilterWrapper } from 'shared/components/filters/filter-wrapper';
import { FilterEmploymentType } from 'shared/components/filters/inputs/employment-type/filter-employment-type';
import { FilterFilledStatus } from 'shared/components/filters/inputs/filled-status/filter-filled-status';
import { FilterJobTypes } from 'shared/components/filters/inputs/job-types/filter-job-types';
import { JobType } from 'shared/components/filters/inputs/job-types/filter-job-types.types';
import { FilterPartners } from 'shared/components/filters/inputs/partners/filter-partners';
import { PartnersFilterData } from 'shared/components/filters/inputs/partners/filter-partners.types';
import { OgpBusinessUnitsData } from 'ogp/services/ogp-shared-service.types';
import { FilterBusinessUnits } from 'shared/components/filters/inputs/business-units/filter-business-units';
import { OgpFilterApplicantStatus } from 'shared/components/forms/inputs/has-applicants-filter/has-applicants-filter';
import { PlanningFilters, ShiftPlanningFiltersFormType } from './planning-filter.types';
import { getShiftPlanningFiltersDefaultValues } from './get-default-values';
import { usePlanningParamsContext } from '../../../providers/planning-params-provider';

export type PlanningFilterData = {
  businessUnits: OgpBusinessUnitsData;
  jobTypes: JobType[];
  partners: PartnersFilterData[];
};
type PlanningFilterProps = {
  isOpen: boolean;
  toggleFilters: () => void;
  filterData: PlanningFilterData;
  appliedFilters: PlanningFilters;
  handleFiltersChange: ReturnType<typeof usePlanningParamsContext>['setFilters'];
};
export const PlanningFilter = ({
  isOpen,
  toggleFilters,
  filterData,
  appliedFilters,
  handleFiltersChange,
}: PlanningFilterProps) => {
  const defaultFilterValues = useMemo(() => {
    return getShiftPlanningFiltersDefaultValues(appliedFilters, filterData);
  }, [appliedFilters, filterData]);
  const form = useForm<ShiftPlanningFiltersFormType>({ defaultValues: defaultFilterValues });

  const handleClear = useCallback(() => {
    form.reset({
      businessUnitCheckboxValue: false,
      branchOffice: null,
      department: null,
      allowApplicantsFilter: false,
      filledStatusCheckboxValue: false,
      filledStatus: null,
      partnersCheckboxValue: false,
      partner: null,
      jobTypeCheckboxValue: false,
      jobType: [],
      employmentTypeCheckboxValue: false,
      isFreelance: false,
      isTempWork: false,
    });
  }, [defaultFilterValues, form]);

  const handleCancel = () => {
    toggleFilters();
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();

      form.handleSubmit((data) => {
        const dataToSubmit = {
          branchOfficeId: data.businessUnitCheckboxValue ? data.branchOffice?.value : undefined,
          departmentId: data.businessUnitCheckboxValue ? data.department?.value : undefined,
          branchOffice: data.businessUnitCheckboxValue ? data.branchOffice?.value : undefined,
          hasApplicants: data.allowApplicantsFilter ? data.hasApplicants : undefined,
          filledStatus:
            data.filledStatusCheckboxValue && data.filledStatus !== null
              ? data.filledStatus
              : undefined,
          jobType:
            data.jobTypeCheckboxValue && data.jobType?.length
              ? data.jobType.map((jobTypeSingle) => jobTypeSingle.id)
              : undefined,
          partner: data.partnersCheckboxValue ? data.partner?.value : undefined,
          isFreelance: data.employmentTypeCheckboxValue ? data.isFreelance : undefined,
          isTempWork: data.employmentTypeCheckboxValue ? data.isTempWork : undefined,
        };

        handleFiltersChange(dataToSubmit);
        toggleFilters();
      })();
    },
    [form, handleFiltersChange, toggleFilters]
  );
  return (
    <Drawer anchor="right" open={isOpen} onClose={handleCancel}>
      <FormProvider {...form}>
        <FilterWrapper
          filterName="Filter"
          onCancel={handleCancel}
          onClear={handleClear}
          onSubmit={handleSubmit}
        >
          <FilterBusinessUnits branchOffices={filterData.businessUnits.branchOffices ?? []} />
          {filterData.partners ? <FilterPartners partners={filterData.partners} /> : null}
          <OgpFilterApplicantStatus />
          <FilterFilledStatus />
          <FilterJobTypes jobTypes={filterData.jobTypes} />
          <FilterEmploymentType />
        </FilterWrapper>
      </FormProvider>
    </Drawer>
  );
};
