import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { shiftService } from 'ogp/services';
import { QUERY_KEYS } from 'api/query-keys';

export const useOgpArchiveShift = (shiftId: string = '', options?: UseMutationOptions) => {
  const mutateFn = async () => await shiftService.archiveShift(shiftId);
  const queryClient = useQueryClient();

  return useMutation(mutateFn, {
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries(QUERY_KEYS.shifts());

      options?.onSuccess && options.onSuccess(...args);
    },
  });
};
