import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { useGetUser } from 'ogp/hooks/queryHooks/users/use-get-user';
import { routes } from 'Routes';
import { useArchiveUser, useCompanyRelativePath } from 'ogp/hooks';
import { AuthenticatedElement } from 'hq/components/common/authenticated-element';
import { BranchOfficeRole, HqRole, TakeDefined } from '@types';
import { PolicyGroups } from 'shared/utils/policy-controller';
import { RenderUnsettledUI } from 'shared/components';
import {
  AcknowledgeButton,
  DeleteButton,
  PrimaryButton,
  useDialog,
} from 'ogp/providers/dialog-provider';
import { Header, LwButton, LwCheckbox, Page, Toolbox } from 'redesign';
import { useAlert, userAlerts } from 'shared/components/alerts';
import { invariant } from 'shared/utils/utils';
import { IconCancel, IconEdit, IconMail, IconTrash } from 'assets/img';
import { useResendTemporaryCredentials } from 'ogp/hooks/queryHooks/users/use-resend-credentials';

const UserDetailDataProvider = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'User id is missing');
  const userQuery = useGetUser(id);

  if (userQuery.status !== 'success') {
    return <RenderUnsettledUI data={userQuery} />;
  }

  return <UserDetail userData={userQuery.data} id={id} />;
};

const UserDetail = ({
  userData,
  id,
}: {
  userData: TakeDefined<ReturnType<typeof useGetUser>['data']>;
  id: string;
}) => {
  const generatePath = useCompanyRelativePath();
  const navigate = useNavigate();
  const { alertSuccess, alertError } = useAlert();
  const { mutate } = useResendTemporaryCredentials();
  const archiveUser = useArchiveUser({
    onSuccess: () => {
      closeDialog();
      alertSuccess(userAlerts.success.deleted);
      navigate(generatePath(routes.Users));
    },
    onError: (e) => alertError(e),
    onSettled: () => {
      enableDialog();
    },
  });

  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();

  const handleDelete = () => {
    openDialog(
      {
        title: 'Weet je zeker dat je deze medewerker wilt verwijderen?',
        secondaryButton: (props) => (
          <PrimaryButton iconColorMode="fill" startIcon={<IconCancel />} {...props}>
            Annuleer
          </PrimaryButton>
        ),
        primaryButton: (props) => <DeleteButton {...props} />,
      },
      () => {
        disableDialog();
        archiveUser.mutate(id);
      }
    );
  };

  const handleForbiddenDelete = () => {
    openDialog(
      {
        title: 'Oeps, dit kan niet!',
        content:
          'Deze medewerker kan niet verwijderd worden omdat hij/zij de laatste admin rol bezit',
        secondaryButton: (props) => <AcknowledgeButton {...props} />,
        primaryButton: null,
      },
      () => {}
    );
  };

  const resendTempCredentials = () => {
    mutate(userData.email, {
      onSuccess: () => alertSuccess(userAlerts.success.resendTempCredentials),
      onError: (e) => alertError(e),
    });
  };

  const statistics: React.ComponentProps<typeof Header>['labelGroups'] = [
    {
      title: 'Company role',
      description: userData.roles.companyRole ?? '-',
    },
    {
      title: 'Functie',
      description: userData.position,
    },
    {
      title: 'Email',
      description: userData.email,
    },
    {
      title: 'Telefoonnummer',
      description: userData.phone,
    },
    {
      title: 'Platform toegang telefoon',
      description: userData.accessPhone ?? '-',
    },
  ];

  const roles = [BranchOfficeRole.MANAGER, BranchOfficeRole.PLANNER, BranchOfficeRole.SPECTATOR];

  return (
    <Page
      header={
        <Header
          titleText={`${userData.firstName} ${userData.lastName}`}
          subheaderText="Gebruikersdetails"
          labelGroups={statistics}
        />
      }
      toolbox={
        <Toolbox>
          <LwButton to={generatePath(routes.Users)} color="secondary">
            Terug
          </LwButton>
          <LwButton
            iconColorMode="fill"
            to={generatePath(routes.UsersEdit, { id })}
            color="secondary"
            startIcon={<IconEdit />}
          >
            Gebruiker aanpassen
          </LwButton>
          <AuthenticatedElement roles={HqRole.SUPERADMIN} policyGroup={PolicyGroups.HQ}>
            <LwButton
              color="secondary"
              startIcon={<IconTrash />}
              onClick={userData.isArchivable ? handleDelete : handleForbiddenDelete}
            >
              Verwijderen
            </LwButton>
          </AuthenticatedElement>
          <AuthenticatedElement roles={HqRole.ADMIN} policyGroup={PolicyGroups.HQ}>
            <LwButton color="secondary" startIcon={<IconMail />} onClick={resendTempCredentials}>
              Verzend uitnodigingsmail
            </LwButton>
          </AuthenticatedElement>
        </Toolbox>
      }
    >
      <Grid item container xs={12} md={12} lg={8}>
        <Box width="100%">
          <Box display="flex" flexDirection="row" paddingBottom={1}>
            <Box flex="3">
              <Typography variant="subtitle1">Vestiging rollen</Typography>
            </Box>
            {roles.map((role) => {
              return (
                <Box key={role} flex="1" justifyContent="center" display="flex">
                  <Typography variant="subtitle1">{role}</Typography>
                </Box>
              );
            })}
          </Box>
          {userData.roles.departmentRoles.map(({ branchOffice, departments }) => (
            <Box display="flex" flexDirection="row" key={branchOffice.id} marginTop={4}>
              <Box flex="3">
                <Typography variant="subtitle1">{branchOffice.name}</Typography>
                {departments.map((dpt) => (
                  <Box display="flex" flexDirection="row" key={dpt.id} mt={4} ml={2}>
                    <Box flex="3" alignSelf="center">
                      <Typography variant="subtitle2">{dpt.name}</Typography>
                    </Box>
                    {roles.map((role) => (
                      <Box key={role} flex="1" justifyContent="center" display="flex">
                        <LwCheckbox title={role} checked={dpt.role === role} disabled={true} />
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Grid>
    </Page>
  );
};

export { UserDetailDataProvider };
