import { QUERY_KEYS } from 'api/query-keys';
import { flexWorkerService } from 'hq/services';
import { useMutation } from 'react-query';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';

type AddFlexWorkerToFlexPoolParams = {
  flexWorkerId: string;
  flexPoolIds: string[];
};

export const useAddFlexWorkerToFlexpool = () => {
  const invalidateQuery = useInvalidateQuery();
  const mutationFn = async ({ flexWorkerId, flexPoolIds }: AddFlexWorkerToFlexPoolParams) => {
    await flexWorkerService.addFlexWorkerToFlexpool(flexWorkerId, flexPoolIds);
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      invalidateQuery(QUERY_KEYS.workers());
      invalidateQuery(QUERY_KEYS.flexPools());
    },
  });
};
