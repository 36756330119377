import { RenderUnsettledUI } from 'shared/components';
import { useGetOgpJobTypes } from 'ogp/hooks/query/filters/use-get-job-types';
import { useGetOgpBusinessUnits } from 'ogp/hooks/query/filters/use-get-business-units';
import { useGetOgpPartners } from 'ogp/hooks/query/filters/use-get-ogp-partners';
import { PlanningFilter } from './filters';
import { usePlanningParamsContext } from '../../../providers/planning-params-provider';
type PlanningFilterDataProps = {
  toggleFilters: () => void;
};
export const PlanningFilterDataProvider = ({ toggleFilters }: PlanningFilterDataProps) => {
  const businessUnitsQuery = useGetOgpBusinessUnits();
  const jobTypeQuery = useGetOgpJobTypes();
  const partnersQuery = useGetOgpPartners();
  const { setFilters: handleFiltersChange, filters } = usePlanningParamsContext();

  if (businessUnitsQuery.status !== 'success') {
    return <RenderUnsettledUI data={businessUnitsQuery} />;
  }

  if (jobTypeQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobTypeQuery} />;
  }
  if (partnersQuery.status !== 'success') {
    return <RenderUnsettledUI data={partnersQuery} />;
  }

  return (
    <PlanningFilter
      isOpen={true}
      toggleFilters={toggleFilters}
      filterData={{
        businessUnits: businessUnitsQuery.data,
        jobTypes: jobTypeQuery.data.jobTypes,
        partners: partnersQuery.data,
      }}
      handleFiltersChange={handleFiltersChange}
      appliedFilters={filters}
    />
  );
};
