import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { userService } from 'hq/services';
import { QUERY_KEYS } from 'api/query-keys';

export const useDeleteHqUser = () => {
  const mutationFn = async (userId: string) => await userService.deleteUser(userId);

  const queryClient = useQueryClient();
  const mutationOptions: UseMutationOptions<unknown, unknown, string> = {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.users());
    },
  };

  return useMutation(mutationFn, mutationOptions);
};
