import { QUERY_KEYS } from 'api/query-keys';
import { adminSettingsService } from 'hq/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { FeatureFlags } from 'shared/services/mappers/mappers';

type FeatureFlagMutationOptions = UseMutationOptions<unknown, unknown, Partial<FeatureFlags>>;

export const useUpdateFeatureFlags = () => {
  const mutationFn = async (featureFlags: Partial<FeatureFlags>) =>
    await adminSettingsService.updateFeatureFlags(featureFlags);
  const queryClient = useQueryClient();
  const mutationOptions: FeatureFlagMutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.system());
    },
  };

  return useMutation(mutationFn, mutationOptions);
};
