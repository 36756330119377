import {
  inputBaseClasses,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  styled,
} from '@mui/material';
import arrowDown from 'assets/img/icons/redesign/arrow-down.svg';

type Action = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
};

type ActionSelectProps = {
  actions: Action[];
};

export const ActionSelect = ({ actions }: ActionSelectProps) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const actionIndex = event.target.value as number;
    if (!actions[actionIndex]?.disabled) {
      actions[actionIndex]?.onClick();
    }
  };

  return (
    <StyledSelect
      value=""
      inputProps={{ role: 'textbox' }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      data-testid="action-select"
      onChange={handleChange}
      renderValue={() => (
        <MenuItem
          disabled
          value=""
          sx={{
            color: 'black !important',
            opacity: '1 !important',
            maxHeight: '1.4375em',
            width: '100%',
            paddingRight: '50px !important',
          }}
        >
          Kies een actie
        </MenuItem>
      )}
      displayEmpty
    >
      {actions.map((action, index) => (
        <MenuItem key={index} value={index} disabled={action.disabled}>
          {action.label}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)<SelectProps>(({ theme }) => ({
  [`&.${inputBaseClasses.root}`]: {
    border: `2px solid ${theme.palette.lwSecondary[40]}`,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    [`&.${inputBaseClasses.focused}`]: {
      borderColor: theme.palette.lwPrimary[100],
      boxShadow: `0 0 0 4px ${theme.palette.lwSecondary[20]}`,
    },
    '&:hover': {
      borderColor: theme.palette.lwPrimary[100],
    },
    '& .MuiSelect-icon': {
      width: '24px',
      height: '24px',
      color: 'transparent',
      backgroundImage: `url("${arrowDown}")`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
}));
