import { useMemo } from 'react';
import { Table } from 'redesign';
import {
  FailedBulkCancelPlacementsColumnDefinition,
  FailedBulkCancelPlacementsProps,
} from './failed-bulk-cancel-placements-table.types';
import { getFailedBulkCancelPlacementsColumnDefinition } from './get-failed-bulk-cancel-placements-table-column-definition';
import { getFailedBulkCancelPlacementsTableData } from './get-failed-bulk-cancel-placements-table-data';

export const FailedBulkCancelPlacementsTable = ({ items }: FailedBulkCancelPlacementsProps) => {
  const columns = useMemo(() => getFailedBulkCancelPlacementsColumnDefinition(), []);
  const tableData = useMemo(() => getFailedBulkCancelPlacementsTableData(items), [items]);

  return (
    <Table<FailedBulkCancelPlacementsColumnDefinition>
      stickyHeader={true}
      columns={columns}
      data={tableData}
      tableStyle={{ tableLayout: 'fixed' }}
      data-testid="failed-bulk-cancel-placements-table"
    />
  );
};
