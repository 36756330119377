import { Box, styled, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormCheckbox } from 'redesign';

type EmploymentTypeCheckboxesFilterFormContext = {
  isFreelancer: boolean;
  isTempWorker: boolean;
};

export const EmploymentTypeCheckboxesFilter = () => {
  const form = useFormContext<EmploymentTypeCheckboxesFilterFormContext>();

  return (
    <Box>
      <Box>
        <Typography variant="body1">Dienstverband</Typography>
        <StyledHelperText hasError={form.formState.errors.isFreelancer?.message !== undefined}>
          Selecteer er minstens een
        </StyledHelperText>
      </Box>
      <Box>
        {[
          { name: 'isFreelancer' as const, label: 'Zelfstandige' },
          { name: 'isTempWorker' as const, label: 'Uitzendkracht' },
        ].map((item) => (
          <FormCheckbox
            label={item.label}
            key={item.name}
            name={item.name}
            onChange={(val) => {
              if (val) {
                form.clearErrors('isFreelancer');
              }
            }}
            control={form.control}
          />
        ))}
      </Box>
    </Box>
  );
};

const StyledHelperText = styled(Box)<{ hasError: boolean }>(({ theme, hasError }) => ({
  textDecorationLine: hasError ? 'underline' : 'none',
  color: hasError ? theme.palette.lwDanger[100] : 'inherit',
  fontSize: '12px',
}));
