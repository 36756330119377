import { ListSubheader, MenuItem } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { LwFormSelect } from 'redesign';

export const DepartmentFormElement = ({
  options,
  disabled,
}: {
  options: React.ComponentProps<typeof LwFormSelect>['options'];
  disabled?: React.ComponentProps<typeof LwFormSelect>['disabled'];
}) => {
  const { control } = useFormContext<{ departmentId: string }>();
  return (
    <LwFormSelect
      renderOption={(opt) => [
        <ListSubheader key={opt.value} value="">
          <em>{opt.label}</em>
        </ListSubheader>,
        opt.options.map((dpt) => (
          <MenuItem key={dpt.value} value={dpt.value}>
            {dpt.label}
          </MenuItem>
        )),
      ]}
      name="departmentId"
      label="Afdeling"
      defaultLabel="Selecteer een afdeling"
      options={options}
      disabled={disabled}
      control={control}
      rules={{ required: 'Selecteer een afdeling' }}
      data-testid="department-select"
    />
  );
};
