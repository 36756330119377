import { Navigate, Route, Routes } from 'react-router-dom';
import { CalendarPlanning } from './content/calendar/providers/calendar-planning';
import { TablePlanning } from './content/table/table-planning';

export const PlanningRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="calendar" replace />} />
      <Route path="calendar" element={<CalendarPlanning />} />
      <Route path="table" element={<TablePlanning />} />
    </Routes>
  );
};
