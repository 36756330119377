import { ProviderFactory } from 'shared/providers/provider-factory';

type BulkActionFailure<T extends string> = {
  id: T;
  error: string;
  [key: string]: string;
};

export type BulkActionContext<T extends string> = {
  setMutationResult: (result: any) => void;
  selectedIds: T[];
  failure: BulkActionFailure<T>[];
  fulfilled: T[];
};

const [Provider, useBulkActionProvider] = ProviderFactory<BulkActionContext<string>>(
  'BulkActionContext can only be accessed within BulkActionProvider'
);

type BulkActionProviderProps<T extends string> = {
  children: React.ReactNode;
} & BulkActionContext<T>;

export const BulkActionProvider = <T extends string>({
  children,
  setMutationResult,
  selectedIds,
  failure,
  fulfilled,
}: BulkActionProviderProps<T>) => {
  return (
    <Provider value={{ setMutationResult, selectedIds, failure, fulfilled }}>{children}</Provider>
  );
};

export const useBulkActionResultContext = () => {
  const bulkActionContext = useBulkActionProvider();
  return bulkActionContext;
};
