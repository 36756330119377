import { useQuery } from 'react-query';
import { CACHE_TIME_LONG, STALE_TIME_LONG } from 'shared/react-query/query-defaults';
import { useSiteDetection } from 'shared/hooks';
import { QUERY_KEYS } from 'api/query-keys';
import { partnerUserService } from '../services';
import { GetPartnerUserInfoResponse } from '../services/partner-user-service.types';

export const useGetPartnerUserInfo = () => {
  const { isPartner } = useSiteDetection();
  const queryFn = async () => await partnerUserService.getUserInfo();

  return useQuery<Promise<GetPartnerUserInfoResponse>, Error, GetPartnerUserInfoResponse>(
    QUERY_KEYS.partnerUserInfo(),
    queryFn,
    {
      cacheTime: CACHE_TIME_LONG,
      staleTime: STALE_TIME_LONG,
      enabled: isPartner,
    }
  );
};
