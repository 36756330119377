import { QUERY_KEYS } from 'api/query-keys';
import { billingEntityService } from 'ogp/services';
import { useQuery } from 'react-query';

const useGetBillingEntitiesListing = () => {
  const query = useQuery(
    QUERY_KEYS.billingEntitiesListing(),
    async () => await billingEntityService.getBillingEntitiesListing()
  );

  return query;
};

export { useGetBillingEntitiesListing };
