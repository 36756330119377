import { branchOfficeService } from 'hq/services';
import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS } from 'api/query-keys';
import { BranchOfficeCreatePayload } from '../../../../../../../../../../../services/branch-office-service.types';

export const useCreateBranchOffice = () => {
  const mutationFn = async (branchOffice: BranchOfficeCreatePayload) =>
    await branchOfficeService.createBranchOffice(branchOffice);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.branchOffices());
    },
  });
};
