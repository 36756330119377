import { useFormContext } from 'react-hook-form';
import { useWizardContext } from 'redesign';
import { BulkFormButtons } from 'shared/components/planner/common/bulk-form-buttons';
import { BulkShiftEditForm } from '../bulk-shift-edit-form/bulk-shift-edit-form';

type BulkShiftActionFormProps = {
  isSubmitting: boolean;
};

export const BulkShiftActionFormButtons = ({ isSubmitting }: BulkShiftActionFormProps) => {
  const { reset, formState } = useFormContext<BulkShiftEditForm>();
  const { close } = useWizardContext();
  return (
    <BulkFormButtons
      isSubmitting={isSubmitting}
      submitLabel="Indienen"
      onCancel={close}
      onReset={() => reset(formState.defaultValues)}
    />
  );
};
