import { shiftService as hqShiftService } from 'hq/services';
import { shiftService as ogpShiftService } from 'ogp/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useSiteDetection } from 'shared/hooks';
import { QUERY_KEYS } from 'api/query-keys';

type OpenShiftToPartnersMutationParams = {
  shiftId: string;
  partnerIds: string[];
};

type ShiftPartnerMutationOptions = UseMutationOptions<
  unknown,
  unknown,
  { shiftId: string; partnerIds: string[] }
>;

export const useOpenShiftToPartners = (shiftId: string, options?: ShiftPartnerMutationOptions) => {
  const { isHQ } = useSiteDetection();
  const mutationFn = isHQ
    ? async (params: OpenShiftToPartnersMutationParams) =>
        await hqShiftService.openShiftToPartners(params)
    : async (params: OpenShiftToPartnersMutationParams) =>
        await ogpShiftService.openShiftToPartners(params);

  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options ?? {};
  const mutationOptions: ShiftPartnerMutationOptions = {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(QUERY_KEYS.shifts());
      queryClient.invalidateQueries(QUERY_KEYS.partners());
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  };

  return useMutation(mutationFn, mutationOptions);
};
