import { PolicyGroups } from 'shared/utils/policy-controller';
import { BranchOfficeRole, CompanyRole } from '@types';

/**
 * This exists only because the routing system we have doesn't work well with different layouts provided to different routes.
 * TODO sort this out and refactor routing in a way:
 *  - we have routes hierarchy in place
 *  - we can provide different layouts to different routes
 */
const paths = {
  Unauthorized: {
    path: 'unauthorized',
    exact: true,
  },
  FlexPools: {
    path: 'planning/flexPools',
    exact: true,
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: [BranchOfficeRole.SPECTATOR, BranchOfficeRole.PLANNER],
    queryParams: {
      FLEXPOOL_ID: 'flexPoolID',
    },
  },
  FlexPoolCreate: {
    path: 'planning/flexPools/create',
    fallbackPath: 'planning/flexPools/',
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: [BranchOfficeRole.PLANNER, BranchOfficeRole.MANAGER],
  },
  FlexPoolEdit: {
    path: 'planning/flexPools/edit',
    fallbackPath: 'planning/flexPools/',
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: [BranchOfficeRole.PLANNER, BranchOfficeRole.MANAGER],
    queryParams: {
      FLEXPOOL_ID: 'flexPoolID',
    },
  },
  Jobs: {
    path: 'planning/jobs',
    exact: true,
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.MANAGER,
  },
  JobsEdit: {
    path: 'planning/jobs/:id/edit',
    fallbackPath: 'planning/jobs',
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.MANAGER,
  },
  JobsCreate: {
    path: 'planning/jobs/create',
    fallbackPath: 'planning/jobs',
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.MANAGER,
  },
  JobsDuplicate: {
    path: 'planning/jobs/:id/duplicate',
    fallbackPath: 'planning/jobs',
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.MANAGER,
  },
  JobsDetail: {
    path: 'planning/jobs/:id',
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: [BranchOfficeRole.PLANNER],
  },
  ShiftPlanning: {
    path: 'planning/shifts/*',
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: [BranchOfficeRole.SPECTATOR, BranchOfficeRole.PLANNER],
    fallbackPath: 'unauthorized',
  },
  ShiftsEdit: {
    path: 'planning/shifts/:id/edit',
    fallbackPath: 'planning/shifts',
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.PLANNER,
  },
  ShiftsDuplicate: {
    path: 'planning/shifts/:id/duplicate',
    fallbackPath: 'planning/shifts',
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.PLANNER,
  },
  ShiftsMultiply: {
    path: 'planning/shifts/:id/multiply',
    fallbackPath: 'planning/shifts',
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.PLANNER,
  },
  ShiftsCreate: {
    path: 'planning/shifts/create',
    fallbackPath: 'planning/shifts',
    exact: true,
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.PLANNER,
  },
  Company: {
    path: 'management/company',
    exact: true,
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.MANAGER,
  },
  CompanyEdit: {
    path: 'management/company/:id/edit',
    exact: true,
    policyGroup: PolicyGroups.COMPANY,
    roles: CompanyRole.ADMIN,
  },
  BranchOffices: {
    path: 'management/branch-offices',
    exact: true,
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.MANAGER,
  },
  BranchOfficesEdit: {
    path: 'management/branch-offices/:id/edit',
    fallbackPath: 'management/branch-offices',
    policyGroup: PolicyGroups.COMPANY,
    roles: CompanyRole.ADMIN,
  },
  BranchOfficesDuplicate: {
    path: 'management/branch-offices/:id/duplicate',
    fallbackPath: 'management/branch-offices',
    policyGroup: PolicyGroups.COMPANY,
    roles: CompanyRole.ADMIN,
  },
  BranchOfficesCreate: {
    path: 'management/branch-offices/create',
    fallbackPath: 'management/branch-offices',
    exact: true,
    policyGroup: PolicyGroups.COMPANY,
    roles: CompanyRole.ADMIN,
  },
  BranchOfficesDetail: {
    path: 'management/branch-offices/:id',
    fallbackPath: 'management/branch-offices',
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.MANAGER,
  },
  Users: {
    exact: true,
    path: 'management/users',
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.MANAGER,
  },
  UsersEdit: {
    path: 'management/users/:id/edit',
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.MANAGER,
  },
  UsersCreate: {
    exact: true,
    path: 'management/users/create',
    fallbackPath: 'management/users',
    policyGroup: PolicyGroups.COMPANY,
    roles: CompanyRole.ADMIN,
  },
  UsersDetail: {
    path: 'management/users/:id',
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.MANAGER,
  },
  BillingEntitiesCreate: {
    path: 'management/company/billing-entities/create',
    fallbackPath: 'management/company',
    policyGroup: PolicyGroups.COMPANY,
    roles: CompanyRole.ADMIN,
  },
  BillingEntitiesDetail: {
    path: 'management/company/billing-entities/:id',
    fallbackPath: 'management/company',
    policyGroup: PolicyGroups.COMPANY,
    roles: CompanyRole.ADMIN,
  },
  BillingEntitiesDuplicate: {
    path: 'management/company/billing-entities/:id/duplicate',
    fallbackPath: 'management/company',
    policyGroup: PolicyGroups.COMPANY,
    roles: CompanyRole.ADMIN,
  },
  BillingEntitiesEdit: {
    path: 'management/company/billing-entities/:id/edit',
    fallbackPath: 'management/company',
    policyGroup: PolicyGroups.COMPANY,
    roles: CompanyRole.ADMIN,
  },
  CheckoutsInbox: {
    path: 'checkout/inbox',
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.PLANNER,
    queryParams: {
      FILTER_TYPE: 'filterByPrio',
      DEPARTMENT_IDS: 'departmentIds',
      FLEXWORKER_IDS: 'flexWorkerIds',
      BRANCH_OFFICE_ID: 'branchOfficeId',
      FROM_DATE: 'fromDate',
      TO_DATE: 'toDate',
      JOB_IDS: 'jobIds',
      SORT_KEY: 'sort',
      SORT_ORDER: 'order',
    },
  },
  CheckoutsArchive: {
    path: 'checkout/archive',
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.PLANNER,
  },
  Projects: {
    path: 'planning/projects',
    exact: true,
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.PLANNER,
  },
  ProjectsEdit: {
    path: 'planning/projects/:id/edit',
    fallbackPath: 'planning/projects',
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.PLANNER,
  },
  ProjectsCreate: {
    path: 'planning/projects/create',
    fallbackPath: 'planning/projects',
    exact: true,
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.PLANNER,
  },
  ProjectsDuplicate: {
    path: 'planning/projects/:id/duplicate',
    fallbackPath: 'planning/projects',
    exact: true,
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.PLANNER,
  },
  Departments: {
    path: 'management/departments',
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.MANAGER,
  },
  DepartmentDetail: {
    path: 'management/departments/:id',
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: BranchOfficeRole.MANAGER,
  },
  DepartmentEdit: {
    path: 'management/departments/:id/edit',
    fallbackPath: 'management/departments',
    policyGroup: PolicyGroups.COMPANY,
    roles: CompanyRole.ADMIN,
  },
  DepartmentCreate: {
    path: 'management/departments/create',
    fallbackPath: 'management/departments',
    exact: true,
    policyGroup: PolicyGroups.COMPANY,
    roles: CompanyRole.ADMIN,
  },
  DepartmentDuplicate: {
    path: 'management/departments/:id/duplicate',
    fallbackPath: 'management/departments',
    policyGroup: PolicyGroups.COMPANY,
    roles: CompanyRole.ADMIN,
  },
  Legal: {
    path: 'management/legal',
    exact: true,
    policyGroup: PolicyGroups.DEPARTMENT,
    roles: 'SPECTATOR',
  },
} as const;

export { paths };
