import { QUERY_KEYS } from 'api/query-keys';
import { jobService } from 'ogp/services';
import { jobCreateDataResponse2JobCreateDataModel } from 'ogp/services/mappers/mappers';
import { useQuery } from 'react-query';
import { CACHE_TIME_LONG, STALE_TIME_LONG } from 'shared/react-query/query-defaults';

export const useGetJobCreateData = () => {
  const queryFn = async () => await jobService.getJobCreateData();

  return useQuery(QUERY_KEYS.getJobCreateData(), {
    queryFn,
    cacheTime: CACHE_TIME_LONG,
    staleTime: STALE_TIME_LONG,
    select: jobCreateDataResponse2JobCreateDataModel,
  });
};
