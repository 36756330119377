import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { styled } from '@mui/material';
import { useAlert } from 'shared/components/alerts';
import { useWizardContext } from 'redesign';
import { useBulkArchiveShift } from 'hq/components/views/planner/shifts/hooks/use-bulk-archive-shift';
import { useBulkActionResultContext } from 'shared/providers/bulk-action-provider';
import { useSiteDetection } from 'shared/hooks';
import { BulkShiftArchiveFormInputs } from './bulk-shift-archive-form-inputs';
import { BulkShiftActionFormButtons } from '../shared/bulk-shift-action-form-buttons';

export type BulkShiftArchiveForm = {
  archiveShiftsWithPlacements: boolean;
  archiveShiftsWithPayout: boolean;
};

export const BulkShiftForm = () => {
  const formMethods = useForm<BulkShiftArchiveForm>({
    defaultValues: {
      archiveShiftsWithPlacements: false,
      archiveShiftsWithPayout: false,
    },
  });
  const { alertError } = useAlert();
  const { site } = useSiteDetection();
  const { nextStep } = useWizardContext();
  const { selectedIds, setMutationResult } = useBulkActionResultContext();
  const { mutate, status } = useBulkArchiveShift(site);

  const onSubmit = useCallback(
    (data: BulkShiftArchiveForm) => {
      formMethods.clearErrors('root');
      mutate(
        {
          ...data,
          shiftIds: selectedIds,
        },
        {
          onSuccess: (data) => {
            setMutationResult(data);
            nextStep();
          },
          onError: (error) => {
            alertError(error);
          },
        }
      );
    },
    [formMethods.setError, formMethods.clearErrors, mutate, selectedIds]
  );

  return (
    <FormProvider {...formMethods}>
      <StyledForm onSubmit={formMethods.handleSubmit(onSubmit)}>
        <BulkShiftArchiveFormInputs />
        <BulkShiftActionFormButtons
          isSubmitting={status === 'loading' || formMethods.formState.isSubmitting}
        />
      </StyledForm>
    </FormProvider>
  );
};

const StyledForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  width: '600px',
}));
