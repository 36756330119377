import { routePrefix } from 'ogp/settings';
import { useCallback } from 'react';
import { generatePath } from 'react-router-dom';
import { getQueryParameters } from 'shared/hooks/query-hooks/relative-path-utils';
import { useGetOGPUserInfo } from './queryHooks/users/use-get-ogp-user-info';
import { RouteDefinition } from '../../Routes';

export const companyRelativePrefix = `${routePrefix}/:companyHash/`;

export const useCompanyRelativePath = () => {
  const userQuery = useGetOGPUserInfo();

  // To revisit LW1-19735
  return useCallback(
    (
      route: RouteDefinition,
      params?: { [key: string]: string | undefined },
      queryParams?: { [key: string]: string | undefined },
      subroute?: string
    ) => {
      const companyHash = userQuery.data?.userInfo.company.slug;

      if (!companyHash) {
        return route.fallbackPath ? `/${route.fallbackPath}` : '/error';
      }

      let basePath = generatePath(`${companyRelativePrefix}${route.path}`, {
        ...params,
        companyHash,
      });

      if (subroute) {
        basePath = `${basePath}/${subroute}`;
      }

      return basePath + getQueryParameters(queryParams);
    },
    [userQuery.data]
  );
};
