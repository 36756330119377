import { useMutation, useQueryClient } from 'react-query';
import { branchOfficeService } from 'hq/services';
import { QUERY_KEYS } from 'api/query-keys';
import { PartnerRelationFormData } from '../partner-relations/partner-relations.types';

export const useCreatePartnerRelation = (partnerId: string) => {
  const mutationFn = async (partnerRelation: PartnerRelationFormData) =>
    await branchOfficeService.createPartnerRelation({
      ...partnerRelation,
      forwardingRulePercentage: Number(partnerRelation.forwardingRulePercentage),
      partnerId,
      jobTypeIds: partnerRelation.jobTypes.map((jobType) => jobType.id),
    });
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEYS.partners()),
  });
};
