import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { partnerService } from 'hq/services';
import { QUERY_KEYS } from 'api/query-keys';
import { PartnerData } from './use-create-partner.types';

type PartnerMutationOptions = UseMutationOptions<unknown, unknown, PartnerData>;

export const useCreatePartner = (options?: PartnerMutationOptions) => {
  const mutationFn = async (partner: PartnerData) => await partnerService.createPartner(partner);
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options ?? {};
  const mutationOptions: PartnerMutationOptions = {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(QUERY_KEYS.partners());
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  };

  return useMutation(mutationFn, mutationOptions);
};
