import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { shiftAlerts, useAlert } from 'shared/components/alerts';
import {
  AcknowledgeButton,
  PrimaryButton,
  SecondaryButton,
  useDialog,
} from 'ogp/providers/dialog-provider';
import { IconApproved, IconCross, IconTrash } from 'assets/img';
import { ContextMenuItem } from 'redesign';
import { DateTime } from 'luxon';
import { useBulkAcceptApplicationsDialog } from 'shared/components/shift-detail-modal/components/use-bulk-accept-shift-applications-dialog/use-bulk-accept-shift-applications-dialog';
import { useHqAcceptShiftApplication } from 'shared/components/shift-detail-modal/query-hooks/hq/use-hq-accept-shift-application';
import { useHqBulkAcceptShiftApplications } from 'shared/components/shift-detail-modal/query-hooks/hq/use-hq-bulk-accept-shift-applications';
import { useHqDenyShiftApplication } from 'shared/components/shift-detail-modal/query-hooks/hq/use-hq-deny-shift-application';
import { ApplicantDetail } from 'shared/components/shift-detail-modal/components/tabs/applicants/applicant-table.types';
import { QUERY_KEYS } from 'api/query-keys';

const useHqGetApplicantActions = () => {
  const { openDialog, closeDialog, disableDialog, enableDialog } = useDialog();
  const { alertSuccess, alertError } = useAlert();
  const { open: openBulkAcceptShiftApplications, Element: BulkAcceptShiftApplicationsDialog } =
    useBulkAcceptApplicationsDialog(useHqBulkAcceptShiftApplications);
  const invalidateQuery = useInvalidateQuery();
  const denyShiftApplication = useHqDenyShiftApplication();
  const acceptShiftApplication = useHqAcceptShiftApplication();

  const handleAcceptShiftApplication = ({ shiftId, workerId, applicationId }: ApplicantDetail) => {
    openDialog(
      {
        title: 'De aanmelding mag geaccepteerd worden?',
      },
      () => {
        disableDialog();
        acceptShiftApplication.mutate(
          {
            shiftId: shiftId,
            flexWorkerId: workerId,
            shiftApplicationId: applicationId,
          },
          {
            onSuccess: () => {
              invalidateQuery(QUERY_KEYS.shift(shiftId));
              closeDialog();
              alertSuccess(shiftAlerts.success.subscribed);
            },
            onError: (e) => alertError(e),
            onSettled: () => {
              enableDialog();
            },
          }
        );
      }
    );
  };

  const handleDenyShiftApplication = (
    shiftApplicationId: string,
    flexWorkerId: string,
    shiftId: string
  ) => {
    openDialog(
      {
        title: 'Weet je zeker dat je deze aanmelding wilt afwijzen?',
        secondaryButton: (props) => (
          <PrimaryButton startIcon={<IconCross />} {...props}>
            Annuleer
          </PrimaryButton>
        ),
        primaryButton: (props) => (
          <SecondaryButton startIcon={<IconApproved />} iconColorMode="stroke" {...props}>
            Wijs af
          </SecondaryButton>
        ),
      },
      () => {
        disableDialog();
        denyShiftApplication.mutate(
          { shiftApplicationId, flexWorkerId },
          {
            onSuccess: () => {
              invalidateQuery(QUERY_KEYS.shift(shiftId));
              closeDialog();
              alertSuccess(shiftAlerts.success.unsubscribed);
            },
            onError: (e) => alertError(e),
            onSettled: () => {
              enableDialog();
            },
          }
        );
      }
    );
  };

  const handleAccept = (application: ApplicantDetail, shiftStartDateTime: string) => {
    const isShiftInThePast = DateTime.fromISO(shiftStartDateTime).toUTC() < DateTime.now().toUTC();
    if (isShiftInThePast) {
      openDialog(
        {
          title:
            'Oops! Deze shift bevindt zich in het verleden, accepteren van aanmeldingen is niet mogelijk.',
          secondaryButton: (props) => <AcknowledgeButton {...props} />,
          primaryButton: null,
        },
        () => {}
      );

      return;
    }

    if (application.allApplications.length === 1) {
      handleAcceptShiftApplication(application);
    } else {
      openBulkAcceptShiftApplications(
        { id: application.workerId, firstName: application.firstName },
        application.applicationId,
        application.allApplications
      );
    }
  };

  const getApplicantActions = (application: ApplicantDetail, shiftStartDateTime: string) => [
    <ContextMenuItem key="accept" onClick={() => handleAccept(application, shiftStartDateTime)}>
      <IconApproved /> Accepteren
    </ContextMenuItem>,
    <ContextMenuItem
      key="deny"
      onClick={() =>
        handleDenyShiftApplication(
          application.applicationId,
          application.workerId,
          application.shiftId
        )
      }
    >
      <IconTrash />
      Afwijzen
    </ContextMenuItem>,
  ];

  return { getApplicantActions, BulkAcceptShiftApplicationsDialog };
};
export { useHqGetApplicantActions };
