import { useQuery } from 'react-query';
import { flexPoolService } from 'ogp/services';
import { QUERY_KEYS } from 'api/query-keys';
import type { FlexWorkerItem } from '../flex-workers.types';

const useGetFlexPoolFlexWorkers = (flexPoolId: string, flexWorkerName?: string) => {
  const queryFn = async () =>
    await flexPoolService.getFlexPoolFlexWorkers(flexPoolId, flexWorkerName);

  const options = {
    enabled: flexPoolId.length > 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  };

  return useQuery<Promise<FlexWorkerItem[]>, Error, FlexWorkerItem[]>(
    QUERY_KEYS.flexPoolForFlexworker(flexPoolId, flexWorkerName),
    queryFn,
    options
  );
};

export { useGetFlexPoolFlexWorkers };
