import { useQuery } from 'react-query';
import { accreditationsService } from 'hq/services';
import { QUERY_KEYS } from 'api/query-keys';
import { HQCertificateWithFileUrl } from '../../../../services/accreditations-service.types';

const useGetCertificateNames = () => {
  const queryFn = async () => await accreditationsService.getCertificates();

  return useQuery<HQCertificateWithFileUrl[], Error>(
    QUERY_KEYS.accreditationsCertificateNames(),
    queryFn
  );
};

export { useGetCertificateNames };
