import { placementService as ogpPlacementService } from 'ogp/services';
import { placementService as hqPlacementService } from 'hq/services';
import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS } from 'api/query-keys';
import { SiteDetectionResult } from '../../../hooks';

type WorkApplicationMutationParams = {
  placementId: string;
  siteDetectionResult: SiteDetectionResult;
};

export const usePlacementTerminate = () => {
  const mutationFn = (params: WorkApplicationMutationParams) => getMutationFunction(params);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.projects());
    },
  });
};

const getMutationFunction = (params: WorkApplicationMutationParams) => {
  const { siteDetectionResult, placementId } = params;
  if (siteDetectionResult.isHQ) {
    return hqPlacementService.terminatePlacement(placementId);
  }
  if (siteDetectionResult.isOGP) {
    return ogpPlacementService.terminatePlacement(placementId);
  }

  throw new Error(
    `usePlacementTerminate: site "${siteDetectionResult.site}" is neither HQ nor OGP.`
  );
};
