import { flexPoolService } from 'ogp/services';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'api/query-keys';
import { GetFlexPoolDetailResponse } from '../../../services/flex-pool-service.types';

export const useGetFlexPoolDetail = (flexPoolId: string) => {
  const queryFn = async () => await flexPoolService.getFlexPoolDetail(flexPoolId);

  const options = { enabled: flexPoolId.length > 0 };

  return useQuery<GetFlexPoolDetailResponse['flexPoolDetail'], Error>(
    QUERY_KEYS.flexPoolWithFlexWorkers(flexPoolId),
    queryFn,
    options
  );
};
