import { useFormContext } from 'react-hook-form';
import { LwFormInput } from 'redesign';
import { getRequiredMessage } from './shared';
import { ClaFormContext, Props } from './cla.types';

export const ClaInput = (props: Props) => {
  const { control } = useFormContext<ClaFormContext>();
  const formName = 'cla';
  const label = 'Cao / beloningsregeling opdrachtgever';
  const requiredMessage = getRequiredMessage(label);

  return (
    <LwFormInput
      name={formName}
      control={control}
      label={label}
      disabled={props.disabled}
      rules={props.required ? { required: requiredMessage } : undefined}
    />
  );
};
