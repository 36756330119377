import { useFormContext } from 'react-hook-form';
import { LwFormSelect } from 'redesign';
import { BreakOption, InputSelectOption } from '../../../../../../../types';

type BreakMinutesFields = {
  breakMinutes: number;
};

export function breakOptionsSelectInputMapper(breakOptions: BreakOption[]): InputSelectOption[] {
  return breakOptions.map(({ label, minutes }) => ({
    value: minutes,
    label,
  }));
}

export const BreakMinutesInput = ({
  options,
  disabled,
  helperText,
  required,
}: {
  options: InputSelectOption[];
  disabled: React.ComponentProps<typeof LwFormSelect>['disabled'];
  helperText: React.ComponentProps<typeof LwFormSelect>['helperText'];
  required?: boolean;
}) => {
  const { control } = useFormContext<BreakMinutesFields>();
  return (
    <LwFormSelect
      name="breakMinutes"
      label="Pauze"
      defaultLabel="Selecteer de lengte van de pauze..."
      options={options}
      control={control}
      rules={
        required ? { required: 'Selecteer een pauze of geef aan dat er geen pauze is' } : undefined
      }
      disabled={disabled}
      data-testid="break-mins"
      helperText={helperText}
    />
  );
};
