import { Box, Divider } from '@mui/material';
import { useEffect } from 'react';
import { RenderUnsettledUI, SmallError, SmallLoader } from 'shared/components';
import { PartnerMessageDetailModalHead } from './message-detail-modal-header/message-detail-modal-header';
import { MessageDetailDescription } from './message-detail-description';
import { useGetPartnerMessageDetail } from '../hooks/use-get-inbox-message';
import { useMarkMessageAsRead } from '../hooks/use-mark-message-as-read';

type Props = {
  id: string;
  close: () => void;
};

export const MessageDetail = ({ id, close }: Props) => {
  const messageQuery = useGetPartnerMessageDetail(id);
  const mutation = useMarkMessageAsRead();

  useEffect(() => {
    if (messageQuery.status === 'success' && !messageQuery.data.isRead) {
      mutation.mutate({ messageId: id });
    }
  }, [messageQuery.status, messageQuery.data, id]);

  if (messageQuery.status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={messageQuery}
      />
    );
  }

  return (
    <Box
      p={8}
      height="100%"
      display="flex"
      flexDirection="column"
      data-testid="partner-message-modal"
    >
      <PartnerMessageDetailModalHead data={{ ...messageQuery.data, id }} close={close} />
      <Divider />
      <MessageDetailDescription data={{ ...messageQuery.data, id: id }} close={close} />
    </Box>
  );
};
