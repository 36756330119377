import { useQuery } from 'react-query';
import { workApplicationService as ogpWorkApplicationService } from 'ogp/services';
import { workApplicationService as hqWorkApplicationService } from 'hq/services';
import { QUERY_KEYS } from 'api/query-keys';
import { SiteDetectionResult, useSiteDetection } from '../../../../../../hooks';

export const useGetApplicants = (projectId: string) => {
  const siteDetectionResult = useSiteDetection();
  const queryFn = getQueryFunction(siteDetectionResult, projectId);
  return useQuery(QUERY_KEYS.workApplicationsForProject(projectId), queryFn);
};

const getQueryFunction = (siteDetectionResult: SiteDetectionResult, projectId: string) => {
  if (siteDetectionResult.isHQ) {
    return async () => await hqWorkApplicationService.workApplicationsForProject(projectId);
  }
  if (siteDetectionResult.isOGP) {
    return async () => await ogpWorkApplicationService.workApplicationsForProject(projectId);
  }

  throw new Error(`useGetApplicants: site "${siteDetectionResult.site}" is neither HQ nor OGP.`);
};
