import { versionService } from 'ogp/services';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'api/query-keys';
import { OgpVersionInfo } from './site-info.types';

const useGetApiVersionInfo = () => {
  async function queryFn(): Promise<OgpVersionInfo> {
    return await versionService.getVersion();
  }

  return useQuery<Promise<OgpVersionInfo>, Error, OgpVersionInfo>(QUERY_KEYS.version(), queryFn, {
    enabled: true,
  });
};

export { useGetApiVersionInfo };
