import { partnerPlacementService } from 'partner/shared/services';
import { GetPartnerPlacementsParams } from 'partner/shared/services/partner-placements-service.types';
import { useInfiniteQuery } from 'react-query';
import { DEFAULT_PAGE_SIZE } from 'shared/utils/constants';
import { QUERY_KEYS } from 'api/query-keys';
import { PlacementsFilters } from './use-placements-page.types';

const useGetPartnerPlacements = ({ fromDate, ...filters }: PlacementsFilters) => {
  const params: Omit<GetPartnerPlacementsParams, 'page'> = {
    fromDate: fromDate ? fromDate.toISO() : undefined,
    companyId: filters.company,
    branchOfficeId: filters.branchOffice,
    departmentId: filters.department,
    jobTypeIds: filters.jobType,
    workerId: filters.worker,
    isFreelance: filters.isFreelance,
    isTempWork: filters.isTempWork,
    pageSize: DEFAULT_PAGE_SIZE,
  };

  return useInfiniteQuery({
    queryKey: QUERY_KEYS.placementsWithFilters(params),
    queryFn: async ({ pageParam = 0 }) =>
      await partnerPlacementService.getPartnerPlacements({ ...params, page: pageParam }),
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};

export { useGetPartnerPlacements };
