import { HqApiService } from './hqApiService';
import { GetJobDetailsForWorkTemplateHQ, JobDetail } from './job-service.types';

export class JobService {
  constructor(private readonly hqApiService: HqApiService) {}

  public getJob = async (jobId: string): Promise<JobDetail> => {
    const response = await this.hqApiService.get<JobDetail>({
      path: `jobs/${jobId}`,
    });

    return response;
  };

  public getJobDetailsForWorkTemplate = async (
    jobId: string
  ): Promise<GetJobDetailsForWorkTemplateHQ> => {
    return await this.hqApiService.get<GetJobDetailsForWorkTemplateHQ>({
      path: `jobs/${jobId}/details-for-work-template`,
    });
  };
}
