import { DateTime } from 'luxon';
import { AutocompleteOption } from 'redesign';
import { getJobTypes, getPartner } from './utils';
import { ShiftPlanningFilters } from '../../table/hooks/use-shift-planning.types';
import {
  ShiftPlanningFiltersData,
  ShiftPlanningFiltersFormType,
} from '../shift-planning-filters.types';

const getShiftPlanningFiltersDefaultValues = (
  appliedFilters: ShiftPlanningFilters,
  filterData: ShiftPlanningFiltersData
): ShiftPlanningFiltersFormType => {
  const fromDate = appliedFilters.fromDate
    ? appliedFilters.fromDate.toFormat('yyyy-MM-dd')
    : DateTime.now().toFormat('yyyy-MM-dd');
  const result: ShiftPlanningFiltersFormType = {
    fromDate,
    company: null,
    branchOffice: null,
    department: null,
    jobType: null,
    partner: null,
    isFreelance: appliedFilters.isFreelance ?? false,
    isTempWork: appliedFilters.isTempWork ?? false,
    areFreelanceApplications: appliedFilters.areFreelanceApplications ?? false,
    areTempworkApplications: appliedFilters.areTempworkApplications ?? false,
    areNoApplications: appliedFilters.areNoApplications ?? false,
    filledStatusCheckboxValue: !!appliedFilters.filledStatus,
    jobTypeCheckboxValue: !!appliedFilters.jobType?.length,
    partnersCheckboxValue: !!appliedFilters.partner,
    filledStatus: appliedFilters.filledStatus ? appliedFilters.filledStatus : 'inProgress',
    businessUnitCheckboxValue: !!appliedFilters.company,
    fromDateCheckboxValue: !!appliedFilters.fromDate,
    employmentTypeCheckboxValue: !!appliedFilters.isFreelance || !!appliedFilters.isTempWork,
    applicationTypesCheckboxValue:
      !!appliedFilters.areFreelanceApplications ||
      !!appliedFilters.areTempworkApplications ||
      !!appliedFilters.areNoApplications,
  };

  if (appliedFilters.jobType?.length) {
    const jobTypes = getJobTypes(appliedFilters.jobType, filterData.jobTypes);
    if (jobTypes?.length) {
      result.jobType = jobTypes.map((jt) => ({ id: jt.value, name: jt.label }));
    }
  }

  if (appliedFilters.partner) {
    const partner = getPartner(appliedFilters, filterData.partners ?? []);
    if (partner) {
      result.partner = partner;
    }
  }

  if (!appliedFilters.company) {
    return result;
  }
  if (!filterData?.companies) {
    return result;
  }
  const companyFilter = filterData.companies.find((c) => c.companyId === appliedFilters.company);

  if (!companyFilter) {
    return result;
  }

  const company: AutocompleteOption = {
    value: companyFilter.companyId,
    label: companyFilter.companyName,
  };
  result.company = company;

  if (!appliedFilters.branchOffice) {
    return result;
  }

  const branchOfficeFilter = companyFilter.branchOffices.find(
    (bo) => bo.branchOfficeId === appliedFilters.branchOffice
  );

  if (!branchOfficeFilter) {
    return result;
  }

  const branchOffice: AutocompleteOption = {
    value: branchOfficeFilter.branchOfficeId,
    label: branchOfficeFilter.branchOfficeName,
  };
  result.branchOffice = branchOffice;

  if (!appliedFilters.department) {
    return result;
  }

  const departmentFilter = branchOfficeFilter.departments.find(
    (dept) => dept.departmentId === appliedFilters.department
  );

  if (!departmentFilter) {
    return result;
  }

  const department = {
    value: departmentFilter.departmentId,
    label: departmentFilter.departmentName,
  };

  result.department = department;

  return result;
};
export { getShiftPlanningFiltersDefaultValues };
