import React, { useCallback, useEffect, useMemo } from 'react';
import { Drawer } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { FilterEmploymentType } from 'shared/components/filters/inputs/employment-type/filter-employment-type';
import { FilterWrapper } from 'shared/components/filters/filter-wrapper';
import { FilterBusinessUnitsWithCompany } from 'shared/components/filters/inputs/business-units-with-company/filter-business-units-with-company';
import { FilterFromDate } from 'shared/components/filters/inputs/date/filter-from-date';
import { FilterJobTypes } from 'shared/components/filters/inputs/job-types/filter-job-types';
import { FilterStates } from 'shared/components/filters/inputs/placement-state/placement-state';
import { FlexWorkerSelectStateProps } from 'hq/components/views/flexWorkers/flex-worker-select';
import { FilterWorkersSelect } from 'shared/components/filters/inputs/flex-workers/filter-workers-all-select';
import { placementToSubmitMapper } from './map-placement-data-to-submit';
import { getPlacementsFiltersDefaultValues } from './get-placements-filter-default-values';
import { PlacementsFiltersDataProps, PlacementsFiltersFormType } from './placements-filters.types';
export const PlacementsFilters = ({
  appliedFilters,
  filterData,
  toggleFilters,
  handleFiltersChange,
  isOpen,
  flexWorkerOption,
  flexWorkerSearch,
  setFlexWorkerOption,
  setFlexWorkerSearch,
}: PlacementsFiltersDataProps & FlexWorkerSelectStateProps) => {
  const defaultFilterValues = useMemo(
    () => getPlacementsFiltersDefaultValues(appliedFilters, filterData, setFlexWorkerOption),
    [appliedFilters, filterData]
  );

  const form = useForm<PlacementsFiltersFormType>({
    defaultValues: { ...defaultFilterValues },
  });

  useEffect(() => {
    form.setValue('worker', flexWorkerOption);
  }, [flexWorkerOption]);
  const dateFilterMinDate = DateTime.now().toFormat('yyyy-MM-dd');

  const handleClear = useCallback(() => {
    form.reset({
      fromDate: defaultFilterValues.fromDate ? defaultFilterValues.fromDate : dateFilterMinDate,
      fromDateCheckboxValue: false,
      businessUnitCheckboxValue: false,
      company: null,
      branchOffice: null,
      department: null,
      jobTypeCheckboxValue: false,
      jobType: [],
      allowWorkersFilter: false,
      worker: null,
      employmentTypeCheckboxValue: false,
      isFreelance: false,
      isTempWork: false,
      state: [],
    });
    setFlexWorkerOption(null);
    setFlexWorkerSearch('');
  }, [defaultFilterValues, form]);

  const handleCancel = () => {
    toggleFilters();
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      form.handleSubmit((data) => {
        const dataToSubmit = placementToSubmitMapper(data);
        handleFiltersChange(dataToSubmit);
        toggleFilters();
      })();
    },
    [form, handleFiltersChange, toggleFilters]
  );

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleCancel}>
      <FormProvider {...form}>
        <FilterWrapper
          filterName="Filter"
          onCancel={handleCancel}
          onClear={handleClear}
          onSubmit={handleSubmit}
        >
          <FilterFromDate />
          <FilterBusinessUnitsWithCompany companies={filterData.companies} />
          <FilterJobTypes jobTypes={filterData.jobTypes} />
          <FilterWorkersSelect
            workers={filterData.workers}
            flexWorkerOption={flexWorkerOption}
            flexWorkerSearch={flexWorkerSearch}
            setFlexWorkerSearch={setFlexWorkerSearch}
            setFlexWorkerOption={setFlexWorkerOption}
          />
          <FilterEmploymentType />
          <FilterStates />
        </FilterWrapper>
      </FormProvider>
    </Drawer>
  );
};
