import { useFormContext } from 'react-hook-form';
import { LwFormNumberInput } from 'redesign';

type MaxClaimantsFields = {
  maxClaimants: number;
};

export const MaxClaimantsElement = ({
  disabled,
  required,
}: {
  disabled: boolean;
  required?: boolean;
}) => {
  const { control } = useFormContext<MaxClaimantsFields>();
  return (
    <LwFormNumberInput
      name="maxClaimants"
      label="Aantal plekken"
      control={control}
      rules={required ? { required: 'Vul gewenste aantal plekken in' } : undefined}
      step={1}
      min={1}
      max={100}
      data-testid="max-claimants"
      disabled={disabled}
    />
  );
};
