import { QUERY_KEYS } from 'api/query-keys';
import { partnerWorkerService } from 'partner/shared/services';
import { useMutation, useQueryClient } from 'react-query';

export const useDeletePartnerWorker = (workerId: string) => {
  const queryClient = useQueryClient();
  const mutationFn = async () => await partnerWorkerService.deletePartnerWorker(workerId);

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.workers());
    },
  });
};
