import { QUERY_KEYS } from 'api/query-keys';
import { branchOfficeService } from 'ogp/services';
import { useQuery } from 'react-query';
import { CACHE_TIME_LONG } from 'shared/react-query/query-defaults';
import { PrebuiltBlock } from 'redesign';
import { FirstParamOfFn } from '@types';

export const useGetBranchOfficesListing = (
  options: FirstParamOfFn<typeof branchOfficeService.getBranchOffices>
) => {
  const queryFn = async () => await branchOfficeService.getBranchOfficesListing(options);

  return useQuery<Promise<PrebuiltBlock[]>, Error, PrebuiltBlock[]>(
    QUERY_KEYS.branchOfficesListing(),
    queryFn,
    { cacheTime: CACHE_TIME_LONG }
  );
};
