import { FailedBulkCancelPlacementsColumnDefinition } from './failed-bulk-cancel-placements-table.types';

type RawFailedBulkCancelPlacementsData = {
  id: string;
  error: string;
}[];

export const getFailedBulkCancelPlacementsTableData = (
  data: RawFailedBulkCancelPlacementsData
): FailedBulkCancelPlacementsColumnDefinition[] => {
  return data.map((item) => ({
    placementId: item.id,
    reason: item.error,
  }));
};
