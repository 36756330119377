import { Grid, styled } from '@mui/material';
import { CalendarShiftItem } from './calendar-shift-item';

type CalendarDayColumnProps = {
  data: {
    applicants: number;
    claimants: number;
    jobName: string;
    flexPoolOnly: boolean;
    dateLabel: string;
    dateTime: string;
    endTime: string;
    id: string;
    maxClaimants: number;
    startTime: string;
    hasPartners: boolean;
    hasCanceledClaim: boolean;
  }[];
  dayCount: number;
};

export const CalendarDayColumn = ({ data, dayCount }: CalendarDayColumnProps) => {
  return (
    <ShiftWrapper item dayCount={dayCount}>
      {data.length ? (
        data.map((shift) => <CalendarShiftItem key={shift.id} shiftData={shift} />)
      ) : (
        <CalendarShiftItem shiftData={null} />
      )}
    </ShiftWrapper>
  );
};

const ShiftWrapper = styled(Grid, { shouldForwardProp: (prop) => prop !== 'dayCount' })<{
  dayCount: number;
}>(({ theme, dayCount }) => ({
  width: `calc(100% / ${dayCount})`,
  padding: theme.spacing(2),
  '&:last-child': {
    borderRight: 'none',
  },
}));
