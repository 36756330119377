import { useQuery } from 'react-query';
import { CACHE_TIME_LONG, STALE_TIME_LONG } from 'shared/react-query/query-defaults';
import { RoleMatrix } from 'hq/services/userService';
import { QUERY_KEYS } from 'api/query-keys';
import { userService } from '../../../../../services';

export const useRolesMatrix = () => {
  const queryFn = async () => await userService.getRolesMatrix();

  return useQuery<Promise<RoleMatrix[]>, Error, RoleMatrix[]>(QUERY_KEYS.rolesMatrix(), queryFn, {
    cacheTime: CACHE_TIME_LONG,
    staleTime: STALE_TIME_LONG,
  });
};
