import { Box, styled } from '@mui/material';
import { FileUpload, LazyImage } from 'redesign';
import { RenderUnsettledUI } from 'shared/components';
import { useGetPlatformFallbackImage } from '../hooks/use-get-platform-fallback-image';
import { useUploadPlatformFallbackImage } from '../hooks/use-upload-platform-fallback-image';

export const PlatformWorkImageTab = () => {
  const platformFallbackWorkImageQuery = useGetPlatformFallbackImage();
  const { mutate } = useUploadPlatformFallbackImage();

  if (platformFallbackWorkImageQuery.status !== 'success') {
    return <RenderUnsettledUI data={platformFallbackWorkImageQuery} />;
  }

  return (
    <Box minHeight={0}>
      <FileUpload onFilesChange={(files) => mutate({ file: files[0] })} />
      <FallbackWorkImage
        src={platformFallbackWorkImageQuery.data.imageUrl ?? ''}
        alt="placeholder"
      />
    </Box>
  );
};

const FallbackWorkImage = styled(LazyImage)`
  margin: auto 12px;
  max-width: 750px;
  width: 100%;
`;
