import { ShiftPlanningShiftResponse } from 'hq/services/shift-planning-service.types';

export const getSelectedRows = (
  flatData: ShiftPlanningShiftResponse[],
  selectedShiftIds: string[]
): Record<string, boolean> => {
  const result: Record<string, boolean> = {};
  flatData.forEach((row, index) => {
    result[index.toString()] = selectedShiftIds.includes(row.id);
  });
  return result;
};
