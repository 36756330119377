import { branchOfficeAlerts } from 'shared/components/alerts';
import { RenderUnsettledUI } from 'shared/components';
import { useParams } from 'react-router-dom';
import { DepartmentFormData } from 'ogp/services/department-service.types';
import { DepartmentCreatePage } from './department-create-page';
import { invariant } from '../../../../../../../shared/utils/utils';
import { useGetDepartmentAsForm } from '../../hooks';
import { useGetCompanyDetailAndJobGroups } from '../../../../../../hooks/queryHooks/companies/use-get-company-detail-and-job-groups';

export const DepartmentDuplicateDataProvider = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'Department ID must be provided in the URL');
  const companyQuery = useGetCompanyDetailAndJobGroups();
  const departmentQuery = useGetDepartmentAsForm(id);

  if (companyQuery.status !== 'success') {
    return <RenderUnsettledUI data={companyQuery} />;
  }

  if (departmentQuery.status !== 'success') {
    return <RenderUnsettledUI data={departmentQuery} />;
  }

  const defaultValues: DepartmentFormData = {
    branchOfficeId: departmentQuery.data.branchOfficeId,
    name: departmentQuery.data.name,
    legalPerson: departmentQuery.data.legalPerson,
    cocNumber: departmentQuery.data.cocNumber ?? '',
    companyNameForOVO: departmentQuery.data.companyNameForOVO,
    location: departmentQuery.data.location,
    locationIndicator: departmentQuery.data.locationIndicator,
    VAT: departmentQuery.data.VAT,
    costCenter: departmentQuery.data.costCenter,
    billingEntityId: departmentQuery.data.billingEntityId ?? '',
    departmentFlexPools: [],
  };

  return (
    <DepartmentCreatePage
      defaultValues={defaultValues}
      successMessage={branchOfficeAlerts.success.created}
      pageTitle="Nieuwe afdeling aanmaken"
    />
  );
};
