import { TableCell } from 'redesign';
import { Tooltip, Typography } from '@mui/material';
import { ApplicantCellProps } from './helpers/applicant-table.types';

export const HourlyRateCell = ({ value }: ApplicantCellProps<'hourlyRate'>) => {
  const tooltipTitle =
    value.workerEmploymentType === 'FREELANCER' ? "Tarief zzp'er" : 'Bruto uurloon';
  return (
    <TableCell>
      <Tooltip title={tooltipTitle}>
        <Typography style={value.hasDivergentRate ? { color: 'orange' } : undefined}>
          {value.hourlyRate
            ? value.hourlyRate.toLocaleString('nl-NL', {
                style: 'currency',
                currency: 'EUR',
              })
            : '-'}
        </Typography>
      </Tooltip>
    </TableCell>
  );
};
