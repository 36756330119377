import { QUERY_KEYS } from 'api/query-keys';
import { sharedShiftService } from 'api/services';
import { useQuery } from 'react-query';
import { Site } from 'shared/hooks';
import { CACHE_TIME_LONG, STALE_TIME_LONG } from 'shared/react-query/query-defaults';

export const useGetBreakOptions = (site: Site) => {
  return useQuery(QUERY_KEYS.breakOptions(), {
    queryFn: async () => await sharedShiftService.getBreakOptions(site),
    cacheTime: CACHE_TIME_LONG,
    staleTime: STALE_TIME_LONG,
  });
};
