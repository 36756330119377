import { accreditationsService } from 'hq/services';
import { DateTime } from 'luxon';
import { useMutation, useQueryClient } from 'react-query';
import { AccreditationFormRepresentation } from 'shared/utils/file-handling/file-handling-utils.types';
import { QUERY_KEYS } from 'api/query-keys';
import { AccreditationType } from '../../../../services/accreditations-service.types';

export type AddAndApproveAccreditationParams = {
  flexWorkerId: string;
  accreditations: AccreditationFormRepresentation[];
  startDate: DateTime | null;
  expirationDate: DateTime | null;
  accreditationType: AccreditationType;
};

export const useAddAndAcceptAccreditation = () => {
  const mutationFn = async ({
    flexWorkerId,
    accreditations,
    startDate,
    expirationDate,
    accreditationType,
  }: AddAndApproveAccreditationParams) => {
    return await accreditationsService.addAndAcceptAccreditation({
      flexWorkerId,
      accreditations,
      startDate,
      expirationDate,
      accreditationType,
    });
  };

  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.accreditations());
    },
  });
};
