import { QUERY_KEYS } from 'api/query-keys';
import { jobService } from 'ogp/services';
import { useInfiniteQuery } from 'react-query';
import { DEFAULT_PAGE_SIZE } from 'shared/utils/constants';

export const useGetJobsListing = (params: { departmentId?: string; search?: string }) => {
  return useInfiniteQuery({
    queryKey: QUERY_KEYS.jobListing(params),
    queryFn: async ({ pageParam = 0 }) => {
      return await jobService.getFilteredJobsListing({
        search: params.search,
        departmentId: params.departmentId,
        page: pageParam,
        size: DEFAULT_PAGE_SIZE,
      });
    },
    getNextPageParam: (params) => params.nextPage,
  });
};
