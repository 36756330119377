import { TableShiftPlanningListItem } from 'ogp/services/shift-service.types';

export const getSelectedRows = (
  flatData: TableShiftPlanningListItem[],
  selectedShiftIds: string[]
): Record<string, boolean> => {
  const shiftIdsMap: Record<string, boolean> = {};
  selectedShiftIds.forEach((i) => (shiftIdsMap[i] = true));

  const result: Record<string, boolean> = {};
  flatData.forEach((row, index) => {
    result[index] = !!shiftIdsMap[row.id];
  });

  return result;
};
