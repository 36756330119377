import { useTranslation } from 'react-i18next';
import {
  IconCertificate,
  IconCheckouts,
  IconCoin,
  IconCompanies,
  IconFlexPools,
  IconInfoRedesigned,
  IconPartner,
  IconProfilePlacementUser,
  IconProject,
  IconShifts,
  IconUser,
  IconUsers,
} from 'assets/img';
import { LogoutIcon, LogoutLabel } from './sidebar-logout';
import {
  getPlannerPlacementDefaultFilter,
  getPlannerProjectDefaultFilter,
  getPlannerShiftDefaultFilter,
} from './utils';
import { hqRoutes } from '../../Routes';

export const useSidebarItems = () => {
  const { t } = useTranslation('hq-screen-specific');

  return [
    {
      key: 'operations',
      name: t('sidebar.operations'),
      children: [
        {
          ...hqRoutes.FlexWorkers,
          key: 'operations',
          label: t('sidebar.operations'),
          icon: <IconUser />,
        },
        {
          ...hqRoutes.Accreditations,
          key: 'accreditations',
          label: t('sidebar.accreditations'),
          icon: <IconCertificate />,
        },
        {
          ...hqRoutes.BankAccount,
          key: 'bank-accounts',
          label: t('sidebar.bank_accounts'),
          icon: <IconCoin />,
        },
      ],
    },
    {
      key: 'sales',
      name: t('sidebar.sales'),
      children: [
        {
          ...hqRoutes.Companies,
          key: 'sales',
          label: t('sidebar.companies'),
          icon: <IconCompanies />,
        },
      ],
    },
    {
      key: 'planning',
      name: t('sidebar.planning'),
      children: [
        {
          ...hqRoutes.PlannerShift,
          path: `${hqRoutes.PlannerShift.path}${getPlannerShiftDefaultFilter()}`,
          key: 'shifts',
          label: t('sidebar.shifts'),
          icon: <IconShifts />,
        },
        {
          ...hqRoutes.Placements,
          path: `${hqRoutes.Placements.path}${getPlannerPlacementDefaultFilter()}`,
          key: 'placements',
          label: t('sidebar.placements'),
          icon: <IconProfilePlacementUser />,
        },
        {
          ...hqRoutes.PlannerProject,
          path: `${hqRoutes.PlannerProject.path}${getPlannerProjectDefaultFilter()}`,
          key: 'projects',
          label: t('sidebar.projects'),
          icon: <IconProject />,
        },
      ],
    },
    {
      key: 'partners',
      name: t('sidebar.partners'),
      children: [
        {
          ...hqRoutes.Partners,
          key: 'partners',
          label: t('sidebar.partners'),
          icon: <IconPartner />,
        },
      ],
    },
    {
      key: 'finance',
      name: t('sidebar.finance'),
      children: [
        {
          ...hqRoutes.Finance,
          key: 'finance',
          label: t('sidebar.finance'),
          icon: <IconCheckouts />,
        },
      ],
    },
    {
      key: 'admin',
      name: t('sidebar.admin'),
      children: [
        {
          ...hqRoutes.FeatureFlags,
          key: 'featureFlags',
          label: t('sidebar.feature_flags'),
          icon: <IconFlexPools />,
        },
        {
          ...hqRoutes.UploadPayments,
          key: 'payments',
          label: t('sidebar.upload_payments'),
          icon: <IconCoin />,
        },
        {
          ...hqRoutes.PlatformSettings,
          key: 'platformSettings',
          label: t('sidebar.platform_settings'),
          icon: <IconInfoRedesigned />,
        },
      ],
    },
    {
      key: 'settings',
      name: t('sidebar.settings'),
      children: [
        { ...hqRoutes.HQUsers, key: 'users', label: t('sidebar.users'), icon: <IconUsers /> },
      ],
    },
  ] as const;
};

export const useProfileInfo = () => {
  const { t } = useTranslation('hq-screen-specific');
  return {
    key: 'profile',
    name: t('sidebar.profile'),
    children: [
      {
        key: 'profile',
        label: t('sidebar.profile'),
        icon: <IconUser />,
      },
      {
        key: 'logout',
        label: <LogoutLabel />,
        icon: <LogoutIcon />,
      },
    ],
  };
};
