import { Box } from '@mui/material';
import { styled } from '@mui/styles';
import { DateTime } from 'luxon';
import { FormProvider, useForm } from 'react-hook-form';
import { DateInputs } from './date-inputs';
import { usePlanningParamsContext } from '../../../providers/planning-params-provider';

export type TableDateForm = {
  fromDate: string;
  toDate?: string | null;
};

export const TableDateControl = () => {
  const { filters } = usePlanningParamsContext();
  const methods = useForm<TableDateForm>({
    mode: 'onChange',
    defaultValues: {
      fromDate: filters.fromDate?.toISODate() ?? DateTime.now().toISODate(),
      toDate: filters.toDate?.toISODate(),
    },
  });

  return (
    <FormProvider {...methods}>
      <StyledBox>
        <DateInputs />
      </StyledBox>
    </FormProvider>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '30%',
  gap: theme.spacing(8),
}));
