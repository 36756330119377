import { projectService } from 'ogp/services';
import { useQuery } from 'react-query';
import { PrebuiltBlock } from 'redesign';
import { QUERY_KEYS } from 'api/query-keys';
import { ProjectGetParams } from '../../../../../services/project-service.types';

export const useGetProjectsPlanning = (params: ProjectGetParams) => {
  const queryFn = async () => await projectService.getProjects(params);

  return useQuery<Promise<PrebuiltBlock[]>, Error, PrebuiltBlock[]>(
    QUERY_KEYS.departmentProjects(JSON.stringify(params)),
    queryFn
  );
};
