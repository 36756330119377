import { Outlet } from 'react-router-dom';
import { Page } from 'redesign';
import { PlanningRoutes } from './planning-routes';
import { PlanningToolBox } from './toolbox/planning-toolbox';
import { PlanningParamsProvider } from './providers/planning-params-provider';
import { ShiftDetails } from './components/shift-details/shift-details';
import { PlanningProvider } from './providers/planning-actions-provider';

export const Planning = () => {
  return (
    <PlanningParamsProvider>
      <PlanningProvider>
        <Page header={<PlanningToolBox />}>
          <PlanningRoutes />
          <Outlet />
          <ShiftDetails />
        </Page>
      </PlanningProvider>
    </PlanningParamsProvider>
  );
};
