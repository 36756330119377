/* eslint-disable no-console */
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { Box, styled, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { FlexPoolOnlyIndicator } from 'shared/components/common/flexpool-only-indicator';
import { useMemo } from 'react';
import { CalendarShiftPartnersPopover } from './calendar-shift-partners-popover';
import { usePlanningParamsContext } from '../../../../providers/planning-params-provider';

type ShiftData = {
  shiftData: {
    applicants: number;
    claimants: number;
    jobName: string;
    flexPoolOnly: boolean;
    dateLabel: string;
    dateTime: string;
    endTime: string;
    id: string;
    maxClaimants: number;
    startTime: string;
    hasPartners: boolean;
    hasCanceledClaim: boolean;
  } | null;
};

const defaultCapacityBoxColor = '#F5FAFF';

export const CalendarShiftItem = ({ shiftData }: ShiftData) => {
  const theme = useTheme();
  const { setFilters } = usePlanningParamsContext();
  const colorData = useMemo(() => getColor({ shiftData }, theme), [shiftData, theme]);

  if (!shiftData) {
    return <EmptyShift item />;
  }
  const isHistorical = DateTime.fromISO(shiftData.dateTime) < DateTime.now();
  const ShiftWrapperComponent = isHistorical ? HistoricalShiftWrapper : RegularShiftWrapper;

  return (
    <ShiftWrapperComponent data-testid={`shift-${shiftData.id}`} item>
      <ShiftBox
        onClick={() => {
          setFilters({
            shiftId: shiftData.id,
          });
        }}
        backgroundColor={colorData.cardColor}
      >
        <ShiftJobTitleBox>
          <JobNameTypography variant="subtitle2" noWrap>
            {shiftData.jobName}
          </JobNameTypography>
        </ShiftJobTitleBox>

        <ShiftTimeBox>
          <ShiftTimeTypography variant="subtitle2">
            {shiftData.startTime} - {shiftData.endTime}
          </ShiftTimeTypography>
          <FlexPoolOnlyIndicator flexPoolOnly={shiftData.flexPoolOnly} />
        </ShiftTimeBox>
        <CapacityBox backgroundColor={colorData.capacityBoxColor}>
          <ClaimantsTypography variant="body2">
            <ClaimantsValue>{shiftData.claimants}</ClaimantsValue>
            {shiftData.hasCanceledClaim ? <RedDot component="span"></RedDot> : null}
            <MaxClaimants>
              {' / '}
              {shiftData.maxClaimants}
            </MaxClaimants>
          </ClaimantsTypography>
          <PartnersAndBadgeBox>
            <PartnersPopoverBox>
              {shiftData.hasPartners ? (
                <CalendarShiftPartnersPopover shiftId={shiftData.id} />
              ) : null}
            </PartnersPopoverBox>
            <BadgeBox backgroundColor={colorData.badgeColor}>
              <Typography variant="body2">{shiftData.applicants}</Typography>
            </BadgeBox>
          </PartnersAndBadgeBox>
        </CapacityBox>
      </ShiftBox>
    </ShiftWrapperComponent>
  );
};

const EmptyShift = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: 'transparent',
  height: theme.spacing(4),
}));

const RegularShiftWrapper = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  opacity: 1,
}));

const HistoricalShiftWrapper = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  opacity: 0.5,
}));

const ShiftBox = styled(Box)<{ backgroundColor: string }>(({ theme, backgroundColor }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor,
  padding: theme.spacing(2),
  width: '100%',
  height: '10rem',
  flexShrink: 0,
  borderRadius: '1rem',
  boxShadow:
    '0px 4px 6px 0px rgba(51, 51, 51, 0.03), 0px 0px 8px 0px rgba(51, 51, 51, 0.03), 0px 12px 16px 0px rgba(51, 51, 51, 0.08)',
  transition: '0.5s',
  '&:hover': {
    boxShadow: '0px 12px 16px rgba(51, 51, 51, 0.16), 0px 4px 6px rgba(51, 51, 51, 0.08)',
  },
}));

const CapacityBox = styled(Box)<{ backgroundColor: string }>(({ backgroundColor }) => ({
  backgroundColor,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  borderRadius: '0.75rem',
  padding: '1rem 0.75rem',
}));

const ShiftJobTitleBox = styled(Box)({
  padddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  display: 'flex',
  justifyContent: 'flex-start',
});

const ShiftTimeBox = styled(Box)({
  display: 'flex',
  height: '2rem',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  paddingBottom: '0.5rem',
  alignItems: 'center',
});

const JobNameTypography = styled(Typography)(({ theme }) => ({
  width: '100%',
  fontWeight: 600,
  fontSize: '14px',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),

  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
}));

const ShiftTimeTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '14px',
  letterSpacing: '.3px',
  gap: theme.spacing(2),
  whiteSpace: 'wrap',
  overflow: 'hidden',
}));

const ClaimantsTypography = styled(Typography)({
  fontWeight: 700,
  fontSize: '14px',
  display: 'flex',
});

const ClaimantsValue = styled(Box)(({ theme }) => ({
  color: theme.palette.lwBlack['100'],
}));

const MaxClaimants = styled(Box)(({ theme }) => ({
  color: theme.palette.lwBlack['60'],
}));

const BadgeBox = styled(Box)<{ backgroundColor: string }>(({ theme, backgroundColor }) => ({
  height: theme.spacing(6),
  width: theme.spacing(6),
  backgroundColor,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '1px',
  '& .MuiTypography-root': {
    color: 'white',
  },
}));

const PartnersPopoverBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '0px',
});
const PartnersAndBadgeBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
const RedDot = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  height: '6px',
  width: '6px',
  backgroundColor: theme.palette.lwDanger['100'],
  display: 'inline-block',
  verticalAlign: 'super',
}));

const getColor = ({ shiftData }: ShiftData, theme: Theme) => {
  if (shiftData) {
    if (DateTime.fromISO(shiftData.dateTime) < DateTime.now()) {
      return {
        cardColor: theme.palette.lwBlack['20'],
        capacityBoxColor: defaultCapacityBoxColor,
        badgeColor: theme.palette.lwBlack['60'],
      };
    }
    if (shiftData.claimants === shiftData.maxClaimants) {
      return {
        cardColor: theme.palette.lwWhite['100'],
        capacityBoxColor: theme.palette.lwSuccess['20'],
        badgeColor: theme.palette.lwSuccess['100'],
      };
    }
    if (shiftData.applicants === 0) {
      return {
        cardColor: theme.palette.lwWhite['100'],
        capacityBoxColor: defaultCapacityBoxColor,
        badgeColor: theme.palette.lwDanger['60'],
      };
    }
    if (shiftData.applicants > 0) {
      return {
        cardColor: theme.palette.lwWhite['100'],
        capacityBoxColor: theme.palette.lwPrimary['10'],
        badgeColor: theme.palette.lwPrimary['100'],
      };
    }
  }
  return {
    cardColor: theme.palette.lwWhite['100'],
    capacityBoxColor: defaultCapacityBoxColor,
    badgeColor: theme.palette.lwBlack['100'],
  };
};
