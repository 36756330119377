import { QUERY_KEYS } from 'api/query-keys';
import { flexPoolService } from 'ogp/services';
import { useMutation, useQueryClient } from 'react-query';

type DeleteFlexWorkerFromFlexPoolData = { flexPoolId: string; flexWorkerId: string };

export const useDeleteFlexWorkerFromFlexPool = () => {
  const queryClient = useQueryClient();
  const mutationFn = async (data: DeleteFlexWorkerFromFlexPoolData) =>
    await flexPoolService.deleteFlexWorkerFromFlexPool(data.flexPoolId, data.flexWorkerId);

  const options = {
    onSettled: async () => {
      queryClient.invalidateQueries(QUERY_KEYS.flexPools());
      queryClient.invalidateQueries(QUERY_KEYS.workers());
    },
  };
  return useMutation(mutationFn, options);
};
