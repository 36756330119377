import { Box, styled, Typography } from '@mui/material';
import { CandidateInfo } from 'shared/services/planning.types';
import { formatFullName } from 'shared/utils/formatting';

export const FormatCandidateNameComponent = ({
  candidates,
  fontColor = 'black',
}: {
  candidates: CandidateInfo[];
  fontColor?: string;
}) => {
  return (
    <NameContainer>
      {candidates.map((candidate) => (
        <Box key={`${candidate.lastName}-${candidate.firstName}`}>
          <Typography variant="body2" color={fontColor}>
            {formatFullName(candidate)}
          </Typography>
        </Box>
      ))}
    </NameContainer>
  );
};

const NameContainer = styled(Box)(({ theme }) => {
  const columnGap = theme.spacing(3);
  return {
    display: 'flex',
    flexWrap: 'wrap',
    gap: `${theme.spacing(1)} ${columnGap}`,
    '& > div': {
      minWidth: `calc(50% - ${columnGap} / 2)`,
    },
  };
});
