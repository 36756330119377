import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import { RenderUnsettledUI } from 'shared/components';
import { usePlanningParamsContext } from '../../../providers/planning-params-provider';
import { useGetCalendarShiftsPlanning } from '../hooks/use-get-shifts-planning';
import { CalendarShift } from '../calendar-shift/calendar-shift';

export const CalendarPlanning = () => {
  const { filters } = usePlanningParamsContext();
  const startOfWeek = filters.date?.startOf('week') || DateTime.now().startOf('week');
  const endOfWeek = startOfWeek.plus({ days: 6 });

  const planningQuery = useGetCalendarShiftsPlanning({
    branchOfficeId: filters.branchOfficeId,
    departmentId: filters.departmentId,
    fromDate: startOfWeek.toISODate(),
    toDate: endOfWeek.toISODate(),
    jobType: filters.jobType,
    hasApplicants: filters.hasApplicants,
    filledStatus: filters.filledStatus,
    partnerId: filters.partner,
    isFreelance: filters.isFreelance,
    isTempWork: filters.isTempWork,
  });

  if (planningQuery.status !== 'success') {
    return <RenderUnsettledUI data={planningQuery} />;
  }

  return (
    <Box width={'100%'} display={'flex'} flexDirection={'column'}>
      <CalendarShift data={planningQuery.data} />
    </Box>
  );
};
