import { ProjectCreateFormPage } from './form/project-create-form-page';

export const ProjectCreateDataProvider = () => {
  return (
    <ProjectCreateFormPage
      successMessage={`Project aangemaakt`}
      pageTitle={`Maak nieuw project aan`}
    />
  );
};
