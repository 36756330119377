import { AutocompleteOption } from 'redesign';
import { getJobTypes } from 'hq/components/views/planner/shifts/filters/utils/utils';
import { PartnersFilterData } from 'shared/components/filters/inputs/partners/filter-partners.types';
import { PlanningFilters, ShiftPlanningFiltersFormType } from './planning-filter.types';
import { PlanningFilterData } from './filters';

const getShiftPlanningFiltersDefaultValues = (
  appliedFilters: PlanningFilters,
  filterData: PlanningFilterData
): ShiftPlanningFiltersFormType => {
  const result: ShiftPlanningFiltersFormType = {
    branchOffice: null,
    department: null,
    jobType: null,
    partner: null,
    isFreelance: appliedFilters.isFreelance ?? false,
    isTempWork: appliedFilters.isTempWork ?? false,
    filledStatusCheckboxValue: !!appliedFilters.filledStatus,
    allowApplicantsFilter: appliedFilters.hasApplicants != null ? true : false,
    jobTypeCheckboxValue: !!appliedFilters.jobType?.length,
    partnersCheckboxValue: !!appliedFilters.partner,
    filledStatus: appliedFilters.filledStatus ? appliedFilters.filledStatus : 'inProgress',
    hasApplicants: appliedFilters.hasApplicants != null ? appliedFilters.hasApplicants : true,
    businessUnitCheckboxValue: !!appliedFilters.branchOfficeId,
    employmentTypeCheckboxValue: !!appliedFilters.isFreelance || !!appliedFilters.isTempWork,
  };

  if (appliedFilters.jobType?.length) {
    const jobTypes = getJobTypes(appliedFilters.jobType, filterData.jobTypes);
    if (jobTypes?.length) {
      result.jobType = jobTypes.map((jt) => ({ id: jt.value, name: jt.label }));
    }
  }

  if (appliedFilters.partner) {
    const partner = getPartner(appliedFilters, filterData.partners ?? []);
    if (partner) {
      result.partner = partner;
    }
  }

  if (!appliedFilters.branchOfficeId) {
    return result;
  }
  if (!filterData?.businessUnits) {
    return result;
  }

  const { businessUnits } = filterData;

  if (!businessUnits.branchOffices) {
    return result;
  }

  const branchOfficeFilter = businessUnits.branchOffices.find(
    (bo) => bo.branchOfficeId === appliedFilters.branchOfficeId
  );

  if (!branchOfficeFilter) {
    return result;
  }

  const branchOffice: AutocompleteOption = {
    value: branchOfficeFilter.branchOfficeId,
    label: branchOfficeFilter.branchOfficeName,
  };
  result.branchOffice = branchOffice;

  if (!appliedFilters.departmentId) {
    return result;
  }

  const departmentFilter = branchOfficeFilter.departments.find(
    (dept) => dept.departmentId === appliedFilters.departmentId
  );

  if (!departmentFilter) {
    return result;
  }

  const department = {
    value: departmentFilter.departmentId,
    label: departmentFilter.departmentName,
  };

  result.department = department;

  return result;
};

const getPartner = (appliedFilters: PlanningFilters, partners: PartnersFilterData[]) => {
  if (!appliedFilters) {
    return undefined;
  }
  const partner = partners.find((databasePartner) => {
    return databasePartner.id === appliedFilters.partner;
  });
  if (partner) {
    return { value: partner.id, label: partner.name };
  }
  return undefined;
};
export { getShiftPlanningFiltersDefaultValues };
