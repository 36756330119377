import { Box, styled } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CheckboxOption, FilterModal, FormCheckboxGroup, FormSelectGroup } from 'redesign';
import {
  FlexWorkersForProjectFilterOptions,
  FlexWorkersForProjectFilterOptionsStrings,
} from 'shared/components/flex-workers-for-work-table/flex-workers-for-work.types';

import { EmploymentType } from '@types';
import { EmploymentTypeCheckboxesFilter } from 'shared/components/planner/common/filters/employment-type-checkboxes-filter';
import {
  parseFlexWorkersForProjectFilters,
  stringifyFlexWorkersForProjectFilters,
} from '../../../../flex-workers-for-work-table/utils/filtering-utils';

interface Props {
  handleOpeneningFilters: (val: boolean) => void;
  isOpen: boolean;
  filterValues: FlexWorkersForProjectFilterOptions;
  setFilterValues: (props: FlexWorkersForProjectFilterOptions) => void;
  allowedEmploymentTypes: EmploymentType[];
}

export const CreatePlacementFlexWorkerFilters = (props: Props) => {
  const { handleOpeneningFilters, isOpen, setFilterValues, filterValues, allowedEmploymentTypes } =
    props;

  const filterValuesStrings = stringifyFlexWorkersForProjectFilters(filterValues);
  const defaultFilterValues: FlexWorkersForProjectFilterOptionsStrings = useMemo(
    () => ({
      flexPoolOnly: filterValuesStrings.flexPoolOnly ?? undefined,
      maxDistance: filterValuesStrings.maxDistance ?? '0',
      workedForDepartment: filterValuesStrings.workedForDepartment ?? undefined,
      hasCertificate: filterValuesStrings.hasCertificate ?? undefined,
      isFreelancer: filterValuesStrings.isFreelancer ?? undefined,
      isTempWorker: filterValuesStrings.isTempWorker ?? undefined,
    }),
    [filterValuesStrings]
  );

  const emptyFilterValues: FlexWorkersForProjectFilterOptionsStrings = useMemo(
    () => ({
      flexPoolOnly: '',
      maxDistance: '0',
      workedForDepartment: '',
      hasCertificate: '',
      isFreelance: '',
      isTempWork: '',
    }),
    []
  );

  const form = useForm<FlexWorkersForProjectFilterOptionsStrings>({
    defaultValues: { ...defaultFilterValues },
  });

  const handleCancel = useCallback(() => {
    form.reset(defaultFilterValues);
    handleOpeneningFilters(false);
  }, [form, handleOpeneningFilters]);

  const handleClear = useCallback(() => {
    form.reset(emptyFilterValues);
  }, [form]);

  const handleSubmit = () => {
    const res = form.getValues();
    if (allowedEmploymentTypes.length >= 2 && !res.isFreelancer && !res.isTempWorker) {
      // At least one employment type should be checked
      form.setError('isFreelancer', {
        type: 'minChecked',
        message: 'Selecteer er minstens een',
      });
      return;
    }
    setFilterValues(
      parseFlexWorkersForProjectFilters({
        flexPoolOnly: res.flexPoolOnly?.[0],
        maxDistance: res.maxDistance,
        workedForDepartment: res.workedForDepartment?.[0],
        hasCertificate: res.hasCertificate?.[0],
        isFreelancer: res.isFreelancer === undefined ? undefined : res.isFreelancer ? '1' : '0',
        isTempWorker: res.isTempWorker === undefined ? undefined : res.isTempWorker ? '1' : '0',
      })
    );
    handleOpeneningFilters(false);
  };

  const maxDistanceOptions = [
    { label: 'Alle afstanden', value: '0' },
    { label: '< 5km', value: '5' },
    { label: '< 10km', value: '10' },
    { label: '< 30km', value: '30' },
    { label: '< 50km', value: '50' },
    { label: '< 75km', value: '75' },
    { label: '< 100km', value: '100' },
  ];

  const flexpoolCheckboxes: CheckboxOption[] = [
    { label: 'Ja', value: '1' },
    { label: 'Nee', value: '0' },
  ];

  const workedForDepartment: CheckboxOption[] = [{ label: 'Ja', value: '1' }];

  const hasCertificateCheckboxes: CheckboxOption[] = [{ label: 'Ja', value: '1' }];

  return isOpen ? (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <FilterModal
          handleCancel={handleCancel}
          handleClear={handleClear}
          isOpen={isOpen}
          handleSubmit={handleSubmit}
        >
          <FilterGrid>
            <FormCheckboxGroup
              label="In de flexpool"
              name="flexPoolOnly"
              items={flexpoolCheckboxes}
              control={form.control}
              display="flex"
              maxChecked={1}
            />
            <FormSelectGroup
              label="Maximale afstand"
              name={'maxDistance'}
              control={form.control}
              options={maxDistanceOptions}
            />
            <FormCheckboxGroup
              label="Heeft eerder voor deze afdeling gewerkt"
              name="workedForDepartment"
              items={workedForDepartment}
              control={form.control}
              display="flex"
              maxChecked={1}
            />
            <FormCheckboxGroup
              label="In bezit van certificaten"
              name="hasCertificate"
              items={hasCertificateCheckboxes}
              control={form.control}
              display="flex"
              maxChecked={1}
            />
            {allowedEmploymentTypes.length >= 2 && <EmploymentTypeCheckboxesFilter />}
          </FilterGrid>
        </FilterModal>
      </form>
    </FormProvider>
  ) : null;
};

const FilterGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'max-content auto',
  gap: theme.spacing(7),
  alignItems: 'center',
}));
