import { useToggle } from 'shared/hooks';
import { Header, LwButton, Table, Toolbox, useOnScrollDown } from 'redesign';
import { FilterButton } from 'ogp/components/views/administration/shared';
import { DEFAULT_PAGE_SIZE } from 'shared/utils/constants';
import { RenderUnsettledUI } from 'shared/components';
import { Box } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useAlert } from 'shared/components/alerts';
import { partnerPlannerService } from 'partner/shared/services';
import { useGetShiftPlanningShifts } from './hooks/use-get-shift-planning-shifts';
import {
  INITIAL_PAGE,
  PartnerShiftPlanningColumnDefinition,
  PartnerShiftPlanningTableOptions,
} from './shift-planning-table.types';
import { getShiftPlanningColumnDefinition } from './utils/get-shift-planning-column-definition';
import { useShiftPlanningPage } from './hooks/use-shift-planning-page';
import { getPartnerPlanningShiftsTableData } from './utils/get-partner-shift-planning-table-data';
import { ShiftPlanningFiltersDataProvider } from '../filters/shift-planning-filters-data-provider';

const ShiftPlanningTable = ({
  openShiftDetail,
}: {
  openShiftDetail: (shiftId: string) => void;
}) => {
  const { filters: appliedFilters, setFilters, selectedFilterCount } = useShiftPlanningPage();
  const { alertSuccess, alertError } = useAlert();

  const shiftsQuery = useGetShiftPlanningShifts({
    fromDate: appliedFilters.fromDate ?? undefined,
    company: appliedFilters.company ?? undefined,
    branchOffice: appliedFilters.branchOffice ?? undefined,
    department: appliedFilters.department ?? undefined,
    jobType: appliedFilters.jobType ?? undefined,
    filledStatus: appliedFilters.filledStatus ?? undefined,
    workerId: appliedFilters.worker ?? undefined,
    isFreelance: appliedFilters.isFreelance ?? undefined,
    isTempWork: appliedFilters.isTempWork ?? undefined,
    pageParam: INITIAL_PAGE,
    pageSizeParam: DEFAULT_PAGE_SIZE,
  });
  const { data } = shiftsQuery;
  const flatData = useMemo(() => data?.pages?.flatMap((page) => page.items) ?? [], [data]);
  const tableData = useMemo(() => getPartnerPlanningShiftsTableData(flatData), [flatData]);
  const columns = useMemo(() => getShiftPlanningColumnDefinition(), []);
  const { fetchIfBottomScrolled } = useOnScrollDown({
    infiniteQueryResult: shiftsQuery,
    flatData: flatData,
  });

  const { on: filtersOpen, setOn, setOff } = useToggle();

  const exportData = useCallback(async () => {
    try {
      await partnerPlannerService.createPlanningExport({
        fromDate: appliedFilters.fromDate,
        companyId: appliedFilters.company,
        branchOfficeId: appliedFilters.branchOffice,
        departmentId: appliedFilters.department,
        jobTypeIds: appliedFilters.jobType,
        workerId: appliedFilters.worker,
        isFreelance: appliedFilters.isFreelance,
        isTempWork: appliedFilters.isTempWork,
      });
      alertSuccess(
        'Export succesvol aangevraagd. Binnen enkele minuten kunt u een email verwachten.'
      );
    } catch (error) {
      alertError(error);
    }
  }, [alertError, alertSuccess]);

  if (shiftsQuery.status !== 'success') {
    return <RenderUnsettledUI data={shiftsQuery} />;
  }
  return (
    <>
      <Header titleText="Shift Planning" />
      <Toolbox underline={false}>
        <Toolbox.PushRight>
          <LwButton color="secondary" onClick={exportData}>
            Export
          </LwButton>
          <FilterButton onClick={setOn} nrOfActiveFilters={selectedFilterCount} />
        </Toolbox.PushRight>
      </Toolbox>
      <Box style={{ height: '80%' }}>
        <Table<PartnerShiftPlanningColumnDefinition, PartnerShiftPlanningTableOptions>
          stickyHeader={true}
          columns={columns}
          data={tableData}
          tableStyle={{ tableLayout: 'fixed' }}
          onScroll={fetchIfBottomScrolled}
          data-testid="shift-planning-virtual-table"
          openShiftDetail={openShiftDetail}
        />
      </Box>
      {filtersOpen ? (
        <ShiftPlanningFiltersDataProvider
          appliedFilters={appliedFilters}
          handleFiltersChange={setFilters}
          onClose={setOff}
        />
      ) : null}
    </>
  );
};

export { ShiftPlanningTable };
