import { QUERY_KEYS } from 'api/query-keys';
import { shiftService } from 'ogp/services';
import { shiftPlanningListItemsResponse2PlanningModel } from 'ogp/services/mappers/mappers';
import { GetCalendarShiftPlanningListItemsParams } from 'ogp/services/shift-service.types';
import { useQuery } from 'react-query';
import { CACHE_TIME_MEDIUM } from 'shared/react-query/query-defaults';

export const useGetCalendarShiftsPlanning = (params: GetCalendarShiftPlanningListItemsParams) => {
  return useQuery(
    QUERY_KEYS.shiftPlanning(params),
    async () => await shiftService.getCalendarShiftPlanningListItems(params),
    { cacheTime: CACHE_TIME_MEDIUM, select: shiftPlanningListItemsResponse2PlanningModel }
  );
};
