import { useAlert } from 'shared/components/alerts';
import { partnerUserAlerts } from 'shared/components/alerts/alert-messages';
import { useNavigate, useParams } from 'react-router-dom';
import { hqRoutes } from 'hq/Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';

import { FormProvider, useForm } from 'react-hook-form';
import { Header, LwButton, Page, Toolbox, useFormButtons } from 'redesign';
import { useCallback } from 'react';
import { invariant } from 'shared/utils/utils';
import { RenderUnsettledUI } from 'shared/components';
import { useQueryClient } from 'react-query';
import { QUERY_KEYS } from 'api/query-keys';
import { PartnerUserForm } from './partner-user-form';
import { PartnerUserFormData } from './partner-user-form.types';
import { useGetPartnerUser } from '../hooks/use-get-user';
import { useEditPartnerUser } from '../hooks/use-edit-partner';

export const PartnerUserEdit = () => {
  const { partnerId } = useParams<'partnerId'>();
  const { userId } = useParams<'userId'>();
  invariant(partnerId);
  invariant(userId);
  const partnerUserQuery = useGetPartnerUser(userId);

  if (partnerUserQuery.status !== 'success') {
    return <RenderUnsettledUI data={partnerUserQuery} />;
  }

  return (
    <PartnerUserEditForm
      partnerId={partnerId}
      userId={userId}
      partnerUserQuery={partnerUserQuery}
    />
  );
};

const PartnerUserEditForm = ({
  partnerId,
  userId,
  partnerUserQuery,
}: {
  partnerId: string;
  userId: string;
  partnerUserQuery: any;
}) => {
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const { alertSuccess, alertError } = useAlert();
  const editPartnerUser = useEditPartnerUser(partnerId, userId);
  const queryClient = useQueryClient();
  const formMethods = useForm<PartnerUserFormData>({ defaultValues: partnerUserQuery.data });
  const { submitButtonProps, cancelButtonProps } = useFormButtons(editPartnerUser.status);

  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit((data) =>
      editPartnerUser.mutate(
        { ...data },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(QUERY_KEYS.partners());
            alertSuccess(partnerUserAlerts.success.created);
            navigate(generatePath(hqRoutes.PartnerUsers, { id: partnerId }));
          },
          onError: (e) => alertError(e),
        }
      )
    )();
  }, [
    formMethods,
    editPartnerUser,
    queryClient,
    userId,
    partnerId,
    alertSuccess,
    navigate,
    generatePath,
    alertError,
  ]);

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Partner gebruiker bewerken" />}
        toolbox={
          <Toolbox underline>
            <LwButton onClick={handleSubmit} iconColorMode="stroke" {...submitButtonProps}>
              Maak aan
            </LwButton>
            <LwButton
              onClick={() => navigate(generatePath(hqRoutes.PartnerUsers, { id: partnerId }))}
              iconColorMode="fill"
              {...cancelButtonProps}
            >
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <PartnerUserForm onSubmit={handleSubmit} state="update" />
      </Page>
    </FormProvider>
  );
};
