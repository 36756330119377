import { Box, Grid, Typography } from '@mui/material';
import { IconDuplicate, IconEdit, IconTrash } from 'assets/img';
import { useCompanyRelativePath } from 'ogp/hooks/use-company-relative-path';
import { routes } from 'ogp/Routes';
import { useNavigate, useParams } from 'react-router-dom';
import { Header, LwButton, Page, Toolbox } from 'redesign';
import { RenderUnsettledUI } from 'shared/components';
import { invariant } from 'shared/utils/utils';
import { AuthenticatedElement } from 'ogp/components/common/authenticated-element';
import { CompanyRole } from '@types';
import { PolicyGroups } from 'shared/utils/policy-controller';
import { useDialog } from 'ogp/providers/dialog-provider';
import { billingEntityAlerts, useAlert } from 'shared/components/alerts';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { groupBy } from 'lodash';
import { Fee } from 'ogp/services/billing-entity-service.types';
import { QUERY_KEYS } from 'api/query-keys';
import { useGetBillingEntity } from '../hooks/use-get-billing-entity';
import { useArchiveBillingEntity } from '../hooks/use-archive-billing-entity';
import { FeeDetails } from '../components/billing-entity-form/fee-details';

const BillingEntityDetail = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'BillingEntityDetail id is required');
  const generatePath = useCompanyRelativePath();
  const { data, status, error, refetch } = useGetBillingEntity(id);
  const { alertSuccess, alertError } = useAlert();
  const navigate = useNavigate();
  const archiveBillingEntity = useArchiveBillingEntity();
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const invalidateQuery = useInvalidateQuery();

  const handleArchiveBillingEntity = () => {
    invariant(id, 'BillingEntity id is not defined');
    disableDialog();
    archiveBillingEntity.mutate(id, {
      onSuccess: () => {
        alertSuccess(billingEntityAlerts.success.deleted);
        invalidateQuery(QUERY_KEYS.companies());
        navigate(generatePath(routes.Company));
        closeDialog();
      },
      onError: (e) => alertError(e),
      onSettled: () => {
        enableDialog();
      },
    });
  };

  const handleDelete = () => {
    openDialog(
      { title: 'Weet je zeker dat je deze facturatiegegevens wilt verwijderen?' },
      handleArchiveBillingEntity
    );
  };

  if (status !== 'success') {
    return <RenderUnsettledUI data={{ error, status, refetch }} />;
  }

  const getFeeList = () => {
    const feesByType = groupBy(data.fees, 'feeType');
    const feeList: (Fee & { index: number })[] = [];
    for (const feeType in feesByType) {
      feeList.push(...feesByType[feeType].map((fee, index) => ({ ...fee, index })));
    }
    return feeList;
  };

  return (
    <Page
      header={<Header titleText={data.name} subheaderText="Vestigingsdetails" />}
      toolbox={
        <Toolbox>
          <LwButton to={generatePath(routes.Company)} color="primary">
            Terug
          </LwButton>
          <LwButton
            iconColorMode="fill"
            to={generatePath(routes.BillingEntitiesEdit, { id })}
            color="secondary"
            startIcon={<IconEdit />}
          >
            Facturatiegegevens aanpassen
          </LwButton>
          <AuthenticatedElement policyGroup={PolicyGroups.COMPANY} roles={CompanyRole.HQADMIN}>
            <LwButton
              iconColorMode="fill"
              to={generatePath(routes.BillingEntitiesDuplicate, { id })}
              color="secondary"
              startIcon={<IconDuplicate />}
            >
              Facturatiegegevens dupliceren
            </LwButton>
          </AuthenticatedElement>
          <AuthenticatedElement roles={CompanyRole.ADMIN} policyGroup={PolicyGroups.COMPANY}>
            <LwButton
              onClick={handleDelete}
              iconColorMode="stroke"
              startIcon={<IconTrash />}
              color="secondary"
            >
              Verwijderen
            </LwButton>
          </AuthenticatedElement>
        </Toolbox>
      }
    >
      <Box>
        <Grid container spacing={2} item xs={12} md={12} lg={8}>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="secondary" noWrap>
              Bedrijfsnaam
            </Typography>
            <Typography variant="body1">{data.companyName ?? 'Onbekend'}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="secondary" noWrap>
              Adres
            </Typography>
            <Typography variant="body1">{data.location.address}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="secondary" noWrap>
              Contactpersoon
            </Typography>
            <Typography variant="body1">{data.contactPerson}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="secondary" noWrap>
              E-mail contactpersoon
            </Typography>
            <Typography variant="body1">{data.contactEmail}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="secondary" noWrap>
              KVK nummer
            </Typography>
            <Typography variant="body1">{data.cocNumber}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="secondary" noWrap>
              BTW nummer
            </Typography>
            <Typography variant="body1">{data.vatNumber}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="secondary" noWrap>
              Afdelingen
            </Typography>
            {data.departments.map((department) => (
              <Typography key={department} variant="body1">
                {department}
              </Typography>
            ))}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="secondary" noWrap>
              Betalingstermijn
            </Typography>
            <Typography variant="body1">{data.paymentTerm} dagen</Typography>
          </Grid>
          {getFeeList().map(({ index, ...fee }) => (
            <FeeDetails key={`${fee.feeType}-${index}`} fee={fee} index={index} />
          ))}
        </Grid>
      </Box>
    </Page>
  );
};

export { BillingEntityDetail };
