import { DateTime } from 'luxon';
import { useState } from 'react';
import { idCheckAlerts, useAlert } from 'shared/components/alerts';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { useForm } from 'react-hook-form';
import { useRejectFlexWorkerIdentityCheck } from 'hq/hooks/queryHooks/flexWorkers/useRejectFlexWorkerIdentityCheck';
import { useApproveFlexWorkerIdentityCheck } from 'hq/hooks/queryHooks/flexWorkers/useApproveFlexWorkerIdentityCheck';
import { Box, Button, Grid, Typography } from '@mui/material';
import { LwFormDate, LwFormInput, LwFormSelect } from 'redesign';
import { styled } from '@mui/styles';
import { QUERY_KEYS } from 'api/query-keys';
import {
  FlexworkerIdentityVerificationForm,
  TempworkIdCheckProps,
} from './flex-worker-id-check.types';
import { getWorkerAgeFromIso, isWorkerUnderMinumumAge } from '../../utils/worker-age';
import { IDDocumentType, InputSelectOption } from '../../../../../../../types';

export const TempworkIdCheck = (props: TempworkIdCheckProps) => {
  const {
    identityVerificationId,
    flexWorkerId,
    workerIdentityInformation,
    closeModal,

    platformSettings,
  } = props;
  const [isIdDocumentDownloaded, setIsIdDocumentDownloaded] = useState(false);
  const { allowCandidateOnboarding } = useFeatureFlagContext();
  const invalidateQuery = useInvalidateQuery();
  const { alertError, alertSuccess } = useAlert();

  const defaultValues = {
    firstName: workerIdentityInformation.firstName,
    lastName: workerIdentityInformation.lastName,
    dateOfBirth: workerIdentityInformation.dateOfBirth
      ? DateTime.fromISO(workerIdentityInformation.dateOfBirth).toISODate()
      : undefined,
    documentType: workerIdentityInformation.documentType,
    documentNumber: workerIdentityInformation.documentNumber,
    issuanceDate: workerIdentityInformation.issuanceDate
      ? DateTime.fromISO(workerIdentityInformation.issuanceDate).toISODate()
      : undefined,
    expirationDate: workerIdentityInformation.expirationDate
      ? DateTime.fromISO(workerIdentityInformation.expirationDate).toISODate()
      : undefined,
  };

  const { handleSubmit, control } = useForm<FlexworkerIdentityVerificationForm>({
    defaultValues,
  });

  const approveMutation = useApproveFlexWorkerIdentityCheck(identityVerificationId, {
    onSuccess: () => {
      alertSuccess(idCheckAlerts.success.approved);
      invalidateQuery(QUERY_KEYS.workers());
      closeModal();
    },
    onError: (e) => alertError(e),
  });
  const denyMutation = useRejectFlexWorkerIdentityCheck(identityVerificationId, {
    onSuccess: () => {
      alertSuccess(idCheckAlerts.success.reject);
      invalidateQuery(QUERY_KEYS.workers());
      closeModal();
    },
    onError: (e) => alertError(e),
  });

  const onApprove = (formData: FlexworkerIdentityVerificationForm) => {
    approveMutation.mutate({
      bsn: undefined,
      firstName: formData.firstName,
      lastName: formData.lastName,
      documentType: formData.documentType,
      documentNumber: formData.documentNumber,
      issuanceDate: formData.issuanceDate,
      expirationDate: formData.expirationDate,
      dateOfBirth: formData.dateOfBirth,
    });
  };

  const onDeny = async () => {
    await denyMutation.mutateAsync();
  };

  const documentTypeOptions: InputSelectOption[] = [
    { value: IDDocumentType.ID, label: 'ID' },
    { value: IDDocumentType.PASSPORT, label: 'Paspoort' },
    { value: IDDocumentType.RESIDENCE_PERMIT, label: 'Verblijfsvergunning' },
  ];

  const viewIdDocument = () => {
    setIsIdDocumentDownloaded(true);
    window.open(workerIdentityInformation.idDocumentImage);
  };

  const ageLimit = platformSettings.minimumTempWorkerAge;
  const workerAge = getWorkerAgeFromIso(workerIdentityInformation.dateOfBirth) ?? 0;
  const isWorkerUnderAge = isWorkerUnderMinumumAge(workerAge, ageLimit);

  return (
    <form onSubmit={handleSubmit(onApprove)}>
      {workerIdentityInformation.idDocumentImage ? (
        <Grid item xs={12}>
          <StyledButton onClick={viewIdDocument} variant="contained" size="large" color="primary">
            <Typography variant="body2">Bekijk identiteitsbewijs</Typography>
          </StyledButton>
        </Grid>
      ) : null}
      <Grid container item spacing={2} xs={12} md={6}>
        <Grid item xs={6}>
          <LwFormInput
            name="lastName"
            label="Acthernaam"
            control={control}
            rules={{ required: 'Selecteer een Acthernaam' }}
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="firstName"
            label="Voornaam"
            control={control}
            rules={{ required: 'Selecteer een Voornaam' }}
            disabled={true}
          />
        </Grid>
        <Grid item xs={6} display="flex" alignItems="end">
          <LwFormSelect
            name="documentType"
            options={documentTypeOptions}
            label="Document Type"
            control={control}
            rules={{ required: 'Selecteer een document type' }}
          />
        </Grid>
        <Grid item xs={6} display="flex" alignItems="end">
          <LwFormInput
            name="documentNumber"
            label="Documentnummer"
            control={control}
            rules={{ required: 'Selecteer een documentnummer' }}
          />
        </Grid>

        <Grid item xs={6} display="flex" alignItems="end">
          <LwFormDate
            name="issuanceDate"
            label="Datum van uitgifte"
            control={control}
            rules={{ required: 'Selecteer een datum van uitgifte' }}
          />
        </Grid>
        <Grid item xs={6} display="flex" alignItems="end">
          <LwFormDate
            name="expirationDate"
            label="Vervaldatum"
            control={control}
            rules={{ required: 'Selecteer een vervaldatum' }}
          />
        </Grid>

        <Grid item xs={6} display="flex" alignItems="end">
          <LwFormDate
            name="dateOfBirth"
            label="Geboortedatum"
            control={control}
            rules={{ required: 'Selecteer een Geboortedatum' }}
          />
          {isWorkerUnderAge && !allowCandidateOnboarding ? (
            <WarningText>
              Deze uitzendkracht is op dit moment {Math.floor(workerAge)} jaar: dit is onder de
              leeftijdgrens van {ageLimit} jaar. Het ID goedkeuren heeft daarom geen invloed op het
              level van deze uitzendkracht.
            </WarningText>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <StyledButton
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            disabled={workerIdentityInformation.idDocumentImage ? !isIdDocumentDownloaded : false}
          >
            <Typography variant="body2">Goedkeuren</Typography>
          </StyledButton>
          <StyledButton onClick={onDeny} variant="contained" size="large" color="primary">
            <Typography variant="body2">Afwijzen</Typography>
          </StyledButton>
        </Grid>
      </Grid>
    </form>
  );
};

const StyledButton = styled(Button)({
  marginRight: 12,
});

const WarningText = styled(Box)(({ theme }) => ({
  color: theme.palette.warning.main,
  paddingLeft: theme.spacing(2),
  fontSize: 12,
  width: '400px',
}));
