import { z } from 'zod';

export const companyEmploymentTypesSchema = z.object({
  offersFreelanceWork: z.boolean(),
  offersTempWork: z.boolean(),
});
export const companyPartnersSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
  })
);
export type GetCompanyEmploymentTypes = z.infer<typeof companyEmploymentTypesSchema>;
