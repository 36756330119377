import { Box, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { ApplicationsCell } from 'shared/components/planner/common/utils';
import { HqShiftPlanningCellProps } from 'shared/components/planner/shifts/table/shift-planning-table.types';
import { FormatApplicantNameComponent } from './applicant-name-format';
import { HqProjectPlanningCellProp } from '../../projects/table/project-planning-table.types';

export const ApplicationsCellWithTooltip = (
  props: HqShiftPlanningCellProps<'applications'> | HqProjectPlanningCellProp<'applications'>
): JSX.Element => {
  const numberOfFreelancers = props.value.freelancersList.length;
  const numberOfTempworkers = props.value.tempworkersList.length;

  const hasApplicants = numberOfFreelancers > 0 || numberOfTempworkers > 0;

  if (!hasApplicants) {
    return <ApplicationsCell {...props} />;
  }

  return (
    <StyledTooltip
      data-testid="applications-planning-tooltip"
      title={
        <Container>
          {hasApplicants ? (
            <Row>
              <FormatApplicantNameComponent applications={props.value} />
            </Row>
          ) : null}
        </Container>
      }
    >
      <CandidateCell>
        <ApplicationsCell {...props} />
      </CandidateCell>
    </StyledTooltip>
  );
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.grey[300],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    color: 'white',
  },
}));

const CandidateCell = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'left',
  width: '150px',
}));

const Container = styled(Box)(({ theme }) => ({
  '& > :not(:last-child)': {
    paddingBottom: theme.spacing(3),
    '&:not(:has(div))': {
      paddingBottom: theme.spacing(5),
    },
  },
}));

const Row = styled(Box)(({ theme }) => ({
  '& > :not(:last-child)': {
    paddingBottom: theme.spacing(2),
  },
}));
