import { useCallback, useState } from 'react';
import { Header, LwButton, Page, Toolbox } from 'redesign';
import { FilterButton } from 'ogp/components/views/administration/shared';
import { useToggle } from 'shared/hooks';
import { PartnerPlacementsDataProvider } from './partner-placements-table';
import { useCancelShiftPlacements } from './hooks/use-cancel-shift-placements';
import { usePlacementsPage } from './hooks/use-placements-page';
import { partnerPlacementAlerts, useAlert } from '../../../../shared/components/alerts';
import { IconTrash } from '../../../../assets/img';

const PartnerPlacements = () => {
  const { filters: appliedFilters, setFilters, selectedFilterCount } = usePlacementsPage();
  const { on: filtersOpen, setOn, setOff } = useToggle();

  const [selection, setSelection] = useState<string[]>([]);
  const onSelectionChange = useCallback((selectedIds: string[]) => {
    setSelection(selectedIds);
  }, []);
  const { alertSuccess, alertError } = useAlert();
  const { mutate } = useCancelShiftPlacements();

  const cancelPlacements = useCallback(
    () =>
      mutate(
        { placementIds: selection },
        {
          onSuccess: () => alertSuccess(partnerPlacementAlerts.success.bulkDeleted),
          onError: (e) => alertError(e),
        }
      ),
    [alertError, alertSuccess, mutate, selection]
  );

  return (
    <Page
      header={<Header titleText="Plaatsingen" />}
      toolbox={
        <Toolbox>
          <LwButton
            disabled={selection.length === 0}
            type="button"
            color="primary"
            onClick={cancelPlacements}
            startIcon={<IconTrash />}
          >
            Annuleer alle plaatsingen
          </LwButton>
          <Toolbox.PushRight>
            <FilterButton onClick={setOn} nrOfActiveFilters={selectedFilterCount} />
          </Toolbox.PushRight>
        </Toolbox>
      }
    >
      <PartnerPlacementsDataProvider
        onSelectionChange={onSelectionChange}
        appliedFilters={appliedFilters}
        setFilters={setFilters}
        filtersOpen={filtersOpen}
        onClose={setOff}
      />
    </Page>
  );
};

export { PartnerPlacements };
