import { QUERY_KEYS } from 'api/query-keys';
import { companyService } from 'hq/services';
import { useMutation } from 'react-query';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';

export const useArchiveCompany = () => {
  const mutationFn = async (companyId: string) => await companyService.archiveCompany(companyId);
  const invalidateQuery = useInvalidateQuery();

  return useMutation(mutationFn, {
    onSettled: () => {
      invalidateQuery(QUERY_KEYS.companies());
    },
  });
};
