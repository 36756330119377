import { QUERY_KEYS } from 'api/query-keys';
import { shiftPlanningService } from 'hq/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

type OpenShiftToPartnersMutationParams = {
  shiftIds: string[];
  partnerIds: string[];
};

type ShiftPartnerMutationOptions = UseMutationOptions<
  unknown,
  unknown,
  { shiftIds: string[]; partnerIds: string[] }
>;

export const useBulkOpenShiftsToPartners = () => {
  const mutationFn = async (params: OpenShiftToPartnersMutationParams) =>
    await shiftPlanningService.bulkAssignPartnersToShifts(params.shiftIds, params.partnerIds);
  const queryClient = useQueryClient();
  const mutationOptions: ShiftPartnerMutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.shifts());
      queryClient.invalidateQueries(QUERY_KEYS.partners());
    },
  };

  return useMutation(mutationFn, mutationOptions);
};
