import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { styled } from '@mui/material';
import { useWizardContext } from 'redesign';
import { useAlert } from 'shared/components/alerts';
import { useBulkActionResultContext } from 'shared/providers/bulk-action-provider';
import { BulkCancelFormOptions } from './bulk-cancel-form-options';
import { BulkCancelFormButtons } from './bulk-cancel-form-buttons';
import { BulkCancelPlacementForm } from './bulk-cancel-placements.types';
import { useBulkCancelPlacements } from '../utils/hooks/use-bulk-cancel-placements';

export const BulkCancelForm = () => {
  const form = useForm<BulkCancelPlacementForm>();
  const { selectedIds, setMutationResult } = useBulkActionResultContext();
  const { nextStep } = useWizardContext();
  const { alertError } = useAlert();
  const { mutate, status } = useBulkCancelPlacements();

  const onSubmit = useCallback(
    (data: BulkCancelPlacementForm) => {
      mutate(
        {
          ...data,
          placementIds: selectedIds,
        },
        {
          onSuccess: (data) => {
            setMutationResult(data);
            nextStep();
          },
          onError: (error) => {
            alertError(error);
          },
        }
      );
    },
    [selectedIds, mutate]
  );

  return (
    <FormProvider {...form}>
      <StyledForm onSubmit={form.handleSubmit(onSubmit)}>
        <BulkCancelFormOptions />
        <BulkCancelFormButtons isSubmitting={status === 'loading' || form.formState.isSubmitting} />
      </StyledForm>
    </FormProvider>
  );
};

const StyledForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  width: '600px',
}));
