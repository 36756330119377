import _ from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { invariant } from 'shared/utils/utils';

type ParamType = 'array' | 'boolean' | 'date' | 'number' | 'string' | 'string_array';

type ParamTypeToType<T extends ParamType> = T extends 'array'
  ? unknown[]
  : T extends 'string_array'
    ? string[]
    : T extends 'boolean'
      ? boolean
      : T extends 'date'
        ? DateTime
        : T extends 'number'
          ? number
          : T extends 'string'
            ? string
            : never;
type Config = {
  [key: string]: {
    initialValue: unknown;
    type: ParamType;
  };
};

/**
 * It only touches key value pairs that are defined in `config`
 */
const useExternalParams = <T extends Config>(config: T) => {
  // infinite render otherwise
  const configRef = useLatestConfig(config);
  const [safeParams, setSearchParams] = useExternalSafeParams(configRef.current);

  // apply initial values only when they're missing in the URL
  // OR when the ones in URL are invalid
  useEffect(() => {
    const initialParams = getInitialParams(configRef.current);

    const hasParams = initialParams.find(([_, value]) => {
      return value != null;
    });

    if (!hasParams) {
      return;
    }

    setSearchParams((cur) => {
      const next = new URLSearchParams(Object.fromEntries(cur.entries()));

      initialParams.forEach(([initialKey, initialValue]) => {
        // URL does not contain key that was initialized
        if (!cur.has(initialKey) && initialValue != null) {
          // set the key=value pair
          next.set(initialKey, initialValue);

          return;
        }

        const paramValue = cur.get(initialKey);
        const { type } = configRef.current[initialKey];

        // URL does contain key that was initialized
        if (paramValue) {
          try {
            // try to decode it with expected decoder
            codecs[type].decode(paramValue);
          } catch (e) {
            // if it throws, we drop the value and use initial one instead
            if (initialValue != null) {
              next.set(initialKey, initialValue);
            }
          }
        }
      });

      return next;
    });
  }, [configRef]);

  const setParams = (params: {
    [key in keyof typeof config]?: ParamTypeToType<(typeof config)[key]['type']>;
  }) => {
    setSearchParams((cur) => {
      Object.entries(params).forEach(([key, value]) => {
        const nextValue = encode(value);

        if (nextValue != null) {
          // TODO key.toString() ???
          cur.set(key.toString(), nextValue);
        } else {
          cur.delete(key.toString());
        }
      });

      return cur;
    });
  };

  return [safeParams, setParams] as const;
};

/**
 * Accesses URL keys that are part of initial `config` definition
 * and tries to decode them into a valid type.
 *
 * Won't let you access a URL param you didn't list in `config`.
 */
const useExternalSafeParams = <T extends Config>(config: T) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams.entries());
  const typedParams = Object.entries(params)
    .filter(([key]) => key in config)
    .reduce((prev, [key, value]) => {
      const { type } = config[key];
      try {
        const decoded = codecs[type].decode(value);
        return {
          ...prev,
          [key]: decoded,
        };
      } catch {
        return {
          ...prev,
        };
      }
    }, {}) as { [key in keyof T]?: ParamTypeToType<T[key]['type']> };

  return [typedParams, setSearchParams] as const;
};

const useLatestConfig = <T extends Config>(config: T) => {
  const configRef = useRef<T>(config);

  // update config on every change
  useEffect(() => {
    configRef.current = config;
  });

  return configRef;
};

/**
 * Collects initial param defined by dev and checks whether they are valid.
 */
const getInitialParams = (config: Config) =>
  Object.keys(config).map((key) => {
    const { initialValue, type } = config[key];
    let encoded;

    if (initialValue !== undefined) {
      encoded = codecs[type].encode(initialValue);

      invariant(
        encoded,
        `Initial value ${initialValue} of type ${typeof initialValue} doesn't match expected type ${type}.`
      );
    }

    return [key, encoded] as const;
  });

const codecs: Record<
  ParamType,
  {
    encode: (value: unknown) => string | undefined;
    decode: (value: string) => ParamTypeToType<ParamType>;
  }
> = {
  array: {
    encode: (value) => {
      if (Array.isArray(value)) {
        return JSON.stringify(value);
      }
    },
    decode: (value) => {
      try {
        return JSON.parse(value);
      } catch {
        throw new Error('unknown');
      }
    },
  },
  boolean: {
    encode: (value) => {
      if (typeof value === 'boolean') {
        return value.toString();
      }
    },
    decode: (value) => {
      if (value === 'true') {
        return true;
      } else if (value === 'false') {
        return false;
      }
      throw new Error('unknown');
    },
  },
  date: {
    encode: (value) => {
      if (DateTime.isDateTime(value)) {
        return value.toISODate();
      }
    },
    decode: (value) => {
      const date = DateTime.fromISO(value);
      if (date.isValid) {
        return date;
      }
      throw new Error('unknown');
    },
  },
  number: {
    encode: (value) => {
      if (typeof value === 'number') {
        return value.toString();
      }
    },
    decode: (value) => {
      if (_.isFinite(_.toNumber(value))) {
        return _.toNumber(value);
      }
      throw new Error('unknown');
    },
  },
  string: {
    encode: (value) => {
      if (typeof value === 'string') {
        return value;
      }
    },
    decode: (value) => {
      return value;
    },
  },
  string_array: {
    encode: (value) => {
      if (Array.isArray(value)) {
        return JSON.stringify(value);
      }
    },
    decode: (value) => {
      try {
        return JSON.parse(value);
      } catch {
        throw new Error('unknown');
      }
    },
  },
};

/**
 * from local to external storage (e.g. to URL)
 */
const encode = (value: unknown) => {
  for (const [, source] of Object.entries(codecs)) {
    const result = source.encode(value);
    if (result !== undefined) {
      return result;
    }
  }
};

export { useExternalParams };
