import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { CompanyData } from '@types';
import { companyService } from 'hq/services';
import { QUERY_KEYS } from 'api/query-keys';

type CompanyMutationOptions = UseMutationOptions<unknown, unknown, CompanyData>;

export const useCreateCompany = (options?: CompanyMutationOptions) => {
  const mutationFn = async (company: CompanyData) => await companyService.createCompany(company);
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options ?? {};
  const mutationOptions: CompanyMutationOptions = {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(QUERY_KEYS.companies());
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  };

  return useMutation(mutationFn, mutationOptions);
};
