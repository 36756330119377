import { jobService } from 'ogp/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { QUERY_KEYS } from 'api/query-keys';
import { CreateJobPayload, CreateJobResponse } from '../../../services/job-service.types';

type JobMutationOptions = UseMutationOptions<CreateJobResponse, unknown, CreateJobPayload>;

export const useCreateJob = () => {
  const mutationFn = async (job: CreateJobPayload) => await jobService.createJob(job);

  const queryClient = useQueryClient();
  const mutationOptions: JobMutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.jobs());
      queryClient.invalidateQueries(QUERY_KEYS.workTemplates());
    },
  };

  return useMutation(mutationFn, mutationOptions);
};
