import { Avatar, Box, IconButton, styled, Tooltip, Typography } from '@mui/material';
import { IconCross, IconProfilePlacementUser } from 'assets/img';
import { useActivateFlexWorker } from 'hq/hooks/queryHooks/flexWorkers/useActivateFlexWorker';
import { useDeactivateFlexWorker } from 'hq/hooks/queryHooks/flexWorkers/useDeactivateFlexWorker';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { useNavigate } from 'react-router-dom';
import { ContextMenuButton, ContextMenuItem, LWModal } from 'redesign';
import {
  formatEmploymentType,
  formatFlexWorkerLevel,
  formatFullName,
} from 'shared/utils/formatting';
import { HQGetFlexWorkerDetailsResponse } from '@types';
import { flexWorkerPaydayOnboardingAlerts, useAlert } from 'shared/components/alerts';
import { useCallback, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { useAddFlexWorkerToPayday } from './hooks/use-add-flex-worker-to-payday';
import { FlexWorkerContract } from './forms/contract/flex-worker-contract';
import { routes } from '../../../../../Routes';

type Props = {
  data: HQGetFlexWorkerDetailsResponse;
  onClose: () => void;
  allowEmploymentId: boolean;
};

function getReadablePaydayState(paydayState: string | null): string {
  switch (paydayState) {
    case 'NotOnboarding':
      return 'Geen payday account';
    case 'Onboarding':
      return 'Account incompleet, banner zichtbaar in app';
    case 'Onboarded':
      return 'Gebruiker is volledig onboard';
    default:
      return 'Onbekend';
  }
}

export const FlexWorkerDetailHead = ({ data, onClose, allowEmploymentId }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { alertError, alertSuccess } = useAlert();
  const generatePath = useHqRelativePath();
  const navigate = useNavigate();
  const flexWorkerName = formatFullName(data);
  const flexWorkerLevel = formatFlexWorkerLevel(data.level);
  const employmentType = `(${formatEmploymentType(data.employmentType)})`;
  const addFlexworkerToPayday = useAddFlexWorkerToPayday();
  const initials = flexWorkerName
    .split(' ')
    .map((item) => item[0])
    .join('');

  const { queryParams } = routes.Placements;

  const contractInfo = useMemo(() => {
    if (data.employmentType === 'FREELANCER') {
      return null;
    }
    if (data.contractStartDate == null) {
      return null;
    }

    const startDateIso = DateTime.fromISO(data.contractStartDate).toISODate();

    if (data.contractEndDate == null) {
      return startDateIso;
    }

    const endDateIso = DateTime.fromISO(data.contractEndDate).toISODate();

    return `${startDateIso} - ${endDateIso}`;
  }, [data]);

  const { mutate: activate, isLoading: loadingActivate } = useActivateFlexWorker(data.id, {
    onError: (e) => alertError(e),
  });
  const { mutate: deactivate, isLoading: loadingDeactivate } = useDeactivateFlexWorker(data.id, {
    onError: (e) => alertError(e),
  });

  const handleEdit = () => {
    const path = generatePath(routes.FlexWorkersEdit, { id: data.id });
    navigate(path);
  };

  const handleAddFlexworkerToPayday = () => {
    if (data.employmentType === 'FREELANCER' && data.level !== 'STARTER') {
      alertError(
        new Error('Flexwerker (freelancer) mag nog niet werken, hij moet het level STARTER hebben.')
      );
      return;
    }
    if (data.employmentType === 'TEMPWORKER' && data.level !== 'WORKER') {
      alertError(
        new Error(
          'Flexwerker (uitzendkracht) mag nog niet werken, hij moet het level WORKER hebben.'
        )
      );
      return;
    }

    addFlexworkerToPayday.mutate(data.id, {
      onSuccess: () => {
        alertSuccess(flexWorkerPaydayOnboardingAlerts.success.created);
      },
      onError: (e) => alertError(e),
    });
  };

  const handleShowContractModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const showPaydayInfo = data.employmentType === 'FREELANCER';

  const placementsLink = `${generatePath(routes.Placements)}?${[queryParams.WORKER]}=${data.id}`;

  return (
    <>
      <Box display="flex" padding={2}>
        <Box display="flex" alignItems="center">
          <StyledAvatar src={data?.profilePictureUrl}>{initials}</StyledAvatar>
          <Box>
            <Typography variant="h3" mb={1}>
              {flexWorkerName}
            </Typography>
            <Typography mb={1}>{`${flexWorkerLevel} ${employmentType}`}</Typography>
            <Typography mb={1}>
              {data?.workedShifts + ' gewerkte shift' + (data?.workedShifts === 1 ? '' : 's')}
            </Typography>
            {showPaydayInfo ? (
              <Tooltip title={data?.paydayOnboardingStatus}>
                <Typography mb={1}>
                  Payday status: {getReadablePaydayState(data?.paydayOnboardingStatus)}
                </Typography>
              </Tooltip>
            ) : null}

            {allowEmploymentId && data?.employmentId ? (
              <Tooltip title={data?.employmentId}>
                <Typography mb={1}>Employment ID: {data.employmentId}</Typography>
              </Tooltip>
            ) : null}

            {contractInfo && data.employmentType === 'TEMPWORKER' ? (
              <Tooltip title={contractInfo}>
                <Typography mb={1}>Contractgegevens: {contractInfo}</Typography>
              </Tooltip>
            ) : null}
          </Box>
        </Box>
        <Box marginLeft="auto">
          <Tooltip title={'Plaatsingen flexwerker'}>
            <IconButton data-testid="placements-link" onClick={() => navigate(placementsLink)}>
              <IconProfilePlacementUser width={24} height={24} />
            </IconButton>
          </Tooltip>

          <ContextMenuButton
            loading={loadingActivate || loadingDeactivate || addFlexworkerToPayday.isLoading}
            size="medium"
          >
            <ContextMenuItem
              onClick={() => activate()}
              disabled={!data.disabled || data.hasPendingIdentityVerification}
            >
              Activeren
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() => deactivate()}
              disabled={data.disabled || data.hasPendingIdentityVerification}
            >
              Deactiveren
            </ContextMenuItem>
            <ContextMenuItem onClick={() => handleEdit()}>Aanpassen</ContextMenuItem>
            {showPaydayInfo ? (
              <ContextMenuItem
                onClick={() => handleAddFlexworkerToPayday()}
                disabled={
                  data.disabled ||
                  data.paydayOnboardingStatus !== 'NotOnboarding' ||
                  data.hasPendingIdentityVerification
                }
              >
                Aanmelden bij Payday
              </ContextMenuItem>
            ) : null}
            {data.level != null && data.employmentType === 'TEMPWORKER' ? (
              <ContextMenuItem onClick={handleShowContractModal}>Bekijk contract</ContextMenuItem>
            ) : null}
          </ContextMenuButton>
          <IconButton onClick={onClose}>
            <IconCross width={24} height={24} />
          </IconButton>
        </Box>
      </Box>
      <LWModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        testId="flex-worker-contract-modal"
      >
        <FlexWorkerContract data={data} onClose={() => setIsModalOpen(false)} />
      </LWModal>
    </>
  );
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  height: theme.spacing(26),
  width: theme.spacing(26),
  marginRight: theme.spacing(4),
}));
