import { jobService as ogpJobService } from 'ogp/services';
import { jobService as hqJobService } from 'hq/services';
import { useQuery, UseQueryResult } from 'react-query';
import { JobDetailResponse } from 'ogp/services/job-service.types';
import { QUERY_KEYS } from 'api/query-keys';
import { useSiteDetection } from '..';

export const useGetJob = (jobId: string = ''): UseQueryResult<JobDetailResponse, Error> => {
  const { isOGP, isHQ } = useSiteDetection();
  let queryFn;
  if (isOGP) {
    queryFn = async () => await ogpJobService.getJob(jobId);
  } else if (isHQ) {
    queryFn = async () => await hqJobService.getJob(jobId);
  } else {
    throw new Error('Site is not supported');
  }

  const options = {
    enabled: jobId.length > 0,
  };

  return useQuery(QUERY_KEYS.job(jobId), queryFn, options);
};
