import { Box, Typography } from '@mui/material';
import { useBulkActionResultContext } from 'shared/providers/bulk-action-provider';

export const SuccessfulBulkCancelSection = () => {
  const { failure, fulfilled } = useBulkActionResultContext();

  const totalMutations = failure.length + fulfilled.length;
  const totalOfSuccessfulMutations = fulfilled.length;

  return (
    <Box>
      <Typography variant="h6">
        {totalOfSuccessfulMutations} van {totalMutations} geselecteerde plaatsingen zijn succesvol
        gewijzigd.
      </Typography>
    </Box>
  );
};
