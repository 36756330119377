import { useFormContext } from 'react-hook-form';
import { ListSubheader, MenuItem } from '@mui/material';
import { LwFormSelect } from 'redesign';
import { DepartmentSelectFormContext, Props } from './department.types';

export const DepartmentSelect = (props: Props) => {
  const { control } = useFormContext<DepartmentSelectFormContext>();
  return (
    <LwFormSelect
      renderOption={(opt) => [
        <ListSubheader key={opt.value} value="">
          <em>{opt.label}</em>
        </ListSubheader>,
        opt.options.map((dpt) => (
          <MenuItem key={dpt.value} value={dpt.value}>
            {dpt.label}
          </MenuItem>
        )),
      ]}
      name="department"
      label="Afdeling"
      defaultLabel="Selecteer een afdeling..."
      options={props.options ?? []}
      control={control}
      rules={{ required: props.required ? 'Selecteer een afdeling' : undefined }}
      disabled={props.disabled}
      onChange={props.onChange}
    />
  );
};
