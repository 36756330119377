import { flexWorkerService } from 'hq/services';
import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS } from 'api/query-keys';

export const useAddFlexWorkerToPayday = () => {
  const mutateFn = async (flexWorkerId: string): Promise<string> => {
    await flexWorkerService.onboardFlexworkerToPayday(flexWorkerId);
    return flexWorkerId;
  };

  const queryClient = useQueryClient();

  return useMutation(mutateFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.workers());
    },
  });
};
