const QUERY_KEYS_SHIFT = {
  shifts: () => [...QUERY_KEYS.all(), 'shifts'],
  shift: (shiftId: string) => [...QUERY_KEYS.all(), { shiftId }],
  shiftClaimsWithFilters: (filters?: Record<string, unknown>) => [
    ...QUERY_KEYS.shifts(),
    'shift-claims',
    filters,
  ],
  shiftApplication: (shiftApplicationId: string) => [
    ...QUERY_KEYS_SHIFT.shifts(),
    'shift-application',
    { shiftApplicationId },
  ],
  shiftClaimsForFlexWorker: (filters: { [key: string]: string }) => [
    ...QUERY_KEYS_SHIFT.shifts(),
    'shiftClaimsForFlexWorker',
    { filters },
  ],
  shiftPartner: (shiftId: string) => [...QUERY_KEYS_SHIFT.shifts(), 'shift-partners', { shiftId }],
  shiftEligiblePartners: (shiftId: string) => [
    ...QUERY_KEYS_SHIFT.shifts(),
    'eligible-partners',
    { shiftId },
  ],
  shiftPlanningShiftsFilters: (params: Record<string, unknown>) => [
    ...QUERY_KEYS_SHIFT.shifts(),
    'shift-planning-shifts',
    { params },
  ],
  plannerShiftsPartners: (shiftIds: string[]) => [
    ...QUERY_KEYS_SHIFT.shifts(),
    'planner-shifts-partners',
    ...shiftIds,
  ],
  shiftPlanning: (params: Record<string, unknown>) => [
    ...QUERY_KEYS_SHIFT.shifts(),
    'shift-planning',
    { params },
  ],
  shiftPlanningWithFilters: (params: Record<string, unknown>) => [
    ...QUERY_KEYS_SHIFT.shifts(),
    'shift-planning-with-filters',
    { params },
  ],
  shiftPlanningShiftDetail: (shiftId: string | undefined) => [
    ...QUERY_KEYS_SHIFT.shifts(),
    'shiftPlanningShiftDetail',
    shiftId,
  ],
  shiftPlanningShiftWorkers: (shiftId: string | undefined, fullNameSearch?: string) => [
    ...QUERY_KEYS_SHIFT.shifts(),
    'workers',
    { shiftId, fullNameSearch },
  ],
  shiftPlanningShiftClaimants: (shiftId: string) => [
    ...QUERY_KEYS_SHIFT.shifts(),
    'claimants',
    { shiftId },
  ],
  breakOptions: () => [...QUERY_KEYS_SHIFT.shifts(), 'shift-bulk-edit-options'],
  minimumRateForJobTypes: () => [...QUERY_KEYS_SHIFT.shifts(), 'minimum-rate-for-job-types'],
  partnersFilter: () => [...QUERY_KEYS_SHIFT.shifts(), 'partners-filter'],
  getShiftCreateData: () => [...QUERY_KEYS_SHIFT.shifts(), 'getShiftCreateData'],
} as const;

const QUERY_KEYS_PROJECT = {
  projects: () => [...QUERY_KEYS.all(), 'projects'],
  departmentProjects: (params: string) => [
    ...QUERY_KEYS_PROJECT.projects(),
    'departmentProjects',
    { params },
  ],
  projectDetail: (projectId: string) => [
    ...QUERY_KEYS_PROJECT.projects(),
    'projectDetail',
    { projectId },
  ],
  projectWorkers: () => [...QUERY_KEYS_PROJECT.projects(), 'workers'],
  workApplicationsForProject: (projectId: string) => [
    ...QUERY_KEYS_PROJECT.projects(),
    'project-work-applications',
    { projectId },
  ],
  projectPlanningProjectsFilters: (params: Record<string, unknown>) => [
    ...QUERY_KEYS_PROJECT.projects(),
    'project-planning-projects',
    { params },
  ],
} as const;

const QUERY_KEYS_CHECKOUT = {
  checkouts: () => [...QUERY_KEYS.all(), 'checkouts'],
  checkoutsWithFilters: (filters: Record<string, unknown>) => [
    ...QUERY_KEYS_CHECKOUT.checkouts(),
    'checkouts',
    filters,
  ],
  checkout: (shiftClaimId: string) => [
    ...QUERY_KEYS_CHECKOUT.checkouts(),
    'checkout',
    { shiftClaimId },
  ],
  checkoutsPending: (params?: Record<string, unknown>) => [
    ...QUERY_KEYS_CHECKOUT.checkouts(),
    'pending',
    { ...params },
  ],
  checkoutsSettled: (params?: Record<string, unknown>) => [
    ...QUERY_KEYS_CHECKOUT.checkouts(),
    'settled',
    { ...params },
  ],
  checkoutInboxFilters: () => [...QUERY_KEYS_CHECKOUT.checkouts(), 'checkout-inbox-filters'],
  checkoutArchiveFilters: () => [...QUERY_KEYS_CHECKOUT.checkouts(), 'checkouts-archive-filters'],
  checkoutInboxProjectsFilters: () => [...QUERY_KEYS_CHECKOUT.checkouts(), 'checkouts-filters'],
  checkoutArchiveProjectsFilters: () => [
    ...QUERY_KEYS_CHECKOUT.checkouts(),
    'checkout-archive-projects-filters',
  ],
  projectsCheckoutsArchive: (params?: Record<string, unknown>) => [
    ...QUERY_KEYS_PROJECT.projects(),
    'project-checkouts-archive',
    { params },
  ],
  projectsCheckouts: (params?: Record<string, unknown>) => [
    ...QUERY_KEYS_PROJECT.projects(),
    'project-checkouts',
    { params },
  ],
  checkoutsMetaData: () => [...QUERY_KEYS_PROJECT.projects(), 'checkoutsMetaData'],
} as const;

const QUERY_KEYS_DEPARTMENT = {
  departments: () => [...QUERY_KEYS.all(), 'departments'],
  department: (departmentId: string) => [...QUERY_KEYS_DEPARTMENT.departments(), { departmentId }],
  departmentDetail: (departmentId: string) => [
    ...QUERY_KEYS_DEPARTMENT.departments(),
    'departmentDetail',
    { departmentId },
  ],
  departmentAsDetail: (departmentId: string) => [
    ...QUERY_KEYS_DEPARTMENT.departments(),
    'departmentAsDetail',
    { departmentId },
  ],
  departmentsWithFilters: (params: Record<string, unknown> = {}) => [
    ...QUERY_KEYS_DEPARTMENT.departments(),
    'with-filters',
    { params },
  ],
  departmentsAsUserRolesList: (params: Record<string, unknown> = {}) => [
    ...QUERY_KEYS_DEPARTMENT.departments(),
    'as-user-roles',
    { params },
  ],
  departmentsForBranchOffice: (branchOfficeId?: string) => [
    ...QUERY_KEYS_DEPARTMENT.departments(),
    'for-branch-office',
    { branchOfficeId },
  ],
  departmentsForCompany: (companyId: string) => [
    ...QUERY_KEYS_DEPARTMENT.departments(),
    'for-company',
    { companyId },
  ],
} as const;

const QUERY_KEYS_BRANCH_OFFICE = {
  branchOffices: () => [...QUERY_KEYS.all(), 'branch-offices'],
  branchOffice: (branchOfficeId: string) => [
    ...QUERY_KEYS_BRANCH_OFFICE.branchOffices(),
    { branchOfficeId },
  ],
  branchOfficesActive: () => [...QUERY_KEYS_BRANCH_OFFICE.branchOffices(), 'branch-offices-active'],
  branchOfficesForCompany: (companyId: string) => [
    ...QUERY_KEYS_BRANCH_OFFICE.branchOffices(),
    'for-company',
    { companyId },
  ],
  branchOfficesListing: () => [...QUERY_KEYS_BRANCH_OFFICE.branchOffices(), 'listing'],
  branchOfficesList: () => [...QUERY_KEYS_BRANCH_OFFICE.branchOffices(), 'list'],
  brachOfficeCreateData: () => [...QUERY_KEYS_BRANCH_OFFICE.branchOffices(), 'create-data'],
} as const;

const QUERY_KEYS_COMPANY = {
  companies: () => [...QUERY_KEYS.all(), 'companies'],
  companyBasicInfo: (companyId?: string) => [
    ...QUERY_KEYS_COMPANY.companies(),
    { companyId },
    'basic-info',
  ],
  company: (companyId: string) => [...QUERY_KEYS_COMPANY.companies(), { companyId }],
  companiesList: (filter: string | undefined) => [
    ...QUERY_KEYS_COMPANY.companies(),
    'companies',
    { filter },
  ],
  companyPartnersByCompany: (companyId: string) => [
    ...QUERY_KEYS_COMPANY.companies(),
    'partners',
    { companyId },
  ],
  companyGrossHourlyRate: (companyId: string) => [
    ...QUERY_KEYS_COMPANY.companies(),
    'gross-hourly-rate',
    { companyId },
  ],
  companyForEmploymentTypes: (companyId?: string) => [
    ...QUERY_KEYS_COMPANY.companies(),
    'employment-types',
    { companyId },
  ],
  companiesForFlexWorker: (workerId: string) => [
    ...QUERY_KEYS_COMPANY.companies(),
    'for-flexWorker',
    { workerId },
  ],
  companyEmploymentTypes: (companyId: string) => [
    ...QUERY_KEYS_COMPANY.companies(),
    'company-employment-types',
    { companyId },
  ],
  ownCompanyDetailAndJobGroups: () => [
    ...QUERY_KEYS_COMPANY.companies(),
    'own-company-detail-and-job-groups',
  ],
  ownCompanyEmploymentTypes: () => [
    ...QUERY_KEYS_COMPANY.companies(),
    'own-company-employment-types',
  ],
  billingEntities: () => [...QUERY_KEYS_COMPANY.companies(), 'billingEntities'],
  billingEntitiesListing: () => [...QUERY_KEYS_COMPANY.companies(), 'billingEntitiesListing'],
  billingEntitiesForCompany: (companyId: string) => [
    ...QUERY_KEYS_COMPANY.companies(),
    'billingEntities',
    { companyId },
  ],
  billingEntity: (id: string) => [...QUERY_KEYS_COMPANY.companies(), 'billingEntities', { id }],
  orts: () => [...QUERY_KEYS_COMPANY.companies(), 'orts'],
} as const;

const QUERY_KEYS_WORK_TEMPLATE = {
  workTemplates: () => [...QUERY_KEYS.all(), 'work-templates'],
  workTemplate: (workTemplateId: string) => [
    ...QUERY_KEYS_WORK_TEMPLATE.workTemplates(),

    { workTemplateId },
  ],
  workTemplatesForCompany: (companyId?: string) => [
    ...QUERY_KEYS_WORK_TEMPLATE.workTemplates(),
    'for-company',
    { companyId },
  ],
} as const;

const QUERY_KEYS_WORK = {
  work: () => [...QUERY_KEYS.all(), 'work'],
  workDetails: (workId: string) => [QUERY_KEYS_WORK.work(), 'details', { workId }],
  workCreateData: (companyId?: string) => [...QUERY_KEYS_WORK.work(), 'create-data', { companyId }],
} as const;

const QUERY_KEYS_WORKER = {
  workers: () => [...QUERY_KEYS.all(), 'flexWorkers'],
  worker: (workerId: string) => [...QUERY_KEYS_WORKER.workers(), { workerId }],
  workersList: (filters: Record<string, unknown>) => [
    ...QUERY_KEYS_WORKER.workers(),
    'list',
    { filters },
  ],
  workersForShift: (shiftId: string, filters?: Record<string, unknown>) => [
    ...QUERY_KEYS_WORKER.workers(),
    'for-shift',
    { shiftId },
    { ...filters },
  ],
  flexWorkerBankAccounts: () => [...QUERY_KEYS_WORKER.workers(), 'bank-accounts'],
  flexWorkerProfile: (workerId: string) => [workerId, 'profile'],
  workerList: (search: string) => [...QUERY_KEYS_WORKER.workers(), 'worker-list', { search }],
  workerIdCheck: (workerId: string) => [
    ...QUERY_KEYS_WORKER.workers(),
    'worker-id-check',
    { workerId },
  ],
  flexWorkersForProject: (projectId: string, filters?: Record<string, unknown>) => [
    ...QUERY_KEYS_WORKER.workers(),
    'for-project',
    { projectId },
    { ...filters },
  ],
  flexWorkersForShiftThatWorkedForCompany: (shiftId: string, filters?: Record<string, unknown>) => [
    ...QUERY_KEYS_WORKER.workers(),
    'for-shift-that-worked-for-company',
    { shiftId },
    { ...filters },
  ],
  flexWorkerWithFlexPoolsForShift: (workerId: string, shiftId: string) => [
    ...QUERY_KEYS_WORKER.worker(workerId),
    'with-flexpools-for-shift',
    { shiftId },
  ],
  partnerWorkersFiltered: (filters?: string) => [
    ...QUERY_KEYS.workers(),
    'partner-workers-filtered',
    { filters },
  ],
  partnerWorker: (workerId: string) => [
    ...QUERY_KEYS_WORKER.workers(),
    'partner-worker',
    { workerId },
  ],
  statistics: () => [...QUERY_KEYS_WORKER.workers(), 'statistics'],
  schedule: () => [...QUERY_KEYS_WORKER.workers(), 'schedule'],
  flexWorkerSchedule: (workerId: string) => [
    ...QUERY_KEYS_WORKER.workers(),
    'schedule',
    { workerId },
  ],
} as const;

const QUERY_KEYS_PARTNER = {
  partners: () => [...QUERY_KEYS.all(), 'partners'],
  partner: (partnerId: string) => [...QUERY_KEYS_PARTNER.partners(), { partnerId }],
  partnerRelations: (partnerId: string) => [
    ...QUERY_KEYS_PARTNER.partners(),
    'partnerRelations',
    { partnerId },
  ],
  partnerUsers: (partnerUserId: string | undefined) => [
    ...QUERY_KEYS_PARTNER.partners(),
    'partnerUsers',
    { partnerUserId },
  ],
  partnerUser: (userId: string | undefined) => [
    ...QUERY_KEYS_PARTNER.partners(),
    'partner-user',
    { userId },
  ],
} as const;

const QUERY_KEYS_FLEXPOOL = {
  flexPools: () => [...QUERY_KEYS.all(), 'flex-pools'],
  flexPool: (flexPoolId: string) => [...QUERY_KEYS_FLEXPOOL.flexPools(), { flexPoolId }],
  flexPoolList: () => [...QUERY_KEYS_FLEXPOOL.flexPools(), 'flex-pool-list'],
  flexPoolsForFlexWorker: (flexWorkerId: string) => [
    ...QUERY_KEYS_FLEXPOOL.flexPools(),
    'for-flexWorker',
    { flexWorkerId },
  ],
  flexPoolsWithParams: (params: Record<string, unknown>) => [
    ...QUERY_KEYS_FLEXPOOL.flexPools(),
    'flexPoolsWithParams',
    { params },
  ],
  flexPoolJobs: (jobId: string) => [...QUERY_KEYS_FLEXPOOL.flexPools(), 'jobs', { jobId }],
  flexPoolListWithParams: (params: Record<string, unknown>) => [
    ...QUERY_KEYS_FLEXPOOL.flexPools(),
    'flex-pool-list',
    { params },
  ],
  flexPoolWithFlexWorkers: (flexPoolId: string) => [
    ...QUERY_KEYS_FLEXPOOL.flexPools(),
    'with-flex-workers',
    { flexPoolId },
  ],
  flexPoolForFlexworker: (flexPoolId: string, flexWorkerName?: string) => [
    ...QUERY_KEYS_FLEXPOOL.flexPools(),
    'flexPoolForFlexworker',
    { flexPoolId, flexWorkerName },
  ],
  flexpoolManagementData: () => [...QUERY_KEYS_FLEXPOOL.flexPools(), 'flexpool-management-data'],
} as const;

const QUERY_KEYS_JOB = {
  jobs: () => [...QUERY_KEYS.all(), 'jobs'],
  job: (jobId: string) => [...QUERY_KEYS_JOB.jobs(), { jobId }],
  jobDetail: (jobId: string) => [...QUERY_KEYS_JOB.jobs(), 'jobDetail', { jobId }],
  jobListing: (params: Record<string, unknown>) => [
    ...QUERY_KEYS_JOB.jobs(),
    'listing',
    { params },
  ],
  getJobCreateData: () => [...QUERY_KEYS.all(), 'getJobCreateData'],
} as const;

const QUERY_KEYS_PLACEMENT = {
  placements: () => [...QUERY_KEYS.all(), 'placements'],
  placementsWithFilters: (params: Record<string, unknown>) => [
    ...QUERY_KEYS_PLACEMENT.placements(),
    'placements-with-filters',
    { params },
  ],
} as const;

const QUERY_KEYS_USERS = {
  users: () => [...QUERY_KEYS.all(), 'users'],
  user: (userId: string) => [...QUERY_KEYS_USERS.users(), { userId }],
  ogpUserInfo: () => [...QUERY_KEYS_USERS.users(), 'ogp', 'user-info'],
  hqUserInfo: () => [...QUERY_KEYS_USERS.users(), 'hq', 'user-info'],
  partnerUserInfo: () => [...QUERY_KEYS_USERS.users(), 'partner', 'user-info'],
  rolesMatrix: () => [...QUERY_KEYS_USERS.users(), 'roles-matrix'],
} as const;

const QUERY_KEYS_FILTER = {
  filters: () => [...QUERY_KEYS.all(), 'filters'],
  businessUnits: () => [...QUERY_KEYS_FILTER.filters(), 'business-units'],
  jobTypes: () => [...QUERY_KEYS_FILTER.filters(), 'job-types'],
  jobTypesList: (companyId?: string, branchOfficeId?: string) => [
    ...QUERY_KEYS_FILTER.filters(),
    'jobTypesList',
    { companyId, branchOfficeId },
  ],
  filterWorkersData: () => [...QUERY_KEYS_FILTER.filters(), 'filters-workers'],
} as const;

const QUERY_KEYS_INBOX = {
  inbox: () => [...QUERY_KEYS.all(), 'inbox'],
  notifications: () => [...QUERY_KEYS_INBOX.inbox(), 'notifications'],
  inboxMessages: () => [...QUERY_KEYS_INBOX.inbox(), 'messages'],
  inboxMessage: (messageId: string) => [...QUERY_KEYS_INBOX.inbox(), 'messages', { messageId }],
} as const;

const QUERY_KEYS_ACCREDITATION = {
  accreditations: () => [...QUERY_KEYS.all(), 'accreditations'],
  certificates: () => [...QUERY_KEYS_ACCREDITATION.accreditations(), 'certificates'],
  accreditationDetail: () => [...QUERY_KEYS_ACCREDITATION.accreditations(), 'accreditationDetail'],
  accreditationsWithFilters: (filterParams: Record<string, unknown>) => [
    ...QUERY_KEYS_ACCREDITATION.accreditations(),
    'accreditationsWithFilters',
    { filterParams },
  ],
  accreditationsCertificateNames: () => [
    ...QUERY_KEYS_ACCREDITATION.accreditations(),
    'accreditationsCertificateNames',
  ],
  accreditationsCertificates: () => [
    ...QUERY_KEYS_ACCREDITATION.accreditations(),
    'accreditationsCertificates',
  ],
  accreditationsProfile: () => [
    ...QUERY_KEYS_ACCREDITATION.accreditations(),
    'accreditationsProfile',
  ],
  requiredCertificatesForJobType: (jobTypeId: string) => [
    ...QUERY_KEYS_ACCREDITATION.accreditations(),
    'required-certificates-for-job-type',
    { jobTypeId },
  ],
} as const;

const QUERY_KEYS_COUNTER_OFFER = {
  counterOffers: () => [...QUERY_KEYS.all(), 'counterOffers'],
  counterOffersWithFilters: (filters: Record<string, unknown>) => [
    ...QUERY_KEYS_COUNTER_OFFER.counterOffers(),
    'counterOffersWithFilters',
    { filters },
  ],
  counterOffer: (shiftClaimId: string) => [
    ...QUERY_KEYS.counterOffers(),
    'counterOffer',
    { shiftClaimId },
  ],
} as const;

const QUERY_KEYS_ENUMS = {
  noShowReasons: () => [...QUERY_KEYS_WORKER.workers(), 'no-show-reasons'],
} as const;

const QUERY_KEYS_SYSTEM = {
  system: () => [...QUERY_KEYS.all(), 'system'],
  platformSettings: () => [...QUERY_KEYS_SYSTEM.system(), 'platformSettings'],
  environmentConfiguration: () => [...QUERY_KEYS_SYSTEM.system(), 'environment-configuration'],
  featureFlags: () => [...QUERY_KEYS_SYSTEM.system(), 'feature-flags'],
  version: () => [...QUERY_KEYS_SYSTEM.system(), 'version'],
  fallbackWorkImage: () => [...QUERY_KEYS_SYSTEM.system(), 'fallback-work-image'],
} as const;

const QUERY_KEYS_NOT_WELCOME_LIST = {
  notWelcomeList: () => [...QUERY_KEYS.all(), 'not-welcome-list'],
} as const;

export const QUERY_KEYS = {
  all: () => ['all'],
  ...QUERY_KEYS_SHIFT,
  ...QUERY_KEYS_DEPARTMENT,
  ...QUERY_KEYS_BRANCH_OFFICE,
  ...QUERY_KEYS_COMPANY,
  ...QUERY_KEYS_WORK_TEMPLATE,
  ...QUERY_KEYS_WORKER,
  ...QUERY_KEYS_FLEXPOOL,
  ...QUERY_KEYS_JOB,
  ...QUERY_KEYS_WORK,
  ...QUERY_KEYS_CHECKOUT,
  ...QUERY_KEYS_PROJECT,
  ...QUERY_KEYS_PARTNER,
  ...QUERY_KEYS_FILTER,
  ...QUERY_KEYS_PLACEMENT,
  ...QUERY_KEYS_USERS,
  ...QUERY_KEYS_INBOX,
  ...QUERY_KEYS_ACCREDITATION,
  ...QUERY_KEYS_COUNTER_OFFER,
  ...QUERY_KEYS_SYSTEM,
  ...QUERY_KEYS_ENUMS,
  ...QUERY_KEYS_NOT_WELCOME_LIST,
} as const;
