import { PlacementPlanningListItem } from 'hq/services/placement-service.types';
import { DateTime } from 'luxon';
import { stateLabels } from 'shared/utils/shift-claim-state';
import { formatEmploymentType } from 'shared/utils/formatting';
import { getFullNameWithInfix } from 'hq/components/views/flexWorkers/utils';
import { GetPlacementsWithParsedInfo } from '../placements.types';
export const getParsedTableData = (
  data: PlacementPlanningListItem[]
): GetPlacementsWithParsedInfo[] => {
  return data.map((value) => {
    const translatedEmploymentType = formatEmploymentType(value.worker.employmentType);
    const translatedPlacementState = mapPlacementStateToCopy(value.placementState);
    return {
      workerName: getFullNameWithInfix({
        firstName: value.worker.firstName,
        infix: value.worker.infix,
        lastName: value.worker.lastName,
      }),
      placementId: value.placementId,
      jobName: value.jobName,
      jobType: value.jobTypeName,
      workerEmploymentType: translatedEmploymentType,
      companyBranchOfficeInfo: {
        companyName: value.companyName,
        branchOfficeName: value.branchOfficeName,
      },
      departmentName: value.departmentName,
      dateTime: {
        fromDateTime: DateTime.fromISO(value.startDateTime),
        toDateTime: DateTime.fromISO(value.endDateTime),
      },
      state: translatedPlacementState,
      actions: value,
    };
  });
};

export const mapPlacementStateToCopy = (valueState: string): string => {
  switch (valueState) {
    case 'accepted':
      return stateLabels.accepted;
    case 'canceled':
      return stateLabels.cancelled;
    case 'checkoutPending':
      return stateLabels.checkoutPending;
    case 'cancelationOfferPending':
      return stateLabels.cancelationOfferPending;
    case 'cancelationOfferClaim':
      return stateLabels.cancelationOfferClaim;
    case 'checkoutSettled':
      return stateLabels.checkoutSettled;
    case 'noShow':
      return stateLabels.noShow;
    case 'counterOffer':
      return stateLabels.counterOffer;
    case 'cancelationOfferSettled':
      return stateLabels.cancelationOfferSettled;
    default:
      return '';
  }
};
