import { DateTime } from 'luxon';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useOgpUpdateShift } from 'shared/components/shift-forms/hooks/ogp/use-ogp-update-shift';
import { shiftAlerts, useAlert } from 'shared/components/alerts';
import { useOgpGetShift } from 'shared/components/shift-forms/hooks/ogp/use-ogp-get-shift';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { RenderUnsettledUI } from 'shared/components';
import { useCompanyRelativePath } from 'ogp/hooks';
import { invariant } from 'shared/utils/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { Header, Page } from 'redesign';
import { paths } from 'ogp/paths';
import { useCallback } from 'react';
import { ShiftDetailResponse } from 'shared/services/shift-service-types';
import { useGetWorkDetails } from 'shared/components/work-forms/details/hooks/use-get-work-details';
import { DetailsForWorkTemplate } from 'shared/components/work-forms/details/work-form-details.types';
import { transformData } from '../../utils/transform-data';
import { OgpShiftForm } from '../ogp-shift-form';
import { ToolboxFormCreate } from '../../../common/toolbox-form-create';
import { OGPShiftFormData } from '../../types/shift-forms.types';
import { getDefaultValuesForShiftForm } from '../../utils/get-default-values-for-shift-form';

type ShiftEditProps = {
  shift: ShiftDetailResponse;
  shiftId: string;
  workDetails: DetailsForWorkTemplate;
};

export const OgpShiftEditDataProvider = () => {
  const { id: shiftId } = useParams<'id'>();
  invariant(shiftId, 'Shift ID was supposed to be passed via route param');
  const shiftQuery = useOgpGetShift(shiftId);
  const workDetailsQuery = useGetWorkDetails(shiftId);

  if (shiftQuery.status !== 'success') {
    return <RenderUnsettledUI data={shiftQuery} />;
  }

  if (workDetailsQuery.status !== 'success') {
    return <RenderUnsettledUI data={workDetailsQuery} />;
  }

  return (
    <ShiftEdit shift={shiftQuery.data} shiftId={shiftId} workDetails={workDetailsQuery.data} />
  );
};

const ShiftEdit = ({ shift, shiftId, workDetails }: ShiftEditProps) => {
  const { alertSuccess, alertError } = useAlert();
  const theme = useTheme();
  const generatePath = useCompanyRelativePath();
  const navigate = useNavigate();
  const location = useLocation();
  const isUserComingFromCalendar = location.state?.isUserComingFromCalendar || false;

  const disableContractFields = shift.claimants.length > 0;

  const mutation = useOgpUpdateShift({
    onSuccess: (_, variables) => {
      navigate(
        generatePath(
          paths.ShiftPlanning,
          undefined,
          {
            shiftId,
            date: DateTime.fromISO(variables.startDate).toISODate(),
          },
          'calendar'
        )
      );

      alertSuccess(shiftAlerts.success.created);
    },
    onError: (e) => alertError(e),
  });

  const onCancel = useCallback(
    () =>
      isUserComingFromCalendar
        ? navigate(-1)
        : navigate(
            generatePath(
              paths.ShiftPlanning,
              undefined,
              { shiftId, date: DateTime.fromISO(shift.startDate).toISODate() },
              'calendar'
            )
          ),
    [navigate, generatePath, shiftId]
  );

  const formMethods = useForm<OGPShiftFormData>({
    defaultValues: {
      ...getDefaultValuesForShiftForm(shift),
      jobId: undefined,
    },
  });

  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit((shiftFormData: OGPShiftFormData) => {
      const data = transformData(shiftFormData);
      mutation.mutate({ ...data, id: shiftId });
    })();
  }, [formMethods, mutation, shiftId]);

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Shift bewerken" />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={onCancel}
          />
        }
      >
        <Box>
          {disableContractFields ? (
            <Typography style={{ marginBottom: theme.spacing(2) }}>
              Contract-gerelateerde velden kunnen niet aangepast worden, omdat er claims op deze
              shift staan.
            </Typography>
          ) : null}
          <Grid container>
            <Grid item xs={12}>
              <OgpShiftForm
                mode={disableContractFields ? 'disableContractFields' : 'update'}
                workDetails={workDetails}
              />
            </Grid>
          </Grid>
        </Box>
      </Page>
    </FormProvider>
  );
};
