import { useFormContext } from 'react-hook-form';
import { LwFormSelect } from 'redesign';

export const JobFormElement = ({
  options,
  disabled,
}: {
  options: React.ComponentProps<typeof LwFormSelect>['options'];
  disabled?: React.ComponentProps<typeof LwFormSelect>['disabled'];
}) => {
  const { control } = useFormContext<{ jobId: string }>();
  return (
    <LwFormSelect
      name="jobId"
      label="Functie"
      defaultLabel="Selecteer een functie..."
      options={options}
      disabled={disabled}
      control={control}
      data-testid="job-select"
      rules={{ required: 'Selecteer een functie' }}
    />
  );
};
