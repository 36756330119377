import type { Column } from 'react-table';
import { Chip, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ContextMenuButton, TableCell, TableHeader } from 'redesign';
import { IconFlexPoolOnlyJob } from 'assets/img';
import { CancelledCellProps, CancelledColumnDefinition } from './cancelled-table.types';
import { getShiftDetailsColumnDefinitionBase } from '../../shared/columns/get-shift-details-column-definition-base';
import { ArchiveTableColumnDefinition } from '../../../../../../../ogp/components/views/administration';

export const getCancelledColumnDefinition: () => Column<CancelledColumnDefinition>[] = () => {
  const [flexWorkerImage, fw] = getShiftDetailsColumnDefinitionBase();

  const cancellationReason: Column<CancelledColumnDefinition> = {
    Header: '',
    accessor: 'cancellationReason',
    Cell: CancellationReasonCell,
  };

  const flexPoolIndicator: Column<CancelledColumnDefinition> = {
    Header: '',
    accessor: 'flexPoolIndicator',
    Cell: FlexPoolIndicatorCell,
  };
  const hourlyRate: Column<CancelledColumnDefinition> = {
    Header: () => <TableHeader>Tarief zzp'er</TableHeader>,
    accessor: 'hourlyRate',
    Cell: HourlyRateCell,
  };

  const actions: Column<ArchiveTableColumnDefinition> = {
    accessor: 'actions',
    Header: '',
    Cell: ActionCell,
  };

  return [flexWorkerImage, cancellationReason, flexPoolIndicator, fw, hourlyRate, actions];
};

const HourlyRateCell = ({ value }: CancelledCellProps<'hourlyRate'>) => {
  return (
    <TableCell>
      {value.toLocaleString('nl-NL', {
        style: 'currency',
        currency: 'EUR',
      })}
    </TableCell>
  );
};

const FlexPoolIndicatorCell = ({ value }: CancelledCellProps<'flexPoolIndicator'>) => {
  return value ? <IconFlexPoolOnlyJob width={24} /> : null;
};

const CancellationReasonCell = ({ value }: CancelledCellProps<'cancellationReason'>) => {
  const classes = getClasses();
  return <Chip className={classes.chip} size="small" label={value} />;
};

const ActionCell = ({ value, getRowActions }: CancelledCellProps<'actions'>) => {
  const actions = getRowActions(value);

  if (actions.length === 0) {
    return null;
  }

  return (
    <ContextMenuButton menuId={`cancelled-actions-menu-${value.workerId}`}>
      {actions}
    </ContextMenuButton>
  );
};

const getClasses = makeStyles((theme: Theme) => {
  return {
    chip: {
      backgroundColor: theme.palette.warning.main,
      '& classˆ="MuiChip-label"': {
        ...theme.typography.body2,
      },
    },
  };
});
