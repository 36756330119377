import { QUERY_KEYS } from 'api/query-keys';
import { shiftApplicationService } from 'ogp/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

interface ShiftApplicationMutationParams {
  shiftApplicationId: string;
  flexWorkerId: string;
}
type ShiftApplicationMutationOptions = UseMutationOptions<
  unknown,
  unknown,
  ShiftApplicationMutationParams
>;

export const useDenyShiftApplication = (options?: ShiftApplicationMutationOptions) => {
  const mutationFn = async (params: ShiftApplicationMutationParams) =>
    await shiftApplicationService.denyShiftApplication(
      params.shiftApplicationId,
      params.flexWorkerId
    );

  const queryClient = useQueryClient();
  const mutationOptions: ShiftApplicationMutationOptions = {
    onSettled: async () => {
      queryClient.invalidateQueries(QUERY_KEYS.shifts());
    },
    ...options,
  };

  return useMutation(mutationFn, mutationOptions);
};
