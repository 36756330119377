import { FormCheckbox } from 'redesign';
import { useFormContext } from 'react-hook-form';
import { Box, styled } from '@mui/material';
import { FilterApplicantStatusFormContext } from './filter-applicant-status.types';

const applicationTypesArray = [
  { name: 'areFreelanceApplications' as const, label: 'Freelancer' },
  { name: 'areTempworkApplications' as const, label: 'Uitzendkracht' },
  { name: 'areNoApplications' as const, label: 'Geen aanmeldingen' },
];

export const FilterApplicantStatus = () => {
  const { watch, control, clearErrors } = useFormContext<FilterApplicantStatusFormContext>();
  const { applicationTypesCheckboxValue } = watch();

  return (
    <Box width={200}>
      <FormCheckbox
        name="applicationTypesCheckboxValue"
        label="Heeft aanmeldingen"
        control={control}
        isHeaderCheckbox={true}
      />
      {applicationTypesCheckboxValue ? (
        <StyledBox>
          {applicationTypesArray.map((item) => (
            <FormCheckbox
              label={item.label}
              key={item.name}
              name={item.name}
              onChange={(val) => {
                if (val) {
                  clearErrors('areFreelanceApplications');
                }
              }}
              control={control}
            />
          ))}
        </StyledBox>
      ) : null}
    </Box>
  );
};

const StyledBox = styled(Box)(() => ({
  paddingLeft: '10px',
}));
