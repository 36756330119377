import { QUERY_KEYS } from 'api/query-keys';
import { companyService } from 'ogp/services';
import { useMutation, useQueryClient } from 'react-query';

export const useAddFlexWorkerToNotWelcomeList = () => {
  const mutationFn = async ({
    flexWorkerId,
    reason,
    departmentIds,
    shouldCancelAll,
  }: {
    flexWorkerId: string;
    reason: string;
    departmentIds: string[];
    shouldCancelAll: boolean;
  }) =>
    await companyService.addFlexWorkerToNotWelcomeList(
      flexWorkerId,
      reason,
      departmentIds,
      shouldCancelAll
    );
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.notWelcomeList());
    },
  });
};
