import { LocationAutoSuggestItem, LocationData } from '@types';
import { breakOptionsSelectInputMapper } from 'shared/components/shift-forms/components/forms/shared/inputs/break-minutes-input';
import { SmallError } from 'shared/components/common/state/SmallError';
import { SmallLoader } from 'shared/components/common/state/SmallLoader';

import { RenderUnsettledUI } from 'shared/components/render-unsettled-ui';
import { useSiteDetection } from 'shared/hooks';
import { useGetBreakOptions } from 'partner/components/views/partner-checkouts/checkout-create/hooks/use-get-break-options';
import { BulkShiftForm } from './bulk-shift-edit-form';

export type BulkShiftEditForm = {
  startTime: string | null;
  endTime: string | null;
  breakMinutes: number | null;
  maxClaimants: number | null;
  location: LocationAutoSuggestItem | LocationData | null;
};

export const BulkShiftEditFormProvider = () => {
  const { site } = useSiteDetection();
  const breakOptionsQuery = useGetBreakOptions(site);

  if (breakOptionsQuery.status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={breakOptionsQuery}
      />
    );
  }

  const breakMinutesOptions = breakOptionsSelectInputMapper(breakOptionsQuery.data.breakOptions);

  return <BulkShiftForm breakOptions={breakMinutesOptions} />;
};
