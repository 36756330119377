import { IconIsolationMode } from 'assets/img';
import type { Column } from 'react-table';
import {
  BoxAction,
  BoxIcon,
  CandidatePlacementsCell,
  Cell,
  DateTimeCell,
  Header,
  PartnersCell,
} from 'shared/components/planner/common/utils';
import {
  TableShiftPlanningCellProp,
  TableShiftPlanningColumnDefinition,
} from '../shift-planning-table.types';

const getTableShiftPlanningColumnDefinition: () => Column<TableShiftPlanningColumnDefinition>[] =
  () => {
    const jobName = {
      Header: () => <Header>Functie</Header>,
      accessor: 'jobName',
      Cell,
      width: '200px',
    } as Column<TableShiftPlanningColumnDefinition>;
    const jobType = {
      Header: () => <Header>Functiegroep</Header>,
      accessor: 'jobTypeName',
      Cell,
      width: '200px',
    } as Column<TableShiftPlanningColumnDefinition>;

    const departmentName = {
      Header: () => <Header>Afdeling</Header>,
      accessor: 'departmentName',
      Cell: Cell,
      width: '350px',
    } as Column<TableShiftPlanningColumnDefinition>;
    const dateTime = {
      Header: () => <Header>Datum & tijd</Header>,
      accessor: 'dateTime',
      Cell: DateTimeCell,
      width: '230px',
    } as Column<TableShiftPlanningColumnDefinition>;
    const state = {
      Header: () => <Header>Status</Header>,
      accessor: 'state',
      Cell: PartnerStateCell,
      width: '100px',
    } as Column<TableShiftPlanningColumnDefinition>;
    const partners = {
      Header: () => <Header>Partners</Header>,
      accessor: 'partners',
      Cell: PartnersCell,
      width: '85px',
    } as Column<TableShiftPlanningColumnDefinition>;
    const action = {
      Header: () => <Header>Actie</Header>,
      accessor: 'action',
      Cell: ActionCell,
      width: '65px',
    } as Column<TableShiftPlanningColumnDefinition>;

    return [jobName, jobType, departmentName, dateTime, state, partners, action];
  };

const ActionCell = (props: TableShiftPlanningCellProp<'action'>) => {
  return (
    <BoxAction>
      <BoxIcon>
        <IconIsolationMode onClick={() => props.onShowDetail(props.value)} />
      </BoxIcon>
    </BoxAction>
  );
};

const PartnerStateCell = (props: TableShiftPlanningCellProp<'state'>) => {
  return <CandidatePlacementsCell {...props} />;
};

export { getTableShiftPlanningColumnDefinition as getShiftPlanningColumnDefinition };
