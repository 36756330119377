import { ProjectForm } from 'shared/components/project-forms/components/project-form';
import { DetailsForWorkTemplate } from 'shared/components/work-forms/details/work-form-details.types';

type Props = {
  workDetails: DetailsForWorkTemplate | undefined;
};

export const ProjectCreateFormProvider = ({ workDetails }: Props) => {
  return (
    <ProjectForm mode="create" isProjectWithActivePlacement={false} workDetails={workDetails} />
  );
};
