import { useEffect } from 'react';

export const useHubSpotChat = (isEnabled: boolean) => {
  useEffect(() => {
    const styleId = 'hubspot-override-style';
    let styleTag = document.getElementById(styleId);

    if (!styleTag) {
      styleTag = document.createElement('style');
      styleTag.id = styleId;
      document.head.appendChild(styleTag);
    }

    // We need to use specific selectors to override the chat iframe. (Just !important doesn't override the Hubspot styles)
    styleTag.innerHTML = isEnabled
      ? ''
      : `html body #hubspot-messages-iframe-container { display: none !important; }`;
  }, [isEnabled]);
};
