import { z } from 'zod';
import { locationSchema, vatSchema } from '../zod-schemas/schema';

const keyValueSchema = z.object({
  id: z.string(),
  name: z.string(),
});

const tempWorkSchema = z.object({
  grossHourlyRateCents: z.number(),
  cla: z.string(),
});

const freelanceSchema = z.object({
  hourlyRateCents: z.number(),
  mutableUntilMinutes: z.number(),
});

const jobRequirementsSchema = z.object({
  skills: z.array(keyValueSchema),
  language: z.array(keyValueSchema),
  apparel: z.array(keyValueSchema),
});

const flexpoolsSchema = z.object({
  id: z.string(),
  name: z.string(),
  directClaimAllowed: z.boolean(),
});

export const workTemplateSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    descriptionShort: z.string(),
    descriptionLawRequirements: z.string(),
    descriptionLong: z.string().nullable(),
    contactName: z.string(),
    contactPhoneNumber: z.string(),
    location: locationSchema.nullable(),
    locationUrl: z.string(),
    departmentId: z.string().nullable(),
    breakMinutes: z.number(),
    imageUrl: z.string().nullable(),
    VAT: vatSchema,
    flexPools: z.array(flexpoolsSchema),
    flexPoolOnly: z.boolean(),
    costCenter: z.string().nullable(),
    jobTypeId: z.string().nullable(),
    ortApplicable: z.boolean(),
    tempWorkJob: tempWorkSchema.optional(),
    freelanceJob: freelanceSchema.optional(),
    requiredCertificates: z.array(keyValueSchema).optional(),
    jobCertificates: z.array(keyValueSchema).optional(),
    jobRequirements: jobRequirementsSchema.optional(),
  })
  .transform((x) => ({ ...x, tempWorkJob: x.tempWorkJob, freelanceJob: x.freelanceJob }));

export const workTemplatesSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
    flexPoolOnly: z.boolean(),
  })
);

export type WorkTemplateDetail = z.infer<typeof workTemplateSchema>;
export type WorkTemplatesResponse = z.infer<typeof workTemplatesSchema>;
