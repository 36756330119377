import { Box, Divider, Grid, styled } from '@mui/material';
import { CompanyEmploymentTypes, CompanyRole, vatListOptions } from '@types';
import { useFormContext } from 'react-hook-form';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { useGetDepartmentAsDetail } from 'ogp/components/views/management/departments';
import { PolicyGroups } from 'shared/utils/policy-controller';
import { AuthenticatedElement } from 'ogp/components/common/authenticated-element';
import {
  BreakMinutesSelect,
  ClaInput,
  ContactNameInput,
  ContactPhoneWithPrefixInput,
  CostCenterInput,
  DepartmentSelect,
  DescriptionLegalRequirementsInput,
  FlexpoolOnlySwitch,
  HourlyRateInput,
  ImageUrlInput,
  IsFreelanceCheckbox,
  IsOrtApplicableSwitch,
  IsTempworkCheckbox,
  JobCertificatesSelect,
  JobRequirementsSelect,
  JobTypeSelect,
  LongDescriptionInput,
  MutableUntilHoursInput,
  ShortDescriptionInput,
  VatSelect,
  WorkNameInput,
} from 'shared/components/forms/inputs';
import { JobCreateDataModel } from 'ogp/services/mappers/mappers';
import { FlexPoolsSection } from './flex-pools/flex-pools-section';
import { LocationSelect } from './location';
import { JobFormContext, JobFormState } from './job-form.types';
import { JobTypeRequiredCertificatesWrapper } from '../../job-type-required-certificates-list/job-type-required-certificates-wrapper';

const atLeastOneType = (val: any, formData: Partial<JobFormContext>) => {
  if (!formData.isTempwork && !formData.isFreelance) {
    return 'Vul tenminste 1 arbeidsvorm in';
  }
};

export const JobForm = ({
  employmentType,
  jobCreateData,
  formState,
}: {
  employmentType: CompanyEmploymentTypes;
  jobCreateData: JobCreateDataModel;
  formState: JobFormState;
}) => {
  const { allowReverseChargingVAT } = useFeatureFlagContext();
  const { watch, setValue, reset, trigger, unregister } = useFormContext<JobFormContext>();
  const isTempwork = watch('isTempwork');
  const isFreelance = watch('isFreelance');
  const jobTypeIdWatch = watch('jobType');
  const departmentId = watch('department');
  const { data: departmentData } = useGetDepartmentAsDetail(
    departmentId ? departmentId.toString() : undefined
  );

  const isFreelancePropertyRequired = isFreelance && formState !== 'read';
  const isTempworkPropertyRequired = isTempwork && formState !== 'read';

  return (
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Box display="flex" flexDirection="row" height="100%">
          <form aria-label="Job form">
            <Grid container item spacing={4} xs={12}>
              <Grid item xs={6}>
                <AuthenticatedElement
                  roles={CompanyRole.HQADMIN}
                  policyGroup={PolicyGroups.COMPANY}
                >
                  <FlexpoolOnlySwitch disabled={formState === 'read'} />
                </AuthenticatedElement>
              </Grid>
              <Grid item xs={6}>
                <IsOrtApplicableSwitch
                  disabled={!jobCreateData.companyOrtProvided || formState === 'read'}
                />
              </Grid>

              <Grid item xs={6}>
                <WorkNameInput disabled={formState === 'read'} required={true} />
              </Grid>
              <Grid item xs={6}>
                <DepartmentSelect
                  disabled={formState === 'read' || formState === 'update'}
                  options={jobCreateData.branchOfficesWithDepartments}
                  onChange={() => {
                    if (!departmentData) {
                      return;
                    }
                    setValue('vat', departmentData.VAT);
                    setValue('flexPools', []);
                    reset((formValues) => ({
                      ...formValues,
                      flexPool: '',
                    }));
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <IsFreelanceCheckbox
                  disabled={!employmentType.offersFreelanceWork || formState === 'read'}
                  onChange={async () => {
                    await Promise.all([trigger('isFreelance'), trigger('isTempwork')]);
                    unregister('hourlyRate'); // Input needs to be re-registered after checkbox changes so rules are updated
                    unregister('mutableUntilHours'); // Input needs to be re-registered after checkbox changes so rules are updated
                    await Promise.all([trigger('mutableUntilHours'), trigger('hourlyRate')]);
                  }}
                  validate={atLeastOneType}
                />
              </Grid>
              <Grid item xs={6}>
                <HourlyRateInput
                  disabled={!isFreelancePropertyRequired}
                  required={isFreelancePropertyRequired}
                />
              </Grid>
              <Grid item xs={6}>
                <MutableUntilHoursInput
                  disabled={!isFreelancePropertyRequired}
                  required={isFreelancePropertyRequired}
                />
              </Grid>

              <Grid item xs={12}>
                <IsTempworkCheckbox
                  disabled={!employmentType.offersTempWork || formState === 'read'}
                  onChange={async () => {
                    await Promise.all([trigger('isFreelance'), trigger('isTempwork')]);
                    unregister('cla'); // Input needs to be re-registered after checkbox changes so rules are updated
                    await trigger('cla');
                  }}
                  validate={atLeastOneType}
                />
              </Grid>
              <Grid item xs={12}>
                <ClaInput
                  disabled={!isTempworkPropertyRequired}
                  required={isTempworkPropertyRequired}
                />
              </Grid>

              <Grid item xs={6}>
                <BreakMinutesSelect
                  options={jobCreateData.breakOptions}
                  disabled={formState === 'read'}
                  required={true}
                />
              </Grid>
              <Grid item xs={12}>
                <JobTypeSelect
                  disabled={formState === 'read' || formState === 'update'}
                  jobGroups={jobCreateData.jobGroups}
                  required={true}
                />
              </Grid>
              <Grid item xs={12}>
                <JobCertificatesSelect disabled={false} options={jobCreateData.jobCertificates} />
              </Grid>
              <FlexPoolsSection
                state={formState}
                flexPoolOptions={jobCreateData?.flexPools ?? []}
              />
              <Grid item xs={12}>
                <VatSelect
                  disabled={formState === 'read'}
                  options={vatListOptions(allowReverseChargingVAT)}
                />
              </Grid>
              <Grid item xs={12}>
                <ShortDescriptionInput disabled={formState === 'read'} required={true} />
              </Grid>
              <Grid item xs={12}>
                <LongDescriptionInput disabled={formState === 'read'} required={true} />
              </Grid>
              <JobRequirementsSelect
                disabled={formState === 'read'}
                options={jobCreateData.jobRequirements}
              />
              <Grid item xs={12}>
                <DescriptionLegalRequirementsInput
                  disabled={formState === 'read'}
                  required={true}
                />
              </Grid>
              <Grid item xs={6}>
                <ContactNameInput disabled={formState === 'read'} required={true} />
              </Grid>
              <Grid item xs={6}>
                <ContactPhoneWithPrefixInput disabled={formState === 'read'} required={true} />
              </Grid>
              <Grid item xs={12}>
                <CostCenterInput
                  disabled={formState === 'read'}
                  helperText={
                    departmentData?.costCenter
                      ? `De standaard voor deze afdeling is ${departmentData.costCenter}`
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <LocationSelect
                  disabled={formState === 'read'}
                  address={departmentData?.location?.address}
                />
              </Grid>
              <AuthenticatedElement roles={CompanyRole.HQADMIN} policyGroup={PolicyGroups.COMPANY}>
                <Grid item xs={12}>
                  <ImageUrlInput disabled={formState === 'read'} />
                </Grid>
              </AuthenticatedElement>
            </Grid>
          </form>
          <Box>
            <StyledDivider orientation="vertical" />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <JobTypeRequiredCertificatesWrapper jobTypeId={jobTypeIdWatch} />
      </Grid>
    </Grid>
  );
};

const StyledDivider = styled(Divider)(({ theme }) => ({
  height: '100%',
  marginLeft: theme.spacing(7),
  width: 2,
}));
