import { ProjectForm } from 'shared/components/project-forms/components/project-form';
import { DetailsForWorkTemplate } from 'shared/components/work-forms/details/work-form-details.types';

type Props = {
  workDetails: DetailsForWorkTemplate;
};

export const ProjectDuplicateFormProvider = ({ workDetails }: Props) => {
  return (
    <ProjectForm mode="duplicate" isProjectWithActivePlacement={false} workDetails={workDetails} />
  );
};
