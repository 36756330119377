import SignIn from 'shared/components/views/sign-in/SignIn';
import { AppSettingsProvider } from 'shared/contexts/AppSettingsContext';
import { CheckAuthentication } from 'shared/components/auth';
import { ThemeProvider } from 'shared/contexts/theme-provider';
import { TranslationProvider } from 'shared/contexts/translation-provider';

import { App } from './app';

const Root = () => {
  return (
    <AppSettingsProvider>
      <ThemeProvider>
        <TranslationProvider>
          <CheckAuthentication>
            {(signedIn) => (signedIn ? <App /> : <SignIn />)}
          </CheckAuthentication>
        </TranslationProvider>
      </ThemeProvider>
    </AppSettingsProvider>
  );
};

export { Root };
