import { DateTime } from 'luxon';
import { EmploymentType } from '@types';
import {
  HistoryEvent,
  HistoryEventActor,
  HistoryEventContent,
  KnownActionEvent,
  PropertyName,
  TranslatedEvent,
  TranslatedEventContent,
} from './types';

export class ShiftInfoTranslator {
  constructor(private employmentType: EmploymentType) {}

  public translateEvents(events: HistoryEvent[]): TranslatedEvent[] {
    const translatedEvents = events.map(this.translateEvent).filter(this.isTranslatedEvent);
    if (!translatedEvents.length) {
      return [{ definition: 'Geen informatie gevonden' }];
    } else {
      return translatedEvents;
    }
  }

  // List of Events that we want to show to the users. Expand as we need
  private translateEvent = (event: HistoryEvent): TranslatedEvent | null => {
    switch (event.type) {
      case 'ShiftCreated':
        return this.mapShiftCreatedEvent(event);
      case 'ShiftArchived':
        return this.mapShiftArchivedEvent(event);
      case 'ShiftUpdated':
        return this.mapShiftUpdatedEvent(event);
      case 'ShiftCreatedByMultiplication':
        return this.mapShiftCreatedByMultiplicationEventEvent(event);
      case 'Migrate':
        return this.mapShiftMigrateEvent(event);
      default:
        return null;
    }
  };

  private mapShiftCreatedEvent = (event: KnownActionEvent) => {
    const { actor, date, properties } = this.getTranslated(event);
    const definition = `Dienst is aangemaakt door ${actor} op ${date}`;
    return { definition, properties };
  };

  private mapShiftArchivedEvent = (event: KnownActionEvent) => {
    const { actor, date, properties } = this.getTranslated(event);
    const definition = `Gebruiker ${actor} heeft deze dienst gearchiveerd op: ${date}`;
    return { definition, properties };
  };

  private mapShiftUpdatedEvent = (event: KnownActionEvent) => {
    const { actor, date, properties } = this.getTranslated(event);
    const definition = `Deze dienst is bijgewerkt door ${actor} op ${date}, aanpassingen:`;
    return { definition, properties };
  };

  private mapShiftMigrateEvent = (event: KnownActionEvent) => {
    const { date, properties } = this.getTranslated(event);
    const definition = `Dienst is aangemaakt door "onbekend" op ${date}`;
    return { definition, properties };
  };

  private mapShiftCreatedByMultiplicationEventEvent = (event: KnownActionEvent) => {
    const { actor, date } = this.getTranslated(event);
    const definition = `Deze dienst is gedupliceerd door ${actor} op ${date}.`;
    return { definition };
  };

  private getTranslated = (
    event: KnownActionEvent
  ): { actor: string; date: string; properties: TranslatedEventContent[] | undefined } => ({
    actor: this.getTranslatedActor(event.actor),
    date: this.getTranslatedDate(event.createdAt),
    properties: this.getTranslatedProperties(event.properties),
  });

  private getTranslatedActor = (actor: HistoryEventActor) => {
    if (actor.archived) {
      return '(gearchiveerd)';
    } else {
      switch (actor.type) {
        case 'HQ':
          return `HQ medewerker "${actor.email}"`;
        case 'OGP':
          return `"${actor.email}"`;
        case 'APP':
          return `flexwerker "${actor.email}"`;
        default:
          return 'onbekend';
      }
    }
  };

  private getTranslatedDate = (dateISO: string) =>
    DateTime.fromISO(dateISO).toLocaleString({ dateStyle: 'medium', timeStyle: 'medium' });

  private getTranslatedProperties = (properties?: HistoryEventContent[]) => {
    if (properties) {
      const propertiesOnCents = ['hourlyRateCents', 'noShowFineCents'];
      const translatedProperties: TranslatedEventContent[] = [];
      properties.forEach((property) => {
        const translatedName = this.getTranslatedPropertyName(property.name);
        if (translatedName) {
          if (typeof property.value === 'string' && DateTime.fromISO(property.value).isValid) {
            translatedProperties.push({
              name: translatedName,
              value: this.getTranslatedDate(property.value),
            });
          } else {
            translatedProperties.push({
              name: translatedName,
              value: propertiesOnCents.includes(property.name)
                ? Number(property.value) / 100
                : property.value,
            });
          }
        }
      });
      return translatedProperties;
    }
  };

  private getTranslatedPropertyName = (propertyName: PropertyName) => {
    switch (propertyName) {
      case 'breakMinutes':
        return 'Pauze (mins)';
      case 'utcStartDate':
        return 'Startdatum en tijd';
      case 'utcEndDate':
        return 'Einddatum en tijd';
      case 'hourlyRateCents':
        return this.employmentType === 'FREELANCER' ? "Tarief zzp'er (€)" : 'Bruto uurloon (€)';
      case 'noShowFineCents':
        return 'No-show borg (€)';
      case 'maxClaimants':
        return 'Aantal plekken';
      case 'timeZone':
        return 'Tijdzone';
      case 'mutableUntilMinutes':
        return 'Annuleringstermijn';
      default:
        return null;
    }
  };

  private isTranslatedEvent = (event: TranslatedEvent | null): event is TranslatedEvent => {
    return event !== null;
  };
}
