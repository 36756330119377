import { BulkEditSidebar } from 'shared/components/bulk/bulk-shift-action/bulk-edit-sidebar';
import { BulkArchiveSidebar } from 'shared/components/bulk/bulk-shift-action/bulk-archive-sidebar';
import { TableShiftPlanningProvider } from './shift-planning-table';
import { usePlanningContext } from '../../providers/planning-actions-provider';

export const TablePlanning = () => {
  const { selectedShiftIds, handleSelectionChange, bulkSidebarState, setBulkSidebarState } =
    usePlanningContext();

  return (
    <>
      <TableShiftPlanningProvider
        selectedShiftIds={selectedShiftIds}
        onSelectionChange={handleSelectionChange}
      />
      <BulkEditSidebar
        selectedShiftIds={selectedShiftIds}
        isBulkSideBarOpen={bulkSidebarState === 'edit'}
        setIsBarSideBarOpen={() => setBulkSidebarState(null)}
      />
      <BulkArchiveSidebar
        selectedShiftIds={selectedShiftIds}
        isBulkArchiveBarOpen={bulkSidebarState === 'archive'}
        setIsBarSideBarOpen={() => setBulkSidebarState(null)}
      />
    </>
  );
};
