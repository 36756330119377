import { useFormContext } from 'react-hook-form';
import { FormSwitch } from 'redesign';
import { getRequiredMessage } from './shared';
import { IsOrtApplicableFormContext, Props } from './is-ort-applicable.types';

export const IsOrtApplicableSwitch = (props: Props) => {
  const { control } = useFormContext<IsOrtApplicableFormContext>();
  const formName = 'isOrtApplicable';
  const label = 'ORT toegepast';
  const requiredMessage = getRequiredMessage(label);
  return (
    <FormSwitch
      name={formName}
      control={control}
      label={label}
      disabled={props.disabled}
      rules={props.required ? { required: requiredMessage } : undefined}
    />
  );
};
