import { companyService } from 'ogp/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { QUERY_KEYS } from 'api/query-keys';
import { CompanyFormPayload } from '@types';

type CompanyMutationOptions = UseMutationOptions<unknown, unknown, CompanyFormPayload>;

export const useUpdateCompany = (companyId: string, options?: CompanyMutationOptions) => {
  const mutationFn = async (company: CompanyFormPayload) =>
    await companyService.updateCompany(companyId, company);

  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options ?? {};
  const mutationOptions: CompanyMutationOptions = {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(QUERY_KEYS.companies());
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  };

  return useMutation(mutationFn, mutationOptions);
};
