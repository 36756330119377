import { QUERY_KEYS } from 'api/query-keys';
import { accreditationsService } from 'ogp/services';
import { CertificateDataOGP } from 'ogp/services/accreditations-service.types';
import { useQuery } from 'react-query';

const useGetFlexworkerAccreditations = (flexWorkerId: string) => {
  const queryFn = async () => await accreditationsService.getAccreditations(flexWorkerId);

  return useQuery<CertificateDataOGP[], Error>(QUERY_KEYS.accreditations(), queryFn);
};

export { useGetFlexworkerAccreditations };
