import { Box, styled } from '@mui/material';
import { LwButton } from 'redesign';

type FormButtonsProps = {
  isSubmitting: boolean;
  submitLabel: string;
  onCancel?: () => void;
  onReset: () => void;
  isSubmitDisabled?: boolean;
};

export const BulkFormButtons = ({
  isSubmitting,
  submitLabel,
  onCancel = () => {},
  onReset,
  isSubmitDisabled,
}: FormButtonsProps) => {
  return (
    <Actions>
      <Box>
        <LwButton color="secondary" onClick={onCancel} disabled={isSubmitting}>
          Annuleren
        </LwButton>
      </Box>
      <Box display="flex" gap={5}>
        <LwButton color="secondary" onClick={onReset} disabled={isSubmitting}>
          Velden wissen
        </LwButton>
        <LwButton color="primary" type="submit" disabled={isSubmitting || isSubmitDisabled}>
          {submitLabel}
        </LwButton>
      </Box>
    </Actions>
  );
};

const Actions = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[200]}`,
  padding: theme.spacing(5),
  paddingTop: theme.spacing(7),
  paddingBottom: theme.spacing(7),
  display: 'flex',
  justifyContent: 'space-between',
}));
