import { workService as workServiceHQ } from 'hq/services';
import { workService as workServiceOGP } from 'ogp/services';
import { useQuery } from 'react-query';
import { useSiteDetection } from 'shared/hooks';
import { GetWorkDetailsForWorkTemplateHQ } from 'hq/services/work-service.types';
import { GetWorkDetailsForWorkTemplateOGP } from 'ogp/services/work-service.types';
import { QUERY_KEYS } from 'api/query-keys';

export const useGetWorkDetails = (workId: string) => {
  const { isHQ } = useSiteDetection();

  const queryFn = getQueryFn(isHQ);

  return useQuery<
    Promise<GetWorkDetailsForWorkTemplateHQ | GetWorkDetailsForWorkTemplateOGP>,
    Error,
    GetWorkDetailsForWorkTemplateHQ | GetWorkDetailsForWorkTemplateOGP
  >(QUERY_KEYS.workDetails(workId), () => queryFn(workId), {
    enabled: !!workId,
  });
};

const getQueryFn = (
  isHQ: boolean
): ((
  id: string
) => Promise<GetWorkDetailsForWorkTemplateHQ | GetWorkDetailsForWorkTemplateOGP>) => {
  return isHQ
    ? async (id: string) => await workServiceHQ.getWorkDetailsForWorkTemplate(id)
    : async (id: string) => await workServiceOGP.getWorkDetailsForWorkTemplate(id);
};
