import { useFormContext } from 'react-hook-form';
import { LwFormInput } from 'redesign';
import { CostCenterFormContext, Props } from './cost-center.types';

export const CostCenterInput = (props: Props) => {
  const { control } = useFormContext<CostCenterFormContext>();

  return (
    <LwFormInput
      name="costCenter"
      label="Kostenplaats"
      control={control}
      disabled={props.disabled}
      helperText={props.helperText}
    />
  );
};
