import { shiftClaimService } from 'hq/services';
import { useQuery } from 'react-query';
import { HQShiftClaimForFlexWorkerListItem } from 'hq/services/shift-claim-service.types';
import { QUERY_KEYS } from 'api/query-keys';

export const useGetShiftClaimsForFlexWorker = (shiftClaimsOptions: {
  flexWorkerId?: string;
  companyId?: string;
  branchOfficeId?: string;
  jobTypeId?: string;
  fromDate?: string;
  toDate?: string;
  state?: string;
}) => {
  const queryFn = async () =>
    await shiftClaimService.getShiftClaimsForFlexWorker(shiftClaimsOptions);

  return useQuery<
    Promise<HQShiftClaimForFlexWorkerListItem[]>,
    Error,
    HQShiftClaimForFlexWorkerListItem[]
  >(QUERY_KEYS.shiftClaimsForFlexWorker(shiftClaimsOptions), queryFn, {
    enabled: !!shiftClaimsOptions.flexWorkerId,
  });
};
