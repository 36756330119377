import { useCallback, useState } from 'react';
import { Header, Page } from 'redesign';
import { BulkEditSidebar } from 'shared/components/bulk/bulk-shift-action/bulk-edit-sidebar';
import { BulkArchiveSidebar } from 'shared/components/bulk/bulk-shift-action/bulk-archive-sidebar';
import { ShiftPlanningTableDataProvider } from './table/shift-planning-table';
import { useShiftPlanningPage } from './table/hooks/use-shift-planning';
import { ShiftPlanningDataProvider } from './filters/shift-planning-filters-data-provider';
import { ShiftPlanningToolbox } from './shift-planning-toolbox';
import { BulkForwardToPartnerSidebar } from './bulk-forward-to-partner/bulk-forward-to-partner-sidebar';
import { useToggle } from '../../../../../shared/hooks';

const ShiftPlanning = () => {
  const [bulkSidebarState, setBulkSidebarState] = useState<'edit' | 'archive' | 'forward' | null>(
    null
  );
  const [selectedShiftIds, setSelectedShiftIds] = useState<string[]>([]);
  const { on: filtersOpen, toggle: toggleFilters } = useToggle();
  const { filters: appliedFilters, setFilters, selectedFilterCount } = useShiftPlanningPage();

  const handleSelectionChange = useCallback((ids: string[]) => {
    setSelectedShiftIds(ids);
  }, []);

  return (
    <Page
      header={<Header titleText="Shift Planning" />}
      toolbox={
        <ShiftPlanningToolbox
          selectedFilterCount={selectedFilterCount}
          appliedFilters={appliedFilters}
          selectedShiftIds={selectedShiftIds}
          setIsBulkForwardToPartnerOpen={() => setBulkSidebarState('forward')}
          setIsBarSideBarOpen={() => setBulkSidebarState('edit')}
          setIsBulkArchiveBarOpen={() => setBulkSidebarState('archive')}
          toggleFilters={toggleFilters}
        />
      }
    >
      <ShiftPlanningDataProvider
        appliedFilters={appliedFilters}
        handleFiltersChange={setFilters}
        isOpen={filtersOpen}
        toggleFilters={toggleFilters}
      />

      {bulkSidebarState === 'edit' && (
        <BulkEditSidebar
          selectedShiftIds={selectedShiftIds}
          isBulkSideBarOpen={bulkSidebarState === 'edit'}
          setIsBarSideBarOpen={() => setBulkSidebarState(null)}
        />
      )}
      {bulkSidebarState === 'forward' && (
        <BulkForwardToPartnerSidebar
          shiftIds={selectedShiftIds}
          isBulkSideBarOpen={bulkSidebarState === 'forward'}
          setIsBarSideBarOpen={() => setBulkSidebarState(null)}
        />
      )}
      {bulkSidebarState === 'archive' && (
        <BulkArchiveSidebar
          selectedShiftIds={selectedShiftIds}
          isBulkArchiveBarOpen={bulkSidebarState === 'archive'}
          setIsBarSideBarOpen={() => setBulkSidebarState(null)}
        />
      )}
      <ShiftPlanningTableDataProvider
        selectedShiftIds={selectedShiftIds}
        onSelectionChange={handleSelectionChange}
      />
    </Page>
  );
};

export { ShiftPlanning };
