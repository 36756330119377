import { useUpdateUser } from 'ogp/hooks/queryHooks/users/use-update-user';
import { useCompanyRelativePath, useGetOGPUserInfo } from 'ogp/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'Routes';
import { userAlerts as alerts, useAlert } from 'shared/components/alerts';
import { invariant } from 'shared/utils/utils';
import { RenderUnsettledUI } from 'shared/components';
import { Header, LwButton, Page, Toolbox, useFormButtons } from 'redesign';
import { FormProvider, useForm } from 'react-hook-form';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { TakeDefined } from '@types';
import { userModel2UserPayload } from 'ogp/services/mappers/mappers';
import { QUERY_KEYS } from 'api/query-keys';
import { useGetUserAsForm } from 'ogp/hooks/queryHooks/users/use-get-user';
import { UserForm } from './user-form/user-form';

const UserEditDataProvider = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'User id is missing');
  const userQuery = useGetUserAsForm(id);

  if (userQuery.status !== 'success') {
    return <RenderUnsettledUI data={userQuery} />;
  }

  return <UserEdit defaultValues={userQuery.data} />;
};

const UserEdit = ({
  defaultValues,
}: {
  defaultValues: TakeDefined<ReturnType<typeof useGetUserAsForm>['data']>;
}) => {
  const formMethods = useForm({ defaultValues });
  const userInfo = useGetOGPUserInfo();
  const { alertSuccess, alertError } = useAlert();
  const { id } = useParams<'id'>();
  const navigate = useNavigate();
  invariant(id);
  const generatePath = useCompanyRelativePath();
  const invalidateQuery = useInvalidateQuery();
  const updateUser = useUpdateUser();
  const { submitButtonProps, cancelButtonProps, returnTo } = useFormButtons(updateUser.status);

  const handleSubmit = () => {
    formMethods.handleSubmit((data) => {
      invariant(userInfo.data, 'User info is missing');
      const createPayload = userModel2UserPayload({
        companyId: userInfo.data?.userInfo.company.slug,
      });
      updateUser.mutate(
        {
          userId: id,
          data: createPayload(data),
        },
        {
          onSuccess: () => {
            invalidateQuery(QUERY_KEYS.users());
            alertSuccess(alerts.success.updated);
            navigate(generatePath(routes.UsersDetail, { id }));
          },
          onError: (e) => alertError(e),
        }
      );
    })();
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Gebruiker aanpassen" />}
        toolbox={
          <Toolbox underline>
            <LwButton onClick={handleSubmit} iconColorMode="stroke" {...submitButtonProps}>
              Bewaar
            </LwButton>
            <LwButton
              onClick={returnTo(routes.UsersDetail, { id })}
              iconColorMode="fill"
              {...cancelButtonProps}
            >
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <UserForm state="update" />
      </Page>
    </FormProvider>
  );
};

export { UserEditDataProvider };
