import { useCompanyRelativePath } from 'ogp/hooks';
import { routes } from 'ogp/Routes';
import { billingEntityService } from 'ogp/services';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { billingEntityAlerts as alerts, useAlert } from 'shared/components/alerts';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { FirstParamOfFn, SecondParamOfFn } from '@types';
import { QUERY_KEYS } from 'api/query-keys';

const useUpdateBillingEntity = () => {
  const navigate = useNavigate();
  const generatePath = useCompanyRelativePath();
  const { alertError, alertSuccess } = useAlert();
  const invalidateQuery = useInvalidateQuery();
  const mutationFn = async ({
    id,
    payload,
  }: {
    id: FirstParamOfFn<typeof billingEntityService.updateBillingEntity>;
    payload: SecondParamOfFn<typeof billingEntityService.updateBillingEntity>;
  }) => await billingEntityService.updateBillingEntity(id, payload);

  return useMutation({
    mutationFn,
    onSuccess: () => {
      invalidateQuery(QUERY_KEYS.companies());
      alertSuccess(alerts.success.updated);
      navigate(generatePath(routes.Company));
    },
    onError: (e) => alertError(e),
  });
};

export { useUpdateBillingEntity };
