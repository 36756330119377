import { ShiftPlanningShiftResponse } from 'hq/services/shift-planning-service.types';
import { DateTime } from 'luxon';
import { HqShiftPlanningApplicationColumnDefinition } from '../shift-planning-table.types';

export const getShiftPlanningTableData = (
  data: ShiftPlanningShiftResponse[]
): HqShiftPlanningApplicationColumnDefinition[] => {
  const result: HqShiftPlanningApplicationColumnDefinition[] = data.map((item) => {
    const dateTime = {
      fromDateTime: DateTime.fromISO(item.dateTime.fromDateTime),
      toDateTime: DateTime.fromISO(item.dateTime.toDateTime),
    };

    const freelancersList = item.applications.filter(
      (application) => application.employmentType === 'FREELANCER'
    );

    const tempworkersList = item.applications.filter(
      (application) => application.employmentType === 'TEMPWORKER'
    );

    return {
      id: item.id,
      jobName: item.jobName,
      companyName: item.companyName,
      departmentName: item.departmentName,
      location: item.location,
      partners: item.partners,
      dateTime,
      candidatePlacements: item.candidatePlacements,
      applications: { freelancersList, tempworkersList },
      action: item.id,
    };
  });
  return result;
};
