import { RenderUnsettledUI, SmallError, SmallLoader } from 'shared/components';
import { useOgpGetShift } from 'shared/components/shift-forms/hooks/ogp/use-ogp-get-shift';
import { ShiftDetailsModal } from './shift-details-modal';

type ShiftDetailsProviderProps = {
  shiftId: string;
};

export const ShiftDetailsProvider = ({ shiftId }: ShiftDetailsProviderProps) => {
  const shiftQuery = useOgpGetShift(shiftId);

  if (shiftQuery.status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={shiftQuery}
      />
    );
  }

  return <ShiftDetailsModal data={shiftQuery.data} />;
};
