import { useFormContext } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import { FormCheckbox, LwFormAutocomplete, LwFormInput, LwFormMultiSelect } from 'redesign';
import { useMemo, useState } from 'react';
import { FlexWorkerForNotWelcomeList, WorkerType } from 'ogp/services/company-service.types';
import { RenderUnsettledUI } from 'shared/components';
import { GetDepartmentsResponse } from 'ogp/services/department-service.types';
import { InArray } from 'shared/utils/ts-utils';
import { useDebounce } from 'shared/hooks/use-debounce';
import { useGetAvailableFlexWorkers } from './hooks/use-get-available-flex-workers';
import { useGetDepartmentsAsList } from '../../../departments/hooks';

export type AddWorkerToNotWelcomeListForm = {
  flexWorker: FlexWorkerForNotWelcomeList;
  reason: string;
  departments: GetDepartmentsResponse;
  shouldCancelAll: boolean;
  shouldCancelWithoutRule: boolean;
};

const NotWelcomeListAddFlexWorker = () => {
  const { control, setValue } = useFormContext<AddWorkerToNotWelcomeListForm>();

  const [input, setSelected] = useState<string>('');
  const debouncedInput = useDebounce(input, 300);
  const getAvailableFlexworkersQuery = useGetAvailableFlexWorkers(debouncedInput);
  const getDepartmentsQuery = useGetDepartmentsAsList({ includeArchived: false });

  const departmentOptions = useMemo(() => {
    return getDepartmentsQuery.data
      ? [
          { id: 'all', name: 'all', branchOffice: { name: '', id: '' } },
          ...getDepartmentsQuery.data,
        ]
      : [];
  }, [getDepartmentsQuery.data]);

  const getOptionLabel = (option: InArray<GetDepartmentsResponse>) =>
    option.name === 'all' ? 'Alle afdelingen' : option.branchOffice.name + ': ' + option.name;

  /**
   * If the user selects 'all' departments, we should only store that in the form state.
   * We don't allow the user to select individual departments if 'all' is selected.
   * Once 'all' is unselected, the user can select individual departments.
   */
  const onSelectDepartment = (_event: any, value: GetDepartmentsResponse) => {
    if (value.find((v) => v.id === 'all')) {
      setValue('departments', [{ id: 'all', name: 'all', branchOffice: { name: '', id: '' } }]);
    } else {
      setValue('departments', value);
    }
  };

  if (getAvailableFlexworkersQuery.status === 'error') {
    return <RenderUnsettledUI data={getAvailableFlexworkersQuery} />;
  }

  if (getDepartmentsQuery.status === 'error') {
    return <RenderUnsettledUI data={getDepartmentsQuery} />;
  }

  const getWorkerOptionLabel = (option: FlexWorkerForNotWelcomeList | Record<string, never>) => {
    if (!option || Object.keys(option).length === 0) {
      return '';
    }

    const fullName = [option.firstName, option.infix, option.lastName].filter(Boolean).join(' ');
    const fullNameWithEmail = `${fullName} (${option.email})`;
    return `${fullNameWithEmail} ${option.workerType == WorkerType.PARTNERWORKER ? '(Partner)' : ''}`;
  };

  return (
    <>
      <Grid style={{ alignItems: 'end' }} container item spacing={2} xs={12}>
        <Grid item xs={12}>
          <LwFormAutocomplete
            name="flexWorker"
            label="Flexwerker"
            getOptionLabel={getWorkerOptionLabel}
            getOptionKey={(option) => option.id ?? ''}
            isOptionEqualToValue={(option, value) => {
              return option?.email === value?.email;
            }}
            disableClearable={true}
            options={getAvailableFlexworkersQuery.data ?? []}
            isLoading={getAvailableFlexworkersQuery.isFetching}
            control={control}
            rules={{ required: true }}
            onChange={(_event, value) => {
              if (value) {
                setValue('flexWorker', value);
              }
            }}
            onInputChange={(_e, value) => {
              setSelected(value);
            }}
            data-testid="fw-select"
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormInput control={control} label="Reden" name="reason" />
        </Grid>
        <Grid item xs={12}>
          <LwFormMultiSelect
            control={control}
            label="Afdelingen"
            name="departments"
            rules={{ required: true }}
            options={departmentOptions}
            getOptionKey={(option) => option.id}
            getOptionLabel={getOptionLabel}
            onChange={onSelectDepartment}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            data-testid="department-multiselect"
          />
        </Grid>
        <Grid container spacing={4}>
          <Typography variant="subtitle2" marginTop={2}>
            Wat wil je doen met de toekomstige aanmeldingen en claims van deze flexwerker?
          </Typography>
          <Grid item xs={12}>
            <FormCheckbox
              label="Alleen shiftclaims annuleren waar de 50%-regel niet van toepassing is"
              control={control}
              name="shouldCancelWithoutRule"
              onChange={(value) => setValue('shouldCancelAll', !value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormCheckbox
              label="Alles annuleren"
              control={control}
              name="shouldCancelAll"
              onChange={(value) => setValue('shouldCancelWithoutRule', !value)}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export { NotWelcomeListAddFlexWorker };
