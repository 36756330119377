import { QUERY_KEYS } from 'api/query-keys';
import { partnerPlannerService } from 'partner/shared/services';
import { useQuery } from 'react-query';

export const usePartnerGetShift = (shiftId: string) => {
  const queryFn = async () => await partnerPlannerService.getShift(shiftId!);
  const queryKey = QUERY_KEYS.shiftPlanningShiftDetail(shiftId);

  return useQuery({
    queryKey,
    queryFn,
  });
};
