import { useInfiniteQuery } from 'react-query';
import { FirstParamOfFn } from '@types';
import { shiftService } from 'ogp/services';
import { QUERY_KEYS } from 'api/query-keys';

export const useGetTableShiftPlanningShifts = (
  params: FirstParamOfFn<typeof shiftService.getTableShiftPlanningListItems>
) => {
  return useInfiniteQuery({
    queryKey: QUERY_KEYS.shiftPlanning(params),
    queryFn: async ({ pageParam = 0 }) =>
      await shiftService.getTableShiftPlanningListItems({
        pageParam,
        fromDate: params.fromDate,
        toDate: params.toDate,
        departmentId: params.departmentId,
        pageSizeParam: params.pageSizeParam,
        jobType: params.jobType,
        hasApplicants: params.hasApplicants,
        filledStatus: params.filledStatus,
        partnerId: params.partnerId,
        isFreelance: params.isFreelance,
        isTempWork: params.isTempWork,
      }),
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};
