import { Box, Grid, styled, Typography } from '@mui/material';
import { JobTypeRequiredCertificatesList } from './job-type-required-certificates-list';

export const JobTypeRequiredCertificatesWrapper = ({
  jobTypeId,
}: {
  jobTypeId: string | undefined;
}) => {
  return (
    <Disclaimer>
      <ScrollableContainer>
        <Box width={'100%'}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="secondary">
                Vereiste certificaten:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {jobTypeId ? (
                <JobTypeRequiredCertificatesList jobTypeId={jobTypeId} />
              ) : (
                <Typography>Er is geen functiegroep geselecteerd</Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </ScrollableContainer>
    </Disclaimer>
  );
};

const Disclaimer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing(6),
  paddingRight: theme.spacing(6),
  paddingLeft: theme.spacing(2),
  width: '100%',
  height: '100%',
  borderRadius: theme.shape.borderRadius,
}));

const ScrollableContainer = styled(Box)(() => ({
  maxHeight: 'calc(100vh - 300px)',
  overflowY: 'auto',
  '::-webkit-scrollbar': {
    width: '22px',
  },
  '::-webkit-scrollbar-track': {
    background: 'rgba(0, 0, 0, 0.1)',
    border: '8px solid white',
    borderRadius: '12px',
  },

  '::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.2)',
    backgroundClip: 'content-box',
    border: '8px solid transparent',
    borderRadius: '12px',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.4)',
      backgroundClip: 'content-box',
      border: '8px solid transparent',
      borderRadius: '12px',
    },
  },
}));
