import { useState } from 'react';
import { Drawer } from '@mui/material';
import { ShiftPlanningShiftsPartner } from 'hq/services/shift-planning-service.types';
import { BulkShiftActionData } from 'api';
import { BulkShiftActionResult } from 'shared/components/bulk/bulk-shift-action/bulk-shift-action-result/bulk-shift-action-result';
import { WizardProvider } from 'redesign';
import { BulkActionProvider } from 'shared/providers/bulk-action-provider';
import { BulkShiftForwardToPartnerFormProvider } from './bulk-forward-to-partner-form-provider';

type BulkForwardToPartnerForm = {
  shiftIds: string[];
  isBulkSideBarOpen: boolean;
  setIsBarSideBarOpen: (isOpen: boolean) => void;
};
export type BulkForwardToShiftsFormInput = {
  partners: ShiftPlanningShiftsPartner[];
};

export const BulkForwardToPartnerSidebar = ({
  shiftIds,
  isBulkSideBarOpen,
  setIsBarSideBarOpen,
}: BulkForwardToPartnerForm) => {
  const [mutationResult, setMutationResult] = useState<BulkShiftActionData>({
    fulfilled: [],
    failure: [],
  });

  return (
    <BulkActionProvider
      setMutationResult={setMutationResult}
      selectedIds={shiftIds}
      failure={mutationResult.failure}
      fulfilled={mutationResult.fulfilled}
    >
      <Drawer
        anchor="right"
        open={isBulkSideBarOpen}
        onClose={() => setIsBarSideBarOpen(false)}
        PaperProps={{ style: { width: 600 } }}
      >
        <WizardProvider onClose={() => setIsBarSideBarOpen(false)}>
          <BulkShiftForwardToPartnerFormProvider
            shiftIds={shiftIds}
            setIsBulkForwardToPartnerOpen={setIsBarSideBarOpen}
          />
          <BulkShiftActionResult />
        </WizardProvider>
      </Drawer>
    </BulkActionProvider>
  );
};
