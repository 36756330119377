import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
  Typography,
} from '@mui/material';
import { IconApproved } from 'assets/img';
import { SecondaryButton } from 'ogp/providers/dialog-provider';
import { useState } from 'react';
import { LwButton, UncontrolledFormSwitch } from 'redesign';

// Dialog can't be opened twice using `useDialog`, so this is a temp workaround
export const NotWelcomeListConfirmationDialog = ({
  isOpen,
  shouldCancelAll,
  onClose,
  onConfirm,
}: {
  isOpen: boolean;
  shouldCancelAll: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const [allowConfirmation, setAllowConfirmation] = useState(false);
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="nwl-dialog-title"
      aria-describedby="nwl-dialog-description"
    >
      <DialogTitle id="nwl-dialog-title">Weet je het zeker?</DialogTitle>
      <DialogContent>
        <DialogContentText id="nwl-dialog-description" component={'div'}>
          Wanneer je de flexwerker aan de niet-welkom-lijst toevoegt, heeft dat de volgende
          consequenties:
          <ul>
            <li>De flexwerker wordt uit alle flexpools verwijderd</li>
            <li>Alle shiftaanmeldingen van de flexwerker worden afgewezen</li>
            <li>
              {shouldCancelAll
                ? `Alle toekomstige shiftclaims worden geannuleerd. Voor shifts waarvan de
              annuleringstermijn verstreken is, komt de flexwerker in aanmerking voor de 50%
              uitbetalingsregeling.`
                : `Alle toekomstige shiftclaims waar de 50%-regel niet van toepassing is, worden geannuleerd.`}
            </li>
          </ul>
          <Box display="flex" alignItems="center">
            <Toggle
              onChange={() => setAllowConfirmation((prev) => !prev)}
              value={allowConfirmation}
              name="allowConfirmation"
              label=""
            />
            <Typography variant="body1" marginTop={2}>
              Ja, ik wil de flexwerker op de lijst zetten
            </Typography>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', gap: '32px' }}>
        <SecondaryButton onClick={onClose} />
        <LwButton
          iconColorMode="stroke"
          startIcon={<IconApproved />}
          color="primary"
          disabled={!allowConfirmation}
          onClick={onConfirm}
        >
          Toevoegen
        </LwButton>
      </DialogActions>
    </Dialog>
  );
};

const Toggle = styled(UncontrolledFormSwitch)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(4),
  marginLeft: theme.spacing(2),
  alignItems: 'flex-start',
}));
