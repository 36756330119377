import { WorkerEmploymentType } from '@types';
import { DateTime } from 'luxon';

export const isWithinCancelationPeriod = (
  shiftData: { isFreelance: boolean; mutableUntilDate: string },
  candidate: {
    claimId: string;
    isFlexWorker: boolean;
    employmentType: WorkerEmploymentType | undefined;
  }
) => {
  const isCancelationOffer = DateTime.now() > DateTime.fromISO(shiftData.mutableUntilDate);
  return (
    shiftData.isFreelance &&
    isCancelationOffer &&
    candidate.isFlexWorker &&
    candidate.employmentType === 'FREELANCER'
  );
};
