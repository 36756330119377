import { QUERY_KEYS } from 'api/query-keys';
import { partnerCheckoutsService } from 'partner/shared/services';
import { PartnerPortalCheckoutsParams } from 'partner/shared/services/partner-checkouts-service.types';
import { useInfiniteQuery, useQuery } from 'react-query';

const usePartnerWorkedShifts = (params: PartnerPortalCheckoutsParams) => {
  return useInfiniteQuery({
    queryKey: QUERY_KEYS.checkoutsWithFilters(params),
    queryFn: async ({ pageParam = 0 }) =>
      await partnerCheckoutsService.getWorkedShifts({
        ...params,
        pageParam,
        pageSizeParam: params.pageSizeParam,
      }),
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};

const usePartnerCounterOffers = (params: PartnerPortalCheckoutsParams) => {
  return useInfiniteQuery({
    queryKey: QUERY_KEYS.counterOffersWithFilters(params),
    queryFn: async ({ pageParam = 0 }) =>
      await partnerCheckoutsService.getCounterOffers({
        ...params,
        pageParam,
        pageSizeParam: params.pageSizeParam,
      }),
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};

const usePartnerCheckoutsMetaData = () =>
  useQuery({
    queryKey: QUERY_KEYS.checkoutsMetaData(),
    queryFn: async () => await partnerCheckoutsService.getMetaData(),
  });

export { usePartnerWorkedShifts, usePartnerCounterOffers, usePartnerCheckoutsMetaData };
