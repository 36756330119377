import { partnerWorkerService } from 'api/services';
import { useMutation } from 'react-query';
import { useSiteDetection } from 'shared/hooks';

export const usePartnerClaimShift = () => {
  const { site } = useSiteDetection();
  const mutationFn = async ({ flexWorkerId, shiftId }: { flexWorkerId: string; shiftId: string }) =>
    await partnerWorkerService.claimShift(site, flexWorkerId, shiftId);

  return useMutation(mutationFn);
};
