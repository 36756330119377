import { QUERY_KEYS } from 'api/query-keys';
import { companyService } from 'ogp/services';
import { useQuery } from 'react-query';
import { CompanyEmploymentTypes } from '@types';

export const useGetCompanyEmploymentTypes = () => {
  const queryFn = async () => await companyService.getCompanyEmploymentTypes();

  return useQuery<Promise<CompanyEmploymentTypes>, Error, CompanyEmploymentTypes>(
    QUERY_KEYS.ownCompanyEmploymentTypes(),
    queryFn
  );
};
