import { useFormContext } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import { useRolesMatrix } from 'ogp/hooks/queryHooks/users/use-roles-matrix';
import { RenderUnsettledUI } from 'shared/components';
import { FormSwitch, LwFormInput } from 'redesign';
import { TakeDefined } from '@types';
import { useGetUser } from 'ogp/hooks/queryHooks/users/use-get-user';
import { UserFormRoles } from './user-form-roles';
import { UserFormProps } from './user-form.types';
import { useGetCompanyDetailAndJobGroups } from '../../../../../hooks/queryHooks/companies/use-get-company-detail-and-job-groups';

const UserForm = ({ state }: UserFormProps) => {
  const companyQuery = useGetCompanyDetailAndJobGroups();
  const rolesMatrixQuery = useRolesMatrix();
  const { control } = useFormContext<TakeDefined<ReturnType<typeof useGetUser>['data']>>();
  const phoneNumberRegex = new RegExp('^\\+[0-9]+$');

  if (rolesMatrixQuery.status !== 'success') {
    return <RenderUnsettledUI data={rolesMatrixQuery} />;
  }

  if (companyQuery.status !== 'success') {
    return <RenderUnsettledUI data={companyQuery} />;
  }

  return (
    <form>
      <Grid container item xs={12} spacing={4}>
        <Grid item xs={12}>
          <h2 className="subtitle">Persoonlijke informatie</h2>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LwFormInput
                name="firstName"
                label="Voornaam"
                control={control}
                rules={{ required: 'Voer een voornaam in' }}
                disabled={state === 'read'}
              />
            </Grid>
            <Grid item xs={6}>
              <LwFormInput
                name="lastName"
                label="Achternaam"
                control={control}
                rules={{ required: 'Voer een achternaam in' }}
                disabled={state === 'read'}
              />
            </Grid>
            <Grid item xs={6}>
              <LwFormInput
                name="nickName"
                label="Publieke naam"
                control={control}
                rules={{ required: 'Voer een publieke naam in' }}
                disabled={state === 'read'}
              />
            </Grid>
            <Grid item xs={6}>
              <LwFormInput
                name="email"
                label="Email"
                inputType="email"
                control={control}
                rules={{ required: 'Voer een email adres in' }}
                disabled={state === 'read' || state === 'update'}
              />
            </Grid>
            <Grid item xs={6}>
              <LwFormInput
                name="phone"
                label="Telefoonnummer"
                inputType="tel"
                placeholder="0612345678"
                control={control}
                rules={{ required: 'Voer een telefoonnummer in' }}
                disabled={state === 'read'}
              />
            </Grid>
            <Grid item xs={6}>
              <LwFormInput
                name="accessPhone"
                label="Platform toegang telefoon"
                inputType="tel"
                control={control}
                rules={{
                  required: 'Voer een telefoonnummer in',
                  pattern: {
                    value: phoneNumberRegex,
                    message: 'Voorbeeld: +31612345678',
                  },
                }}
                disabled={state === 'read'}
                helperText="Voorbeeld: +31612345678"
              />
            </Grid>
            <Grid item xs={6}>
              <LwFormInput
                name="position"
                label="Functie"
                inputType="tel"
                placeholder="Functie vrij invullen"
                control={control}
                rules={{ required: 'Voer een functie in' }}
                disabled={state === 'read'}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ paddingTop: '2px' }} variant="subtitle2">
                Bedrijfsrol
              </Typography>
              <Grid item xs={6} style={{ marginTop: '22px' }}>
                <FormSwitch
                  label={'Admin'}
                  control={control}
                  name="roles.companyRole"
                  disabled={state === 'read'}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <UserFormRoles matrix={rolesMatrixQuery.data} state={state} />
        </Grid>
      </Grid>
    </form>
  );
};

export { UserForm };
