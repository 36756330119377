import { userService } from 'hq/services';
import { useQuery } from 'react-query';
import { HqUser } from '@types';
import { QUERY_KEYS } from 'api/query-keys';

export const useGetUser = (userId: string) => {
  const queryFn = async () => await userService.getUser(userId);

  return useQuery<Promise<HqUser>, Error, HqUser>(QUERY_KEYS.user(userId), queryFn);
};
