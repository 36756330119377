import { QUERY_KEYS } from 'api/query-keys';
import { accreditationsService } from 'hq/services';
import { AccreditationType } from 'hq/services/accreditations-service.types';
import { useMutation, useQueryClient } from 'react-query';

type RejectAccreditationParams = {
  flexWorkerId: string;
  certificateId: string;
  accreditationType: AccreditationType;
};

export const useRejectAccreditation = () => {
  const mutationFn = async ({
    flexWorkerId,
    certificateId,
    accreditationType,
  }: RejectAccreditationParams) =>
    await accreditationsService.rejectAccreditation(flexWorkerId, certificateId, accreditationType);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.accreditations());
    },
  });
};
