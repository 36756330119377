import { useFormContext } from 'react-hook-form';
import { LwFormNumberInput } from 'redesign';
import { getRequiredMessage } from './shared';
import { MutableUntilHoursFormContext, Props } from './mutable-until-hours.types';

export const MutableUntilHoursInput = (props: Props) => {
  const { control } = useFormContext<MutableUntilHoursFormContext>();
  const formName = 'mutableUntilHours';
  const label = 'Annuleringstermijn';
  const requiredMessage = getRequiredMessage(label);
  return (
    <LwFormNumberInput
      name={formName}
      control={control}
      label={label}
      placeholder="In uren"
      disabled={props.disabled}
      min={0}
      rules={props.required ? { required: requiredMessage } : undefined}
    />
  );
};
