import { Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useGeocode } from 'ogp/hooks/queryHooks/locations/use-geocode';
import { vatListOptions } from '@types';
import {
  createSelectOption,
  LwFormAutocomplete,
  LwFormInput,
  LwFormMultiSelect,
  LwFormSelect,
} from 'redesign';
import { useGetBranchOffices } from 'ogp/hooks';
import { useGetBranchOffice } from 'ogp/hooks/queryHooks/branchoffices/use-get-branch-office';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { useDebounce } from 'shared/hooks/use-debounce';
import { DepartmentFormData } from '../../../../../../services/department-service.types';
import { RenderUnsettledUI } from '../../../../../../../shared/components';
import {
  GetBranchOfficeCreateDataResponse,
  GetBranchOfficesResponse,
} from '../../../../../../services/branch-office-service.types';
import { useGetBranchOfficeCreateData } from '../../../branch-offices/use-get-branch-office-create-data';

type Props = {
  state: 'read' | 'update' | 'create';
};

export const DepartmentFormDataProvider = ({ state }: Props) => {
  const branchOfficesQuery = useGetBranchOffices({ includeArchived: false });
  const createDataQuery = useGetBranchOfficeCreateData();

  if (branchOfficesQuery.status !== 'success') {
    return <RenderUnsettledUI data={branchOfficesQuery} />;
  }

  if (createDataQuery.status !== 'success') {
    return <RenderUnsettledUI data={createDataQuery} />;
  }

  return (
    <DepartmentForm
      state={state}
      branchOfficeData={branchOfficesQuery.data}
      createData={createDataQuery.data}
    />
  );
};

const DepartmentForm = ({
  state,
  branchOfficeData,
  createData,
}: Props & {
  branchOfficeData: GetBranchOfficesResponse['branchOffices'];
  createData: GetBranchOfficeCreateDataResponse;
}) => {
  const { control, setValue, watch } = useFormContext<DepartmentFormData>();
  const defaultAddress = React.useRef('');
  const [input, setInput] = React.useState(defaultAddress.current);
  const debouncedInput = useDebounce(input, 300);
  const geoQuery = useGeocode(debouncedInput, debouncedInput !== defaultAddress.current);
  const { allowReverseChargingVAT } = useFeatureFlagContext();
  const vatOptions = useMemo(
    () => vatListOptions(allowReverseChargingVAT),
    [allowReverseChargingVAT]
  );
  const branchOfficeOptions = useMemo(
    () =>
      branchOfficeData.map((branchOffice) =>
        createSelectOption(branchOffice, {
          value: 'id',
          label: 'name',
        })
      ),
    [branchOfficeData]
  );

  const branchOfficeId = watch('branchOfficeId');
  const { data: branchOffice } = useGetBranchOffice(branchOfficeId);

  React.useEffect(() => {
    if (state === 'create' && branchOffice) {
      setValue('cocNumber', branchOffice.cocNumber);
      setValue('costCenter', branchOffice.costCenter ?? null);
      setValue('legalPerson', branchOffice.legalPerson);
      setValue('location', branchOffice.location);
      setValue('VAT', branchOffice.VAT);
    }
  }, [branchOffice, state]);

  return (
    <form aria-label="Department form">
      <Grid container item xs={6} spacing={2}>
        <Grid item xs={6}>
          <LwFormSelect
            name="branchOfficeId"
            label="Vestiging"
            defaultLabel="Selecteer een vestiging..."
            options={branchOfficeOptions}
            control={control}
            rules={{ required: 'Selecteer een vestiging' }}
            disabled={state === 'read' || state === 'update'}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="name"
            control={control}
            rules={{ required: 'Voer een departmentsnaam in' }}
            label="Afdelingsnaam"
            disabled={state === 'read'}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="companyNameForOVO"
            control={control}
            rules={{ required: 'Voer een bedrijfsnaam voor de OVO in' }}
            label="Bedrijfsnaam voor OVO"
            disabled={state === 'read'}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="legalPerson"
            control={control}
            rules={{ required: 'Voer een wettelijke vertegenwoordiger in' }}
            label="Wettelijke vertegenwoordiger"
            disabled={state === 'read'}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="cocNumber"
            control={control}
            rules={{
              required: 'Voer een KVK-nummer in',
              pattern: {
                value: /^[0-9]{8}$/,
                message: 'Een KVK-nummer bestaat uit acht cijfers',
              },
            }}
            label="KVK-nummer"
            placeholder="12345678"
            disabled={state === 'read'}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormSelect
            name="VAT"
            control={control}
            options={vatOptions}
            label="BTW-tarief"
            disabled={state === 'read'}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormAutocomplete
            label="Locatie"
            name="location"
            control={control}
            rules={{ required: 'Voer een locatie in' }}
            disableClearable={true}
            getOptionLabel={(option) => option.address}
            isOptionEqualToValue={(option, value) => option.hereId === value.hereId}
            getOptionKey={(option) => option.hereId ?? ''}
            onChange={(_event, value) => {
              if (value) {
                setValue('location', value);
              }
            }}
            onInputChange={(_e, value) => {
              setInput(value);
            }}
            disabled={state === 'read'}
            options={geoQuery.data ?? []}
            isLoading={geoQuery.status === 'loading'}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormInput
            name="locationIndicator"
            control={control}
            label="Locatie indicator"
            placeholder="e.g. 2nd floor, 3rd door at the right"
            disabled={state === 'read'}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="costCenter"
            label="Kostenplaats"
            control={control}
            disabled={state === 'read'}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormSelect
            name="billingEntityId"
            options={createData.billingEntities}
            label="Facturatiegegevens"
            control={control}
            rules={{ required: 'Selecteer een facturatiegegevens' }}
            disabled={state === 'read'}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormMultiSelect
            name="departmentFlexPools"
            control={control}
            options={createData.companyFlexPools}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) => option.name}
            label="Flexpools"
          />
        </Grid>
      </Grid>
    </form>
  );
};
