import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS } from 'api/query-keys';
import { branchOfficeService } from '../../../../services';

export const useArchivePartnerRelation = () => {
  const mutationFn = async (partnerRelationId: string) =>
    await branchOfficeService.archivePartnerRelation(partnerRelationId);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => queryClient.invalidateQueries(QUERY_KEYS.partners()),
  });
};
