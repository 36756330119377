import type { Column } from 'react-table';
import { IconIsolationMode } from 'assets/img';
import { Cell, Header } from 'shared/components/planner/common/utils';
import { useCallback } from 'react';
import { DateTime } from 'luxon';
import { useSiteDetection } from 'shared/hooks';
import { useLocation } from 'react-router';
import {
  FailedBulkShiftActionsCellProp,
  FailedBulkShiftActionsColumnDefinition,
} from './failed-bulk-shift-actions-table.types';

export const getFailedBulkShiftActionsColumnDefinition: () => Column<FailedBulkShiftActionsColumnDefinition>[] =
  () => {
    const error = {
      Header: () => <Header>Foutmelding</Header>,
      accessor: 'error',
      Cell: Cell,
    } as Column<FailedBulkShiftActionsColumnDefinition>;
    const shiftName = {
      Header: () => <Header>Functie</Header>,
      accessor: 'shiftName',
      Cell: Cell,
      width: '100px',
    } as Column<FailedBulkShiftActionsColumnDefinition>;
    const utcStartEndDateTime = {
      Header: () => <Header>Datum & tijd</Header>,
      accessor: 'dateStartEndTime',
      Cell: Cell,
      width: '100px',
    } as Column<FailedBulkShiftActionsColumnDefinition>;
    const action = {
      Header: () => <Header>Actie</Header>,
      accessor: 'action',
      Cell: ActionCell,
      width: '50px',
    } as Column<FailedBulkShiftActionsColumnDefinition>;

    return [error, shiftName, utcStartEndDateTime, action];
  };

const ActionCell = (cell: FailedBulkShiftActionsCellProp<'action'>) => {
  const currentDate = DateTime.now().toISODate();
  const { site } = useSiteDetection();
  const { pathname } = useLocation();
  const url =
    site === 'ogp'
      ? pathname.concat(`?fromDate=${currentDate}&shiftId=${cell.value}`)
      : `/hq/planner/shifts?fromDate=${currentDate}&shiftDetailId=${cell.value}`;
  const navigateCallback = useCallback(() => {
    window.open(url, '_blank');
  }, []);

  return (
    <IconIsolationMode
      onClick={navigateCallback}
      data-testid="action-button"
      style={{ cursor: 'pointer' }}
    />
  );
};
