import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { EnvironmentService } from 'shared/services';
import { ProviderFactory } from 'shared/providers/provider-factory';
import { StateError } from 'shared/components/common/state/State';

const S3_BUCKET_URL = EnvironmentService.getVariable('LOCIZE_COPIES_S3_URL');

type TranslationContext = { isInitialized: boolean };

const [Provider, useTranslationContext] = ProviderFactory<TranslationContext>(
  'TranslationContext can only be accessed within TranslationProvider'
);

export const TranslationProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const initializeTranslations = async () => {
      try {
        const s3Url = `${S3_BUCKET_URL}/`;
        await i18next
          .use(HttpBackend)
          .use(initReactI18next)
          .init({
            backend: {
              loadPath: `${s3Url}{{lng}}/{{ns}}.json`,
            },
            saveMissing: false,
            updateMissing: false,
            supportedLngs: ['nl'],
            preload: ['nl'],
            ns: ['hq-screen-specific'],
            debug: false,
            fallbackLng: 'nl',
            interpolation: {
              escapeValue: false,
            },
            missingKeyNoValueFallbackToKey: true,
            returnEmptyString: false,
          });

        setIsInitialized(true);
      } catch (err) {
        setError(new Error(`Translation initialization failed: ${err}`));
      }
    };

    initializeTranslations();
  }, []);

  if (error) {
    return <StateError error={error} />;
  }

  return <Provider value={{ isInitialized }}>{children}</Provider>;
};

export const useTranslationReady = () => useTranslationContext();
