import { VAT } from '@types';
import { z } from 'zod';

export const vatSchema = z.enum([VAT.LOW, VAT.HIGH, VAT.NONE, VAT.REVERSE]);

export const locationSchema = z.object({
  id: z.string(),
  address: z.string(),
  latitude: z.number().nullable(),
  longitude: z.number().nullable(),
  street: z.string().nullable(),
  houseNumber: z.string().nullable(),
  postalCode: z.string().nullable(),
  country: z.string().nullable(),
  city: z.string().nullable(),
});
