import { Box, BoxProps, styled, Tab, tabClasses, TabProps, useTheme } from '@mui/material';

const LwTab = ({ ...props }: TabProps) => {
  const theme = useTheme();
  return <StyledTab theme={theme} {...props} />;
};

const StyledTab = styled(Tab)<TabProps>(({ theme }) => ({
  [`&.${tabClasses.root}`]: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '16px',
    borderRadius: '8px',
    padding: '8px 12px 8px 12px',
    height: '36px',
    color: theme.palette.lwSecondary[60],
    backgroundColor: theme.palette.lwWhite[100],
    border: `1px solid ${theme.palette.lwSecondary[20]}`,

    '&:hover': {
      color: theme.palette.lwPrimary[100],
      backgroundColor: theme.palette.lwSecondary[10],
      border: `1px solid ${theme.palette.lwSecondary[40]}`,
    },
  },
  [`&.${tabClasses.selected}`]: {
    color: theme.palette.lwPrimary[100],
    backgroundColor: theme.palette.lwPrimary[10],
    border: '1px solid transparent',
  },
  [`&.${tabClasses.disabled}`]: {
    color: theme.palette.lwSecondary[40],
    backgroundColor: theme.palette.lwState.disabled[50],
    border: `1px solid ${theme.palette.lwSecondary[10]}`,
  },
}));

const LwTabIndicator = ({ ...props }: BoxProps) => {
  const theme = useTheme();
  return <StyledTabIndicator theme={theme} {...props} />;
};

const StyledTabIndicator = styled(Box)<BoxProps>(({ theme }) => ({
  '&.MuiBox-root': {
    height: 20,
    width: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.lwSecondary[80],
    font: 'Intern',
    fontSize: '12px',
    fontWeight: 600,
    borderRadius: 20,
    backgroundColor: theme.palette.lwSecondary[20],
    aspectRatio: '1',
    padding: '4 8 4 8',
    marginLeft: 10,
  },
}));

export { LwTab, LwTabIndicator };
