import { HqApiService } from 'hq/services/hqApiService';
import { OgpApiService } from 'ogp/services/ogp-api-service';
import { Site } from 'shared/hooks';
import { ShiftBulkArchivePayload } from 'hq/services/shift-planning-service.types';
import { BreakOption } from '@types';
import { PartnerApiService } from 'partner/shared/services/partner-api-service';
import {
  BulkShiftActionData,
  bulkShiftActionDataResponseDtoSchema,
  getBreakOptionsResponseDtoSchema,
  GetShiftCreateData,
  getShiftCreateDataSchema,
} from './shift-service.schema';
import {
  BulkShiftActionDataResponseDto,
  GetShiftCreateDataResponseDto,
  ShiftBulkEditPayloadDto,
} from './shift-service.dto';

export class ShiftService {
  constructor(
    private readonly ogpApiService: OgpApiService,
    private readonly hqApiService: HqApiService,
    private readonly partnerApiService: PartnerApiService
  ) {}

  public async getShiftCreateData(site: Site, companyId?: string): Promise<GetShiftCreateData> {
    const response = await this.getShiftCreateDataImpl(site, companyId);

    return getShiftCreateDataSchema.parse(response);
  }

  public async bulkUpdateShifts(
    site: Site,
    data: ShiftBulkEditPayloadDto
  ): Promise<BulkShiftActionData> {
    const response = await this.bulkUpdateShiftsImpl(site, data);

    return bulkShiftActionDataResponseDtoSchema.parse(response);
  }

  public async bulkArchiveShifts(
    site: Site,
    data: ShiftBulkArchivePayload
  ): Promise<BulkShiftActionData> {
    const response = await this.bulkArchiveShiftsImpl(site, data);

    return bulkShiftActionDataResponseDtoSchema.parse(response);
  }

  public async getBreakOptions(site: Site): Promise<{ breakOptions: BreakOption[] }> {
    const response = await this.getBreakOptionsImpl(site);
    return getBreakOptionsResponseDtoSchema.parse(response);
  }
  private async getBreakOptionsImpl(site: Site): Promise<{ breakOptions: BreakOption[] }> {
    if (site === 'hq') {
      return await this.hqApiService.get<{ breakOptions: BreakOption[] }>({
        path: 'break-options',
      });
    }
    if (site === 'ogp') {
      return await this.ogpApiService.get<{ breakOptions: BreakOption[] }>({
        path: 'shifts/get-break-options',
      });
    }

    if (site === 'partner') {
      return await this.partnerApiService.get<{ breakOptions: BreakOption[] }>({
        path: 'checkouts/break-options',
      });
    }
    throw new Error('Not implemented');
  }
  private async bulkUpdateShiftsImpl(
    site: Site,
    data: ShiftBulkEditPayloadDto
  ): Promise<BulkShiftActionDataResponseDto> {
    const path = `shifts/bulk-update`;

    if (site === 'hq') {
      return await this.hqApiService.update<BulkShiftActionDataResponseDto>({
        path,
        body: data,
      });
    }

    if (site === 'ogp') {
      return await this.ogpApiService.update<BulkShiftActionDataResponseDto>({
        path,
        body: data,
      });
    }

    throw new Error('Not implemented');
  }

  private async bulkArchiveShiftsImpl(
    site: Site,
    data: ShiftBulkArchivePayload
  ): Promise<BulkShiftActionDataResponseDto> {
    const path = `shifts/bulk-archive`;

    if (site === 'hq') {
      return await this.hqApiService.update<BulkShiftActionDataResponseDto>({
        path,
        body: data,
      });
    }

    if (site === 'ogp') {
      return await this.ogpApiService.update<BulkShiftActionDataResponseDto>({
        path,
        body: data,
      });
    }

    throw new Error('Not implemented');
  }

  private async getShiftCreateDataImpl(
    site: Site,
    companyId: string | undefined
  ): Promise<GetShiftCreateDataResponseDto> {
    const path = `shifts/get-shift-create-data`;
    if (site === 'hq') {
      return await this.hqApiService.get<GetShiftCreateDataResponseDto>({
        path,
        params: { companyId },
      });
    }

    if (site === 'ogp') {
      return await this.ogpApiService.get<GetShiftCreateDataResponseDto>({
        path,
      });
    }

    throw new Error('Not implemented');
  }
}
