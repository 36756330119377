import { Table, useOnScrollDown } from 'redesign';
import { DEFAULT_PAGE_SIZE } from 'shared/utils/constants';
import { RenderUnsettledUI } from 'shared/components';
import { Box } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import {
  INITIAL_PAGE,
  TableShiftPlanningColumnDefinition,
  TableShiftPlanningOptions,
  TableShiftPlanningProps,
} from './shift-planning-table.types';

import { useGetTableShiftPlanningShifts } from './hooks/use-get-table-shift-planning-shifts';
import { getTableShiftPlanningData } from './utils/get-table-shift-planning-data';
import { getShiftPlanningColumnDefinition } from './utils/get-table-shift-planning-column-definition';
import { getSelectedRows } from './utils/get-selected-row-ids';
import { usePlanningParamsContext } from '../../providers/planning-params-provider';

type TableShiftPlanningProviderProps = {
  onSelectionChange: (selectedIds: string[]) => void;
  selectedShiftIds: string[];
};

export const TableShiftPlanningProvider = ({
  onSelectionChange,
  selectedShiftIds,
}: TableShiftPlanningProviderProps) => {
  const { filters } = usePlanningParamsContext();

  const shiftsQuery = useGetTableShiftPlanningShifts({
    fromDate: filters.fromDate?.toFormat('yyyy-MM-dd') ?? DateTime.now().toFormat('yyyy-MM-dd'),
    toDate: filters.toDate?.toFormat('yyyy-MM-dd'),
    branchOfficeId: filters.branchOfficeId,
    departmentId: filters.departmentId,
    pageParam: INITIAL_PAGE,
    pageSizeParam: DEFAULT_PAGE_SIZE,
    jobType: filters.jobType,
    hasApplicants: filters.hasApplicants,
    filledStatus: filters.filledStatus,
    partnerId: filters.partner,
    isFreelance: filters.isFreelance,
    isTempWork: filters.isTempWork,
  });

  if (shiftsQuery.status !== 'success') {
    return <RenderUnsettledUI data={shiftsQuery} />;
  }

  return (
    <ShiftPlanningTable
      shiftsQuery={shiftsQuery}
      selectedShiftIds={selectedShiftIds}
      onSelectionChange={onSelectionChange}
    />
  );
};

const ShiftPlanningTable = ({
  shiftsQuery,
  onSelectionChange,
  selectedShiftIds,
}: TableShiftPlanningProps) => {
  const flatData = useMemo(
    () => shiftsQuery.data.pages.flatMap((page) => page.items),
    [shiftsQuery.data]
  );
  const tableData = useMemo(() => getTableShiftPlanningData(flatData), [flatData]);
  const columns = useMemo(() => getShiftPlanningColumnDefinition(), []);
  const { fetchIfBottomScrolled } = useOnScrollDown({
    infiniteQueryResult: shiftsQuery,
    flatData: flatData,
  });
  const { setFilters } = usePlanningParamsContext();
  const selectedRows = getSelectedRows(flatData, selectedShiftIds);

  const handleSelectionChange = useCallback(
    (rows: TableShiftPlanningColumnDefinition[]) => {
      const selectedIds = rows.map((row) => row.id);
      onSelectionChange(selectedIds);
    },
    [onSelectionChange]
  );

  return (
    <Box minHeight={0}>
      <Table<TableShiftPlanningColumnDefinition, TableShiftPlanningOptions>
        stickyHeader={true}
        columns={columns}
        data={tableData}
        tableStyle={{ tableLayout: 'fixed' }}
        onScroll={fetchIfBottomScrolled}
        onShowDetail={(shiftId: string) => {
          setFilters({ shiftId });
        }}
        data-testid="shift-planning-virtual-table"
        onSelectionChange={handleSelectionChange}
        selectable={true}
        selectedRows={selectedRows}
      />
    </Box>
  );
};
